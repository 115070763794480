<template>
  <div class="tag">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheCampaignCardTagsItem'
}
</script>

<style lang="scss">
.tag {
  font-weight: 400;
  line-height: 14px;
  padding: 8px 10px;
  font-size: 12px;
  color: #62646A;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  border: 1px solid #D3D2D2;
  cursor: default;
  white-space: nowrap;
  background: #fff;

  &:hover {
    background: #EDFCFB;
  }
}
</style>
