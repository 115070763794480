
import { Mixins, Component, Watch } from 'vue-property-decorator'
import { PanelType, RootSteps, LinkType } from '../../types'

import personalSpendLimitProp from '../mixins/personalSpendLimitProp'
import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import ShowErrorMessage from '../mixins/showErrorMessage'
import PreviewCampaign from '@/mixins/previewCampaign'
import recipientsProp from '../mixins/recipientsProp'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import SendCampaign from '../mixins/sendCampaign'
import formProp from '../mixins/formProp'
import Api from '@/axios/api'

import TheCampaignCardImages from '@/components/myCampaign/TheCampaignCardImages.vue'

interface ICampaignCosts {
  fee: number | null;
  fee_per_unit: number | null;
  fee_rate: number | null;
  fee_type: 'quantity' | 'percent' | 'fixed';
  gift_card_fee: number | null;
  gift_card_fee_per_unit: number | null;
  gift_card_fee_rate: number | null;
  shipping: number | null;
  shipping_per_unit: number | null;
  subtotal: number | null;
  subtotal_per_unit: number | null;
  dropship_fee: number | null;
  dropship_fee_per_unit: number | null;
  tax: number | null;
  tax_rate: number | null;
  total: number | null;
}

@Component({
  components: {
    TheCampaignCardImages,
  },
})
export default class SendEgiftPanelReviewSummary extends Mixins(
  personalSpendLimitProp,
  recipientMethodProp,
  approvalDataProps,
  ShowErrorMessage,
  PreviewCampaign,
  recipientsProp,
  SendCampaign,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  loading = false;

  loadingCosts = false;

  campaignCosts: ICampaignCosts = {
    fee: 0,
    fee_per_unit: 0,
    fee_rate: 0,
    fee_type: 'quantity',
    gift_card_fee: 0,
    gift_card_fee_per_unit: 0,
    gift_card_fee_rate: 0,
    shipping: 0,
    shipping_per_unit: 0,
    subtotal: 0,
    subtotal_per_unit: 0,
    dropship_fee: 0,
    dropship_fee_per_unit: 0,
    tax: 0,
    tax_rate: 0,
    total: 0,
  }

  get campaignName(): string {
    return this.campaign?.name ?? ''
  }

  get availableItemsInGift () {
    const { egift_settings: settings, collection: { items } } = this.campaign
  
    const availableItems = items?.filter((item) => {
      return !item.product.qty.is_out_of_stock
    })

    if (settings?.egift_type === 'coin') {
      return availableItems.filter(item => item.product.price <= Number(settings?.coin_value || 0))
    }

    return availableItems
  }

  get egiftFeeLabel(): string {
    const { fee_rate, fee_type } = this.campaignCosts
    return fee_type === 'percent' ? `${fee_rate}% eGift fee` : 'eGift fee'
  }

  // total costs
  get giftMaxCost(): number {
    return this.campaignCosts?.subtotal ?? 0
  }

  get giftMaxShipping(): number {
    return this.campaignCosts?.shipping ?? 0
  }

  get giftSalesTax(): number {
    return this.campaignCosts?.tax ?? 0
  }

  get dropshipFeeTotal(): number {
    return this.campaignCosts?.dropship_fee ?? 0;
  }

  get egiftFeeTotal(): number {
    return this.campaignCosts?.fee ?? 0
  }

  get giftCardFeeTotal(): number {
    return this.campaignCosts?.gift_card_fee ?? 0
  }

  get giftTotalCost(): number {
    return this.campaignCosts?.total ?? 0
  }

  get ctaButtonName(): string {
    const { checkoutNeedsApproval, numberOfRecipients, panelType } = this

    return checkoutNeedsApproval
      ? 'Continue' : panelType === PanelType.Link
        ? numberOfRecipients > 1
          ? 'Create links'
        : 'Create link'
      : 'Send'
  }

  @Watch('giftTotalCost')
  onGiftTotalCostChange (val: number) {
    this.$emit('update:campaignTotalCost', val)
  }

  @Watch('giftMaxShipping')
  onGiftShippingChange (val: number) {
    this.$emit('update:campaignShippingCost', val)
  }

  mounted () {
    this.calculatePrices()
  }

  showPreview(): void {
    const { form, campaign, panelType } = this

    const calendlyLink = form.actionLinkType === 'calendly' ? form.calendlyLink : undefined

    this.previewCampaign({
      campaignId: campaign.entity_id,
      logoUrl: form.logoUrl || undefined,
      recipients: [],
      senderName: form.giftGiverName || undefined,
      subjectLine: form.emailSubjectLine || undefined,
      greetingMessage: form.greeting || undefined,
      calendlyLink: calendlyLink,
      videoUrl: form.videoGreeting || undefined,
      freeFormButton: calendlyLink ? undefined : form.freeForm.buttonText,
      freeFormUrl: calendlyLink ? undefined : form.freeForm.link,
      canUpgrade: form.settings.recipientCanUpgrade,
      canRegift: form.settings.recipientCanReEgift,
      hideEmailStep: panelType === PanelType.Link,
      linkType: form.linkType,
      welcomeMessage: form.linkType !== LinkType.StandardLink ? form.welcomeMessage : undefined
    })
  }

  showRefundPolicy(): void {
    this.$emit('update:step', RootSteps.RefundPolicy)
  }

  showDepositInfo(): void {
    this.$emit('update:step', RootSteps.DepositInfo)
  }

  calculatePrices(): void {
    const { campaign: { entity_id: id }, numberOfRecipients  } = this

    this.loadingCosts = true

    Api.get<{ number_of_recipients: number }, ICampaignCosts>(`campaigns/send-egift/${id}/calculate`, {
      params: { number_of_recipients: numberOfRecipients }
    })
      .then((costs) => (this.campaignCosts = costs))
      .finally(() => (this.loadingCosts = false))
  }

  validationChecker (): boolean {
    let result = true

    const {
      panelType,
      form: {
        greeting,
        welcomeMessage,
        linkType,
        emailSubjectLine,
        giftGiverName,
        giftGiverEmail,
      },
    } = this

    const message = greeting?.replaceAll('\n', '')

    if (panelType === PanelType.EGift) {
      if (!emailSubjectLine) {
        this.showErrorMessage('Email subject line is required')
        this.findReviewFieldWithLabel('Email subject line')
        result = false
      }

      if (!message) {
        this.showErrorMessage('Greeting Message is required')
        this.findReviewFieldWithLabel('Greeting')
        result = false
      }
    }

    if (panelType === PanelType.Link) {
      if (linkType === LinkType.SingleLink && !welcomeMessage) {
        this.showErrorMessage('Welcome message is required')
        this.findReviewFieldWithLabel('Welcome message')
        result = false
      }
    }

    if (!giftGiverName) {
      this.showErrorMessage('Gift giver name is required')
      this.findReviewFieldWithLabel('Gift giver name')
      result = false
    }

    if (!giftGiverEmail) {
      this.showErrorMessage('Gift giver email is required')
      this.findReviewFieldWithLabel('Gift giver email')
      result = false
    }

    return result
  }

  handleSendCampaign(): void {
    if (!this.validationChecker()) { return }

    if (this.checkoutNeedsApproval) {
      this.$emit('update:step', RootSteps.Approval)
      return
    }

    this.sendCampaign()
  }

  findReviewFieldWithLabel(labelName: string): void {
    const elements = this?.$parent?.$el.querySelectorAll<HTMLDivElement>('.review-field__label')

    if (elements?.length) {
      let elementToFind: HTMLDivElement | undefined = undefined;

      for (const element of elements) {
        if (element.textContent?.includes(labelName)) { elementToFind = element }
      }

      if (elementToFind) { elementToFind.classList.add('review-field__label--error') }
    }
  }
}

