<template>
  <div
    class="settings-panel-variants-option"
    :class="{
      'settings-panel-variants-option--selected': isSelected,
      'settings-panel-variants-option--disabled': disabled
    }"
    @click="!disabled ? $emit('input', itemValue) : null"
  >
    <div class="settings-panel-variants-option__content">
      <icons-check-circle
        :filled="isSelected"
        :checkStrokeWidth="1.8"
        :hideCheck="!isSelected"
      />

      <div class="content-title">
        <slot name="title" />
      </div>

      <div class="content-title">
        How it works:
      </div>

      <ul>
        <li :key="index" v-for="(item, index) in items">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="settings-panel-variants-option__video">
      <span @click.stop="$emit('showExampleVideo')">See example video</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsPanelVariantsOptionItem',
  props: {
    value: {
      type: String,
      required: false
    },
    itemValue: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  computed: {
    isSelected() {
      const { value, itemValue } = this
      return value === itemValue
    }
  },
}
</script>

<style lang="scss" scoped>
.settings-panel-variants-option {
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  padding: 24px 24px 30px;
  max-width: 378px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;

  &--selected {
    border-color: #42B77A;
  }

  &--disabled {
    cursor: default;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > .content-title {
      font-size: 15px;
      line-height: 18px;
      color: #000;
      font-weight: 700;
    }

    & > ul {
      font-size: 15px;
      line-height: 18px;
      color: #95979D;
      font-weight: 400;
      font-style: italic;

      & > li {
        list-style: disc;
        font-family: inherit;
      }
    }
  }

  &__video {
    & > span {
      font-weight: 400;
      font-style: italic;
      font-size: 15px;
      line-height: 18px;
      cursor: pointer;
      color: #42B77A;
    }
  }
}
</style>
