<template>
  <router-link
    class="create-campaign-header-gifts-icon"
    aria-label="See selected products"
    :aria-disabled="disabled ? 'true' : 'false'"
    :tabindex="disabled ? -1 : 0"
    :to="{ name: 'CreateCampaignSourceReview', params: $route.params }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 33"
      fill="none"
      width="32"
      height="33"
    >
      <path
        d="M5.75387 28.6339V14.3877H3.8667V8.2391H9.9616C9.77355 7.97757 9.63465 7.68937 9.5449 7.3745C9.45517 7.05964 9.4103 6.72902 9.4103 6.38267C9.4103 5.38554 9.7593 4.53797 10.4573 3.83997C11.1553 3.14197 12.0029 2.79297 13 2.79297C13.5841 2.79297 14.1259 2.92801 14.6253 3.1981C15.1247 3.46819 15.5573 3.82717 15.9231 4.27503C16.2667 3.8357 16.6953 3.47886 17.209 3.2045C17.7226 2.93015 18.2684 2.79297 18.8461 2.79297C19.8433 2.79297 20.6909 3.14197 21.3889 3.83997C22.0869 4.53797 22.4359 5.38554 22.4359 6.38267C22.4359 6.70062 22.382 7.02071 22.2743 7.34294C22.1666 7.66516 22.0367 7.96388 21.8846 8.2391H28.1333V14.3877H26.2461V28.6339H5.75387ZM18.8461 4.52624C18.3202 4.52624 17.8793 4.70415 17.5234 5.05997C17.1676 5.41579 16.9897 5.85669 16.9897 6.38267C16.9897 6.90867 17.1676 7.34957 17.5234 7.70537C17.8793 8.06119 18.3202 8.2391 18.8461 8.2391C19.3721 8.2391 19.813 8.06119 20.1688 7.70537C20.5247 7.34957 20.7026 6.90867 20.7026 6.38267C20.7026 5.85669 20.5247 5.41579 20.1688 5.05997C19.813 4.70415 19.3721 4.52624 18.8461 4.52624ZM11.1436 6.38267C11.1436 6.90867 11.3215 7.34957 11.6773 7.70537C12.0331 8.06119 12.474 8.2391 13 8.2391C13.526 8.2391 13.9669 8.06119 14.3227 7.70537C14.6785 7.34957 14.8564 6.90867 14.8564 6.38267C14.8564 5.85669 14.6785 5.41579 14.3227 5.05997C13.9669 4.70415 13.526 4.52624 13 4.52624C12.474 4.52624 12.0331 4.70415 11.6773 5.05997C11.3215 5.41579 11.1436 5.85669 11.1436 6.38267ZM5.6 9.9724V12.6545H15.1334V9.9724H5.6ZM15.1334 26.9006V14.3877H7.48713V26.9006H15.1334ZM16.8666 26.9006H24.5129V14.3877H16.8666V26.9006ZM26.4 12.6545V9.9724H16.8666V12.6545H26.4Z"
        fill="currentColor"
      />
    </svg>

    <div class="create-campaign-header-gifts-icon__badge" :style="`--badge-color: ${badgeColor}`">
      {{ numberOfProducts }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CreateCampaignHeaderGiftsIcon',
  props: {
    numberOfProducts: {
      type: Number,
      required: true,
    },
  },
  computed: {
    disabled () { return this.numberOfProducts < 1 },
    badgeColor () {
      const { disabled } = this
      return disabled ? '#95979D' : '#6E50FF'
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-header-gifts-icon {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-end;
  width: 43px;
  position: relative;
  transition: color ease-out 0.2s;
  color: #222325;

  &__badge {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 48px;
    padding: 0 4px;
    height: 22px;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    line-height: 16px;
    font-size: 14px;
    font-weight: 700;
    min-width: 22px;

    background-color: var(--badge-color, #95979D);
  }

  &[aria-disabled="true"] {
    cursor: default;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: #22232599;
  }
}
</style>
