<template>
  <div class="create-campaign-review-card__text-wrapper">
    <div class="create-campaign-review-card__text">
      <b><slot name="label" /></b>

      <template v-if="!edit">
        <slot />
      </template>
    </div>

    <review-textarea
      v-if="edit"
      v-model="modelValue"
      :rules="rules"
      @exitEditMode="edit = false"
    />

    <button v-else @click="edit = true">
      Edit
    </button>
  </div>
</template>

<script>
import ReviewTextarea from '../myCampaign/panels/sendEgift/components/ReviewTextarea.vue'

export default {
  name: 'CreateCampaignReviewCardEditInput',
  components: { ReviewTextarea },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [v => !!v || ''],
    }
  },
  data: () => ({
    edit: false,
  }),
  computed: {
    modelValue: {
      get () { return this.value },
      set (val) { this.$emit('input', val) },
    }
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-review-card__text-wrapper {
  align-items: center;

  & > button {
    border: none;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    line-height: 21.28px;
    font-weight: 400;
    font-size: 16px;
    color: #6E50FF;
  }
}
</style>
