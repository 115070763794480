<template>
  <v-col cols="12" class="tags-warpper">
    <template v-for="(tag, index) in tagsToDispaly">
      <the-campaign-card-tags-item :key="index">
        {{ tag }}
      </the-campaign-card-tags-item>
    </template>

    <div class="more-tag-wrapper">
      <div class="more-tag-wrapper__tooltip">
        {{ unusedTags.join(', ') }}
      </div>
      <the-campaign-card-tags-item v-if="displayMoreTag">
        More...
      </the-campaign-card-tags-item>
    </div>
  </v-col>
</template>

<script>
import TheCampaignCardTagsItem from './TheCampaignCardTagsItem.vue'

export default {
  name: 'TheCampaignCardTags',
  components: {
    TheCampaignCardTagsItem
  },
  props: {
    tags: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data: () => ({
    wrapperWidth: 0,
    usedSpaceToRenderTags: 0,
    moreTagWidth: 60,
    renderedTagsIndexes: [],
    renderedTagsWidths: []
  }),
  computed: {
    tagsToDispaly () {
      const { tags, renderedTagsIndexes, displayMoreTag, wrapperWidth, usedSpaceToRenderTags, moreTagWidth } = this
      const filteredTags = tags.filter((item, index) => renderedTagsIndexes.includes(index))
      if (displayMoreTag) {
        if (wrapperWidth < (usedSpaceToRenderTags + moreTagWidth)) {
          const tagIndex = this.getTagIndexThatIsMatchingToMoreTagWith()
          filteredTags.splice(tagIndex, 1)
        }
      }
      return filteredTags
    },
    displayMoreTag () {
      const { tags, renderedTagsIndexes } = this
      return tags.length !== renderedTagsIndexes.length
    },
    unusedTags () {
      const { tags, renderedTagsIndexes } = this
      return tags.filter((item, index) => !renderedTagsIndexes.includes(index))
    }
  },
  mounted () {
    const { tags } = this
    // create element to calculate tags width
    const div = document.createElement('div')
    div.classList.add('tag')
    // set tag style to hide it on site
    div.style.position = 'absolute'
    div.style.visibility = 'hidden'
    div.style.height = 'auto'
    div.style.width = 'auto'
    document.body.appendChild(div)
    // take this wrapper width
    this.wrapperWidth = this.$el.clientWidth

    tags.reduce((acc, item, index) => {
      div.innerText = item
      const tagWidth = div.offsetWidth
      if ((acc + tagWidth) <= this.wrapperWidth) {
        // add to acc tagWidth and gap size
        acc += tagWidth + 5
        this.usedSpaceToRenderTags = acc
        this.renderedTagsIndexes.push(index)
        this.renderedTagsWidths.push(tagWidth)
      }

      return acc
    }, 0)
    // remove element to calculate tag width
    document.body.removeChild(div)
  },
  methods: {
    getTagIndexThatIsMatchingToMoreTagWith () {
      const { renderedTagsWidths, moreTagWidth } = this
      const tagIndex = renderedTagsWidths
        .findIndex((tag) =>
          tag === moreTagWidth || (tag >= moreTagWidth - 10 && tag <= moreTagWidth + 10)
        )
      this.renderedTagsIndexes.splice(tagIndex, 1)
      this.renderedTagsWidths.splice(tagIndex, 1)
      return tagIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-warpper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 5px;
  width: 100%;
}

.more-tag-wrapper {
  position: relative;
  cursor: pointer;
  width: fit-content;
  // TODO DEBUG
  &__tooltip {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    left: -100%;
    bottom: calc(100% + 7px);
    max-width: 180px;
    border-radius: 4px;
    padding: 6px 10px;
    z-index: 4;
    background: #4A4A4A;
    font-weight: 400;
    line-height: 17px;
    font-size: 13px;
    color: #fff;
    white-space: break-spaces;
    width: 180px;

    &::before {
      width: 12px;
      height: 12px;
      background: inherit;
      position: absolute;
      transform: rotate(45deg);
      left: calc(50% - 6px);
      content: '';
      top: calc(100% - 7px);
      z-index: -1;
    }
  }

  &:hover > &__tooltip {
    display: flex;
  }
}
</style>
