<template>
  <div
    class="input-wrapper"
    :class="customPlaceholder && 'input-wrapper--columns'"
  >
    <textarea v-model="inputValue" />

    <icons-download-invoice
      v-if="!customPlaceholder"
      width="32"
      height="41"
    />

    <slot />
  </div>
</template>

<script>
export default {
  name: 'ThePasteRecipientsInputWrapper',
  props: {
    value: {
      type: String,
      required: false
    },
    customPlaceholder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    focuesTextField () {
      this.$el.querySelector('textarea')?.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 298px;
  padding: 50px 40px;
  background-color: #F4FFFE;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 11px;
  line-height: 13px;
  color: #9F9F9F;
  text-transform: uppercase;

  &--columns {
    padding: 0px;
  }

  svg {
    margin-bottom: 14px;
  }

  textarea {
    position: absolute;
    resize: none;
    inset: 0;
    height: 100%;
    width: 100%;
    color: transparent;
    border: 4px solid transparent;
    font-size: 36px;
    z-index: 2;

    &:focus {
      outline: 2px solid #219358;
      color: #000;
    }
  }
}
</style>
