<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="1100px"
    class="units-on-hold-panel"
  >
    <div class="units-on-hold-panel__title">
      <div>
        <b>{{ numberOfUnitsOnHold }}</b> Units on-hold
      </div>

      <icons-close color="#62646A" @click.native.stop="show = false" />
    </div>

    <div v-if="product" class="units-on-hold-panel__content-wrapper">
      <div class="units-on-hold-panel__product-info">
        <product-image-display
          :altText="productName"
          :image="productImage"
          :center-horizontal="false"
          :height="120"
          :width="120"
        />

        <div>
          <div>{{ productName }}</div>
          <div v-if="productSku">{{ productSku }}</div>
        </div>
      </div>
      <v-data-table
        :headers="onHoldHeaders"
        :items="unitOnHoldList"
        :items-per-page="999"
        :loading="loading"
        :loader-height="0"
        :sort-by="['date']"
        :sort-desc="[true]"
        item-key="id"
        hide-default-footer
        @click:row="handleRowClick"
      >
        <template #item.campaign="{ item }">
          {{ getCampaignName(item.campaign) }}
        </template>
        <template #loading>
          <common-loader style="padding: 100px 0" />
        </template>
      </v-data-table>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import { historyUnitsOnHold } from '@/assets/headers'
import panelVModel from '@/mixins/panelVModel'

import ProductImageDisplay from '../ProductImage.vue'

export default {
  name: 'UnitsOnHoldPanel',
  mixins: [panelVModel],
  components: { ProductImageDisplay },
  props: {
    product: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    loading: false,
    unitOnHoldList: [],
    onHoldHeaders: historyUnitsOnHold,
  }),
  computed: {
    numberOfUnitsOnHold () {
      return this.unitOnHoldList.reduce((acc, order) => acc += (order?.units_on_hold ?? 0), 0)
    },
    productName () {
      return this.product?.name ?? null
    },
    productImage () {
      return this.product?.image ?? null
    },
    productSku () {
      return this.product?.sku ?? null
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true
        const productId = this?.product?.item_id ?? null

        Api.get(`/customer/purchased-inventory/${productId}/egift-orders`)
          .then(({ data }) => {
            this.unitOnHoldList = data
          })
          .catch(() => (this.unitOnHoldList = []))
          .finally(() => (this.loading = false))
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    getCampaignName (campaign) {
      return campaign?.name ?? null
    },
    handleRowClick ({ order_id, egift_id }) {
      const route = this.$router.resolve({
        name: egift_id ? 'EgiftOrder' : 'MyOrder',
        params: { id: egift_id || order_id }
      });

      window.open(route.href);
    },
  },
}
</script>

<style lang="scss" scoped>
.units-on-hold-panel {
  padding: 50px;

  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &::v-deep .v-data-table .v-data-table__wrapper .v-data-table-header th {
    border-right: none;
    height: 34px;
    text-transform: uppercase;
  }

  &__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    & > div {
      font-weight: 300;
      font-size: 40px;
      line-height: 44px;
      color: #000000;

      & > b {
        font-weight: 700;
      }
    }

    & > svg {
      cursor: pointer;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__product-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 14px;

    & > div:nth-child(1) {
      max-width: 120px;
      max-height: 120px;
    }

    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > div {
        &:nth-child(1) {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #95979D;
        }
      }
    }
  }
}
</style>
