<template>
  <div class="image-card">
    <div class="image-card__container">
      <img v-if="displayAsImage" :src="image" :alt="title">

      <embed v-else :src="image" :title="title">

      <div class="image-card__wrapper">
        <div class="image-card__default">
          <icons-check-circle
            v-if="isDefault"
            width="22"
            height="22"
            outline-stroke-width="1"
            :filled="isDefault"
            fillColor="#fff"
          />
        </div>

        <v-menu
          max-width="190"
          open-on-click
          offset-y
          bottom
          left
          tile
          z-index="4"
          nudge-right="7"
          content-class="image-card__menu"
        >
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="image-card__options">
              <icons-options class="pointer" />
            </div>
          </template>
          <v-list dense>
            <v-list-item dense @click="editTitle()">
              <v-list-item-icon class="d-flex align-center">
                <icons-edit width="13" height="13" />
              </v-list-item-icon>
              <v-list-item-title>
                Edit caption
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="setDefault()">
              <v-list-item-icon class="d-flex align-center">
                <icons-check-circle
                  width="15"
                  height="15"
                  outline-stroke-width="1"
                  outline-color="#9B9B9B"
                  outline-check-color="#9B9B9B"
                />
              </v-list-item-icon>
              <v-list-item-title>
                Make default image
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="removeImage()">
              <v-list-item-icon class="d-flex align-center">
                <icons-delete width="14" height="17" />
              </v-list-item-icon>
              <v-list-item-title>
                Delete image
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <template>
      <div
        v-if="!editMode"
        class="image-card__title"
        :class="{ 'image-card__title--original-name': !title }"
      >
        {{ title || originalName }}
      </div>

      <div v-else class="image-card__title--form">
        <v-text-field
          v-model="newTitle"
          height="36"
          hide-details
          class="ma-0 pa-0"
          ref="title-input"
          color="black"
          solo
          flat
          @blur="exitEditTitle()"
          @keydown.tab.prevent="updateTitle()"
          @keydown.enter.prevent="updateTitle()"
        >
          <template #append>
            <div
              class="d-flex pointer"
              @click="updateTitle()"
            >
              <icons-check-circle
                width="20"
                height="20"
                outline-color="#42B77A"
                outline-check-color="#42B77A"
                outline-stroke-width="1"
              />
            </div>
          </template>
        </v-text-field>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TheImageCard',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    originalName: {
      type: String,
      required: false,
      default: null
    },
    isDefault: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    editMode: false,
    newTitle: null
  }),
  computed: {
    displayAsImage () {
      const { image } = this;
      // display broken image
      if (!image) { return true; }
      const [extension] = image.match(/\.\w{3,4}($|\?)/gmi)
      // display broken image
      if (!extension) { return true; }
      return ['.jpg', '.jpeg', '.png', '.gif'].includes(extension);
    }
  },
  created () {
    this.setTitleInput()
  },
  methods: {
    setTitleInput () {
      if (this.title) this.newTitle = this.title
    },
    removeImage () {
      const { id } = this
      this.$emit('remove', id)
    },
    editTitle () {
      this.editMode = true
      this.setTitleInput()
      setTimeout(() => {
        this.$refs['title-input'].focus()
      }, 0)
    },
    exitEditTitle () {
      this.editMode = false
      this.newTitle = null
    },
    updateTitle () {
      this.$emit('update:title', this.newTitle)
      this.$emit('updateTitle', this.id, this.newTitle)
      this.exitEditTitle()
    },
    setDefault () {
      this.$emit('setDefault', this.id)
    }
  }
}
</script>

<style lang="scss">
.image-card__title--form {
  .v-input__slot {
    border: none !important;
  }
}

.image-card__menu {
  padding: 0px !important;

  .v-list {
    padding: 8px 0px;

    &-item {
      padding: 0 20px !important;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      &--dense {
        min-height: 30px;
      }

      &__icon {
        margin: 3px 4px 3px 0px !important;
      }

      &__title {
        font-weight: 400;
        font-size: 14px !important;
        color: #16181F !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.image-card {
  width: 150px;
  min-width: 150px;
  min-height: 150px;

  &__container {
    height: 150px;
    max-height: 150px;
    width: 150px;
    max-width: 150px;
    border: 1px solid #E4E4E4;
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 0 auto;

    & > img,
    & > embed {
      object-fit: contain;
      max-width: 150px;
    }
  }

  .image-card__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 7px;
    position: absolute;
    flex: 1 1 auto;
    width: 100%;
  }

  &__default, &__options {
    height: 23px;
  }

  &__title {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #16181F;
    padding-top: 8px;
    cursor: default;

    &--add {
      font-style: italic;
      color: #A1A1A1;
      line-height: 18px;
    }

    &--form {
      border: 1px solid #E4E4E4;
      border-top: none;

      .v-text-field {
        border-radius: 0 !important;

        & > .v-input__slot {
          border: none !important;
        }
      }
    }

    &--original-name {
      color: #62646A;
      word-break: break-word;
      font-style: italic;
    }
  }
}
</style>
