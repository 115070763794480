<template>
  <v-row class="mx-0 justify-space-between px-3 my-4 product-card flex-nowrap">
    <v-col class="px-0" style="max-width: 164px">
      <the-product-card-images
        :images="product.product_images"
        v-if="product.egift_count < 2"
      />
    </v-col>
    <div
      v-if="this.$store.state.selectedOrders.length < 2"
      class="d-flex flex-column product-card_product-info mx-4 my-3 mr-0"
    >
      <span
        class="f14 black1 lato-bold"
        style="width: 350px; max-width: 350px;"
        v-if="!!product.is_coins_gift"
      >
        {{ product.product_name }} (GiftCoin eGift)
      </span>
      <span
        class="f20 black1 lato-bold"
        :class="!product.is_campaign && 'f14'"
        style="width: 350px; max-width: 350px;"
        v-else
      >
        {{ product.product_name }} {{ product.selectable_items_count > 1 ? `(${product.selectable_items_count} items)` : null }}
      </span>
      <span
        class="f12 black1"
        v-if="!product.is_campaign"
      >
        SKU: {{ product.product_sku }}
      </span>
      <update-status-sheet
        v-if="isPreProduction"
        color="#ECD884"
        text-color="#222325"
        class="my-2"
        v-tooltip="{
              content: `Items will be ordered and produced once all eGift recipients have made their selections,
                or when the campaign ends. Track redemptions and details through the &quot;My eGift orders&quot;
                on your dashboard`,
          ...standardTooltipProps,
        }"
      >
        Zero waste
      </update-status-sheet>

      <update-status-sheet
        v-if="isAutomated"
        color="#E5DFFF"
        text-color="#4E3AAF"
        class="my-2"
      >
        Automated
      </update-status-sheet>

      <div class="mt-auto d-flex flex-row g10">
        <common-tooltip v-if="product.message" top :max-width="450">
          <template #activator>
            <div class="product-card__link">
              <v-img src="/images/greetings_small1.svg" max-width="15px" max-height="18px" />
              Greeting message
            </div>
          </template>

          {{ product.message }}
        </common-tooltip>

        <common-tooltip top :max-width="300" :z-index="8">
          <template #activator>
            <div class="product-card__link">
              <icons-logo-preview color="#000" :width="18" :height="18" />
              Logo
            </div>
          </template>

          <img
            v-if="product.logo_url"
            :src="product.logo_url"
            alt=""
            height="200"
            width="200"
            style="object-fit: contain"
          >
          <template v-else>No logo attached to this eGift</template>
        </common-tooltip>
      </div>
    </div>
    <div
      :class="this.$store.state.selectedOrders.length > 1 ?
        'd-flex flex-column ml-1 mr-3 my-3 product-card_qty' :
        'd-flex flex-column ml-0 mr-3 my-3 product-card_qty'
      "
    >
      <span class="f14 gray0 lato-bold uppercase">
        QTY
      </span>
      <span
        class="product-card_product-info_description mt-3"
        v-if="product.recipient_count > 1"
      >
        <span class="black1 f14">
        <b>Total: </b>{{ product.recipient_count }}
        <br>
        <b>Redeemed: </b>{{ product.recipient_redeem_count }}
        </span>
        <br>
        <span class="red0"><b>Not redeemed:</b> {{ product.recipient_not_redeem_count }}</span>
      </span>
      <span class="product-card_product-info_description mt-3" v-else>
        <span class="black1 f14">
          <b>Total: </b>1
          <br>
          <span v-if="product.recipient_redeem_count > 0"><b>Redeemed</b></span>
          <br v-if="product.recipient_redeem_count > 0">
          <span class="red0" v-if="product.recipient_not_redeem_count > 0"><b>Unredeemed</b></span>
        </span>
      </span>
    </div>
    <div :class="this.$store.state.selectedOrders.length > 1 ? 'd-flex flex-column ml-auto pr-5 py-3 br1' : 'd-flex flex-column ml-3 pr-5 py-3 br1'">
      <span class="f14 gray0 lato-bold uppercase">ESTIMATED PRICE</span>
      <div class="product-card_product-info_description mt-3 ml-auto">
        <span>{{ product.estimated | priceFilter }}</span>
      </div>
    </div>
    <div
      width="320px"
      class="d-flex flex-column ml-3 my-3 pt-2"
    >
      <v-row class="mx-0 px-0 py-0">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="px-0 py-0 product-card_actual_title">
            ACTUAL COST
          </v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ product.subtotal | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mx-0 px-0 py-0">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="px-0 py-0 product-card_actual_title">
            shipping & handling
          </v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ product.shipping | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row v-if="dropshipFeeTotal" class="mx-0 pa-0">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="pa-0 product-card_actual_title">Dropship fee</v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ dropshipFeeTotal | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mx-0 px-0 py-0">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="px-0 py-0 product-card_actual_title">
            TAX
          </v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ product.tax | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mx-0 pa-0" v-if="collectionFee && collectionFeeAmount">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="pa-0 product-card_actual_title">
            {{ collectionFeeTitle }}
          </v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ collectionFeeAmount | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mx-0 pa-0" v-if="giftHasCardFee">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="pa-0 product-card_actual_title">GIFT CARD FEE</v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ giftCardFee | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mx-0 px-0 py-0" v-if="showSetupFee">
        <v-col cols="12" class="d-flex px-0 py-0 product-card_actual">
          <v-col class="px-0 py-0 product-card_actual_title">
            SETUP FEE
          </v-col>
          <v-col cols="3" class="px-0 py-0 text-end product-card_actual_price">
            {{ product.setup_fee | priceFilter }}
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mx-0 px-0 py-0 mt-3 justify-space-between product-card_total">
        <span>ACTUAL TOTAL COST</span>
        <span>{{ product.total | priceFilter }}</span>
      </v-row>
    </div>
  </v-row>
</template>

<script>
import standardTooltipProps from '@/mixins/standardTooltipProps'

import TheProductCardImages from './myEgift/TheProductCardImages.vue'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'

export default {
  name: 'product-card',
  mixins: [standardTooltipProps],
  components: {
    TheProductCardImages,
    UpdateStatusSheet,
  },
  props: {
    product: Object
  },
  data: () => ({
    showSetupFee: false
  }),
  computed: {
    giftCardFee () {
      return this.product.gift_card_fee_amount
    },
    giftHasCardFee () {
      return !!Number(this.giftCardFee)
    },
    collectionFee () {
      return this.product?.fee ?? 0
    },
    collectionFeeAmount () {
      return this.product?.fee_amount ?? 0
    },
    dropshipFeeTotal () {
      const { dropship_fee_amount } = this.product ?? {}
      return Number(dropship_fee_amount || 0)
    },
    collectionFeeType () {
      // fixed || percent
      return this.product?.fee_type ?? 'fixed'
    },
    collectionFeeTitle () {
      const { collectionFee, collectionFeeType } = this
      return collectionFeeType === 'percent' ? `FEE ${collectionFee}%` : 'eGIFT FEE'
    },
    isPreProduction () {
      return this.product?.is_pre_production ?? false
    },
    isAutomated () {
      return this.product?.is_automated ?? false
    },
  },
  watch: {
    product (val) {
      this.showSetupFee = val.setup_fee !== '0.00'
    }
  },
  methods: {
    getImagePath () {
      const { product_image: image, cover } = this.product
      if (cover) return cover
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    }
  }
}
</script>

<style lang="scss">
.product-card {
  border: 1px solid #e6e6e6;
  color: #000;

  &_product-info {

    &_title {
      text-transform: uppercase;
      color: #b8b8b8;
      font-size: 14px;
      font-weight: 700;
    }

    &_description {
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;
    }

    &_greet-msg {

      &_btn {
        font-weight: 700;
        font-size: 14px;
        color: #00a86b;
        padding-top: 2px;
        padding-left: 2px;
        cursor: pointer;

        .greeting-message-tooltip {
          visibility: hidden;
          color: black;
          background: white;
          border-radius: 2.6px;
          position: absolute;
          top: 100px;
          box-shadow: -3px 10px 18px 0px rgba(0,0,0,0.2);
          padding: 26px 13px 38px 18px!important;
          max-width: 223px!important;

          &::before {
            content: '';
            position: absolute;
            top: 101%;
            left: 50%;
            margin-left: -60px;
            border-width: 5px;
            width: 15px;
            height: 15px;
            transform: rotate(45deg) translate(-50%, -50%);
            background: white;
          }
        }

        &:hover {
          .greeting-message-tooltip {
            visibility: visible;
          }
        }
      }
    }
  }

  &_actual {
    font-size: 14px;
    line-height: 33px;

    &_title,
    &_price {
      text-transform: uppercase;
      font-weight: 700;
    }

    &_title {
      min-width: 185px;
    }
  }

  &_total {
    border-top: 6px solid #e4e4e4;
    min-width: 322px;
    font-weight: 700;
    line-height: 40px
  }

  &__link {
    color: #42B77A;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;

    &:hover {
      color: #219358;
    }
  }
}
</style>
