<template>
  <v-container class="ma-0 px-0 py-2 success-area">
    <v-row
      class="flex-column align-center"
      v-if="!showApprovalMessage && linkType === 'standard_link'"
    >
      <v-col cols="9">
        <v-row class="justify-center align-center center-row-items">
          <v-col cols="12">
            <icons-thumb-up
              :width="32"
              :height="32"
            />
          </v-col>
          <v-col cols="12" class="success-area__thank-you">
            Thank you!
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="9">
        <v-row class="center-row-items">
          <v-col cols="12" class="success-area__order-number">
            Your order number is {{ response.order_number }}
          </v-col>
          <v-col cols="12" class="success-area__order-number-info">
            You will receive a confirmation email with your order details and a link to track its progress.
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="9" class="success-area__track-order mt-6" v-if="panelType === 'egift'">
        To view eGift redemption status, track deliveries, manage eGift orders, and view estimated
        and actual costs, visit
        <router-link
          class="green2 pointer"
          :to="{ name: 'EgiftOrders' }"
          :target="isPageInIframe && '_blank'"
        >
          My E-Gifts Orders
        </router-link>
        dashboard in your account.
      </v-col>

      <v-col cols="9" class="mt-6" v-if="panelType === 'link'">
        <v-row dense>
          <v-col cols="12" v-if="getResponseRecipientsLength() === 1">
            <the-success-area-link :linkUrl="response.recipients[0].url" />

          </v-col>

          <v-col cols="12" v-else>
            <the-success-area-file
              :recipientsResponse="response.recipients || []"
              :getTooltipTextBySlug="getTooltipTextBySlug"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="9" class="pt-12 d-flex justify-center">
        <span
          class="green2 pointer success-area__preview-egift"
          @click="$emit('showGiftPreview')"
        >
          Preview your eGift
        </span>
      </v-col>
    </v-row>

    <v-row v-else-if="!showApprovalMessage && linkType !== 'standard_link'">
      <the-success-area-single-link v-bind="{ linkType, response }" />
    </v-row>

    <v-row no-gutters v-else>
      <v-col cols="12" class="success-area__approval">
        <icons-check-circle
          filled
          :checkStrokeWidth="1.5"
          :width="30"
          :height="30"
          class="mr-4"
        />

        <div class="d-flex flex-column" style="gap: 8px">
          <span class="success-area__approval--main-text">
            The order was sent for approval, you will be notified once it is approved.
          </span>
          <span class="success-area__approval--sub-text">
            Your order number is {{ response.order_number }}
          </span>
        </div>
      </v-col>
    </v-row>

    <pre-production-dialog v-model="showPreProductionDialog" />
  </v-container>
</template>

<script>
import TheSuccessAreaLink from './TheSuccessAreaLink.vue'
import TheSuccessAreaFile from './TheSuccessAreaFile.vue'
import TheSuccessAreaSingleLink from './TheSuccessAreaSingleLink.vue'
import PreProductionDialog from './PreProductionDialog.vue'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import campaignNeedsApproval from '../mixins/campaignNeedsApproval'
import panelType from '../mixins/panelType'
import isPageInIframe from '../mixins/isPageInIframe'

export default {
  name: 'TheSuccessArea',
  components: {
    TheSuccessAreaFile,
    TheSuccessAreaLink,
    PreProductionDialog,
    TheSuccessAreaSingleLink,
  },
  mixins: [
    panelType,
    isPageInIframe,
    campaignNeedsApproval,
    getTooltipTextBySlug
  ],
  props: {
    response: {
      type: Object,
      required: true
    },
    linkType: {
      type: String,
      required: true
    },
    isPreProduction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showPreProductionDialog: false,
  }),
  computed: {
    isAutoApproved () {
      return this.response?.is_approved
    },
    showApprovalMessage () {
      const { campaignNeedsApproval, isAutoApproved } = this
      return !isAutoApproved && campaignNeedsApproval
    },
  },
  mounted () {
    if (this.isPreProduction) {
      this.showPreProductionDialog = true
    }
  },
  methods: {
    getResponseRecipientsLength () {
      return this.response?.recipients?.length || 0
    }
  }
}
</script>

<style lang="scss" scoped>
.success-area {
  &__thank-you {
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    color: #000;
  }

  &__order-number {
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #000;
    padding-top: 80px;
  }

  &__order-number-info {
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 17px;
    color: #9F9F9F;
  }

  &__track-order {
    background-color: #F6F6F6;
    padding: 20px 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000;
  }

  &__preview-egift {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  .center-row-items > .col {
    display: flex;
    justify-content: center;
  }

  &__approval {
    background: #D8F1E4;
    padding: 20px !important;
    display: flex;
    align-items: center;

    &--main-text {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #000;
    }

    &--sub-text {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #000;
    }
  }
}
</style>
