<template>
  <div class="my-orders-wrapper">
    <div class="my-orders-wrapper__filters">
      <template v-if="$vuetify.breakpoint.lgAndUp">
        <common-input
          v-model="search"
          placeholder="Order id"
          prepend-inner-icon="mdi-magnify"
          style="min-width: 180px; max-width: 220px"
          :height="30"
          clearable
          filter-style
        />
        <common-my-date-range-picker
          :dateRange="dateRange"
          @setDateRange="(val) => dateRange = val"
          style="min-width: 220px; max-width: 220px"
        />
        <common-select
          v-model="type"
          :items="typeItems"
          placeholder="Order type"
          style="min-width: 180px; max-width: 220px"
          filter-style
          :height="30"
        />
        <common-select
          v-model="status"
          :items="statusItems"
          placeholder="Status"
          style="min-width: 180px; max-width: 220px"
          filter-style
          :height="30"
        />
        <common-select
          v-model="user"
          :items="userItems"
          placeholder="User"
          item-text="name"
          item-value="id"
          style="min-width: 180px; max-width: 220px"
          filter-style
          :height="30"
        />
        <common-select
          v-model="source"
          :items="sourceItems"
          placeholder="Source"
          style="min-width: 180px; max-width: 220px"
          filter-style
          :height="30"
        />

        <search-product-sku
          v-model="productId"
          :initialSearch.sync="productIdSearch"
          :params="params"
        />

        <span role="button" v-if="isPageFiltered" @click="resetFilters()">
          Clear
        </span>
      </template>

      <template v-else>
        <div
          class="my-orders-wrapper__filter-tablet"
          @keypress="showFilterPanel = true"
          @click="showFilterPanel = true"
          role="button"
          tabindex="0"
        >
          <icons-filter :height="16" :width="16" />

          Filters

          <div class="my-orders-wrapper__filter-tablet-badge" v-if="numberOfActiveFilters > 0">
            {{ numberOfActiveFilters }}
          </div>
        </div>
      </template>

      <div class="my-orders-wrapper__orders-info">
        Showing {{ totalNumberOfOrders }} orders

        <download-invoice :downloadMethod="downloadInvoice" :size="20" />
      </div>
    </div>

    <template v-if="!loadingOrders">
      <the-my-orders-table />

      <load-more
        :is-visible="showLoadMoreButton"
        :loading="loadingBtn"
        :size="loadMoreNumber"
        @more="showMoreOrders()"
      />
    </template>

    <common-loader v-else style="padding: 100px 0" />
    
    <orders-filters-panel
      v-model="showFilterPanel"
      :currentSearch.sync="search"
      :currentDateRange.sync="dateRange"
      :currentType.sync="type"
      :currentStatus.sync="status"
      :currentUser.sync="user"
      :currentSource.sync="source"
      :currentProductId.sync="productId"
      :currentProductIdSearch.sync="productIdSearch"
      :typeItems="typeItems"
      :statusItems="statusItems"
      :userItems="userItems"
      :sourceItems="sourceItems"
      :params="params"
    />
  </div>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'

import LoadMore from '@/components/LoadMore'
import TheMyOrdersTable from './TheMyOrdersTable'
import SearchProductSku from './SearchProductSkuInput'
import OrdersFiltersPanel from './OrdersFiltersPanel'
import DownloadInvoice from '@/components/myCGCredits/TheTableDownloadInvoice'

import { createNamespacedHelpers } from 'vuex'
import myOrdersFilters from './mixins/myOrdersFilters'

const { mapState, mapGetters, mapActions, } = createNamespacedHelpers('new-orders')

export default {
  name: 'TheMyOrdersWrapper',
  mixins: [myOrdersFilters],
  components: {
    LoadMore,
    DownloadInvoice,
    TheMyOrdersTable,
    SearchProductSku,
    OrdersFiltersPanel,
  },
  data: () => ({
    loadingBtn: false,
    showFilterPanel: false,
  }),
  computed: {
    ...mapState(['orders', 'loadingOrders', 'filters']),
    ...mapGetters([
      'params',
      'loadMoreNumber',
      'isPageFiltered',
      'showLoadMoreButton',
      'totalNumberOfOrders',
      'numberOfActiveFilters',
    ]),
  },
  watch: {
    filters: {
      handler: 'handleFilterParams',
      deep: true,
    }
  },
  created () {
    if (!this.orders.length) this.getOrders()
  },
  methods: {
    ...mapActions(['getOrders', 'loadMoreOrders', 'resetFilters']),
    showMoreOrders () {
      this.loadingBtn = true
      this.loadMoreOrders()
        .catch(e => (console.error(e)))
        .finally(() => (this.loadingBtn = false))
    },
    handleFilterParams: debounce(function () {
      this.getOrders()
    }, 400),
    downloadInvoice () {
      const { params } = this

      return Api.get('/customer/orders/csv', { params })
        .then((response) => (this.$store.commit('downloadCSV', response)))
    }
  },
}
</script>

<style lang="scss" scoped>
// move to file and use in two places
.my-orders-wrapper {
  display: flex;
  flex-direction: column;
  // overflow-x: auto;
  gap: 30px;

  &__filters {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;

    & > span[role="button"] {
      color: #42B77A;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  &__filter-tablet {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;

    color: #42B77A;
    font-size: 14px;
    font-weight: 400;
  }

  &__filter-tablet-badge {
    width: 22px;
    min-width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #219358;
    color: #fff;
    border-radius: 48px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  &__orders-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    color: #9B9B9B;
    font-weight: 700;
    white-space: nowrap;
    font-size: 13px;
    padding-left: 40px;
    justify-content: flex-end;
    min-width: 210px;
    margin-left: auto;

    position: sticky;
    right: 0;
    height: 30px;
    background: linear-gradient(
      to left,
      #fff,
      #fff 80%,
      rgba(255, 255, 255, 0.22) 90.05%,
      rgba(255, 255, 255, 0) 98.23%
    );
  }
}
</style>
