<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="650px"
  >
    <close-panel-button
      @close="() => show = false"
      text="Close"
    />

    <v-container class="px-10 py-0" v-if="show">
      <v-row dense>
        <v-col cols="12" class="f30 lato-light py-6">
          {{ contact ? 'Edit contact' : 'Add contact' }}
        </v-col>
        <!-- form -->
        <v-col cols="12">
          <cg-form ref="form">
            <v-row dense class="contact-form__wrapper">
              <v-col cols="10">
                <label for="address-name" class="required">
                  Full name
                </label>

                <v-row no-gutters id="address-name">
                  <v-col cols="6">
                    <cg-input
                      v-model="firstName"
                      id="given-name"
                      placeholder="First name"
                      :validation="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <cg-input
                      v-model="lastName"
                      id="family-name"
                      placeholder="Last name"
                      :validation="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="10">
                <label for="bday">
                  Birthday
                </label>

                <common-month-day-input
                  v-model="birthdate"
                  id="bday"
                  placeholder="MM/DD"
                />

                <span class="helper-text">
                  You’ll be able to use this for gifting automation
                </span>
              </v-col>

              <v-col cols="10">
                <label for="anniversary">
                  Work anniversary
                </label>

                <common-month-day-input
                  v-model="anniversary"
                  id="anniversary"
                  allow-year
                  placeholder="E.g. Employee  work start date"
                  focusPlaceholder="MM/DD/YYYY"
                />

                <span class="helper-text">
                  You’ll be able to use this for gifting automation
                </span>
              </v-col>

              <v-col cols="10">
                <cg-input
                  v-model="emailAddress"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  :validation="[rules.email]"
                >
                  <template #label>Email</template>
                  <template #hint-message>Can be used for sending eGifts</template>
                </cg-input>
              </v-col>

              <v-col cols="10">
                <cg-input
                  v-model="companyName"
                  id="company-name"
                  placeholder="Company name"
                  :validation="locationType === 'Business' ? [rules.required] : []"
                >
                  <template #label>Company</template>
                </cg-input>
              </v-col>

              <v-col cols="10">
                <label for="street-address">
                  Street Address
                </label>

                <v-row no-gutters>
                  <v-col cols="9">
                    <cg-input
                      v-model="streetAddress"
                      id="street-address"
                      placeholder="House number and street name"
                    />
                  </v-col>
                  <v-col cols="3">
                    <cg-input
                      v-model="apt"
                      placeholder="APT / Suite"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="10">
                <cg-input v-model="city" id="city" placeholder="City">
                  <template #label>City</template>
                </cg-input>
              </v-col>

              <v-col cols="10">
                <cg-input v-model="postalCode" id="postcode" placeholder="Postal code">
                  <template #label>Postal code</template>
                </cg-input>
              </v-col>

              <template>
                <v-col cols="10">
                  <cg-country-select v-model="country" id="country" placeholder="Country">
                    <template#label>Country</template#label>
                  </cg-country-select>
                </v-col>

                <v-col cols="10">
                  <cg-state-select
                    v-model="region"
                    :country="country"
                    id="state-region"
                    placeholder="State / Region"
                  >
                    <template #label>State / Region</template>
                  </cg-state-select>
                </v-col>
              </template>

              <v-col cols="10">
                <cg-phone-input v-model="phoneNumber" :height="36">
                  <template #label>
                    Phone
                  </template>
                  <template #hint-message>
                    We might need the recipient phone number for delivery questions
                  </template>
                </cg-phone-input>
              </v-col>

              <v-col cols="10">
                <div class="label-wrapper">
                  <label for="location-type">
                    Location type
                  </label>

                  <popper
                    :options="{
                      placement: 'top',
                      modifiers: { offset: { offset: '0, 5px' } },
                    }"
                  >
                    <div class="popper pa-4 text-left" style="min-width: 100px; max-width: 460px">
                      {{ getTooltipTextBySlug('contact_location_type') }}
                    </div>
                    <div slot="reference" class="pointer">
                      <icons-question width="16" height="16" />
                    </div>
                  </popper>
                </div>

                <cg-select
                  v-model="locationType"
                  id="location-type"
                  :items="locationTypes"
                  placeholder="Location type"
                />
              </v-col>
            </v-row>
          </cg-form>
        </v-col>
        <!-- add to group -->
        <v-col cols="12" class="py-10">
          <the-add-to-group-button
            v-model="userGroups"
            v-bind="_props"
            :userNewGroup.sync="userNewGroup"
          />
        </v-col>
        <!-- controls -->
        <v-col cols="12" class="pb-6">
          <common-button
            height="40"
            width="130"
            class="mr-4"
            outlined
            @click="show = false"
          >
            Cancel
          </common-button>

          <common-button
            height="40"
            width="130"
            @click="contact ? updateUser() : addNewUser()"
            :loading="loading"
          >
            Save
          </common-button>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import { CgForm, CgPhoneInput, CgInput, CgStateSelect, CgCountrySelect, CgSelect } from '@corporategift/design-system'
import TheAddToGroupButton from './TheAddToGroupButton'
import Popper from 'vue-popperjs'

import { locations } from '@/assets/common'
import { required, email } from '@corporategift/design-system/validations'

import panelVModel from '@/mixins/panelVModel'
import userIsCgElite from '@/mixins/userIsCgElite'
import { groups, addToGroup } from './mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'TheAddEditContactPanel',
  components: {
    TheAddToGroupButton,
    CgPhoneInput,
    CgStateSelect,
    CgCountrySelect,
    CgInput,
    CgSelect,
    CgForm,
    Popper,
  },
  mixins: [
    userIsCgElite,
    panelVModel,
    addToGroup,
    groups
  ],
  props: {
    contact: {
      type: [Object, Array],
      required: false,
      default: null
    }
  },
  data: () => ({
    firstName: null,
    lastName: null,
    companyName: null,
    birthdate: null,
    anniversary: null,
    streetAddress: null,
    apt: null,
    city: null,
    region: null,
    postalCode: null,
    country: null,
    phoneNumber: null,
    phoneNumberExt: null,
    emailAddress: null,
    userGroups: [],
    userNewGroup: null,
    locationType: null,
    locationTypes: locations,
    rules: {
      required: required({ errorMessage: '' }),
      email: email({ errorMessage: '' }),
    },
    loading: false,
    regions: [],
  }),
  computed: {
    ...mapGetters('tooltips', [
      'getTooltipTextBySlug'
    ]),
    requestBody () {
      return {
        firstname: this.firstName,
        lastname: this.lastName,
        company: this.companyName,
        street: this.streetAddress,
        apt: this.apt,
        city: this.city,
        region: this.region,
        country: this.country || 'US',
        postcode: this.postalCode,
        telephone: this.phoneNumber,
        location_type: this.locationType,
        group_ids: this.userGroups,
        email_address: this.emailAddress,
        anniversary: this.anniversary,
        dob: this.birthdate
      }
    },
  },
  watch: {
    show: function (val) {
      if (val && this.contact) {
        this.firstName = this.contact.firstname
        this.lastName = this.contact.lastname
        this.companyName = this.contact.company
        this.streetAddress = this.contact.street
        this.apt = this.contact.apt
        this.city = this.contact.city
        this.region = this.contact.region
        this.country = this.contact.country
        this.postalCode = this.contact.postcode
        this.phoneNumber = this.contact.telephone
        this.locationType = this.contact.location_type
        this.emailAddress = this.contact.email_address
        this.birthdate = this.contact.dob
        this.anniversary = this.contact.anniversary
        this.userGroups = this.contact.groups?.map((item) => item.entity_id) || []
      } else {
        Object.assign(this.$data, this.$options.data())
      }

      if (val && this.addToGroup) {
        const id = this.addToGroup.entity_id
        this.userGroups.push(id)
      }
    }
  },
  methods: {
    goToMissingRequiredField () {
      this.$nextTick(() => {
        const form = this.$refs.form?.$el
        if (form) {
          const firstInvalidElement = form.querySelector('[aria-invalid="true"]')

          if (firstInvalidElement) {
            firstInvalidElement.scrollIntoView({ behavior: 'smooth' })
          }
        }
      })
    },
    addNewUser () {
      if (!this.$refs.form.validate()) {
        this.goToMissingRequiredField()
        return
      }
      this.loading = true

      Api.post('/customer/address-book/addresses', this.requestBody)
        .then(({ success, data }) => {
          if (success) {
            if (this.userNewGroup) {
              this.createNewGroup(data.entity_id).then(({ data: groupData }) => {
                this.$emit('addGroup', groupData)
                this.$emit('addContact', data)
                this.$emit('fetchData')
                this.loading = false
                this.show = false
              })
            } else {
              this.$emit('addContact', data)
              this.loading = false
              this.show = false
            }

            this.$cgToast.successBold('Successfully created a new contact')
          }
        })
        .catch(({ response }) => {
          const errorMessage = response?.data?.message || 'An error occurred, please contact our support'
          this.$cgToast.error(errorMessage)
          this.goToMissingRequiredField()
          this.loading = false
        })
    },
    updateUser () {
      if (!this.$refs.form.validate()) {
        this.goToMissingRequiredField()
        return
      }
      this.loading = true
      const contactId = this.contact.entity_id
      const promises = []
      promises.push(Api.put(`/customer/address-book/addresses/${contactId}`, this.requestBody))

      if (this.userNewGroup) promises.push(this.createNewGroup(contactId))

      Promise.all(promises).then((response) => {
        const userData = response[0].data
        const groupData = response[1]?.data || null
        this.$emit('updateContact', userData)
        if (groupData) this.$emit('addGroup', groupData)
        this.$cgToast.successBold('Successfully updated a contact')
        this.loading = false
        this.show = false
      })
      .catch(({ response }) => {
        const errorMessage = response?.data?.message || 'An error occurred, please contact our support'
        this.$cgToast.error(errorMessage)
        this.goToMissingRequiredField()
        this.loading = false
      })
    },
    createNewGroup (customerId) {
      return Api.post('/customer/address-book/groups', {
        name: this.userNewGroup,
        address_ids: [customerId]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-form__wrapper > .col-10:not(:first-child) {
  padding-top: 16px;
}

label {
  font-size: 15px;
  display: inline-block;
  font-weight: 700;
  color: #000;
  line-height: 18px;
  position: relative;
  padding: 0 0 10px;

  &.required {
    &::before {
      position: absolute;
      content: '*';
      padding: inherit;
      font-size: 18px;
      color: #000;
      left: 100%;
      top: 0;
    }
  }
}

.label-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;

  label {
    padding: 0;
  }
}

.helper-text {
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  line-height: 18px;
  color: #a1a1a1;
  display: block;
  padding-top: 8px;
}
</style>
