
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import type IQuoteItemDetails from '../types/IQuoteItemDetails'
import type IQuoteResponse from '../types/IQuoteResponse'

import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import { required } from '@corporategift/design-system/validations'

import PanelHeader from '@/components/PanelHeader.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { CgTextarea, CgButton } from '@corporategift/design-system'

interface IDeclineBody {
  reason?: string;
}

@Component({
  mixins: [panelVModel],
  components: {
    PanelHeader,
    VuePdfEmbed,
    CgTextarea,
    CgButton,
  },
  methods: {required},
})
export default class MyQuotesDetailsPanel extends Vue {
  @Prop({ default: undefined }) readonly selectedQuoteId!: number | undefined;

  @Prop({ required: false, default: null }) readonly selectedQuoteItem!: IQuoteItemDetails | null;

  loading = false;

  loadingPdf = false;

  downloadingPdf = false;

  showDeclineForm = false;

  declineReason?: string = '';

  get quotePdfLink() {
    return this.selectedQuoteItem?.document_url;
  }

  get quoteStatus() {
    return this.selectedQuoteItem?.status;
  }

  get declineQuoteReason() {
    return this.selectedQuoteItem?.declined_reason;
  }

  get redirectTo() {
    return this.selectedQuoteItem?.redirect_to;
  }

  get itemIsReady() {
    return this.selectedQuoteItem?.is_ready ?? false;
  }

  @Ref('decline-reason') readonly declineReasonTextarea!: typeof CgTextarea;

  @Watch('show')
  onShowChange (val: boolean) {
    if (!val) {
      const getDefaultData = this.$options.data as { apply: (args: unknown) => void }
      Object.assign(this.$data, getDefaultData.apply(this))
    }
  }

  @Watch('selectedQuoteId')
  onQuoteIdChange (val: number) {
    if (val !== null && this.quotePdfLink) { this.setLoadingPdf() }
  }

  setLoadingPdf () {
    this.loadingPdf = true
    setTimeout(() => { this.loadingPdf = false }, 500)
  }

  approveQuote () {
    const { selectedQuoteId } = this;
    this.loading = true;

    Api.patch<undefined, IQuoteResponse>(`/customer/quotes/${selectedQuoteId}/approve`)
      .then(({ data }) => {
        this.$emit('updateQuoteItem', data);
        this.$cgToast.success('The quote has been successfully approved, and you may now checkout and finalize the transaction')
      })
      .catch(() => {
        this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
      })
      .finally(() => (this.loading = false))
  }

  declineQuote () {
    const { declineReason, declineReasonTextarea, selectedQuoteId } = this

    // @ts-ignore
    if (declineReasonTextarea.validate()) {
      this.loading = true;

      Api.patch<IDeclineBody, IQuoteResponse>(`/customer/quotes/${selectedQuoteId}/decline`, {
        reason: declineReason
      })
        .then(({ data }) => {
          this.$emit('updateQuoteItem', data);
          this.$cgToast.success('You have effectively rejected the quote')
          this.showDeclineForm = false;
          this.declineReason = '';
        })
        .catch(() => {
          this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
        })
        .finally(() => (this.loading = false))
    }
  }

  convertQuoteToCart () {
    const { selectedQuoteId, redirectTo } = this;
    this.loading = true;

    Api.post(`/convert-quote/${selectedQuoteId}`)
      .then(() => {
        const redirectUrl = redirectTo === 'cart' ? 'cart' : 'checkout/onepage/shipping-address'

        window.location.href = `${process.env.VUE_APP_MAGENTO_URL}/${redirectUrl}`
      })
      .catch(() => {
        this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
      })
      .finally(() => (this.loading = false))
  }

  downloadPdf () {
    const { selectedQuoteId } = this;
    this.downloadingPdf = true;

    Api.get<undefined, string>(`/customer/quotes/${selectedQuoteId}/download`, {
      responseType: 'arraybuffer'
    })
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `quote-${selectedQuoteId}`);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
      })
      .finally(() => (this.downloadingPdf = false))
  }
}
