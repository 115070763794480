<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="850px"
    class="add-to-cart-panel"
  >
    <div class="add-to-cart-panel__alert">
      <icons-info :width="20" :height="20" />
      Some items cannot be added to cart, they either out of stock or need customization
    </div>

    <close-panel-button @close="() => show = false">Close</close-panel-button>

    <common-loader v-if="loading" />

    <v-container class="px-md-9 pb-0 pt-4" v-else>
      <v-row class="add-to-cart-panel__products">
          <the-my-favorites-problem-with-add-to-cart-panel-item
            v-for="product in filteredProducts"
            :key="product.id"
            :product="product"
          />
        <v-col cols="12" class="d-flex g10 pt-10">
          <common-button outlined @click="show = false">
            Cancel
          </common-button>
          <common-button @click="addItemsToCart()">
            Continue with eligible items
          </common-button>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

import TheMyFavoritesProblemWithAddToCartPanelItem  from '@/components/myFavorites/TheMyFavoritesProblemWithAddToCartPanelItem.vue'

export default {
  name: 'TheMyFavoritesProblemWithAddToCartPanel',
  mixins: [panelVModel],
  components: {
    TheMyFavoritesProblemWithAddToCartPanelItem,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    filteredProducts () {
      return this.products?.filter(({ product }) => product.qty.is_out_of_stock || product.should_be_customized) ?? []
    }
  },
  methods: {
    addItemsToCart () {
      this.loading = true
      const productsTOAdd = this.products.filter(({ product }) => !(product.qty.is_out_of_stock || product.should_be_customized))
      const data = productsTOAdd.map((item) => ({
        qty: item.product?.min_qty ?? 1,
        product_id: Number(item.product_id),
        redirect_url: item?.link ?? '-',
      }))

      Api.post('/cart/items', data)
        .then((data) => {
          this.$cgToast.successBold('Successfully added products to cart')
          this.show = false
        })
        .catch((e) => {
          console.error(e)
          this.$cgToast.error('An error occurred, please contact our support')
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-cart-panel {
  &__alert {
    display: flex;
    gap: 16px;
    font-weight: 400;
    background-color: #FCEBA4;
    color: #222325;
    padding: 16px 40px;
    font-size: 16px;
    line-height: 19px;
  }

  &__products {
    gap: 10px;
    row-gap: 30px;
    padding: 12px;

    @media (min-width: 960px) {
      gap: 30px;
    }
  }
}
</style>
