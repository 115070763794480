<template>
  <v-container class="pa-0 ma-0">
    <common-loader v-show="loading" style="padding: 200px 0" />

    <v-row v-show="!loading">
      <v-col cols="12" class="f15 lato-bold black1 pb-6">
        Enter your credit card information:
      </v-col>

      <v-col cols="8">
        <label for="card-number">Credit card number</label>
        <div class="iframe-handler" id="card-number" data-bluesnap="ccn" />
      </v-col>
      <v-col cols="1"></v-col>

      <v-col cols="4" class="pr-0">
        <label for="exp-date">Expiry date</label>
        <div class="iframe-handler" id="exp-date" data-bluesnap="exp" />
      </v-col>
      <v-col cols="4" class="pl-0">
        <div class="d-flex justify-space-between">
          <label for="cvv">Card verification code</label>
          <popper
            :options="{
              placement: 'top',
              modifiers: {
                offset: {
                  offset: '0,8px'
                }
              }
            }"
          >
            <div class="popper pa-4 pl-5 text-left" style="max-width: 160px;">
              3 digits code on the back of your card
            </div>
            <v-img
              max-width="18"
              height="19"
              slot="reference"
              src="/images/question-icon.svg"
              class="pointer"
            />
          </popper>
        </div>
        <div class="iframe-handler" id="cvv" data-bluesnap="cvv" />
      </v-col>

      <v-col cols="10" class="f15 error--text" v-if="errors.length">
        {{ errors[0] }}
      </v-col>

      <v-col cols="12">
        <common-button
          height="42"
          width="200"
          @click="$emit('addCC')"
        >
          Buy Credits
        </common-button>
      </v-col>
      <v-col cols="10" xl="11" class="f15 lato-italic gray2">
        By clicking “Buy Credits” you agree to the CorporateGift.com
        <a
          href="https://corporategift.com/terms-and-conditions/"
          target="_blank"
          class="f15 lato-italic green3"
        >
          Terms of Service and Payments Policy.
        </a>
        Prepaid credits are non-refundable and non-transferrable.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* global bluesnap */
import Api from '@/axios/api'

import Popper from 'vue-popperjs'

export default {
  name: 'CreditCardPayment',
  components: {
    Popper
  },
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data: () => ({
    loading: false,
    blueSnapToken: null,
    invalidValues: ['ccn', 'exp', 'cvv']
  }),
  created () {
    if (this.blueSnapToken === null) this.initBluesnap()
  },
  methods: {
    async takeToken () {
      return Api.get('bluesnap/payment-fields-token').then((data) => {
        this.blueSnapToken = data
        return data
      })
    },
    initBluesnap () {
      this.loading = true
      this.takeToken().then((apiToken) => {
        var bsObj = {
          token: apiToken,
          onFieldEventHandler: {
            setupComplete: function () {
              this.updateLoadingState(false)
            },
            onFocus: function (tagId) {},
            onBlur: function (tagId) {},
            onType: function (tagId, cardType, cardData) {},
            onEnter: function (tagId) {},
            onValid: function (tagId) {
              this.removeInvalidValues(tagId)
            },
            onError: function (tagId, errorCode) {
              if (errorCode === '10') this.setInvalidValues(tagId) || console.log('Invalid values')
              if (errorCode === '14040') this.updateToken() || console.log('Token is expired')
              if (errorCode === '14041') this.updateToken() || console.log('Could not find token')
              if (errorCode === '400') console.log('Session expired - refrresh page to continue')
            }
          },
          style: {
            input: {
              'font-family':
                'Lato ,RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
              'font-size': '15px',
              'line-height': '20px',
              'font-weight': '400',
              color: '#000000de',
              'border-color': '#d3d2d2',
              'border-width': '1px',
              'border-style': 'solid',
              display: 'inherit',
              padding: '0 8px'
            },
            ':focus': {
              color: 'black'
            },
            '::placeholder': {
              'font-family':
                'Lato ,RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
              'font-style': 'italic',
              'font-weight': '400',
            },
            '.invalid': {
              color: '#ff5252',
              'border-color': '#ff5252'
            }
          },
          ccnPlaceHolder: ' ',
          cvvPlaceHolder: 'CVC',
          expPlaceHolder: 'MM / YY'
        }

        bsObj.onFieldEventHandler.updateToken = this.updateToken
        bsObj.onFieldEventHandler.setInvalidValues = this.setInvalidValues
        bsObj.onFieldEventHandler.removeInvalidValues = this.removeInvalidValues
        bsObj.onFieldEventHandler.updateLoadingState = (val) => {
          this.loading = val
        }

        bluesnap.hostedPaymentFieldsCreate(bsObj)

        // BlueSnap adds iframe to the body and that element adds another scrollbar
        // this query select hides all BlueSnap iframes
        document.querySelectorAll('#bs-fraud-sid').forEach(element => {
          element.style.display = 'none'
        })
      })
    },
    updateToken () {
      this.takeToken().then(token => bluesnap.hostedPaymentFieldsUpdateToken(token))
    },
    setInvalidValues (tagId) {
      if (!this.invalidValues.includes(tagId)) this.invalidValues.push(tagId)
    },
    removeInvalidValues (tagId) {
      this.invalidValues = this.invalidValues.filter(item => item !== tagId)
    },
    validate () {
      bluesnap.hostedPaymentFieldsSubmitData()

      if (this.invalidValues.length) return false
      return true
    },
    async submitCreditCard () {
      await this.validate()

      return Api.post('/bluesnap/credit-cards', {
        pfToken: this.blueSnapToken
      }).catch(() => {
        bluesnap.hostedPaymentFieldsSubmitData()
      })
    }
  }
}
</script>

<style lang="scss">
.iframe-handler {
  iframe {
    max-height: 32px;
  }
}
</style>

<style lang="scss" scoped>
label {
  font-size: 15px;
  margin-bottom: 2px;
  font-weight: 700;
}
</style>
