<template functional>
  <div class="create-campaign-cg-elite-badge">
    CG members only
  </div>
</template>

<script>
export default {
  name: 'CreateCampaignCgEliteBadge'
}
</script>

<style lang="scss" scoped>
.create-campaign-cg-elite-badge {
  color: #1D048F;
  border-radius: 4px;
  padding: 4px 8px;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  background-color: #E5DFFF;
}
</style>