
import { Component, Mixins, Watch } from 'vue-property-decorator'
import type { IApprovalForm, VuetifyForm } from '../../types'

import Api from '@/axios/api'
import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import takeCurrentDate from '../mixins/takeCurrentDate'
import recipientsProp from '../mixins/recipientsProp'
import SendCampaign from '../mixins/sendCampaign'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'

import SingleDatePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'
import ApprovalFileInput from '@/components/myCampaign/common/ApprovalFileInput.vue'

enum ApprovalFormFieldType {
  Text = 'text',
  Select = 'select',
  Multiple = 'multiple',
  Datepicker = 'datepicker',
  Upload = 'upload',
}

interface IApprovalFormField {
  type: ApprovalFormFieldType;
  code: string;
  label: string;
  required: boolean;
  options: Array<IApprovalFormFieldOption> | null;
  allow_past_dates: boolean | null;
}

interface IApprovalFormFieldOption {
  label: string;
  value: unknown,
  default: boolean;
  question: IApprovalFormField | null;
}

@Component({
  components: {
    SingleDatePicker,
    ApprovalFileInput,
  },
})
export default class SendEgiftPanelApproval extends Mixins(
  recipientMethodProp,
  approvalDataProps,
  takeCurrentDate,
  recipientsProp,
  panelTypeProp,
  SendCampaign,
  campaignProp,
  formProp,
) {
  loading = false;

  approvalFormFields: Array<IApprovalFormField> = []

  approvalFormFieldsToDisplay: Array<IApprovalFormField> = []

  rules = {
    required: (v: string | null | number) => !!v || '',
    requiredMultiple: (v: Array<unknown>) => !!v.length || ''
  }

  get formValues(): IApprovalForm {
    return this.approvalForm
  }

  set formValues(val: IApprovalForm) {
    this.$emit('update:approvalForm', val)
  }

  @Watch('approvalForm', { immediate: true, deep: true })
  handleApprovalFormChange() {
    const newForm = this.approvalFormFields.reduce<Array<IApprovalFormField>>((acc, field) => {
      acc.push(field)

      if (field.type === ApprovalFormFieldType.Select) {
        const additionalQuestions = field.options?.filter((option) => option.question) || []

        additionalQuestions.forEach((additionalQuestion) => {
          if (this.formValues[field.code] === additionalQuestion.value) {
            acc.push(additionalQuestion.question as IApprovalFormField)
          }
        })
      } else if (field.type === ApprovalFormFieldType.Multiple) {
        const additionalQuestions = field.options?.filter((option) => option.question) || []

        additionalQuestions.forEach((additionalQuestion) => {
          const selectedValues = this.formValues[field.code] as Array<string> ?? []

          if (selectedValues.includes(additionalQuestion.value as string)) {
            acc.push(additionalQuestion.question as IApprovalFormField)
          }
        })
      }

      return acc;
    }, [])

    this.approvalFormFieldsToDisplay = newForm;
  }

  mounted() {
    this.loading = true

    Api.get('/customer/approval-form-fields')
      .then(({ data }: { data: Array<IApprovalFormField> }) => {
        this.approvalFormFields = data
        this.setApprovalForm(this.approvalFormFields)
      })
      .finally(() => (this.loading = false))
  }

  setApprovalForm(formFields: Array<IApprovalFormField>): void {
    formFields?.forEach((field) => {
      this.$set(this.formValues, field.code, null)

      if (field.type === ApprovalFormFieldType.Datepicker) {
        this.$set(this.formValues, field.code, this.takeCurrentDate() ?? null)
      }

      if (field.type === ApprovalFormFieldType.Select) {
        const defaultValue = field.options?.find((option) => option.default)?.value || null
        this.$set(this.formValues, field.code, defaultValue)
      }

      if (field.type === ApprovalFormFieldType.Multiple) {
        this.$set(this.formValues, field.code, [])
      }

      const additionalQuestions = field?.options
        ?.map((option) => option.question)
        ?.filter((question) => question) ?? []

      if (additionalQuestions.length) {
        this.setApprovalForm(additionalQuestions as Array<IApprovalFormField>)
      }
    })
  }

  handleSendCampaign(): void {
    if (!(this.$refs['approval-form'] as VuetifyForm).validate()) {
      return
    }

    this.sendCampaign()
  }
}
