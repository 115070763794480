<template>
  <v-navigation-drawer
    class="orders-filters-panel"
    v-lock-scroll="show"
    v-model="show"
    width="550px"
    temporary
    stateless
    right
    app
  >
    <div class="orders-filters-panel__title">
      Filters

      <div class="orders-filters-panel__title-icon" @click="show = false">
        <icons-close color="#62646A" :width="17" :height="17" />
      </div>
    </div>

    <div class="orders-filters-panel__content">
      <common-input
        v-model="search"
        placeholder="Order id"
        prepend-inner-icon="mdi-magnify"
        :height="40"
        clearable
        filter-style
      />
      <common-my-date-range-picker
        :dateRange="dateRange"
        :height="40"
        right
        @setDateRange="(val) => dateRange = val"
      />
      <common-select
        v-model="type"
        :items="typeItems"
        placeholder="Order type"
        disable-attach
        filter-style
        :height="40"
      />
      <common-select
        v-model="status"
        :items="statusItems"
        placeholder="Status"
        filter-style
        disable-attach
        :height="40"
      />
      <common-select
        v-model="user"
        :items="userItems"
        placeholder="User"
        item-text="name"
        item-value="id"
        filter-style
        disable-attach
        :height="40"
      />
      <common-select
        v-model="source"
        :items="sourceItems"
        placeholder="Source"
        filter-style
        disable-attach
        :height="40"
      />
      <search-product-sku
        disable-styles
        :max-width="470"
        :min-width="470"
        v-model="productId"
        :params="params"
        :height="40"
        :initialSearch.sync="productIdSearch"
      />
    </div>

    <div class="orders-filters-panel__actions">
      <common-button @click="clearFilters()" outlined :height="44">Clear</common-button>
      <common-button @click="applyFilters()" :height="44">Apply filters</common-button>
    </div>
  </v-navigation-drawer>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import SearchProductSku from './SearchProductSkuInput'

export default {
  name: 'OrdersFiltersPanel',
  mixins: [panelVModel],
  components: { SearchProductSku },
  props: {
    currentSearch: {
      type: String,
      required: false,
      default: null,
    },
    currentDateRange: {
      type: Object,
      required: false,
      default: () => ({ start: null, end: null }),
    },
    currentType: {
      type: [String, Number],
      required: false,
      default: null,
    },
    currentStatus: {
      type: [String, Number],
      required: false,
      default: null,
    },
    currentUser: {
      type: [String, Number],
      required: false,
      default: null,
    },
    currentSource: {
      type: [String, Number],
      required: false,
      default: null,
    },
    currentProductId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    currentProductIdSearch: {
      type: String,
      required: false,
      default: null,
    },
    typeItems: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    statusItems: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    userItems: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    sourceItems: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },
  data: () => ({
    search: null,
    dateRange: {
      start: null,
      end: null
    },
    type: null,
    status: null,
    user: null,
    source: null,
    productId: null,
    productIdSearch: null,
  }),
  watch: {
    show: function (val) {
      if (val) {
        this.setData()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    setData () {
      const {
        currentSearch,
        currentDateRange,
        currentType,
        currentStatus,
        currentUser,
        currentSource,
        currentProductId,
        currentProductIdSearch,
      } = this

      this.search = currentSearch
      this.dateRange = { ...currentDateRange }
      this.type = currentType
      this.status = currentStatus
      this.user = currentUser
      this.source = currentSource
      this.productId = currentProductId
      this.productIdSearch = currentProductIdSearch
    },
    clearFilters () {
      this.$emit('update:currentSearch', null)
      this.$emit('update:currentDateRange', { start: null, end: null })
      this.$emit('update:currentType', null)
      this.$emit('update:currentStatus', null)
      this.$emit('update:currentUser', null)
      this.$emit('update:currentSource', null)
      this.$emit('update:currentProductId', null)
      this.$emit('update:currentProductIdSearch', null)
      this.show = false
    },
    applyFilters () {
      this.$emit('update:currentSearch', this.search)
      this.$emit('update:currentDateRange', this.dateRange)
      this.$emit('update:currentType', this.type)
      this.$emit('update:currentStatus', this.status)
      this.$emit('update:currentUser', this.user)
      this.$emit('update:currentSource', this.source)
      this.$emit('update:currentProductId', this.productId)
      this.$emit('update:currentProductIdSearch', this.productIdSearch)
      this.show = false
    },
  },
}
</script>

<style lang="scss" scoped>
.orders-filters-panel {
  padding: 40px;

  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 44px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    color: #000;
    font-weight: 300;
    justify-content: space-between;
    font-size: 40px;
    line-height: 44px;
    gap: 12px;

    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 12px;

    & > button {
      text-transform: none;
      padding: 0 32px !important;
      font-size: 18px;
      line-height: 22px !important;
      letter-spacing: unset;

      & > span {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }
    }
  }
}
</style>
