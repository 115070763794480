function addPaddingLeft (el) {
  el.style.paddingLeft = '80px'
}

function removePaddingLeft (el) {
  el.style.paddingLeft = ''
}

function shouldAddPadding (vnode) {
  const { application: { left }, breakpoint: { xl } } = vnode.fnContext.$vuetify
  const isExpanded = left > 64

  return isExpanded && xl
}

export default {
  install (Vue, options) {
    Vue.directive('view-padding', {
      inserted (el, binding, vnode) {
        if (shouldAddPadding(vnode)) {
          addPaddingLeft(el)
        } else {
          removePaddingLeft(el)
        }
      },
      componentUpdated (el, binding, vnode) {
        if (shouldAddPadding(vnode)) {
          addPaddingLeft(el)
        } else {
          removePaddingLeft(el)
        }
      },
      unbind (el) {
        removePaddingLeft(el)
      }
    })
  }
}
