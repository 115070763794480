<template>
  <div class="select-multiple-contacts">
    <div class="select-multiple-contacts__selected" @click="showOptions = !showOptions">
      <div
        class="selected-item"
        :class="{
          'selected-item--all' : ['all'].includes(selectedOption),
          'selected-item--partial' : ['contacts', 'groups'].includes(selectedOption)
        }"
      />
      <v-icon
        :class="showOptions && 'v-icon--opended'"
      >
        mdi-chevron-down
      </v-icon>
    </div>

    <div class="select-multiple-contacts__options" v-if="showOptions">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="options-item"
        :class="selectedOption === option && 'options-item--selected'"
        @click="selectOption(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSelectMultipleContacts', // TODO rename also used in send egift panel
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: false,
      default: () => ([
        'all',
        'none',
        'contacts',
        'groups'
      ])
    }
  },
  data: () => ({
    showOptions: false
  }),
  computed: {
    selectedOption: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.showOptions = false
      }
    },
    selectOption (optionName) {
      if (optionName === 'none') this.$emit('clearSelected')
      this.selectedOption = optionName
      this.showOptions = false
    }
  }
}
</script>

<style lang="scss" scoped>
.select-multiple-contacts {
  position: relative;
  --border-color: #D3D2D2;
  min-width: 68px;

  &__selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 4px;
    border: 1px solid var(--border-color);
    background-color: #fff;
    cursor: pointer;

    .selected-item {
      width: 21px;
      height: 21px;
      border: 1px solid #979393;
      position: relative;
      background-color: inherit;

      &--partial, &--all {
        background-color: #42B77A;
        border: none;
        color: #fff;
      }

      &--all {
        &::before {
          content: ' ';
          border: solid #fff;
          width: 40%;
          height: 60%;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) translate(-35%, -30%);
          position: absolute;
          left: 30%;
          top: 35%;
        }
      }

      &--partial {
        &::before {
          content: ' ';
          border-bottom: 2px solid #fff;
          width: 50%;
          position: absolute;
          transform: translate(-50%, 0%);
          left: 50%;
          top: 50%;
        }
      }
    }

    .v-icon {
      &--opended {
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    border: 1px solid var(--border-color);
    border-top: none !important;
    background-color: #fff;
    width: 100%;
    z-index: 4;

    .options-item {
      cursor: pointer;
      padding: 4px;
      font-weight: 400;
      text-transform: capitalize;
      font-size: 14px;
      color: #333;

      &--selected, &:hover {
        background-color: #F5F5F5;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }
}
</style>
