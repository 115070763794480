<template>
  <div class="aggregated-card">
    <div class="aggregated-card__title">
      Products

      <dashboard-card-pagination
        v-model="currentPage"
        :max-length="totalPages"
        v-if="totalPages > 1"
      />
    </div>

    <div class="aggregated-card__divider" />

    <div class="aggregated-card__content">
      <div v-for="item in itemsToDisplay" :key="item.id" class="aggregated-products__item">
        <product-image
          :altText="item.name"
          :image="item.image_url"
          :height="60"
          :width="60"
        />

        <div>
          {{ item.name }}
        </div>

        <div class="aggregated-products__badge">
          {{ item.sum_qty }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aggregatedViewWithPages from './mixins/aggregatedViewWithPages'

import ProductImage from '../ProductImage.vue'

export default {
  name: 'TheAggregatedViewProducts',
  mixins: [aggregatedViewWithPages],
  components: {
    ProductImage
  },
  data: () =>({
    itemsPerPage: 3,
  }),
}
</script>

<style lang="scss" scoped>
@import "../styles/aggregated-card.scss";

.aggregated-products {
  &__badge {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
    min-width: 22px;
    padding: 3px 8px 0;
    height: 22px;
    background: #38A39C;
    border-radius: 48px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    gap: 4px;
    height: 60px;
    align-items: center;

    & > div:nth-child(2) {
      padding-left: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }
  }
}
</style>
