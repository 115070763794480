
import { Vue, Component, Prop } from 'vue-property-decorator'
import type IQuoteItem from './types/IQuoteItem'

import UpdateStatusSheet from '@/components/UpdateStatusSheet.vue'

@Component({
  components: {
    UpdateStatusSheet,
  }
})
export default class MyQuotesTableRow extends Vue {
  @Prop() readonly item!: IQuoteItem;

  tooltipProps = {
    placement: 'bottom',
    trigger: 'hover',
    classes: 'my-users-table-row__options-tooltip',
    offset: 12,
  }

  get statusTextColor (): string {
    const { status } = this.item;

    switch (status) {
      case 'Active':
        return '#795E04';
      case 'Declined':
        return '#9E040A';
      case 'Approved':
        return '#07703A';
      case 'Expired':
        return '#795E04';
      default:
        return '#07703A';
    }
  }

  get statusBadgeColor (): string {
    const { status } = this.item;

    switch (status) {
      case 'Active':
        return '#FCEBA4';
      case 'Declined':
        return '#FAE3E3';
      case 'Approved':
        return '#D8F1E4';
      case 'Expired':
        return '#FCEBA4';
      default:
        return '#D8F1E4';
    }
  }
}
