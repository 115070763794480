<template>
  <div class="footer-copyright-area">
    <div>
      © {{ new Date().getFullYear() }} CorporateGift.com. All Rights Reserved.
    </div>

    <a href="http://www.saydigitaldesign.com/" target="_blank">Design by Say Digital Design</a>
  </div>
</template>

<script>
export default {
  name: 'TheFooterCopyright',
}
</script>

<style lang="scss" scoped>
.footer-copyright-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 40px;

  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;

  & > a {
    color: #4a4a4a !important;
    font-size: inherit;
    font-family: inherit;

    &:hover {
      text-decoration: underline !important;
    }
  }

  @media (max-width: 639px) {
    gap: 4px;
    flex-direction: column;
    padding: 30px 0;
  }
}
</style>
