
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'

import UserInfo from '@/mixins/userInfo'
import formProp from '../mixins/formProp'
import recipientsProp from '../mixins/recipientsProp'
import GreetingHelperProp from '../mixins/greetingHelperProp'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import GreetingUploadLogo from '../components/GreetingUploadLogo.vue'
import GreetingMentionMenu from '../components/GreetingMentionMenu.vue'

@Component({
  mixins: [getTooltipTextBySlug],
  components: {
    GreetingUploadLogo,
    GreetingMentionMenu,
  },
})
export default class SendEgiftPanelGreeting extends Mixins(
  UserInfo,
  formProp,
  recipientsProp,
  GreetingHelperProp,
) {
  greetingForm = {
    title: null as string | null,
    content: null as string | null,
    from: null as string | null,
  }

  greetingLogo: string | null = null;

  selectedAreaId: string | null = null;

  showGreetingTagTooltip = false;
  
  showGreetingContentTooltip = false;

  get giftGreetingMessage (): string | null {
    return this.form.greeting ?? null
  }

  get giftGreetingLogo (): string | null {
    return this.form.logoUrl ?? null
  }

  mounted (): void {
    const {
      giftGreetingMessage,
      giftGreetingLogo,
      greetingHelper: {
        message: greetingHelperMessage,
        logoUrl: greetingHelperLogo
      }
    } = this

    if (greetingHelperMessage || giftGreetingMessage) {
      const splittedMessage = greetingHelperMessage
        ? greetingHelperMessage?.split('\n\n')
        : giftGreetingMessage?.split('\n\n') ?? []

      const newTitle = splittedMessage?.shift() ?? null
      const newFrom = splittedMessage?.pop() ?? null
      const rest = splittedMessage?.join('\n\n') ?? null

      this.greetingForm = {
        title: newTitle,
        content: rest,
        from: newFrom,
      }

      setTimeout(() => { this.resizeTextArea() }, 0)
    }

    if (greetingHelperLogo || giftGreetingLogo) {
      this.greetingLogo = greetingHelperLogo || giftGreetingLogo
    }

    this.setGreetingTitleAndFrom()

    // focus greeting title on mounted
    setTimeout(() => {
      const formTitle: HTMLInputElement | null = document.querySelector('#greeting-title')

      if (formTitle) { formTitle.focus() }
    }, 200)
  }

  setGreetingTitleAndFrom () {
    const { numberOfRecipients, recipients, greetingForm, userFirstName } = this
    const { title, from } = greetingForm
    const [firstRecipient] = recipients
    const { firstName } = firstRecipient ?? {}

    if (
      !title ||
      (numberOfRecipients === 1 && title?.trim() === 'Hi @first_name') ||
      (numberOfRecipients > 1 && title?.trim() === `Hi ${firstName || ''}`)
    ) {
      let newTitle = 'Hi '
      newTitle += numberOfRecipients === 1 ? firstName : '@first_name'

      this.greetingForm.title = newTitle
    }

    if (!from) {
      let newFrom = 'Yours'

      newFrom += userFirstName ? `, ${userFirstName}` : ' truly'

      this.greetingForm.from = newFrom
    }
  }

  resizeTextArea (): void {
    const textarea = this.$el?.querySelector('textarea')

    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  handleSelectedArea (event: FocusEvent): void {
    this.selectedAreaId = (event.target as HTMLInputElement | HTMLTextAreaElement)?.id ?? null
  }

  updateSelectedAreaMessage ({ id, message }: { id: string, message: string }): void {
    if (id.includes('title')) {
      this.greetingForm.title = message
    } else if (id.includes('content')) {
      this.greetingForm.content = message
      this.resizeTextArea()
    } else if (id.includes('from')) {
      this.greetingForm.from = message
    } else {
      console.error(`Can't handle form with id: ${id}`)
    }
  }

  handleTitleFocus (event: FocusEvent): void {
    this.handleSelectedArea(event)
    setTimeout(() => {
      this.showGreetingTagTooltip = true
    }, 200)
  }

  handleTitleBlur (): void {
    this.selectedAreaId = null
    this.showGreetingTagTooltip = false
  }

  handleContentFocus (event: FocusEvent): void {
    this.handleSelectedArea(event)
    setTimeout(() => {
      this.showGreetingContentTooltip = true
    }, 100)
  }

  handleContentBlur (): void {
    this.selectedAreaId = null
    setTimeout(() => {
      this.showGreetingContentTooltip = false
    }, 200)
  }

  showGreetingIdeasStep (): void {
    const { greetingForm, greetingLogo } = this

    this.$emit('update:greetingHelper', {
      message: Object.values(greetingForm).join('\n\n'),
      logoUrl: greetingLogo,
    })
    this.$emit('update:step', RootSteps.GreetingIdeas)
  }

  saveChanges (): void {
    const { greetingForm, greetingLogo } = this

    this.$emit('update:greetingHelper', { message: null, logoUrl: null })
    this.$emit('update:greeting', Object.values(greetingForm).join('\n\n'))
    this.$emit('update:logoUrl', greetingLogo)
    this.$emit('update:step', RootSteps.Review)
  }
}
