
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import type { IRecipient } from '../../types';

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import takeCurrentDate from '../mixins/takeCurrentDate'
// TODO add types
// @ts-ignore
import cloneDeep from 'lodash/cloneDeep'

import CopyPasteTableInput from '../components/CopyPasteTableInput.vue'

interface ITableForm {
  firstNames: string | null;
  lastNames: string | null;
  emails: string | null;
  sendDates: string | null;
}

@Component({
  components: {
    CopyPasteTableInput,
  },
})
export default class SendEgiftPanelRecipientsCopyPaste extends Mixins(
  panelTypeProp,
  recipientsProp,
  takeCurrentDate
) {
  tableRecipients: Array<IRecipient> = []

  tableForm: ITableForm = {
    firstNames: null,
    lastNames: null,
    emails: null,
    sendDates: null,
  }

  get tableFirstNames(): string | null {
    return this.tableForm.firstNames
  }

  set tableFirstNames(val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'firstName')
    } else {
      this.fillRecipientColumnWith('firstName')
    }

    this.tableForm.firstNames = val
  }

  get tableLastNames(): string | null {
    return this.tableForm.lastNames
  }

  set tableLastNames(val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'lastName')
    } else {
      this.fillRecipientColumnWith('lastName')
    }

    this.tableForm.lastNames = val
  }

  get tableEmails(): string | null {
    return this.tableForm.emails
  }

  set tableEmails(val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'email')
    } else {
      this.fillRecipientColumnWith('email')
    }

    this.tableForm.emails = val
  }

  get tableSendDates () {
    return this.tableForm.sendDates
  }

  set tableSendDates (val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'sendDate')
    } else {
      this.fillRecipientColumnWith('sendDate')
    }

    this.tableForm.sendDates = val
  }

  get isFormFilled(): boolean {
    const { tableForm, isSelectedGiftLink } = this
    const helper: Partial<ITableForm> = { ...tableForm }
    if (isSelectedGiftLink) { delete helper.sendDates }

    return !Object.values(helper).every((value) => value === null)
  }

  get disableContinueButton(): boolean {
    const { tableForm, isSelectedGiftLink } = this
    const helper: Partial<ITableForm> = { ...tableForm }

    delete helper.sendDates
    if (isSelectedGiftLink) { delete helper.emails }

    return Object.values(helper).some((value) => value === null)
  }

  mounted() {
    const { recipients } = this
    if (recipients.length) {
      const recipientsWithData = recipients
        .filter((recipient) => recipient.firstName || recipient.lastName || recipient.email)

      this.tableRecipients = cloneDeep(recipientsWithData)

      this.setFormData()
    }
    this.setFocusedField()
  }

  setFormData(): void {
    this.setFormDataColumn('firstName', 'firstNames')
    this.setFormDataColumn('lastName', 'lastNames')
    this.setFormDataColumn('email', 'emails')
    // Since on the panel init it's adding one recipient with only the send date and because of this the send date column is filled but with empty data
    // and to fix this we should check if there are actually tableRecipients set (check mounted step)
    if (this.tableRecipients.length) { this.setFormDataColumn('sendDate', 'sendDates') }
  }

  setFormDataColumn(recipientKey: keyof IRecipient, formKey: keyof ITableForm): void {
    const value = this.recipients.reduce((acc, recipient) => {
      if (recipient[recipientKey]) { acc += `${recipient[recipientKey]}\n` }
      return acc
    }, '')
    
    if (value) { this.tableForm[formKey] = value }
  }

  setFocusedField(): void {
    const {
      firstNames,
      lastNames,
      emails,
      sendDates,
    } = this.tableForm

    switch (null) {
      case firstNames:
        this.focusTextField('firstNames')
        break;
      case lastNames:
        this.focusTextField('lastNames')
        break;
      case emails:
        this.focusTextField('emails')
        break;
      case sendDates:
        this.focusTextField('sendDates')
        break;
      default:
        break;
    }
  }

  focusTextField(fieldRefName: string): void {
    setTimeout(() => {
      // TODO
      // @ts-ignore
      this.$refs[fieldRefName]?.focusTextArea()
    }, 0)
  }

  isValueASpecialChar (value: string) {
    return value?.match(/^[^a-zA-Z0-9]+$/)
  }

  isDateValid ({ sendDate }: IRecipient) {
    if (!sendDate) { return false }
    return new Date(sendDate) >= new Date(this.takeCurrentDate())
  }

  setRecipientsColumnData (columnData: string, columnName: keyof IRecipient) {
    // split csv columns
    const columnArray = columnData.split('\n')
    // remove the last element when it is empty
    if (!columnArray[columnArray.length - 1]) { columnArray.pop() }

    columnArray.forEach((value, index) => {
      if (this.tableRecipients[index]) {
        const data = this.tableRecipients[index]
        data[columnName] = value
        this.$set(this.tableRecipients, index, data)
      } else {
        const recipientInfo = {}
        recipientInfo[columnName] = value
        this.$set(this.tableRecipients, index, recipientInfo)
      }
    })
    setTimeout(() => {
      this.setFocusedField()
    }, 100)
  }

  fillRecipientColumnWith (columnName: keyof IRecipient, fillWith: string | null = null) {
    this.tableRecipients.forEach((item, index) => {
      const data = this.tableRecipients[index]
      data[columnName] = fillWith
      this.$set(this.tableRecipients, index, data)
    })
  }

  clearRecipientColumnData (columnName: keyof IRecipient, formName: string) {
    this.fillRecipientColumnWith(columnName)
    this.tableForm[formName] = null
    if (!this.isFormFilled) { this.tableRecipients = [] }
    this.focusTextField(formName)
  }

  handleContinueButton(): void {
    const { tableRecipients, takeCurrentDate, isDateValid } = this

    this.$emit('update:recipients', tableRecipients.map((recipient) => ({
      ...recipient,
      country: 'US',
      sendDate: isDateValid(recipient)
        ? new Date(recipient.sendDate as string).toISOString()
        : takeCurrentDate()
    })))
    this.$emit('update:step', RootSteps.ReviewRecipients)
  }
}
