<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" class="page-title pb-0">
        My logos & images
      </v-col>
      <v-col cols="12" class="f15 lato-italic gray1 pt-0">
        This is the place where you keep your logos that will be display in the header of your deliverables
      </v-col>
      <!-- alert -->
      <v-col cols="12">
        <v-alert
          :color="!error.show ? '#FFF4C5' : '#FAE3E3'"
          dense
          class="ma-0"
        >
          <template #prepend>
            <icons-info
              class="mr-2"
              v-if="!error.show"
            />

            <icons-warning
              class="mr-2"
              v-else
            />
          </template>

          <v-row dense class="text-no-wrap" align="baseline" :class="{ 'gray4': error.show }">
            <template v-if="error.show">
              <template v-if="error.message">
                <v-col class="flex-shrink-1 flex-grow-0 f16 lato-bold">
                  {{ error.message }}
                </v-col>
              </template>

              <template v-else>
                <v-col class="flex-shrink-1 flex-grow-0 f16 lato-bold">
                  File format not supported. Supported formats:
                </v-col>
                <v-col class="flex-shrink-1 flex-grow-0">
                  .jpg, .jpeg, .png, .pdf, .gif, .ai, .eps (5MB max)
                </v-col>
              </template>
            </template>

            <template v-else>
              <v-col class="flex-shrink-1 flex-grow-0 f16 lato-bold">Upload preferences:</v-col>
              <v-col class="flex-shrink-1 flex-grow-0 black1 f14">
                5MB max | JPG, JPEG, PNG, PDF, GIF, AI, EPS accepted
              </v-col>
            </template>
          </v-row>
        </v-alert>
      </v-col>

      <v-col cols="12" v-if="loading">
        <common-loader style="padding: 200px 0" />
      </v-col>

      <v-col cols="12" class="images-grid" v-else>
        <div class="images-grid__item">
          <the-upload-image
            @newFile="(file) => addNewFile(file)"
            @showError="(val) => showErrorMessage(val)"
          />
        </div>

        <the-image-card
          v-for="file in userFiles"
          :key="file.id"
          :id="file.id"
          :image="file.url"
          :originalName="file.original_name"
          :title.sync="file.caption"
          :isDefault="file.is_default"
          @remove="(id) => removeItem(id)"
          @updateTitle="(id, title) => updateTitle(id, title)"
          @setDefault="(id) => setDefault(id)"
        />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import Api from '@/axios/api'

import TheUploadImage from '@/components/myLogosAndImages/TheUploadImage.vue'
import TheImageCard from '@/components/myLogosAndImages/TheImageCard.vue'

export default {
  name: 'MyLogosAndImages',
  components: {
    TheUploadImage,
    TheImageCard
  },
  data: () => ({
    loading: false,
    error: {
      show: false,
      message: null
    },
    userFiles: []
  }),
  watch: {
    userFiles: {
      deep: true,
      handler: function (images) {
        const defaultImage = images?.find((image) => image?.is_default)
        if (!defaultImage) {
          const firstImage = images?.at(0) ?? null
          if (firstImage) { this.setDefault(firstImage?.id) }
        }
      }
    },
  },
  created () {
    this.loading = true
    Api.get('/customer/logos')
      .then(({ data }) => {
        this.userFiles = data
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    addNewFile (file) {
      this.userFiles.push(file)
    },
    removeItem (id) {
      // TODO move remove from array to Promise.then ??
      Api.delete(`/customer/logos/${id}`)
      this.userFiles = this.userFiles.filter((item) => item.id !== id)
    },
    updateTitle (id, title) {
      Api.put(`/customer/logos/${id}`, {
        caption: title
      })
    },
    setDefault (id) {
      Api.patch(`/customer/logos/${id}/default`)
      this.userFiles = this.userFiles.map((file) => {
        file.is_default = file.id === id
        return file
      })
    },
    showErrorMessage ({ message }) {
      this.error = {
        show: true,
        message
      }
      setTimeout(() => {
        this.error = {
          show: false,
          message: null
        }
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.images-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  & > &__item {
    width: 150px;
    min-width: 150px;
    min-height: 150px;
  }
}

@media (min-width: 1904px) {
  .images-grid {
    gap: 28px;
  }
}
</style>
