<template>
  <v-container class="my-inventory-page py-4">
    <v-row no-gutters>
      <v-col cols="12" class="my-inventory-page__title">
        My inventory
      </v-col>
      <v-col cols="12">
        <v-tabs
          color="#007E76"
          v-model="selectedTab"
          class="my-inventory-page__tabs"
        >
          <v-tabs-slider color="#007E76" />

          <v-tab :ripple="false">
            Inventory status
          </v-tab>
          <v-tab v-if="superAdmin || masterAccount" :ripple="false">
            Transactions history
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab" touchless>
          <v-tab-item :key="0" eager>
            <inventory-status-page />
          </v-tab-item>

          <v-tab-item v-if="superAdmin || masterAccount" :key="1" eager>
            <transactions-history-page />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InventoryStatusPage from '@/components/myInventory/inventoryStatus/TheInventoryStatusPage.vue'
import TransactionsHistoryPage from '@/components/myInventory/transactionsHistory/TheTransactionsHistoryPage.vue'

import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

export default {
  name: 'MyInventory',
  components: {
    InventoryStatusPage,
    TransactionsHistoryPage,
  },
  mixins: [
    superAdmin,
    masterAccount,
  ],
  data: () => ({
    selectedTab: 0,
    tabsHelper: [
      {
        name: 'inventory',
        key: 0,
      },
      {
        name: 'transactions',
        key: 1,
      },
    ],
  }),
  computed: {
    routeQuery () {
      return this.$route.query ?? {}
    },
  },
  watch: {
    routeQuery: {
      immediate: true,
      deep: true,
      handler: function (params) {
        if (params?.tab) {
          const tabKey = this.getTabHelperKeyByName(params?.tab)

          this.selectedTab = tabKey
        }
      }
    },
    selectedTab: function (val) {
      const tabName = this.getTabHelperNameByKey(val) || undefined

      window.scrollTo({ top: 0, left: 0 })
      
      if (this.routeQuery?.tab !== tabName) {
        const query = Object.assign({}, this.$route.query)
        query.tab = tabName
        this.$router.replace({ query }).catch(() => {})
      }
    }
  },
  methods: {
    getTabHelperNameByKey (key = 0) {
      const { tabsHelper } = this

      return tabsHelper?.filter((tab) => tab.key === key)?.at(0)?.name ?? null
    },
    getTabHelperKeyByName (name) {
      const { tabsHelper } = this

      return tabsHelper?.filter((tab) => tab.name === name)?.at(0)?.key ?? null
    },
  },
}
</script>

<style lang="scss" scoped>
.my-inventory-page {
  // max-width: 1550px;
  max-width: 1618px;
  margin: auto;
  padding: 0 64px;

  & > .row {
    gap: 30px;
    flex-direction: column;
  }

  &__title {
    font-weight: 300;
    font-size: 40px;
    color: #000;
  }

  &__tabs {
    margin-bottom: 30px;
    border-bottom: 1px solid #D3D2D2;

    & .v-tabs-slider-wrapper .v-tabs-slider {
      margin-top: unset;
      margin-left: unset;
    }

    &::v-deep .v-tabs-bar__content {
      gap: 40px;
    }

    & .v-tab {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      padding: 0;
      display: flex;
      align-items: center;
      letter-spacing: unset;
      color: #62646A;
      gap: 8px;

      &:hover {
        color: #007E76 !important;
      }

      &::before {
        content: unset;
      }

      &--active {
        color: #007E76;
      }
    }
  }

  .v-window {
    overflow: unset;
  }

  // @media (min-width: 1700px) {
  //   margin-left: unset;
  //   margin-right: unset;
  //   max-width: 100%;
  // }
}
</style>
