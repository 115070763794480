<template>
  <div class="create-campaign-greeting-message">
    <div class="create-campaign-greeting-message__title-wrapper">
      <h1>Add a greeting message</h1>
      <span>
        You can edit the greeting before you send the campaign
        <common-tooltip
          v-if="!$vuetify.breakpoint.xsOnly"
          bottom
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img alt="" :src="getProductImageBySlug('campaigns_greeting_preview')" />
        </common-tooltip>
      </span>
    </div>

    <cg-form ref="form" class="create-campaign-greeting-message__content">
      <div class="create-campaign-greeting-message__form-wrapper">
        <div class="create-campaign-greeting-message__form-greetings-ideas">
          See <span @click="showGreetingIdeas">Greeting Ideas</span>
        </div>

        <div class="create-campaign-greeting-message__form-content">
          <input
            v-model="greetingMessageTitle"
            id="greeting-title"
            type="text"
            v-tooltip="{
              offset: 8,
              container: $el,
              autoHide: false,
              placement: 'top',
              trigger: 'manual',
              disabled: $vuetify.breakpoint.xsOnly,
              show: showGreetingTagTooltip,
              classes: 'send-egift-panel-greeting__tooltip',
              content: getTooltipTextBySlug('egift_collection_greeting_dynamic_tags'),
            }"
            placeholder="Hi @first name"
            @focus="handleTitleFocus"
            @blur="handleTitleBlur"
          >

          <v-popover
            :disabled="$vuetify.breakpoint.xsOnly"
            :offset="8"
            placement="top"
            trigger="manual"
            :container="$el"
            :open="showGreetingContentTooltip"
          >
            <textarea
              v-model="greetingMessageContent"
              id="greeting-content"
              ref="greeting-content"
              placeholder="Have a wonderful day.
              Choose your favorite gift from this curated collection and enjoy!..."
              @focus="handleContentFocus"
              @blur="handleContentBlur"
            />

            <template slot="popover">
              <div v-if="showGreetingContentTooltip" class="send-egift-panel-greeting__tooltip">
                Use prepopulate
                <span role="button" @click="showGreetingIdeas">greeting messages</span>

                <div class="tooltip-arrow" />
              </div>
            </template>
          </v-popover>

          <input
            v-model="greetingMessageFooter"
            id="greeting-from"
            type="text"
            placeholder="Yours truly"
            @focus="handleSelectedArea"
            @blur="selectedAreaId = null"
          >

          <common-upload-logo-panel
            v-if="userIsLoggedIn"
            v-model="greetingMessageLogo"
            :user-logos="userLogos"
            @onNewLogo="saveNewLogo"
          />

          <greeting-upload-logo
            v-else
            v-model="greetingMessageLogo"
            :on-page-helper="true"
          />
        </div>

        <greeting-mention-menu
          v-if="!$vuetify.breakpoint.xsOnly"
          :text-area-id="selectedAreaId"
          :style="`top: ${formPosition.top}px; left: ${formPosition.left}px`"
          :create-campaign-page="true"
          @changeInput="updateSelectedAreaMessage"
        />
      </div>

      <cg-input
        v-model="campaignDisplayName"
        placeholder="Display name"
        :validation="[checkIfStringContainsNotAllowedTag]"
      >
        <template #label>
          <div class="create-campaign-greeting-message__input-label">
            From

            <common-tooltip
              top
              :max-width="null"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              <img alt="" :src="getProductImageBySlug('egift_collections_from')" />
            </common-tooltip>
          </div>
        </template>
      </cg-input>
    </cg-form>

    <cg-button @click="goToNextPage()">Continue to review</cg-button>

    <div class="create-campaign__sticky-button">
      <cg-button block @click="goToNextPage()">Continue to review</cg-button>
    </div>

    <greetings-ideas-panel
      v-model="showGreetingIdeasPanel"
      @changeGreetingMessage="handleNewGreetingMessage"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import userIsLoggedIn from '@/mixins/userIsLoggedIn'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import checkIfStringContainsNotAllowedTag from '@/components/createCampaign/mixins/checkIfStringContainsNotAllowedTag'

import { CgForm, CgInput, CgButton } from '@corporategift/design-system'
import GreetingMentionMenu from '@/components/myCampaign/panels/sendEgift/components/GreetingMentionMenu.vue'
import GreetingUploadLogo from '@/components/myCampaign/panels/sendEgift/components/GreetingUploadLogo.vue'
import GreetingsIdeasPanel from '@/components/myCampaign/panels/TheGreetingsIdeasPanel.vue'
import CommonUploadLogoPanel from '@/components/CommonUploadLogoPanel.vue'

const { mapState, mapMutations, } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignGreeting',
  mixins: [
    userIsLoggedIn,
    getTooltipTextBySlug,
    getProductImageBySlug,
    checkIfStringContainsNotAllowedTag,
  ],
  components: {
    CgForm,
    CgInput,
    CgButton,
    GreetingMentionMenu,
    GreetingUploadLogo,
    GreetingsIdeasPanel,
    CommonUploadLogoPanel,
  },
  data: () => ({
    selectedAreaId: null,
    showGreetingIdeasPanel: false,
    showGreetingTagTooltip: true,
    showGreetingContentTooltip: false,
    formPosition: {
      top: 0,
      left: 0,
    },
  }),
  computed: {
    ...mapState([
      'form',
      'userLogos',
    ]),
    greetingMessageTitle: {
      get () { return this.form.greetingMessage.title },
      set (val) { this.setGreetingMessageTitle(val) },
    },
    greetingMessageContent: {
      get () { return this.form.greetingMessage.content },
      set (val) { this.setGreetingMessageContent(val) },
    },
    greetingMessageFooter: {
      get () { return this.form.greetingMessage.footer },
      set (val) { this.setGreetingMessageFooter(val) },
    },
    campaignDisplayName: {
      get () { return this.form.displayName },
      set (val) { this.setDisplayName(val) },
    },
    greetingMessageLogo: {
      get () { return this.form.greetingMessage.logo },
      set (val) { this.setGreetingMessageLogo(val) },
    }
  },
  watch: {
    greetingMessageContent: {
      immediate: true,
      handler: 'resizeTextArea'
    },
    selectedAreaId: function (val) {
      if (val) { this.getFormPosition() }
    }
  },
  mounted () {
    if (this.$vuetify.breakpoint.xsOnly) {
      this.showGreetingTagTooltip = false
    }

    this.$nextTick(() => {
      this.resizeTextArea();
      this.getFormPosition();
      this.focusFirstInput();
      this.setDefaultLogo();
    })
  },
  methods: {
    ...mapMutations([
      'addNewLogo',
      'setDisplayName',
      'setGreetingMessageLogo',
      'setGreetingMessageTitle',
      'setGreetingMessageFooter',
      'setGreetingMessageContent',
    ]),
    getFormPosition () {
      const formContent = document.querySelector('.create-campaign-greeting-message__form-content')

      if (formContent) {
        const scrollY = window.scrollY
        const { top, left } = formContent.getBoundingClientRect()
        this.formPosition = {
          top: scrollY + top,
          left,
        }
      }
    },
    handleSelectedArea (event) {
      this.selectedAreaId = event.target?.id ?? null
    },
    resizeTextArea () {
      const textarea = this.$el?.querySelector('textarea')

      if (textarea) {
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    },
    focusFirstInput () {
      const greetingTitle = this.$el.querySelector('#greeting-title');
      if (greetingTitle) { greetingTitle.focus() }
    },
    setDefaultLogo () {
      const { greetingMessageLogo, userLogos } = this

      if (!greetingMessageLogo) {
        const defaultImage = userLogos.find(({ is_default }) => is_default) || userLogos.at(0)
        this.greetingMessageLogo = defaultImage?.url ?? null
      }
    },
    handleTitleFocus (event) {
      this.handleSelectedArea(event)
      if (!this.$vuetify.breakpoint.xsOnly) {
        this.$nextTick(() => (this.showGreetingTagTooltip = true))
      }
    },
    handleTitleBlur () {
      this.selectedAreaId = null
      this.showGreetingTagTooltip = false
    },
    handleContentFocus (event) {
      this.handleSelectedArea(event)

      setTimeout(() => {
        this.showGreetingContentTooltip = true
      }, 100)
    },
    handleContentBlur () {
      this.selectedAreaId = null
      setTimeout(() => {
        this.showGreetingContentTooltip = false
      }, 200)
    },
    updateSelectedAreaMessage ({ id, message }) {
      if (id.includes('title')) {
        this.greetingMessageTitle = message
      } else if (id.includes('content')) {
        this.greetingMessageContent = message
      } else if (id.includes('from')) {
        this.greetingMessageFooter = message
      } else {
        console.error(`Can't handle form with id: ${id}`)
      }
    },
    showGreetingIdeas () {
      this.showGreetingContentTooltip = false;
      this.showGreetingIdeasPanel = true;
    },
    handleNewGreetingMessage (message) {
      this.greetingMessageContent = message;
      this.$nextTick(() => { this.resizeTextArea(); });
    },
    saveNewLogo (logo) {
      this.addNewLogo(logo)
    },
    validateGreetingMessage () {
      const { content, footer, title } = this.form.greetingMessage

      const greetingMessage = [title, content, footer].join(' ')

      const result = this.checkIfStringContainsNotAllowedTag(greetingMessage)

      if (typeof result === 'string') {
        this.$cgToast.error('Greeting message contains not-allowed HTML tag')

        return false
      }

      return result
    },
    goToNextPage () {
      if (!this.validateGreetingMessage()) { return }

      if (this.$refs.form.validate()) {
        this.$router.push({ name: 'CreateCampaignReview', params: this.$route.params })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-greeting-message {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    & > span {
      font-weight: 400;
      font-style: italic;
      font-size: 15px;
      font-weight: 400;
      line-height: normal;
      color: #62646A;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 8px;

      @media screen and (max-width: 430px) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    @media screen and (max-width: 430px) {
      padding: 0 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 46px;

    @media screen and (max-width: 430px) {
      gap: 40px;
      width: 100%;

      .cg-input {
        padding: 0 16px;
      }
    }
  }

  &__form-wrapper {
    background-color: #fafafa;
    width: 100%;
    min-width: 520px;
    min-height: 300px;
    padding: 30px;

    @media screen and (max-width: 430px) {
      min-width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
    }
  }

  &__form-content {
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #D3D2D2;
    padding: 20px;
    height: 100%;
    gap: 40px;


    & > input {
      padding: 10px 0;
    }

    & textarea {
      min-height: 100px;
      padding: 10px;
      resize: none;
      overflow: hidden;
    }

    & > input,
    & textarea {
      width: 100%;
      outline: none;
      font-weight: 400;
      font-style: italic;
      border: 2px solid transparent;
      box-sizing: border-box;
      text-align: center;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      color: #000;
      letter-spacing: 0.293333px;

      &::placeholder {
        color: #95979D;
      }

      &:hover,
      &:focus {
        border-color:#B0A0FF;
      }
    }

    & > .v-popover {
      width: 100%;
    }

    @media screen and (max-width: 430px) {
      border-radius: 16px;

      & > input,
      & textarea {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  &__form-greetings-ideas {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #000;
    display: none;
    margin-bottom: 16px;

    & > span {
      color: #6E50FF;
      cursor: pointer;
    }

    @media screen and (max-width: 430px) {
      display: block;
    }
  }

  &__input-label {
    font-weight: 700;
    display: flex;
    align-items: center;
    line-height: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
  }

  & .send-egift-panel-greeting__tooltip {
    & a,
    & span {
      color: #6E50FF !important;

      &:hover {
        color: #6E50FF !important;
      }
    }
  }

  & > button,
  & > a {
    border-radius: 100px;

    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  @media screen and (max-width: 430px) {
    gap: 40px;
    width: 100%;
  }
}
</style>
