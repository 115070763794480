<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    width="390"
  >
    <v-container class="pa-1">
      <v-row dense>
        <v-col cols="11">
          <v-card class="rounded-0">
            <common-loader v-if="loading" style="padding: 40px 0" />

            <template v-else>
              <v-card-title class="pa-0">
                Remove {{ isCreditCard ? 'card' : 'account' }}
              </v-card-title>

              <v-card-text class="pa-0 py-5">
                Are you sure you want to remove this {{ isCreditCard ? 'card' : 'account' }}?
              </v-card-text>

              <v-card-actions class="pa-0">
                <common-button
                  outlined
                  height="40"
                  width="146"
                  @click="show = false"
                >
                  Cancel
                </common-button>

                <common-button
                  class="ma-0"
                  height="40"
                  width="136"
                  @click="onSubmitMiddleware"
                >
                  Remove
                </common-button>
              </v-card-actions>
            </template>
          </v-card>
        </v-col>
        <v-col cols="1" class="d-flex align-start pt-0">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'

export default {
  name: 'TheDeleteCardAchModal',
  mixins: [
    panelVModel,
    userIsUsingBlueSnap,
  ],
  props: {
    selectedItem: {
      type: Object,
      required: false,
      default: null
    },
  },
  data: () => ({
    loading: false
  }),
  computed: {
    token () {
      return this.selectedItem?.token ?? null
    },
    isCreditCard () {
      return !!this.selectedItem?.cardLastFourDigits ?? false
    },
    isACH () {
      return !!this.selectedItem?.routingNumber ?? false
    },
  },
  watch: {
    show: function (val) {
      if (val) { this.loading = false }
    }
  },
  methods: {
    onSubmitMiddleware () {
      if (this.userIsUsingBlueSnap) {
        this.removeBluesnapItem()
      } else {
        this.onSubmit()
      }
    },
    onSubmit () {
      const { token, isCreditCard, isACH } = this
      this.loading = true

      Api.delete(`/braintree/payment-methods/${token}`)
        .then(() => {
          if (isCreditCard) { this.$emit('onCardRemoved', this.selectedItem) }
          if (isACH) { this.$emit('onACHRemoved', this.selectedItem) }
        })
        .finally(() => (this.show = false))
    },
    removeBluesnapItem () {
      const { selectedItem, isCreditCard, isACH } = this
      this.loading = true

      const url = isCreditCard ? 'bluesnap/credit-cards' : 'bluesnap/ach'

      Api.delete(url, { data: selectedItem })
        .then(() => {
          if (isCreditCard) { this.$emit('onBluesnapCardRemoved', this.selectedItem) }
          if (isACH) { this.$emit('onBluesnapACHRemoved', this.selectedItem) }
        })
        .finally(() => (this.show = false))

    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  padding: 28px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  &__actions {
    gap: 12px;
  }
}

.mdi-close::before {
  font-size: 24px !important;
}
</style>
