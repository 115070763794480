
import { Mixins, Component, Watch } from 'vue-property-decorator'
import type { IRecipient, VuetifyForm } from '../../types';

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import takeCurrentDate from '../mixins/takeCurrentDate'
import recipientValidations from '../mixins/recipientValidations'

import SingleDatePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

@Component({
  components: {
    SingleDatePicker,
  },
})
export default class SendEgiftPanelRecipientsManually extends Mixins(
  panelTypeProp,
  recipientsProp,
  takeCurrentDate,
  recipientValidations,
) {
  setSendDateForAllRecipients = false

  sendDateForNewRecipient: string | null = null

  get egiftRecipients (): Array<IRecipient> {
    return this.recipients
  }

  set egiftRecipients (val: Array<IRecipient>) {
    this.$emit('update:recipients', val)
  }

  get isEveryEmailUnique (): boolean {
    const { egiftRecipients } = this

    const emails = egiftRecipients?.map((recipient) => recipient.email).filter((email) => email) ?? []

    return new Set(emails).size === emails.length
  }

  @Watch('setSendDateForAllRecipients')
  onValueChange (val: boolean) {
    if (!val) { this.sendDateForNewRecipient = null }
  }

  created () {
    if (!this.egiftRecipients.length) {
      this.addNewRecipient();
    }
  }

  addNewRecipient () {
    this.egiftRecipients.push({
      firstName: null,
      lastName: null,
      email: null,
      sendDate: this.sendDateForNewRecipient || this.takeCurrentDate(),
    })
  }

  deleteRecipient (index: number): void {
    if (this.egiftRecipients.length <= 1) { return }

    this.egiftRecipients.splice(index, 1)
  }

  handleSendDateChange (sendDate: string): void {
    const { setSendDateForAllRecipients, egiftRecipients } = this
    if (setSendDateForAllRecipients) {
      this.sendDateForNewRecipient = sendDate

      egiftRecipients.forEach((recipient) => {
        recipient.sendDate = sendDate
      })
    }
  }

  isEmailDuplicated (emailToCheck: string): boolean {
    const { egiftRecipients } = this

    if (!emailToCheck) return false

    const emails = egiftRecipients?.map((recipient) => recipient.email) ?? []
    return emails.filter((email) => email === emailToCheck).length !== 1
  }

  validate (): boolean {
    if (!this.isEveryEmailUnique) { return false }

    return (this.$refs['form'] as VuetifyForm)?.validate() ?? false;
  }
}
