<template>
  <div class="add-multiple-users-panel-upload-file">
    <upload-csv v-model="selectedFile" />

    <a href="https://cf.corporategift.com/resources/sample_recipient_list.csv" download>
      Download our template
    </a>

    <div class="add-multiple-users-panel-upload-file__actions">
      <cg-button :disabled="!file" @click="$emit('update:step', 4)">
        Continue
      </cg-button>
    </div>
  </div>
</template>

<script>
import { CgButton } from '@corporategift/design-system'
import UploadCsv from '@/components/myCampaign/panels/sendEgiftPanel/recipients/TheUploadCsv.vue'

export default {
  name: 'MultipleUsersUploadFile',
  components: {
    CgButton,
    UploadCsv,
  },
  props: {
    file: {
      type: File,
      required: false,
      default: null
    }
  },
  computed: {
    selectedFile: {
      get () { return this.file },
      set (val) { this.$emit('update:file', val) }
    }
  },
  watch: {
    file: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.readFile(val)
        } else {
          this.$emit('update:usersData', [])
        }
      }
    },
  },
  methods: {
    readFile (file) {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        this.$emit('update:usersData', this.csvToArray(result))
      }
      reader.readAsText(file)
    },
    csvToArray (csvFile, delimiter = /,|\t/) {
      // remove first row with headers
      csvFile.slice(0, csvFile.indexOf('\n')).split(delimiter)

      const headers = ['firstname', 'lastname', 'email']
      let rows = csvFile.slice(csvFile.indexOf('\n') + 1).split('\n')
      // Remove empty rows
      rows = rows.filter((item) => !!item.replaceAll(',', ''))
      const arrayOfAddresses = rows.map((row) => {
        // csv row data can contain string with comma
        const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        const address = headers.reduce((object, header, index) => {
          object[header] = values[index]?.replace('\r', '')?.trim()
          return object
        }, {})
        return address
      })

      return arrayOfAddresses
    },
  }
}
</script>

<style lang="scss" scoped>
.add-multiple-users-panel-upload-file {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4px;
    align-items: center;
    color: #42B77A !important;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &:hover {
      color: #219358 !important;
    }
  }
}
</style>
