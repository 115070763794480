
import { Component, Mixins } from 'vue-property-decorator'
import { RecipientMethod, RootSteps } from '../../types'

import RecipientsBulkOptionsCard from '../components/RecipientsBulkOptionsCard.vue'
import RecipientsBulkOptionsUploadCard from '../components/RecipientsBulkOptionsUploadCard.vue'

@Component({
  components: {
    RecipientsBulkOptionsCard,
    RecipientsBulkOptionsUploadCard
  },
})
export default class SendEgiftPanelRecipientsBulkOptions extends Mixins() {
  moveUserToCopyPasteStep (): void {
    this.$emit('update:recipientMethod', RecipientMethod.ImportCopyPaste)
    this.$emit('update:step', RootSteps.RecipientBulkCopyPaste)
  }

  moveUserToContactsStep (): void {
    this.$emit('update:recipientMethod', RecipientMethod.Contacts)
    this.$emit('update:step', RootSteps.RecipientBulkContacts)
  }
}
