<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    tag="aside"
    width="900px"
    class="right-side-panel"
  >
    <panel-header :show.sync="show">
      Greeting messages
    </panel-header>

    <common-loader v-if="loading" style="max-height: 800px" />

    <div v-else class="right-side-panel__content-wrapper">
      <div class="greeting-ideas-panel__text">
        Choose a template. You can edit it later:
      </div>

      <div class="greeting-ideas-panel__filters">
        <common-filter-select
          v-if="$vuetify.breakpoint.xsOnly"
          v-model="selectedType"
          :items="messageTypes"
        />

        <the-greetings-ideas-panel-select
          v-else
          v-model="selectedType"
          :items="messageTypes"
          placeholder="All tags"
        />
      </div>

      <div class="greeting-ideas-panel__messages">
        <div
          v-for="(message, index) in availableMessages"
          :key="message.id"
          @click="changeGreetingMessage(message.gift_message)"
          class="greeting-ideas-panel__message"
          :style="{
            background: randomColor(index + 1)
          }"
        >
          {{ message.gift_message }}
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

import TheGreetingsIdeasPanelSelect from './TheGreetingsIdeasPanelSelect.vue'
import PanelHeader from '@/components/PanelHeader.vue'

export default {
  name: 'TheGreetingsIdeasPanel',
  components: {
    PanelHeader,
    TheGreetingsIdeasPanelSelect
  },
  mixins: [panelVModel],
  data: () => ({
    loading: false,
    messages: [],
    messageTypes: [],
    selectedType: []
  }),
  computed: {
    availableMessages () {
      const { messages, selectedType } = this
      if (!selectedType.length) return messages

      return messages?.filter(message => {
        return selectedType.includes(message.occassion)
      }) || []
    }
  },
  watch: {
    show: function (val) {
      // TODO store in sessionStorage or indexedDB or service worker
      if (val) {
        this.loading = true
        this.messages = []
        this.messageTypes = []

        Api.get('/customer/get-greeting-messages')
          .then(({ data }) => {
            this.messages = data
            this.messageTypes = [...new Set(data.map(message => message.occassion))]
            this.loading = false
          })
      }
    }
  },
  methods: {
    randomColor (index) {
      const colors = [
        '#D8F1E4',
        '#FFF8D9',
        '#DFFDFF',
        '#FFF1FD',
        '#E5DFFF',
        '#FFF0E1',
        '#F1FFBE',
        '#FFEBEC',
        '#F6F5FF',
        '#FAFAFA',
        '#FEE3FA',
      ]
      const colorIndex = Math.floor(Math.abs(Math.cos(index)) * colors.length)
      return colors[colorIndex]
    },
    changeGreetingMessage (newMessage) {
      this.$emit('changeGreetingMessage', newMessage)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/common/right-side-panel.scss';

.greeting-ideas-panel {
  &__filters {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 430px) {
      justify-content: flex-start;
    }
  }

  &__text {
    color: #000;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    font-style: normal;
  }

  &__messages {
    columns: 3 250px;
    column-gap: 12px;
    max-width: 800px;

    @media screen and (max-width: 430px) {
      columns: 2;
    }
  }

  &__message {
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    margin: 0 12px 12px 0;
    padding: 16px;
    cursor: pointer;
    word-break: break-word;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: #000;

    &:hover {
      opacity: 0.7;
    }

    @media screen and (max-width: 430px) {
      width: 100%;
      margin: 0 8px 8px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>
