<template>
  <div class="greeting-select">
    <div class="greeting-select__selected" @click="showOptions()">
      {{ selected ? selected : placeholder }}
    </div>

    <div
      class="greeting-select__icon"
      @click="showOptions()"
      :class="showMenu && 'greeting-select__icon--rotate'"
    >
      <svg
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
        />
      </svg>
    </div>

    <div class="greeting-select__options elevation-4" v-if="showMenu">
      <div class="search-area">
        <common-input
          v-model="search"
          placeholder="Search…"
          height="30"
          prepend-inner-icon="mdi-magnify"
        />
      </div>

      <div class="options__item" @click="selectAll()" v-if="!search">
        <div
          class="options__item--icon"
          :class="value.length === 0 && 'options__item--icon--selected'"
        />
        <div class="options__item--name">
          All
        </div>
      </div>

      <div v-for="(item, index) in filteredItems" :key="index">
        <div class="options__item" @click="selectItem(item)">
          <div
            class="options__item--icon"
            :class="isItemSelected(item) && 'options__item--icon--selected'"
          />
          <div class="options__item--name">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheGreetingsIdeasPanelSelect',
  props: {
    value: Array,
    items: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    closeOnOutsideClick: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    showMenu: false,
    search: ''
  }),
  computed: {
    selected: {
      get () {
        return this.value.toString()
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    filteredItems () {
      const { search, items } = this
      return items?.filter(item => item.toLowerCase().includes(search.toLowerCase())) || []
    }
  },
  mounted () {
    if (this.closeOnOutsideClick) {
      document.addEventListener('click', this.clickHandler)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.showMenu = false
      }
    },
    isItemSelected (item) {
      return this.value.includes(item)
    },
    showOptions () {
      this.showMenu = !this.showMenu
    },
    selectAll () {
      this.selected = []
    },
    selectItem (item) {
      const selectedItems = this.value

      if (selectedItems.includes(item)) {
        this.selected = selectedItems?.filter(selected => selected !== item) || []
      } else {
        selectedItems.push(item)
        this.selected = selectedItems
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.greeting-select {
  display: flex;
  height: 30px;
  min-height: 24px;
  max-width: 240px;
  min-width: 240px;
  padding: 3px;
  border: 1px solid #D3D2D2;
  text-align: left;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  user-select: none;
  box-sizing: border-box;

  &__selected {
    display: inline-block;
    max-width: 90%;
    min-width: 90%;
    cursor: pointer;
    padding: 0 6px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }

  &__icon {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    height: 20px;
    width: 20px;
    transform: rotate(-90deg);
    transition: all 0.33s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &--rotate {
      transform: rotate(90deg);
    }

    svg {
      width: inherit;
      height: inherit;
    }
  }

  &__options {
    background-color: #fff;
    width: 130%;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #d3d2d2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    transform: translateX(1px);
    overflow-y: auto;
    max-height: 500px;
    top: 100%;
    left: -30%;
    right: 0;
    z-index: 5;

    .search-area {
      height: 50px;
      background: inherit;
      width: 100%;
      top: 0;
      position: sticky;
      z-index: 4;
      padding: 10px 14px;
    }

    .options__item {
      cursor: pointer;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      gap: 8px;
      padding: 0 16px;

      &--name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        font-weight: 400;
        font-size: 14px;
        line-height: 37px;
        color: #000;
      }

      &:hover {
        background-color: #F5F5F5;
      }

      &--icon {
        display: flex;
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        border: 1px solid #DADADA;
        background: #fff;
        position: relative;

        &--selected {
          border: none;
          background-color: #42B77A;

          &::after {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%,-50%) rotate(45deg);
            content: ' ';
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #D8D8D8;
    }
  }
}
</style>
