<template>
  <popper
    :options="{
      placement: 'right',
      modifiers: {
        offset: {
          offset: '0, 4px'
        },
        preventOverflow: {
          boundariesElement: 'offsetParent'
        }
      }
    }">
    <div class="popper pa-4 text-left white customized-popper" style="max-width: 350px">
      <ul class="kit-items-tooltip pl-0">
        <li v-for="item in items" :key="item.sku">
          <img :src="item.image_url" alt="">

          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="green2 orders-table__product-status" slot="reference">
      Kit items
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'KitItemsTooltip',
  components: { Popper },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    }
  },
}
</script>

<style lang="scss">
.kit-items-tooltip {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    padding: 4px 0;

    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    &:not(:first-child) {
      border-top: 1px solid #D3D2D2;
    }
  }
}

.orders-table__product-status {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  height: 22px;
}

.customized-popper {
  &.popper[x-placement^="right"] .popper__arrow {
    left: -7px !important;
    transform: rotate(135deg);
  }
}
</style>
