<template>
  <div class="create-campaign-review-campaign">
    <h1>Review campaign</h1>

    <cg-loader v-if="loading" />

    <template v-else>
      <div v-if="!userIsLoggedIn" class="create-campaign-review-campaign__login-text">
        Heads up! To save your campaign for ongoing gifting, you must log in or sign up.
      </div>

      <div class="create-campaign-review-campaign__actions">
        <cg-button v-if="false" outlined>Recipients’ view</cg-button>

        <cg-button :to="loginURL" @click="activateCampaign">
          {{ continueButtonText }}
        </cg-button>
      </div>

      <div class="create-campaign-review-campaign__cards">
        <campaign-details-card />
        <campaign-greeting-card />
        <campaign-advanced-options-card ref="advancedOptions" />
      </div>

      <div class="create-campaign__sticky-button">
        <cg-button :to="loginURL" @click="activateCampaign">
          {{ continueButtonText }}
        </cg-button>
      </div>
    </template>
  </div>
</template>

<script>
import Api from '@/axios/api'
import userIsLoggedIn from '@/mixins/userIsLoggedIn'
import { createNamespacedHelpers } from 'vuex'

import { CgButton, CgLoader } from '@corporategift/design-system'
import CampaignDetailsCard from '@/components/createCampaign/CreateCampaignReviewCardDetails.vue'
import CampaignGreetingCard from '@/components/createCampaign/CreateCampaignReviewCardGreeting.vue'
import CampaignAdvancedOptionsCard from '@/components/createCampaign/CreateCampaignReviewCardAdvancedOptions.vue'

const { mapState, mapGetters, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignReview',
  mixins: [userIsLoggedIn],
  components: {
    CgButton,
    CgLoader,
    CampaignDetailsCard,
    CampaignGreetingCard,
    CampaignAdvancedOptionsCard,
  },
  computed: {
    ...mapState([
      'form',
      'source'
    ]),
    ...mapGetters(['greetingMessage']),
    loading: {
      get () { return this.source.loading },
      set (val) { this.setSourceLoading(val) },
    },
    campaignId () {
      return this.$route.params.campaignId ?? 'create'
    },
    loginURL () {
      const { $route, userIsLoggedIn } = this

      if (userIsLoggedIn) { return undefined }
      return { name: 'LoginPage', query: { redirect: $route.fullPath } }
    },
    continueButtonText () {
      const { campaignId, userIsLoggedIn } = this

      if (!userIsLoggedIn) { return 'Login / Sign up' }
      return campaignId === 'create' ? 'Activate campaign' : 'Save changes'
    },
  },
  methods: {
    ...mapMutations([
      'clearFormState',
      'setSourceLoading',
    ]),
    getRequestBody () {
      const { form, greetingMessage } = this

      const freeFormLink = ({ successButton }) => {
        if (successButton.addButton) {
          return {
            free_form_button: successButton.buttonLabel,
            free_form_url: successButton.buttonURL,
          }
        } else {
          return {
            free_form_button: null,
            free_form_url: null,
          }
        }
      }

      return {
        name: form.name,
        greeting_message: greetingMessage,
        products: form.selectedProducts.map(({ id, sourceId, isInventory, isCollection }) => ({
          id,
          source_id: sourceId,
          source_type: isInventory ? 'inventory'
            : isCollection ? 'collection'
            : 'wishlist'
        })),
        logo_url: form.greetingMessage.logo,
        gift_giver_name: form.displayName,
        is_active: form.isActive,
        tag_ids: form.tags.map((tag) => tag.id),
        email_subject_line: form.advancedOptions.emailSubjectLine,
        video_greeting: form.advancedOptions.videoGreeting,
        enable_upgrade: form.advancedOptions.allowExchange,
        expire_after: form.advancedOptions.expirationDays,
        skip_thankyou_message: form.advancedOptions.disableThankYouNotes,
        disable_recipient_reminders: form.advancedOptions.disableReminderEmails,
        ...freeFormLink(form.advancedOptions),
      }
    },
    activateCampaign () {
      if (!this.userIsLoggedIn) { return }

      if (this.$refs.advancedOptions?.validate()) {
        const { campaignId } = this
        this.loading = true
        const requestBody = this.getRequestBody()

        if (campaignId === 'create') {
          Api.post('/campaigns', requestBody)
            .then(() => {
              this.$cgToast.success('New campaign created successfully!')
              this.clearFormState()
              sessionStorage.setItem('campaignsSortBy', '-date_added')
              this.$router.push({ name: 'MyCampaignsPage' })
            })
            .catch(({ response }) => {
              const errorMessage = response?.data?.message ?? 'An unexpected error occurred, please try again later or contact our support'
              this.$cgToast.error(errorMessage)
            })
            .finally(() => (this.loading = false))
        } else {
          Api.put(`campaigns/${campaignId}`, requestBody)
            .then(() => {
              this.$cgToast.success('Campaign saved successfully!')
              this.clearFormState()
              this.$router.push({ name: 'MyCampaignsPage' })
            })
            .catch(({ response }) => {
              const errorMessage = response?.data?.message ?? 'An unexpected error occurred, please try again later or contact our support'
              this.$cgToast.error(errorMessage)
            })
            .finally(() => (this.loading = false))
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-review-campaign {
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__login-text {
    color: #62646A;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    line-height: 19px;
  }

  &__actions {
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;

    & > button,
    & > a {
      border-radius: 100px;
    }

    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  &__cards {
    gap: 30px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    @media screen and (max-width: 430px) {
      flex-direction: column;
      gap: 16px;
    }
  }
}
</style>

<style lang="scss">
.create-campaign-review-card {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 1 0px;
  border-radius: 16px;
  padding: 30px;
  background-color: #FAFAFA;

  & h2 {
    color: #222325;
    font-weight: 700;
    font-size: 26px;
    line-height: 31.2px;

    @media screen and (max-width: 430px) {
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
    }
  }

  & hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #D3D2D2;
  }

  &__text-wrapper {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;

    & > a {
      line-height: 21.28px;
      font-weight: 400;
      font-size: 16px;
      color: #6E50FF !important;
    }
  }

  &__text {
    color: #222325;
    font-weight: 400;
    font-size: 16px;
    word-break: break-all;
    line-height: 21.28px;

    & > b {
      font-weight: 700;
    }
  }
}
</style>