<template>
  <empty-image-col
    :cols="cols"
    class="multiple-products"
  >
    <slot />
  </empty-image-col>
</template>

<script>
import EmptyImageCol from './EmptyImageCol.vue'

export default {
  name: 'MultipleProductsCol',
  components: {
    EmptyImageCol
  },
  props: {
    cols: {
      type: [Number, String],
      required: false,
      default: 3
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-products {
  background-color: #DFF3F2;
  display: grid;
  place-items: center;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  color: #919191;
}
</style>
