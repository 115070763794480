
import { Vue, Component, Prop } from 'vue-property-decorator'

import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'

@Component({
  components: {
    ReviewSaveCancelIcons
  },
})
export default class ReviewTextarea extends Vue {
  @Prop({ required: false, default: null }) value!: string | null;

  @Prop({ required: false, default: () => ([]) }) rules!: Array<unknown>;

  inputHelper: string | null = null;

  get modelValue (): string | null {
    return this.value
  }

  set modelValue (val: string | null) {
    this.$emit('input', val)
  }

  mounted () {
    this.inputHelper = this.modelValue
  }

  handleOnSave (e: Event): void {
    if (e) { e.preventDefault(); }
    // @ts-ignore
    const result = this.$refs['review-input']?.$children?.[0]?.validate() ?? true
    if (!result) { return }

    this.modelValue = this.inputHelper
    this.handleOnCancel()
  }

  handleOnCancel (): void {
    this.$emit('exitEditMode')
  }
}
