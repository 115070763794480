<template>
<!-- TODO refactor table and bought -->
  <v-navigation-drawer
    class="allocate-budget-panel"
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="800px"
  >
    <close-panel-button @close="show = false" />

    <v-container class="px-10 py-0" v-if="show">
      <v-row dense>
        <v-col cols="12" class="lato-light f30">
          {{ isBoughtCredits ? 'Allocate credits' : 'Reallocate credits' }}

          <span class="gray1 f15 lato-italic" v-if="isBoughtCredits">
            Added:
            {{
              boughtCredits | priceFilter({
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </span>
        </v-col>
        <v-col cols="12" class="f12 text-uppercase d-flex justify-end align-baseline">
          Total:
          <span class="f20 mx-1 lato-light">
            {{
              total | priceFilter({
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
              })
            }}
          </span>
        </v-col>
        <v-col cols="12">
          <div class="d-flex mt-1" style="border-radius: 12px; overflow: hidden;">
            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0,0' } }
              }"
              v-for="{color, balance, name, id } in budgetEntities"
              :key="id"
            >
              <div class="popper pa-4">
                <div class="d-flex align-center" style="gap: 10px">
                  <span class="dot" :style="'background: ' + color"></span>
                  <span class="f13 lato-bold text-capitalize">{{ name }}</span>
                </div>
                <div class="d-flex align-center">
                  <span class="d-inline-block f10 gray2 text-uppercase">Credit balance:</span>
                  <span class="d-inline-block f14 ml-2">
                    {{
                      balance | priceFilter({
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </div>
              <div
                slot="reference"
                :style="'width: ' + (max*balance / total) + 'px; height: 24px; min-width: 3px; ' + 'background: ' + color"
              />
            </popper>
          </div>
        </v-col>

        <v-col cols="12" class="pt-10">
          <common-loader v-if="loading" />

          <v-data-table
            :headers="headers"
            :items="budgetEntities"
            hide-default-footer
            mobile-breakpoint="900"
            :items-per-page="999"
            v-else
          >
            <template v-slot:item.heading="{ item }">
              <div class="d-flex align-center" style="gap: 10px">
                <div class="dot" :style="'background: ' + item.color"></div>
                <div class="text-capitalize">{{ item.name }}</div>
              </div>
            </template>
            <template v-slot:item.balance="{ item }">
              <div class="f14 black1" v-if="item.balance > 0">
                {{
                  item.balance | priceFilter({
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                }}
              </div>
              <div class="f14 black1" v-else>-</div>
            </template>
            <template v-slot:item.action="{ item }">
              <reallocate-move-credits
                v-if="item.balance > 0 && budgetEntities.length > 1"
                :item="item"
                :entities="budgetEntities"
                @rellocateCredits="(info) => rellocateCredits(info)"
                @move="onMoveHandler()"
              />

              <span class="gray4" v-if="budgetEntities.length === 1">
                N/A
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <template #append>
      <v-container class="pa-0" v-if="alertText">
        <v-row
          dense
          class="msg_err pa-4"
          :class="{
            'msg_success': alertText.includes('uccessfully'),
            'msg_warn': alertText.includes('...')
          }"
        >
          <v-col cols="12" class="d-flex align-center" style="gap: 16px">
            <v-img
              :src="alertText.includes('uccessfully') ? '/images/selected-icon.svg' : 'images/info_icon.svg'"
              alt="Info icon"
              width="22"
              height="22"
              max-width="22"
              max-height="22"
            />
            {{ alertText }}
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import ReallocateMoveCredits from '@/components/myCGCredits/credits/ReallocateMoveCredits'
import Popper from 'vue-popperjs'

import panelVModel from '@/mixins/panelVModel'
import { budgetEntities } from '../mixins'

export default {
  name: 'AllocatePanel',
  components: {
    ReallocateMoveCredits,
    Popper
  },
  mixins: [
    panelVModel,
    budgetEntities
  ],
  props: {
    isBoughtCredits: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    max: 720, // for styles and calculations
    headers: [
      {
        text: 'budget entity',
        value: 'heading',
        sortable: false,
        align: 'start',
        width: '210'
      },
      {
        text: 'credits balance',
        value: 'balance',
        sortable: false,
        align: 'end',
        width: '130'
      },
      {
        text: 'move credits',
        value: 'action',
        sortable: false,
        style: 'align: left',
        align: 'start'
      }
    ],
    alertText: null,
    boughtCredits: 0
  }),
  computed: {
    total () {
      return this.budgetEntities.reduce((acc, item) => (acc += item.balance || 0), 0)
    }
  },
  // debug and move to computed if should show bouthgt credits?
  watch: {
    show (val) {
      if (val) {
        if (this.isBoughtCredits) this.boughtCredits = Number(localStorage.getItem('creditAmount'))
      } else {
        this.$emit('bought')
      }
    }
  },
  methods: {
    rellocateCredits (info) {
      this.alertText = `Moving $${info.amount} from ${this.getBudgetEntityNameById(info.from)} to ${this.getBudgetEntityNameById(info.to)}...`
      Api.post('/credit/transactions/transfer', info).then(({ data }) => {
        if (data.length) {
          this.alertText = `Successfully moved $${info.amount} from
            ${this.getBudgetEntityNameById(info.from)} to ${this.getBudgetEntityNameById(info.to)}`
        }
        this.$emit('fetchNewData')
      }).catch(err => console.error(err))
    },
    getBudgetEntityNameById (budgetEntityId) {
      const { budgetEntities } = this
      const [firstEntity] = budgetEntities.filter(item => item.id === budgetEntityId)
      return firstEntity?.name || null
    },
    onMoveHandler () {
      setTimeout(() => { this.alertText = null }, 5000)
    }
  }
}
</script>

<style lang="scss">
.allocate-budget-panel {

  .v-data-table-header {

    tr {

      th {
        height: 33px!important;
        border-right: none;
        padding: 0 12px!important;

        span {
          font-size: 11px!important;
          text-transform: uppercase;
        }
      }

      th:nth-child(2) {
        padding-right: 20px !important;
      }
    }
  }

  tbody {
    tr {
      background: white;
      height: 62px;

      td {
        padding: 0px 12px !important;
        border-bottom: none!important;
      }

      td:nth-child(2) {
        padding-right: 20px !important;
      }
    }
  }

  .dot {
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
  }
  .msg {
    &_err {
      background: #fca4a1;
    }
    &_warn {
      background-color: #fceba4 !important;
    }
    &_success, &_info {
      background-color: #d8f1e4 !important;
    }
  }
}
</style>
