
import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import type { IReviewField, ICampaignSettings } from '../../types';

import campaignProp from '../mixins/campaignProp'

import ReviewField from './ReviewField.vue'
import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'

@Component({
  components: {
    ReviewField,
    ReviewSaveCancelIcons,
  },
})
export default class ReviewFieldsSettings extends Mixins(campaignProp) {
  @Prop({ required: true, default: false }) canUpgrade!: boolean;

  @Prop({ required: true, default: false }) canReEgift!: boolean;

  inEditMode = false;

  canUpgradeHelper = false;

  canReEgiftHelper = false;

  get giftSettingsData (): Array<{ name: string, value: boolean }> {
    return [
      {
        name: 'Exchange',
        value: this.canUpgrade,
      },
      {
        name: 'Regift',
        value: this.canReEgift,
      },
    ]
  }

  get campaignSettings(): ICampaignSettings {
    return this.campaign?.egift_settings ?? {}
  }

  get canOverrideReEgift (): boolean {
    return this.campaignSettings.can_override_is_regift_enabled ?? false
  }

  get canOverrideUpgrade (): boolean {
    return this.campaignSettings.can_override_is_upgrade_enabled ?? false
  }

  get userCanOverrideSettings (): boolean {
    const { canOverrideReEgift, canOverrideUpgrade } = this

    return [canOverrideReEgift, canOverrideUpgrade].includes(true)
  }

  @Watch('inEditMode', { immediate: false })
  onEditModeChange (newVal: boolean) {
    if (newVal) {
      this.canReEgiftHelper = this.canReEgift;
      this.canUpgradeHelper = this.canUpgrade;
    }
  }

  handleOnSave (): void {
    this.$emit('update:canUpgrade', this.canUpgradeHelper)
    this.$emit('update:canReEgift', this.canReEgiftHelper)

    this.handleOnCancel()
  }

  handleOnCancel (): void {
    const refElement = this.$refs?.['review-field']

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
