
import { Mixins, Component } from 'vue-property-decorator'
import { RecipientMethod, RootSteps } from '../../types'
import type { IRecipient } from '../../types'

import takeCurrentDate from '../mixins/takeCurrentDate'

@Component
export default class RecipientsBulkOptionsUploadCard extends Mixins(takeCurrentDate) {
  onChange (): void {
    const input = this.$refs.file as HTMLInputElement

    const file: File | null = input?.files ? input.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.onload = ({ target }) => {
        if (target?.result) { this.convertCsvToArray(target.result as string) }
      }
      reader.readAsText(file)
    }
  }

  isDateValid (sendDate?: string | null) {
    if (!sendDate) { return false }
    return new Date(sendDate) >= new Date(this.takeCurrentDate())
  }

  convertCsvToArray (fileData: string, delimiter: RegExp = /,|\t/): void {
    // remove first row with headers
    fileData.slice(0, fileData.indexOf('\n')).split(delimiter)
    // TODO handle country
    const headers = ['firstName', 'lastName', 'email', 'sendDate']
    let rows = fileData.slice(fileData.indexOf('\n') + 1).split('\n')
    // Remove empty
    rows = rows?.filter((item) => !!item) || []

    const arrayOfRecipients: Array<IRecipient> = rows.map((row) => {
      const values = row.split(delimiter)

      const recipient = headers.reduce<IRecipient>((object, header, index) => {
        // sendDate
        if (header === headers[3]) {
          const value = values[index]
          const date = this.isDateValid(value)
            ? new Date(value).toISOString()
            : this.takeCurrentDate()
          object[header] = date
        } else {
          object[header] = values[index]
        }
        return object
      }, {})

      return recipient
    })

    this.$emit('update:recipientMethod', RecipientMethod.ImportFile)
    this.$emit('update:step', RootSteps.ReviewRecipients)
    this.$emit('update:recipients', arrayOfRecipients)
  }
}
