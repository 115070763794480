<template>
  <div
    class="my-favorites-product"
    @mouseenter="inHoverMode = true"
    @mouseleave="inHoverMode = false"
  >
    <a :href="productPageLink" target="_blank" rel="noopener noreferrer">
      <v-img
        contain
        :src="productImage"
        :alt="productName"
      >
        <div class="d-flex justify-space-between">
          <div class="my-favorites-product__statuses">
            <update-status-sheet
              v-if="productIsCustomized && !shareView"
              color="#A7DAD8"
              textColor="#004642"
            >
              Customized
            </update-status-sheet>

            <update-status-sheet
              v-if="productIsOOS && !shareView"
              color="#fff"
              textColor="#FA5E5E"
              style="border: 1px solid #FA5E5E"
            >
              Out of Stock
            </update-status-sheet>
          </div>

          <the-my-favorites-product-options
            v-on="$listeners"
            :productId="productId"
            :wishlists="wishlists"
            :hoverMode="inHoverMode"
            :hideRemoveItem="shareView"
            :productWishlists="productWishlists"
            :currentWishlistId="currentWishlistId"
            :hideSaveToCollectionsItem="shareView"
          />
        </div>
      </v-img>
    </a>
    <div class="my-favorites-product__info">
      <a :href="productPageLink" target="_blank" rel="noopener noreferrer">
        {{ productName }}
      </a>

      <div class="d-flex justify-space-between">
        <span class="info__from">
          From <b>{{ productPrice | priceFilter }}</b>
        </span>
        <span class="info__min-qty" v-if="productMinQty > 1">
          Min Qty: {{ productMinQty }}
        </span>
      </div>
    </div>
    <div class="my-favorites-product__actions">
      <common-button
        elevation="0"
        color="black darken-4 white--text"
        disabled
        block
        v-if="productIsOOS"
      >
        Out of stock
      </common-button>

      <campaign-card-button
        @showSendEGiftPanel="showSendEGift"
        @showSendDirectPanel="$emit('showSendDirectPanel')"
        :disabled="productIsOOS"
        :items="[product.product]"
        v-else-if="false"
      />
      <!-- product.product.send_as_egift && !productIsCustomized -->

      <common-button
        @click="$emit('showSendDirectPanel')"
        elevation="0"
        color="black darken-4 white--text"
        :disabled="productIsOOS"
        block
        v-else
      >
        {{ productIsCustomized ? 'Customize / Request a sample' : 'Add to cart' }}
      </common-button>
    </div>
  </div>
</template>

<script>
import UpdateStatusSheet from '@/components/UpdateStatusSheet.vue'
import TheMyFavoritesProductOptions from '@/components/myFavorites/TheMyFavoritesProductOptions.vue'
import CampaignCardButton from '@/components/myCampaign/CampaignCardButton.vue'

export default {
  name: 'TheMyFavoritesProduct',
  components: {
    UpdateStatusSheet,
    CampaignCardButton,
    TheMyFavoritesProductOptions,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    shareView: {
      type: Boolean,
      required: false,
      default: false
    },
    wishlists: {
      type: Array,
      required: false,
      default: () => ([])
    },
    currentWishlistId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: () => ({
    inHoverMode: false,
    imageUrl: 'https://cf.corporategift.com/media/catalog/product',
  }),
  computed: {
    productIsCustomized () {
      return this.product?.product?.should_be_customized ?? false
    },
    productIsOOS () {
      return this.product?.product?.qty.is_out_of_stock ?? false
    },
    productIsConfigurable () {
      return this.product?.product?.type.is_configurable ?? false
    },
    productId () {
      return this.product?.product_id
    },
    productName () {
      return this.product?.product?.name ?? null
    },
    productImage () {
      const image = this.product?.product?.image ?? null
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : this.imageUrl + image
    },
    productPageLink () {
      return this.product?.link ?? null
    },
    productPrice () {
      return this.product?.product?.price || 0
    },
    productMinQty () {
      return this.product?.product?.min_qty ?? 0
    },
    productWishlists () {
      return this.product?.product?.wishlists ?? []
    }
  },
  methods: {
    showSendEGift (type) {
      this.$emit('showEGiftPanel', this.product, type)
    },
  }
}
</script>

<style lang="scss" scoped>
.my-favorites-product {
  display: flex;
  flex-direction: column;
  width: 190px;
  max-width: 190px;
  gap: 14px;
  // height: 100%;

  & .v-image {
    width: 190px;
    max-width: 190px;
    height: 190px;
    max-height: 190px;
  }

  &__info {
    font-weight: 400;
    color: #000;
    font-size: 15px;
    line-height: 23px;
    width: 100%;
    height: 100%;
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > a:first-of-type {
      color: inherit !important;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      &:hover {
        color: #219358 !important;
      }
    }

    & span {
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;

      &.info__from {
        color: #62646A;

        & > b {
          font-size: 18px;
          line-height: inherit;
          font-weight: 700;
          color: #000;
        }
      }

      &.info__min-qty {
        color: #38A39C;
      }
    }
  }

  &__statuses {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__actions {
    width: 100%;
    bottom: 0;
    position: relative;

    &::v-deep .btn-menu {
      width: 100%;
      top: 100%;
    }

    &::v-deep button {
      text-transform: unset;
      font-size: 18px;
      height: 44px !important;
      line-height: 22px;
    }
  }

  @media (min-width: 960px) {
    width: 266px;
    max-width: 266px;

    & .v-image {
      width: 260px;
      max-width: 266px;
      height: 266px;
      max-height: 266px;
    }
  }

  @media screen and (max-width: 400px) {
    width: 178px;
    max-width: 178px;

    & .v-image {
      width: 178px;
      max-width: 178px;
    }
  }

  @media screen and (max-width: 380px) {
    width: 170px;
    max-width: 170px;

    & .v-image {
      width: 170px;
      max-width: 170px;
    }
  }

  @media screen and (max-width: 320px) {
    width: 100%;
    max-width: 100%;

    & .v-image {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
