<template>
  <popper
    :options="{
      placement: placement,
      modifiers: { offset: { offset } },
    }"
  >
    <div class="popper dashboard-card-tooltip">
      <slot />
    </div>
    <div class="d-flex pointer" slot="reference">
      <icons-question :width="19" :height="19" color="#95979D" />
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'DashboardCardTooltip',
  components: { Popper },
  props: {
    placement: {
      type: String,
      required: false,
      default: 'top' // 'right
    },
    offset: {
      type: String,
      required: false,
      default: '0, 5px'
    }
  }
}
</script>

<style lang="scss">
.dashboard-card-tooltip {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  text-transform: none;
  padding: 12px;
  line-height: 17px;
  color: #16181F;
}
</style>

