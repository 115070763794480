<template>
  <div class="buy-new-credits-panel-confirmation">
    <div class="buy-new-credits-panel-confirmation__message">
      <slot name="icon" />
      <div class="f15 black1">
        <slot name="text" />
      </div>
    </div>

    <div class="buy-new-credits-panel-confirmation__buttons">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddCreditsConfirmation'
}
</script>

<style lang="scss" scoped>
.buy-new-credits-panel-confirmation {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__message {
    display: flex;
    flex-direction: row;
    gap: 20px;

    & > div {
      font-weight: 400;
      font-size: 15px;
      color: #000;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 14px;
  }
}
</style>