
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types';

import Api from '@/axios/api'
import GreetingHelperProp from '../mixins/greetingHelperProp'

import TheGreetingsIdeasPanelSelect from '@/components/myCampaign/panels/TheGreetingsIdeasPanelSelect.vue'

interface ISavedGreeting {
  id: number;
  occassion: string;
  gift_message: string
}

@Component({
  components: {
    TheGreetingsIdeasPanelSelect,
  }
})
export default class SendEgiftPanelGreetingIdeas extends Mixins(GreetingHelperProp) {
  loading = false;

  selectedType: Array<string> = [];

  availableGreetingMessages: Array<ISavedGreeting> = [];

  get availableTypes () {
    return [...new Set(this.availableGreetingMessages.map(message => message.occassion))]
  }

  get availableMessages () {
    const { availableGreetingMessages, selectedType } = this

    if (!selectedType.length) return availableGreetingMessages

    return availableGreetingMessages?.filter(message => {
      return selectedType.includes(message.occassion)
    }) || []
  }

  mounted() {
    this.loading = true

    Api.get('/customer/get-greeting-messages')
      .then(({ data }: { data: Array<ISavedGreeting> }) => (this.availableGreetingMessages = data))
      .finally(() => (this.loading = false))
  }

  getMessageColor (index: number): string {
    const colors = ['#FBE7E9', '#FCEBA4', '#DFDDF0', '#B6D8CD', '#D1F5F3', '#E5EBE9']
    const colorIndex = Math.floor(Math.abs(Math.cos(index)) * (colors.length - 1))
    return colors[colorIndex]
  }

  selectMessage ({ gift_message }: ISavedGreeting): void {
    const { logoUrl, message } = this.greetingHelper

    const splittedMessage = message?.split('\n\n') ?? []
    const messageTitle = splittedMessage?.shift() ?? null
    const messageFrom = splittedMessage?.pop() ?? null
    const messageContent = gift_message

    this.$emit('update:greetingHelper', {
      message: [messageTitle, messageContent, messageFrom].join('\n\n'),
      logoUrl,
    });
    this.$emit('update:step', RootSteps.GreetingMessage);
  }
}
