<template>
<!-- TODO refactor -->
  <div :class="{ 'mt-3': isFormVisible && isErrorVisible }">
    <span class="f15 green2" @click="isFormVisible = true" v-if="!isFormVisible">Move credits</span>
    <div class="d-flex align-center mb-2" v-if="isFormVisible">
      <common-input
        v-model="moveValue"
        type="number"
        :height="30"
        style="max-width: 80px;"
        prefix="$"
        @input="inputHandler"
      />
      <span class="f14 black1 mx-2">To</span>
      <common-select
        v-model="moveSelected"
        :items="entities.filter(entity => entity.id !== item.id).map(entity => entity.name)"
        item-text="value"
        style="max-width: 123px"
        placeholder="Select"
        :height="30"
        disable-attach
      />
      <span class="f15 green2 ml-3" @click="moveTransaction">Move</span>
      <v-icon class="ml-4" @click="isFormVisible = false">mdi-close</v-icon>
    </div>
    <span
      class="errMsg d-inline-block red0 lato-italic"
      v-if="isFormVisible && isErrorVisible"
    >
      {{errTxt}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ReallocateMoveCredits',
  props: {
    item: {
      type: Object,
      required: true
    },
    entities: {
      type: Array,
      required: true
    },
    selects: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isFormVisible: false,
    moveValue: undefined,
    moveSelected: undefined,
    moveTo: [
      {
        id: 0,
        value: 'Select'
      }
    ],
    isErrorVisible: false,
    errTxt: '',
    errSelect: false,
    errInput: false
  }),
  methods: {
    moveTransaction () {
      if (this.moveValue > this.item.balance) {
        this.isErrorVisible = true
        this.errInput = true
        this.errTxt = "You can't move more credits than you have"
      } else if (this.moveValue === undefined) {
        this.isErrorVisible = true
        this.errInput = true
        this.errTxt = 'Please input move amount'
      } else if (!(this.moveValue > 0)) {
        this.isErrorVisible = true
        this.errInput = true
        this.errTxt = 'Please input integer more than 0'
      } else if (this.moveSelected === undefined) {
        this.isErrorVisible = true
        this.errSelect = true
        this.errTxt = 'Please select entity'
      } else {
        const info = {
          from: this.item.id,
          to: this.entities.filter(entity => entity.name === this.moveSelected)[0].id,
          amount: this.moveValue
        }
        this.$emit('rellocateCredits', info)
        this.isFormVisible = false
        this.$emit('move')
      }
    },

    inputHandler (val) {
      this.errInput = val > this.item.spent
      this.isErrorVisible = this.errInput | this.errSelect
    }
  },
  watch: {
    moveSelected () {
      this.errSelect = false
      this.isErrorVisible = this.errInput | this.errSelect
    }
  }
}
</script>

<style lang="scss">
.bg-warn .v-input__slot {
  background: #fae3e4 !important;
}
.credit_to .v-select__selection {
  font-size: 13px !important;
}
.errMsg {
  height: 0;
}
</style>
