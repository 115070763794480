<template>
  <v-container class="my-orders-page py-4">
    <v-row no-gutters>
      <v-col cols="12" class="my-orders-page__title">
        My Orders
      </v-col>
      <v-col cols="12">
        <v-tabs
          color="#007E76"
          v-model="selectedTab"
          class="my-orders-page__tabs"
        >
          <v-tabs-slider color="#007E76" />

          <v-tab :ripple="false">
            My orders
          </v-tab>
          <v-tab v-show="userHasAccessToApproveOrders" :ripple="false">
            Waiting for approval
            <div class="v-tab-badge">
              {{ numberOfOrdersToApprove }}
            </div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab" touchless>
          <v-tab-item :key="0">
            <the-my-orders />
          </v-tab-item>

          <v-tab-item :key="1">
            <the-approval-orders />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheMyOrders from '@/components/myOrders/TheMyOrdersWrapper'
import TheApprovalOrders from '@/components/myOrders/TheApprovalOrdersWrapper'

import userHasAccessToApproveOrders from '@/mixins/userHasAccessToApproveOrders'

export default {
  name: 'MyOrders',
  mixins: [userHasAccessToApproveOrders],
  components: {
    TheMyOrders,
    TheApprovalOrders,
  },
  data: () => ({
    selectedTab: 0,
  }),
  computed: {
    numberOfOrdersToApprove() {
      return this.$store.getters['new-approvable-orders/numberOfOrders'] ?? 0
    }
  },
  created () {
    if (this.$route.query?.tab === 'approvable-orders') this.selectedTab = 1

    this.$store.dispatch('new-approvable-orders/getOrders')
  },
}
</script>

<style lang="scss" scoped>
.my-orders-page {
  max-width: 1550px;
  padding: 0 64px;

  @media (min-width: 1700px) {
    margin-left: unset;
    margin-right: unset;
    max-width: 100%;
  }

  & > .row {
    gap: 30px;
    flex-direction: column;
  }

  &__title {
    font-weight: 300;
    font-size: 40px;
    color: #000;
  }

  &__tabs {
    margin-bottom: 30px;
    border-bottom: 1px solid #D3D2D2;

    & .v-tabs-slider-wrapper .v-tabs-slider {
      margin-top: unset;
      margin-left: unset;
    }

    &::v-deep .v-tabs-bar__content {
      gap: 40px;
    }

    & .v-tab {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      padding: 0;
      display: flex;
      align-items: center;
      letter-spacing: unset;
      color: #62646A;
      gap: 8px;
      min-width: unset;

      &:hover {
        color: #007E76 !important;
      }

      &::before {
        content: unset;
      }

      &--active {
        color: #007E76;

        & > .v-tab-badge {
          background-color: #FF5A60;
        }
      }
    }

    & .v-tab-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      padding: 0 8px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      background-color: #FF5A60;
      border-radius: 48px;
    }
  }

  .v-window {
    overflow-x: auto;
    display: flex;
  }

  &::v-deep .v-window__container {
    flex-grow: 1;
  }
}
</style>
