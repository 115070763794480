<template>
  <v-snackbar
    v-model="showSnackbar"
    app
    tile
    left
    bottom
    min-width="100"
    max-width="1000"
    :timeout="timeout"
    :color="getSnackbarColor()"
    class="ml-2 mb-2"
    :class="['bold-success', 'bold-error'].includes(type) && 'v-snack--white-text'"
    content-class="pa-5"
  >
    <icons-check-circle
      :filled="type === 'success'"
      :outlineColor="type === 'bold-success' ? '#42B77A' : '#979797'"
      :outlineCheckColor="type === 'bold-success' ? '#42B77A' : '#9B9B9B'"
      :fillColor="type === 'bold-success' ? '#fff' : null"
      :checkStrokeWidth="2"
      v-if="['success', 'bold-success'].includes(type)"
    />

    <icons-warning
      :width="20"
      :height="20"
      :color="type === 'bold-error' ? '#fff' : '#444444'"
      v-if="['error', 'bold-error'].includes(type)"
    />

    <icons-info
      :width="20"
      :height="20"
      v-if="['info', 'warning'].includes(type)"
    />

    <span v-html="message" />
  </v-snackbar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('snackbar')
// types:
// success
// bold-success
// error
// bold-error
// info
// warning
export default {
  name: 'TheAppSnackbar',
  computed: {
    ...mapState([
      'show',
      'message',
      'type',
      'timeout'
    ]),
    showSnackbar: {
      get () {
        return this.show
      },
      set (val) {
        this.$store.commit('snackbar/setShow', val)
      }
    }
  },
  methods: {
    getSnackbarColor () {
      const { type } = this
      switch (type) {
        case 'success':
          return '#D8F1E4'
        case 'bold-success':
          return '#42B77A'
        case 'error':
          return '#FAE3E3'
        case 'bold-error':
          return '#FF5A60'
        case 'info':
          return '#D2F5F3'
        case 'warning':
          return '#FCEBA4'
        default:
          return '#D8F1E4'
      }
    }
  }
}
</script>

<style lang="scss">
.v-snack {
  &--white-text &__content span {
    color: #fff !important;
  }

  &__content, span {
    color: #222325;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__action {
    margin-right: 0 !important;
  }
}
</style>
