<template>
  <v-row class="recipients-unrestricted-gift-link">
    <v-col cols="12" class="recipients-unrestricted-gift-link__label">
      Enter the maximum number of recipients that can redeem
    </v-col>
    <v-col cols="12">
      <common-input
        v-model.number="recipientsNumber"
        type="number"
        min="1"
        ref="recipientsNumber"
        suffix="Recipients"
        style="max-width: 140px"
      />
    </v-col>
    <!-- <v-col cols="12" class="recipients-unrestricted-gift-link__sub-text">
      Enter the maximum number of recipients that can redeem this eGift.
      With this method, we will not validate recipients by name or email
    </v-col> -->
    <v-col cols="12">
      <common-button
        height="44"
        style="width: 280px"
        :disabled="numberOfRecipients <= 0"
        @click="$emit('showPreview', [])"
      >
        Continue to review eGift
      </common-button>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheRecipientsAreaUnrestrictedGiftLink',
  props: {
    numberOfRecipients: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    recipientsNumber: {
      get () { return this.numberOfRecipients },
      set (val) { this.$emit('update:numberOfRecipients', val || null) },
    }
  },
  mounted () {
    this.$el.querySelector('input')?.focus()
  }
}
</script>

<style lang="scss">
.recipients-unrestricted-gift-link {
  .v-input .v-input__slot .v-text-field__suffix {
    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    color: #a1a1a1;
  }

  &__label {
    font-family: 'Lato-Bold', 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: #000;
  }

  &__sub-text {
    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
