
import { Vue, Component } from 'vue-property-decorator'
import type { ISavedLogo } from '../../types'

import Api from '@/axios/api'

import LogoPlaceholderIcon from '@/components/myCampaign/panels/sendEgiftPanel/icons/TheLogoPlaceholderIcon.vue'

@Component({
  components: {
    LogoPlaceholderIcon
  },
})
export default class UploadLogo extends Vue {
    loadingLogo = false;

    onChange (): void {
      const files = (this.$refs.file as HTMLInputElement).files

      Array.from(files || []).forEach((file) => {
        this.uploadFile(file)
      })
    }

    dragover (event: DragEvent) {
      event.preventDefault()

      if (event.currentTarget) {
        const currentTarget = event.currentTarget as HTMLElement

        if (!currentTarget.classList.contains('upload-logo__content--file-hover')) {
          currentTarget.classList.add('upload-logo__content--file-hover')
        }
      }
    }

    dragleave (event: DragEvent) {
      if (event.currentTarget) {
        const currentTarget = event.currentTarget as HTMLElement
        currentTarget.classList.remove('upload-logo__content--file-hover')
      }
    }

    drop (event: DragEvent): void {
      event.preventDefault();

      if (event?.dataTransfer?.files) {
        const currentTarget = event.currentTarget as HTMLElement

        (this.$refs.file as HTMLInputElement).files = event?.dataTransfer?.files
        this.onChange()
        currentTarget.classList.remove('upload-logo__content--file-hover')
      }
    }

    uploadFile (file: File) {
      const formData = new FormData()
      formData.append('file', file, file?.name)
      this.loadingLogo = true

      Api.post('/customer/logos', formData)
        .then(({ success, data }: { success?: boolean; data: ISavedLogo }) => {
          if (success) {
            this.$emit('addNewFile', data)
            this.$store.dispatch('snackbar/showSnackbar',
              { message: 'Logo uploaded successfully', type: 'success' }
            )
          }
        })
        .catch((e) => {
          if (e?.response?.data) {
            const { file } = e?.response?.data?.errors
            const errorMessage = file?.join(' ') || 'The logo failed to upload.'

            this.$store.dispatch('snackbar/showSnackbar',
              { message: errorMessage, type: 'error' }
            )
          } else {
            this.$store.dispatch('snackbar/showSnackbar',
              { message: 'The logo failed to upload.', type: 'error' }
            )
          }
        })
        .finally(() => (this.loadingLogo = false))
    }
}
