<template>
  <v-data-table
    class="order-table"
    :headers="headers"
    :items="products"
    hide-default-footer
    :items-per-page="products.length"
  >
    <template #item.product="{ item }">
      <div class="d-flex py-3">
        <a
          v-if="!isCreditReload"
          class="relative order-image"
          :href="item.url || null"
          target="_blank"
        >
          <img :src="item.image_url && item.image_url.includes('no_selection') ? '/images/empty-order.png' : item.image_url" :alt="item.title">
          <span class="orders-table__label f10 text-center pa-1 lato-bold uppercase full-width"
                v-if="isVirtualSample || isStandardSample">
            {{ isVirtualSample ? 'virtual sample' : 'standard sample' }}
          </span>
        </a>
        <a
          v-else
          class="orders-table__icon d-flex justify-center align-center mr-4"
          :href="item.url || null"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 50">
            <defs/>
            <path fill="#000" fill-rule="nonzero"
                  d="M28.5.722c-5.85 0-10.49 4.058-12.251 9.304-.709-.19-1.49-.36-2.34-.36C6.569 9.667.59 15.69.59 23.084c0 7.395 5.979 13.417 13.32 13.417h1.29c.327 7.103 6.169 12.778 13.301 12.778 7.132 0 12.974-5.675 13.302-12.778h1.288c7.342 0 13.322-6.022 13.322-13.417 0-7.394-5.98-13.416-13.322-13.416-.85 0-1.63.168-2.339.359C38.991 4.78 34.351.722 28.5.722zm0 2.556c5.061 0 9.044 3.758 10.19 8.585.075.331.278.619.565.798.286.18.632.238.96.16 1.007-.248 2.234-.599 2.875-.599 5.971 0 10.784 4.848 10.784 10.861 0 6.014-4.813 10.861-10.784 10.861h-1.407c-.927-6.489-6.487-11.5-13.183-11.5s-12.256 5.011-13.183 11.5H13.91c-5.971 0-10.784-4.847-10.784-10.86 0-6.014 4.813-10.862 10.784-10.862.64 0 1.868.351 2.874.6.329.077.675.02.961-.16.287-.18.49-.468.566-.8 1.145-4.826 5.128-8.584 10.189-8.584zM28.5 25c5.97 0 10.784 4.848 10.784 10.861 0 6.014-4.813 10.861-10.784 10.861-5.97 0-10.784-4.847-10.784-10.86C17.716 29.847 22.529 25 28.5 25zm-.139 2.536a1.275 1.275 0 00-1.13 1.297v.7c-1.85.312-3.171 1.966-3.171 3.773 0 2.177 2.026 3.833 4.44 3.833 1.414 0 1.903.624 1.903 1.278 0 .824-.32 1.277-1.269 1.277h-3.806a1.267 1.267 0 00-1.114.634c-.23.398-.23.89 0 1.288.231.398.657.64 1.114.634h1.903v.639c-.006.46.234.89.63 1.122.395.232.883.232 1.279 0a1.28 1.28 0 00.629-1.122v-.699c1.85-.314 3.171-1.967 3.171-3.773 0-2.178-2.026-3.834-4.44-3.834-1.414 0-1.903-.624-1.903-1.277 0-.825.32-1.278 1.269-1.278h3.806c.457.006.883-.236 1.114-.634.23-.398.23-.89 0-1.288a1.267 1.267 0 00-1.114-.634h-1.903v-.639a1.282 1.282 0 00-.412-.97 1.263 1.263 0 00-.996-.327z"/>
          </svg>
        </a>
        <div class="d-flex flex-column pl-4">
          <ul class="order-list pl-0">
            <li class="f14 lato-bold">
              <a
                :href="item.url || null"
                target="_blank"
                v-html="item.name"
              />
            </li>
            <li class="f12">SKU: #{{ item.sku }}</li>
          </ul>
          <customized-popup class="mt-auto" v-if="customized" :customized="customized"/>

          <kit-items-tooltip
            v-if="item.kit_items && item.kit_items.length"
            class="mt-auto"
            :items="item.kit_items"
          />
        </div>
      </div>
    </template>
    <template #item.price="{ item }"> {{
        item.price | priceFilter({
          style: 'currency',
          currency: 'USD'
        })
      }}
    </template>
    <template #item.tax="{ item }"> {{
        item.tax | priceFilter({
          style: 'currency',
          currency: 'USD'
        })
      }}
    </template>
    <template #item.total="{ item }"> {{
        item.total | priceFilter({
          style: 'currency',
          currency: 'USD'
        })
      }}
    </template>
  </v-data-table>
</template>

<script>
import CustomizedPopup from '@/components/myOrders/CustomizedPopup'
import KitItemsTooltip from './KitItemsTooltip.vue'

export default {
  name: 'OrderProducts',
  components: {
    CustomizedPopup,
    KitItemsTooltip,
  },
  props: {
    products: {
      required: true,
      type: Array
    },
    isCreditReload: {
      type: Boolean
    },
    isVirtualSample: {
      type: Boolean
    },
    isStandardSample: {
      type: Boolean
    },
    less: {
      type: Boolean,
      default: () => false
    },
    customized: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    headers: [
      {
        text: 'PRODUCT',
        align: 'start',
        value: 'product',
        sortable: false,
        width: '65%'
      },
      {
        text: 'PRICE',
        align: 'start',
        value: 'price',
        sortable: false
      },
      {
        text: 'QTY',
        align: 'start',
        value: 'quantity',
        sortable: false
      },
      {
        text: 'TAX',
        align: 'start',
        value: 'tax',
        sortable: false
      },
      {
        text: 'TOTAL',
        align: 'start',
        value: 'total',
        sortable: false,
        width: '10%'
      }
    ]
  }),
  created () {
    if (this.less) {
      this.headers.forEach(header => {
        if (header.text.toLowerCase() === 'product') {
          header.width = '55%'
        } else if (header.text.toLowerCase() === 'total') {
          header.width = '15%'
        }
      })
    }
  }
}
</script>

<style lang="scss">
.order-table {

  tbody tr:hover tbody tr td:first-child {
    background-color: #fff !important;
  }

  table {
    & > thead > tr:last-child > th {
      white-space: normal;
    }
  }
}
</style>
