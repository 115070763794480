<template>
  <div class="aggregated-card-totals">
    <ul>
      <li>
        Qty (units):
        <div>{{ qty }}</div>
      </li>
      <li v-if="perUnits">
        Value per unit
        <div>{{ perUnits | priceFilter }}</div>
      </li>
    </ul>
    <div class="aggregated-card-totals__divider" />
    <div class="aggregated-card-totals__total">
      <div>Total value</div>
      <div>{{ total | priceFilter }}</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('transactions-history')

export default {
  name: 'TheAggregatedViewTotals',
  computed: {
    ...mapState(['aggregatedView']),
    qty () {
      return this.aggregatedView?.total?.sum_qty ?? 0
    },
    perUnits () {
      return this.aggregatedView?.total?.value_per_unit ?? 0
    },
    total () {
      return this.aggregatedView?.total?.total_value ?? 0
    },
  },
}
</script>

<style lang="scss" scoped>
.aggregated-card-totals {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 29px 24px;
  height: 300px;
  max-height: 300px;
  width: 300px;
  max-width: 300px;
  background: #F4F4F4;

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin: 0;
    padding: 0;

    & > li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #000000;

      & > div {
        display: flex;
        flex: 0 0 auto;
        font-weight: 400;
        font-size: inherit;
        color: inherit;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 6px;
    background: #F4F4F4;
  }

  &__total {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 12px;
    align-items: center;

    & > div {
      display: flex;
      flex: 0 0 auto;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #000000;

      &:nth-child(2) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
</style>
