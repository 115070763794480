<template>
  <div class="d-flex">
    <date-range-picker
      ref="picker"
      :opens="opens"
      :minDate="allowPastDates ? null : minDate"
      :maxDate="maxDate"
      :singleDatePicker="singleDatePicker"
      :timePicker="timePicker"
      :timePicker24Hour="timePicker24Hour"
      :showWeekNumbers="showWeekNumbers"
      :showDropdowns="showDropdowns"
      :autoApply="autoApply"
      v-model="range"
      :linkedCalendars="linkedCalendars"
      class="cg-single-date-range-picker cg-date-range-picker"
      :class="{
        'cg-single-date-range-picker--disabled': disabled,
        'cg-single-date-range-picker--no-ranges': hideRanges,
        'cg-single-date-range-picker--top': showOnTop,
      }"
      :style="pickerStyle"
      :ranges="hideRanges ? null : dateRanges"
      :locale-data="customOptions"
      :disabled="disabled"
      @update="updateValues"
      @toggle="onToggle"
    >
      <template #input>
        <popper
          :disabled="!disabled || hideTooltip"
          :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0, 4px' } }
          }"
        >
          <div class="popper px-4 py-2 text-center" style="max-width: 230px; width: 230px">
            To choose a future send date you must be a CG Elite user. Contact sales for more information.
          </div>
          <v-text-field
            v-model="rangeValue"
            :placeholder="placeholder"
            class="rounded-0 date-range-form thin-border"
            style="max-width: 130px; min-height: 36px; pointer-events: auto"
            slot="reference"
            color="black"
            outlined
            readonly
            :disabled="disabled"
            solo
            flat
            dense
            hide-details
            tile
            :height="height"
          >
            <template #append>
              <icons-calendar />
            </template>
          </v-text-field>
        </popper>
      </template>

      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges" :key="name"
              @click="ranges.clickRange(range)"
              v-on:click="setRange(name)"
              :class="name === selectedRange ? 'active' : ''"
            >
              <span class="f14">{{name}}</span>
              <span
                class="f14 ml-1"
                v-if="name === 'This month' || name === 'Last month'">
                ({{getMonth(range[0].toDateString())}})
              </span>
            </li>
          </ul>
        </div>
      </template>

      <template #footer v-if="showCheckbox">
        <div class="footer">
          <common-checkbox v-model="checkboxModel">
            Apply for all recipients
          </common-checkbox>
        </div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'SingleDateRangePicker',
  components: {
    DateRangePicker,
    Popper,
  },
  props: {
    value: {
      type: String
    },
    setMinDateWeekLater: {
      type: Boolean,
      required: false,
      default: false
    },
    allowPastDates: {
      type: Boolean,
      required: false,
      default: false
    },
    pickerStyle: {
      type: String,
      required: false,
      default: null
    },
    showCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    checkboxValue: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Shipping date'
    },
    height: {
      type: [String, Number],
      required: false,
      default: 36
    },
    showRemoveItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideRanges: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0)
    const nextWeek = new Date()
    nextWeek.setDate(today.getDate() + 7)
    nextWeek.setHours(0, 0, 0, 0)
    const minDate = new Date()
    minDate.setDate(minDate.getDate())
    today = new Date()
    if (this.setMinDateWeekLater) {
      minDate.setDate(minDate.getDate() + 7)
    }
    const dateRanges = {}
    if (!this.setMinDateWeekLater) {
      dateRanges.Today = [today, today]
      dateRanges.Tomorrow = [tomorrow, tomorrow]
    }
    dateRanges['Next week'] = [nextWeek, nextWeek]

    dateRanges[this.showRemoveItem ? 'Remove' : ''] = ['', '']

    return ({
      checked: false,
      opens: 'left',
      minDate: minDate,
      maxDate: '01/01/2900',
      singleDatePicker: true,
      timePicker: false,
      timePicker24Hour: false,
      showWeekNumbers: false,
      showDropdowns: false,
      autoApply: true,
      linkedCalendars: true,
      range: {
        start: '',
        end: ''
      },
      dateRanges,
      customOptions: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        firstDay: 0
      },
      selectedRange: '',
      showOnTop: false,
    })
  },
  computed: {
    rangeValue: {
      cache: false,
      get () {
        const { startDate } = this.range
        if (!startDate) return null
        const filteredDate = new Intl.DateTimeFormat('en', { dateStyle: 'medium' }).format(new Date(startDate))
        return filteredDate
      },
      set (val) {
        if (!val) {
          this.$emit('input', '')
        } else if (val) {
          this.$emit('input', val)
          this.$emit('change', val)
        }
      }
    },
    checkboxModel: {
      get () {
        return this.checkboxValue
      },
      set (val) {
        this.$emit('update:checkboxValue', val)
      }
    }
  },
  watch: {
    value: {
      handler: function (model) {
        if (!model) return
        const newDate = new Date(model)
        this.range = {
          startDate: newDate,
          endDate: newDate
        }
      },
      immediate: true
    }
  },
  methods: {
    filterToDate (val) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
      ]
      if (val === null) {
        return
      }
      const arr = String(val).split(' ')
      return ((months.findIndex(month => month === arr[1]) + 1) + '/' + arr[2] + '/' + arr[3])
    },
    getMonth (val) {
      const monthIdxs = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
      ]
      const months = [
        'January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September',
        'October', 'November', 'December'
      ]
      return months[monthIdxs.findIndex(idx => idx === String(val).split(' ')[1])]
    },
    setRange (name) {
      this.selectedRange = name
    },
    updateValues (value) {
      let range
      if (value.startDate === null) {
        range = null
      } else {
        range = new Date(value.startDate).toISOString()
      }
      this.rangeValue = range
    },
    onToggle (val) {
      if (!val) { return }
      
      if (this.$refs.picker) {
        this.$nextTick(() => {
          const dateRangePicker = this.$refs.picker.$el.querySelector('.daterangepicker')

          const { bottom } = dateRangePicker.getBoundingClientRect();
          const windowHeight = window?.innerHeight ?? 1000;

          const restBottom  = windowHeight - bottom;
          this.showOnTop = restBottom < 0
        })
      }
    },
  }
}
</script>

<style lang="scss">
.cg-single-date-range-picker {
  --picker-margin-top: 7px;
  --min-width: 130px;
  min-width: var(--min-width) !important;
  cursor: pointer;

  .reportrange-text {
    width: 100% !important;
    height: 36px !important;
    cursor: inherit;

    .v-input {
      min-width: var(--min-width) !important;
      cursor: inherit;

      
      .v-input__slot,
      input {
        cursor: inherit !important;
      }

      &--is-disabled, input {
        color: rgba(0, 0, 0, 0.87) !important;
      }
    }
  
    .datepicker__append-outer {
      position: absolute !important;
      right: 0 !important;
      top: 50% !important;
      transform: translate(0, -50%) !important;
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
    }
  }

  .vue-daterange-picker{
    position: unset !important;
  }

  .daterangepicker {
    margin-top: var(--picker-margin-top) !important;
    right: 0 !important;
  }

  &--top > .daterangepicker {
    bottom: 100%;
  }


  & .left .next {
    visibility: unset !important;
  }

  & .daterangepicker {
    min-width: 492px !important;
  }

  &--disabled {
    & .reportrange-text {
      cursor: default;
    }

    & .reportrange-text .v-input > .v-input__control > .v-input__slot {
      background: #F4F4F4 !important;
    }
  }

  &--no-ranges .daterangepicker {
    min-width: unset !important;

    & .ranges {
      display: none !important;
    }

    & .drp-calendar.left {
      border: none !important;
    }
  }

  &:not(.cg-single-date-range-picker--disabled) {
    & .reportrange-text .v-input > .v-input__control > .v-input__slot {
      background: #fff !important;
    }
  }
}

.date-range-form {
  max-width: 200px;

  .v-input__slot {
    padding-left: 12px !important;
    font-size: 14px;
  }
}

.cg-date-range-picker {
  .reportrange-text {
    padding: 0 !important;
    border: none !important;
    opacity: 1 !important;
  }

  .drp-calendar {
    max-width: 295px !important;
    width: 295px !important;
  }

  .left {
    border-right: none !important;
    padding: 10px 0 10px 15px !important;
  }

  .right {
    .prev {
      visibility: hidden !important;
    }
  }

  .calendar-table {
    td, th {
      min-width: 38px !important;
      height: 38px !important;
      font-size: 14px!important;
    }
    td.active {
      background: #198b85 !important;
    }
    .in-range {
      background: #dff3f2 !important;
    }
    .off {
      visibility: hidden !important;
    }
  }

  .daterangepicker {
    top: auto;
    min-width: 790px;

    &::before,
    &::after {
      display: none;
    }

    .ranges {
      ul {
        width: 195px !important;
        padding: 10px 13px 0 7px;
      }

      li {
        display: flex;
        align-items: center;
        height: 26px;
        border-radius: 13px;
        color: rgba(0,0,0,0.54);

        &:hover {
          background: #f6f6f6;
        }
      }

      li:last-child {
        position: absolute;
        left: 7px;
        bottom: 20px;
        color: #42B77A;
        line-height: 18px;

        &:hover {
          background: none;
        }

        &.active {
          background: none;
        }
      }

      .active {
        background: #ebebeb;
        color: black;
      }
    }

    .footer {
      padding: 8px;
      background: #f2f2f2 !important;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #16181f;
    }
  }
}

.multiple-date-range-picker .daterangepicker {
  min-width: 790px !important;
}

.date-filtered .v-input__control .v-input__slot {
  background: #DFF3F2 !important;
}

.calendar-icon {
  margin-top: 5px;
  margin-right: -23px;
  z-index: 1;
}
</style>
