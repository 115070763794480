<template>
  <div class="green-text" @click="$emit('onClick')">
    <div class="d-flex">
      <slot />
    </div>
    <div class="d-flex">
      <div
        class="green-text__arrow"
        :class="arrowDown && 'green-text__arrow-down'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreenTextWithArrow',
  props: {
    arrowDown: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.green-text {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #219358;
  cursor: pointer;

  &__arrow {
    content: '';
    border-top: 1px solid #219358;
    border-right: 1px solid #219358;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    transform: rotate(45deg);
    transition: all ease-in-out 0.3s;

    &-down {
      transform: rotate(135deg) translate(-4px);
    }
  }
}
</style>
