import { render, staticRenderFns } from "./TheGreetingsIdeasPanel.vue?vue&type=template&id=5aa0d2ea&scoped=true&"
import script from "./TheGreetingsIdeasPanel.vue?vue&type=script&lang=js&"
export * from "./TheGreetingsIdeasPanel.vue?vue&type=script&lang=js&"
import style0 from "./TheGreetingsIdeasPanel.vue?vue&type=style&index=0&id=5aa0d2ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa0d2ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
