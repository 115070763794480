<template>
  <common-loader v-if="loading"/>
  <v-sheet class="my-cg-order mt-3 mb-12" v-else>
    <v-container v-if="showApproveAlert">
      <div class="my-order-approval-alert">
        <div class="my-order-approval-alert__content">
          <icons-info :width="20" :height="20" color="#4A4A4A" />
          This order is waiting for your approval
        </div>
        <div class="my-order-approval-alert__actions">
          <div
            tabindex="0"
            @click="$store.commit('approvable-orders/setIsApproveDialogShow', true)"
            @keypress="$store.commit('approvable-orders/setIsApproveDialogShow', true)"
          >
            Decline order

            <icons-close-circle-filled
              :height="25"
              :width="25"
              color="#FF5A60"
            />
          </div>
          <div
            tabindex="0"
            @click="approveSelectedOrder"
            @keypress="approveSelectedOrder"
          >
            Approve order

            <icons-check-circle
              filled-color="#42B77A"
              :check-stroke-width="2.2"
              :height="25"
              :width="25"
              filled
            />
          </div>
        </div>
      </div>
    </v-container>

    <v-container>
      <v-row class="mx-0 justify-space-between align-end" v-if="!order.invoice_id">
        <h2
          class="page-title">
          {{ displayOrderId(order) }}
        </h2>
        <router-link
          class="order-link-back f15 lato-bold"
          to="/orders"
        >
          Back to My Orders
        </router-link>
      </v-row>
      <div
        v-else
      >
        <v-row dense>
          <h2
            class="page-title">
            {{ displayOrderId(order) }}
          </h2>
        </v-row>
        <v-row
          justify="space-between"
          align="center"
          dense
        >
          <router-link to="/orders" class="order-link-back f15 lato-bold">
            Back to My Orders
          </router-link>
          <div class="d-flex align-center order-buttons">
            <v-btn
              v-if="isMacOrder"
              class="d-flex align-center text-capitalize"
              color="white"
              depressed
              :loading="isDownloadingOrder"
              @click="downloadOrder"
            >
              <icons-download-file
                color="#919191"
                :width="20"
                :height="20"
              />
              <span class="d-inline-block ml-2 f15 green2">Download</span>
            </v-btn>

            <v-btn
              class="d-flex align-center text-capitalize"
              color="white"
              depressed
              :loading="isPrintInvoiceLoading"
              @click="printInvoice"
            >
              <img src="/images/print-icon.svg" alt="print">
              <span class="d-inline-block ml-2 f15 green2">Print</span>
            </v-btn>
            <v-btn
              class="d-flex align-center text-capitalize"
              color="white"
              depressed
              :loading="isViewInvoiceLoading"
              @click="viewInvoice"
            >
              <img src="/images/invoice.svg" alt="view invoice">
              <span class="d-inline-block ml-2 f15 green2">View invoice</span>
            </v-btn>
          </div>
        </v-row>
      </div>
    </v-container>
    <general-info :order="order"/>
    <additional-info :info="order.additional_data || []" v-if="order.approvable && order.status !== 'Canceled'" />
    <v-container>
      <order-products
        v-if="isCreditReload || isStandardSample || isVirtualSample || !isMultipleAddresses"
        :products="products"
        :customized="order.customized"
        :is-credit-reload="isCreditReload"
        :is-standard-sample="isStandardSample"
        :is-virtual-sample="isVirtualSample"
      />
      <expand-info :recipients="recipients"/>
    </v-container>
    <v-dialog
      v-if="isApproveDialogShow"
      content-class="orders-dialog rounded-0"
      :value="isApproveDialogShow"
      @click:outside="$store.commit('approvable-orders/setIsApproveDialogShow', false)"
      max-width="470"
    >
      <v-card>
        <v-card-title class="headline pa-11 pb-0">
          <span class="f15 lato-bold">Are you sure you want to decline the selected orders?</span>
        </v-card-title>
        <v-card-actions class="pa-11">
          <button
            class="black-btn black-btn_fixed-size f15 lato-bold"
            @click="declineDialog"
          >
            Yes
          </button>
          <button
            class="white-border-btn white-border-btn_fixed-size f15 lato-bold"
            @click="$store.commit('approvable-orders/setIsApproveDialogShow', false)"
          >
            Cancel
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import GeneralInfo from '@/components/myOrders/GeneralInfo'
import OrderProducts from '@/components/myOrders/OrderProducts'
import ExpandInfo from '@/components/myOrders/ExpandInfo'
import AdditionalInfo from '@/components/myOrders/AdditionalInfo'

import { createNamespacedHelpers } from 'vuex'

import userHasAccessToApproveOrders from '@/mixins/userHasAccessToApproveOrders'

import Api from '@/axios/api'
import ApiMPDF from '@/axios/apiMPdf'
import printJS from 'print-js'

const {
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers('orders')

export default {
  name: 'MyOrder',
  mixins: [userHasAccessToApproveOrders],
  data: () => ({
    products: [],
    addresses: [],
    recipients: [],
    isViewInvoiceLoading: false,
    isPrintInvoiceLoading: false,
    isDownloadingOrder: false,
    pdf: ''
  }),
  components: {
    AdditionalInfo,
    OrderProducts,
    ExpandInfo,
    GeneralInfo
  },
  async created () {
    this.setLoading(true)
    const id = this.$route.params.id

    await this.getOrderShippingStatus(id)
    await this.getOrderById(id)
      .then(() => {
        if (this.order.recipient && this.order.recipient.products) this.products = this.order.recipient.products
        this.order.recipients ? this.recipients.push(...this.order.recipients) : this.recipients.push(this.order.recipient)
      })
      .catch(() => {
        this.$cgToast.error("Can't find an order with a specific id")
        this.$router.push({ name: 'Orders' })
      })
    this.setOrder({ ...this.order, ...this.orderShippingStatus && { shipping_status: this.orderShippingStatus } })
    this.setLoading(false)
  },
  mounted () {
    if (this.$route.query?.action) {
      const { action } = this.$route.query
      if (action === 'approve') {
        this.approveSelectedOrder()
      } else if (action === 'decline') {
        this.declineDialog()
      }
    }
  },
  computed: {
    ...mapGetters(['loading', 'order', 'orderShippingStatus']),

    showApproveAlert () {
      const {
        userHasAccessToApproveOrders,
        order: {
          approvable,
          status,
        },
      } = this

      if (!userHasAccessToApproveOrders) { return false }

      return (approvable && status !== 'Canceled') ?? false
    },

    isApproveDialogShow () {
      return this.$store.getters['approvable-orders/isApproveDialogShow']
    },

    isCreditReload () {
      return this.order.type && this.order.type.toLowerCase() === 'credit reload'
    },

    isVirtualSample () {
      return this.order.type && this.order.type.toLowerCase() === 'virtual sample'
    },

    isStandardSample () {
      return this.order.type && this.order.type.toLowerCase() === 'sample'
    },

    isSample () {
      return this.isVirtualSample || this.isStandardSample
    },

    isMultipleAddresses () {
      return this.order.recipients || (this.order.recipient.products && this.order.recipient.products.length > 1)
    },

    isMacOrder () {
      return this.order?.order_type === 'mac'
    },
  },
  methods: {
    ...mapActions(['getOrderById', 'getOrderShippingStatus', 'getOrders']),
    ...mapMutations(['setLoading', 'setOrder']),

    async printInvoice () {
      this.isPrintInvoiceLoading = true
      !this.pdf && await this.fetchPDF()
      this.isPrintInvoiceLoading = false

      const file = new Blob([this.pdf], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)

      printJS(fileURL)
    },

    async viewInvoice () {
      this.isViewInvoiceLoading = true
      !this.pdf && await this.fetchPDF()
      this.isViewInvoiceLoading = false

      this.$store.commit('downloadPDF', this.pdf)
    },

    async fetchPDF () {
      await ApiMPDF.get(
        `/cg/download/download/order_id/${Number(this.order.id)}`,
        { responseType: 'arraybuffer' }
      ).then(resp => {
        if (!resp.message) {
          this.pdf = resp
        }
      })
    },

    downloadOrder () {
      const { id, number } = this.order ?? {}
      this.isDownloadingOrder = true

      Api.post(`/customer/orders/${id}/download-report`, null, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', `${number}-report.xlsx`);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .finally(() => (this.isDownloadingOrder = false))
    },

    displayOrderId (order) {
      if (typeof order?.number === 'string') {
        if (order?.number?.includes('-')) return `Orders ${order.number}`
      }

      return `Order # ${order.number}`
    },

    async declineDialog () {
      this.setLoading(true)
      this.$store.commit('approvable-orders/setIsApproveDialogShow', false)

      await this.$store.dispatch('new-approvable-orders/declineOrder', {
        id: Number(this.$route.params.id)
      })
        .then(() => {
          this.updateInfoAfterChangingStatus()
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
          this.setLoading(false)
        })
    },

    async approveSelectedOrder () {
      this.setLoading(true)

      await this.$store.dispatch('new-approvable-orders/approveOrder', {
        id: Number(this.$route.params.id)
      })
        .then(() => {
          this.updateInfoAfterChangingStatus()
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
          this.setLoading(false)
        })
    },

    async updateInfoAfterChangingStatus () {
      !this.loading && this.setLoading(true)

      await this.getOrderById(Number(this.$route.params.id))
        .catch(() => {
          this.$cgToast.error("Can't find an order with a specific id")
          this.$router.push({ name: 'Orders' })
        })

      this.$store.commit('orders/setOrders', [])
      this.$store.commit('approvable-orders/setOrders', [])
      this.setLoading(false)
    }
  }
}
</script>

<style lang="scss">
.miw70 {
  min-width: 70px;
}

.cost-information {
  max-width: 350px;
  min-width: 270px;
  min-height: 225px;
}

.full-width {
  width: 100%;
}

.order {
  &-link-back {
    color: #00A86B !important;
  }

  &-table {
    &.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      height: 43px;
      border-right: 0;
    }
  }

  &-info {
    width: 100%;
    max-width: 250px;
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 110px;
    height: 110px;

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.bt-large {
  border-top: 6px solid #E4E4E4;
}
</style>

<style lang="scss" scoped>
.my-order-approval-alert {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  background-color: #FFF8D9;

  &__content, &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    color: #222325;
  }

  &__content {
    gap: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  &__actions {
    gap: 30px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      transition: opacity .1s ease-in-out;

      &:hover {
        opacity: 0.78;
      }
    }
  }
}
</style>
