
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CopyPasteTableInput extends Vue {
  @Prop({ required: false }) value!: string | null;

  get inputValue(): string | null {
    return this.value
  }

  set inputValue(val: string | null) {
    this.$emit('input', val)
  }

  focusTextArea(): void {
    this.$el.querySelector('textarea')?.focus()
  }
}
