<template>
  <v-container class="my-users-page py-4">
    <v-row no-gutters>
      <v-col cols="12" class="my-users-page__title">
        Users
      </v-col>
      <v-col cols="12" class="my-users-page__links">
        <div class="my-users-page__link" @click="onAddUserClick()">
          <icons-add-contact :width="20" :height="20" />
          Add user/s
        </div>
      </v-col>
      <v-col cols="12" class="my-users-page__filters">
        <div>
          <common-input
            v-model="search"
            placeholder="Name, Email"
            autocomplete="search"
            prepend-inner-icon="mdi-magnify"
            style="min-width: 220px; max-width: 220px"
            id="search"
            :height="30"
            clearable
            filter-style
          />
          <common-select
            v-model="sort"
            :items="sortOptions"
            placeholder="Sort by"
            prefix="Sort by:"
            style="min-width: 220px; max-width: 220px"
            filter-style
            :height="30"
          />
        </div>

        <div>
          <span>Show:</span>

          <common-toggle v-model="showInactiveUsers" id="inactive-users">
            Inactive users
            <div class="my-users-page__badge">{{ numberOfInactiveAccounts }}</div>
          </common-toggle>
          <common-toggle v-model="showSubAccounts" id="sub-accounts">
            Sub accounts
            <div class="my-users-page__badge">{{ numberOfSubAccounts }}</div>
          </common-toggle>
          <common-toggle v-model="showAdmins" id="admins">
            Admins
            <div class="my-users-page__badge">{{ numberOfAdminAccounts }}</div>
          </common-toggle>
        </div>
      </v-col>
      <v-col cols="12">
        <my-users-table ref="users-table" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import debounce from 'lodash/debounce'
import myUsersPageFilters from '@/components/mySubAccounts/mixins/myUsersPageFilters'

import MyUsersTable from '@/components/mySubAccounts/MyUsersTable.vue'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('users')

export default {
  name: 'MyUsers',
  components: { MyUsersTable },
  mixins: [myUsersPageFilters],
  computed: {
    ...mapState(['users']),
    ...mapGetters([
      'numberOfInactiveAccounts',
      'numberOfSubAccounts',
      'numberOfAdminAccounts',
    ]),
  },
  watch: {
    filters: {
      deep: true,
      handler: 'handleFilterParams',
    },
    // TODO update params to contain show data (require change in the API)
    show: {
      deep: true,
      handler: 'handleFilterParams',
    },
  },
  created () {
    if (!this.users.length) { this.getUsers() }
  },
  methods: {
    ...mapActions(['getUsers']),
    handleFilterParams: debounce(function () {
      this.getUsers()
    }, 400),
    onAddUserClick () {
      this.$refs['users-table']?.handleEditUserData()
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-page {
  max-width: 1550px;
  padding: 0 64px;

  @media (min-width: 1700px) {
    margin-left: unset;
    margin-right: unset;
    max-width: 100%;
  }

  & > .row {
    gap: 30px;
    flex-direction: column;
  }

  &__title {
    font-weight: 300;
    font-size: 40px;
    color: #000;
  }

  &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 12px;
  }

  &__filters {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      &:nth-child(1) {
        gap: 10px;
      }

      &:nth-child(2) {
        gap: 30px;

        & > span {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          color: #95979D;
        }
      }
    }
  }

  &__link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #42B77A;
  }

  &__badge {
    min-width: 22px;
    height: 22px;
    background: #D2F5F3;
    border-radius: 48px;
    padding: 4px;

    line-height: 1;
    font-weight: 700;
    font-size: 13px;

    display: flex;
    align-items: flex-end;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: #007E76;
  }
}
</style>
