<template>
  <div
    class="tray-badge"
    :class="`tray-badge--${color} ${outlined && 'tray-badge__outlined'}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TrayBadge',
  props: {
    color: {
      type: String,
      required: true,
      validator: val => {
        return ['green', 'red', 'grey', 'yellow'].includes(val)
      }
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.tray-badge {
  padding: 10px;
  display: grid;
  place-items: center;
  // border-radius: 50% !important;
  border-radius: 24px !important;
  min-width: 38px;
  height: 38px;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0;
  cursor: default;
  z-index: 2;
  position: relative;

  &[aria-disabled="true"] {
    cursor: default;
    pointer-events: none;
  }

  &__outlined {
    padding: 0;

    &.tray-badge--green {
      color: #198B85;
      background-color: transparent;
      border: 1px solid #198B85;
    }

    &.tray-badge--red {
      color: #F15B5B;
      background-color: transparent;
      border: 1px solid #F15B5B;
    }

    &.tray-badge--grey {
      color: #C8C8C8;
      background-color: transparent;
      border: 1px solid #C8C8C8;
    }

    &.tray-badge--yellow {
      background-color: transparent;
      border: 1px solid #ECD885;
    }
  }

  &--green {
    background-color: #198B85;
  }

  &--red {
    background-color: #F15B5B;
    cursor: pointer;
  }

  &--grey {
    background-color: #C8C8C8;
  }

  &--yellow {
    background-color: #ECD885;
    color: #62646A;
  }
}
</style>
