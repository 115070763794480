var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"campaign-card",attrs:{"outlined":"","tile":"","height":"444"}},[_c('div',{staticClass:"pointer",staticStyle:{"height":"180px"},on:{"click":function($event){return _vm.showGiftOptions()}}},[_c('campaign-card-images',{attrs:{"products":_vm.availableItemsInGift,"disabled":_vm.isCampaignDisabled}})],1),_c('v-card-text',{staticClass:"d-flex px-6 py-4 justify-space-between"},[_c('div',{staticClass:"campaign-card__labels"},[(_vm.campaignCreator)?_c('update-status-sheet',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: `Created by: ${_vm.campaignCreator}`,
          ..._vm.standardTooltipProps,
        }),expression:"{\n          content: `Created by: ${campaignCreator}`,\n          ...standardTooltipProps,\n        }"}],attrs:{"color":"#D2F5F3","text-color":"#004642"}},[_vm._v(" BY: "+_vm._s(_vm.campaignCreator.slice(0, 40))+" ")]):_vm._e(),(_vm.isPreProduction)?_c('update-status-sheet',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: `Items will be ordered and produced once all eGift recipients have made their selections,
            or when the campaign ends. Track redemptions and details through the "My eGift orders"
            on your dashboard`,
          ..._vm.standardTooltipProps,
        }),expression:"{\n          content: `Items will be ordered and produced once all eGift recipients have made their selections,\n            or when the campaign ends. Track redemptions and details through the \"My eGift orders\"\n            on your dashboard`,\n          ...standardTooltipProps,\n        }"}],attrs:{"color":"#ECD884","text-color":"#222325"}},[_vm._v(" Zero waste ")]):_vm._e(),(_vm.isAutomated)?_c('update-status-sheet',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.automationTooltip,
          ..._vm.standardTooltipProps,
        }),expression:"{\n          content: automationTooltip,\n          ...standardTooltipProps,\n        }"}],attrs:{"color":"#E5DFFF","text-color":"#4E3AAF"}},[_vm._v(" Automated ")]):_vm._e()],1),_c('the-campaign-card-settings',{attrs:{"gift-options":_vm.giftOptions,"is-automated":_vm.isAutomated,"user-permissions":_vm.userPermissions,"campaign-permissions":_vm.campaignPermissions},on:{"onGiftOptions":function($event){return _vm.showGiftOptions()},"onCampaignSettings":function($event){return _vm.showCampaignSettingsPanel()},"onEstimateCost":function($event){return _vm.showEstCostPanel()},"onAddAutomation":function($event){return _vm.showAutomationPanel()},"onPreview":function($event){return _vm.previewGift()},"onEdit":function($event){return _vm.editCampaign()}}})],1),_c('v-card-text',{staticClass:"py-0 px-6"},[_c('h1',{staticClass:"campaign-card__name"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-row',{staticClass:"pt-2",attrs:{"dense":""}},[_c('campaign-card-type',{attrs:{"gift-type":_vm.campaignType,"price-range":_vm.item.prices_range,"gift-settings":_vm.item.egift_settings,"gift-products":_vm.item.collection.items || []}}),_c('campaign-card-tags',{attrs:{"tags":_vm.item.tags.map((tag) => tag.name)}})],1)],1),_c('v-card-actions',{staticClass:"px-6 pb-7"},[_c('campaign-card-button',{attrs:{"gift-type":_vm.campaignType,"items":_vm.item.collection.items,"disabled":!_vm.item.collection.items
          .map(item => item.product.qty.is_out_of_stock)
          .includes(false)
        || _vm.isCampaignDisabled},on:{"showSendEGiftPanel":_vm.showSendEGift,"showSendDirectPanel":function($event){return _vm.$emit('showSendDirectPanel')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }