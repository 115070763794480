<template>
  <div class="close-mobile-panel-button">
    <icons-arrow-left color="#000" :width="12" :height="19" />
    <div class="close-mobile-panel-button__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloseMobilePanelButton'
}
</script>

<style lang="scss" scoped>
.close-mobile-panel-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #D3D2D2;

  &__content {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 400;

    & > b {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-weight: 700;
    }
  }
}
</style>
