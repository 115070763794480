import axios from 'axios'

const ApiCMS = axios.create({
  // baseURL: process.env.VUE_APP_CMS_URL || 'https://cms.corporategift.com',
  baseURL: 'https://cms.development.corporategift.com',
  headers: {
    Accept: 'application/json'
  }
})

/**
 * Use response interceptor to catch 401 (Unauthorized) api response.
 * If caught, refresh the token and retry the request.
 */
ApiCMS.interceptors.response.use(
  response => response.data,

  async error => {
    throw error
  }
)

export default ApiCMS
