var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-img',{staticClass:"relative v-image--with-padding",class:{
    'v-image--hide-border': _vm.hideBorder,
    'v-image--hide-border-right': _vm.hideBorderRight
  },attrs:{"alt":_vm.name,"src":_vm.getImagePath(),"width":_vm.width,"contain":"","height":_vm.height,"max-height":_vm.maxHeight,"content-class":"product-responsive-content"}},[(_vm.isOutOfStock)?_c('div',{staticClass:"product_out_of_stock"},[_c('div',{staticClass:"product_out_of_stock__message",class:{
        uppercase: _vm.outOfStockUppercase,
        'product_out_of_stock__message--medium-size': _vm.medium
      }},[_vm._v(" Out of stock ")])]):_vm._e(),(_vm.isFromInventory)?_c('div',{staticClass:"product_from_inventory",class:{ 'product_from_inventory--small': _vm.smallInventoryIcon }},[_c('img',{attrs:{"src":"/images/left_menu_icons/inventory-icon.svg","width":_vm.smallInventoryIcon ? 8 : 20,"height":_vm.smallInventoryIcon ? 8 : 20}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }