<template>
  <v-form ref="form">
    <label for="full-name">Full name</label>
    <div class="d-flex" id="full-name">
      <common-input
        v-model="formFirstName"
        placeholder="First name"
        :rules="[validation.required]"
        autocomplete="given-name"
      />
      <common-input
        v-model="formLastName"
        placeholder="Last name"
        :rules="[validation.required]"
        autocomplete="family-name"
      />
    </div>

    <label for="street">Street address</label>
    <div class="d-flex" id="street">
      <common-input
        v-model="formStreet"
        placeholder="House number and street name"
        :rules="[validation.required]"
      />
      <common-input
        v-model="formApt"
        style="max-width: 100px"
        placeholder="APT/Suite"
      />
    </div>

    <label for="city">City</label>
    <common-input
      v-model="formCity"
      id="city"
      placeholder="City"
      :rules="[validation.required]"
    />

    <label for="postcode">Postal code</label>
    <common-input
      v-model="formPostcode"
      id="postcode"
      placeholder="Postal code"
      :rules="[validation.required]"
    />

    <label for="country">Country</label>
    <common-country-select
      v-model="formCountry"
      id="country"
      placeholder="Country"
      :rules="[validation.required]"
    />

    <label for="state-region">State / Region</label>
    <common-state-select
      v-model="formRegion"
      :country="formCountry"
      id="state-region"
      hide-selected
      placeholder="State / Region"
      :rules="countryHasRegions ? [validation.required] : []"
      @availableStates="(val) => regions = val"
    />

    <cg-phone-input
      v-model="formTelephone"
      :validation="[validation.required]"
      ref="phoneInput"
      style="padding-top: 30px"
    >
      <template #label>
        Phone number
      </template>
    </cg-phone-input>
  </v-form>
</template>

<script>
import { CgPhoneInput } from '@corporategift/design-system'

export default {
  name: 'DefaultBillingAddressForm',
  components: { CgPhoneInput },
  props: {
    firstName: {
      type: String,
      required: false,
      default: null
    },
    lastName: {
      type: String,
      required: false,
      default: null
    },
    street: {
      type: String,
      required: false,
      default: null,
    },
    apt: {
      type: String,
      required: false,
      default: null,
    },
    city: {
      type: String,
      required: false,
      default: null,
    },
    postcode: {
      type: String,
      required: false,
      default: null,
    },
    country: {
      type: String,
      required: false,
      default: null,
    },
    region: {
      type: String,
      required: false,
      default: null,
    },
    telephone: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    regions: [],
    validation: {
      required: v => !!v || '',
      email: v => /.+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ''
    }
  }),
  computed: {
    formFirstName: {
      get () { return this.firstName },
      set (val) { this.$emit('update:firstName', val) }
    },
    formLastName: {
      get () { return this.lastName },
      set (val) { this.$emit('update:lastName', val) }
    },
    formStreet: {
      get () { return this.street },
      set (val) { this.$emit('update:street', val) },
    },
    formApt: {
      get () { return this.apt },
      set (val) { this.$emit('update:apt', val) },
    },
    formCity: {
      get () { return this.city },
      set (val) { this.$emit('update:city', val) },
    },
    formPostcode: {
      get () { return this.postcode },
      set (val) { this.$emit('update:postcode', val) },
    },
    formCountry: {
      get () { return this.country },
      set (val) { this.$emit('update:country', val) },
    },
    formRegion: {
      get () { return this.region },
      set (val) { this.$emit('update:region', val) },
    },
    formTelephone: {
      get () { return this.telephone },
      set (val) { this.$emit('update:telephone', val) },
    },
    countryHasRegions () {
      return this.regions.length
    },
  },
  methods: {
    validate () {
      return this.$refs.form.validate() && this.$refs?.phoneInput?.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 4px;
  color: #000;
  display: block;

  &:not(:first-child) {
    padding-top: 30px;
  }
}
</style>
