<template>
  <div class="transactions-history-table-item">
    <div class="transactions-history-table-item__product">
      <list-view-item-images :images="productImages" />

      <div>
        <div>{{ productName }}</div>
        <div v-if="productSku">{{ productSku }}</div>
      </div>
    </div>
    <div>
      <router-link
        v-if="orderNumber && orderNumber !== 'N/A'"
        :to="orderRouterLink"
        target="_blank"
      >
        {{ orderNumber }}
      </router-link>
      
      <template v-else>
        {{ orderNumber }}
      </template>
    </div>
    <div>{{ kitName }}</div>
    <div>{{ kitSku }}</div>
    <div>{{ campaignName }}</div>
    <div>{{ userName }}</div>
    <div class="transactions-history-table-item__transaction">
      {{ convertTransactionType(transactionType) }}

      <icons-question
        v-if="auditTransactionReason"
        :width="18"
        :height="18"
        v-tooltip="{
          content: auditTransactionReason,
          placement: 'top',
          trigger: 'hover',
          classes: 'transactions-history-table-item__tooltip',
          offset: 6,
          boundariesElement: 'div',
        }"
      />
    </div>
    <div>
      <router-link
        v-if="parentOrderNumber && parentOrderNumber !== 'N/A'"
        :to="parentOrderRouterLink"
        target="_blank"
      >
        {{ parentOrderNumber }}
      </router-link>

      <template v-else>
        {{ parentOrderNumber }}
      </template>
    </div>
    <div>{{ orderDate | date }}</div>
    <div>{{ orderQuantity }}</div>
    <div>{{ orderValue }}</div>
    <div>{{ orderTotalValue }}</div>
    <div class="transactions-history-table-item__status">
      <div>
        <div>Available</div>
        <div>{{ orderStatus.available }}</div>
      </div>
      <div>
        <div>On hold:</div>
        <div>{{ orderStatus.onHold }}</div>
      </div>
      <div>
        <div>On hand:</div>
        <div>{{ orderStatus.onHand }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ListViewItemImages from '../inventoryStatus/TheListViewItemImages.vue'

const { mapState } = createNamespacedHelpers('transactions-history')

export default {
  name: 'TheTransactionsHistoryTableItem',
  components: {
    ListViewItemImages,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState([
      'filtersItems',
    ]),
    transactionTypeHelper () {
      return this.filtersItems?.transactionTypes ?? []
    },
    productInfo () {
      return this.item?.product ?? {}
    },
    productImages () {
      const { name, image_url: image } = this.productInfo ?? {}

      return [{ name, image }]
    },
    productName () {
      return this.productInfo?.name ?? null
    },
    productSku () {
      return this.productInfo?.sku ?? null
    },
    egiftId () {
      return this.item?.egift_id ?? null
    },
    orderId () {
      return (this.parentOrderId || this.item?.order_id) ?? null
    },
    recipientOrderId () {
      return this.item?.order_id ?? null
    },
    orderNumber () {
      return this.item?.order_number ?? 'N/A'
    },
    parentOrderId () {
      return this.item?.parent_order_id ?? null
    },
    orderRouterLink () {
      const { egiftId, orderId, recipientOrderId, transactionType } = this
      const typesWithScrollToRecipient = ['egift_redeem', 'hold_release']

      return ({
        name: egiftId ? 'EgiftOrder' : 'MyOrder',
        params: {
          id: egiftId || orderId,
          recipientId: typesWithScrollToRecipient.includes(transactionType) && recipientOrderId,
        }
      })
    },
    parentOrderRouterLink () {
      const { egiftId, parentOrderId } = this

      return ({
        name: egiftId ? 'EgiftOrder' : 'MyOrder',
        params: { id: egiftId || parentOrderId }
      })
    },
    parentOrderNumber () {
      return this.item?.parent_order_number ?? 'N/A'
    },
    kitName () {
      return this.item?.kit?.name ?? 'N/A'
    },
    kitSku () {
      return this.item?.kit?.sku ?? 'N/A'
    },
    campaignName () {
      return this.item?.campaign?.name ?? 'N/A'
    },
    userName () {
      const { isAuditTransactionType } = this

      if (isAuditTransactionType) { return 'CG Admin' }

      return this.item?.user?.name ?? 'N/A'
    },
    transactionType () {
      return this.item?.type ?? 'N/A'
    },
    isAuditTransactionType () {
      return this.transactionType === 'audit'
    },
    auditTransactionReason () {
      return this.item.reason ?? null
    },
    orderDate () {
      return this.item?.date ?? null
    },
    orderQuantity () {
      return this.item?.qty ?? 1
    },
    orderValue () {
      const cost = this.item?.cost_per_unit ?? null

      if (cost === null) { return 'No Data' }

      return this.$options.filters.priceFilter(cost)
    },
    orderTotalValue () {
      const total = this.item?.total_value ?? null

      if (total === null) { return 'No Data' }

      return this.$options.filters.priceFilter(total)
    },
    orderStatus () {
      const { qty_available, qty_on_hold, qty_on_hand } = this.item ?? {}

      return ({
        available: qty_available ?? 'No Data',
        onHold: qty_on_hold ?? 'No Data',
        onHand: qty_on_hand ?? 'No Data',
      })
    },
  },
  methods: {
    convertTransactionType (val) {
      const helperObject = this.transactionTypeHelper?.find((type) => type.value === val);

      return helperObject?.text || val || 'N/A'
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/components/myInventory/styles/transactions-history-row-grid.scss";

.transactions-history-table-item {
  @include transactionsHistoryRowGrid;

  &__product {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 14px;
    align-items: center;

    & > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > div {
        &:nth-child(1) {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #95979D;
        }
      }
    }
  }

  &__status {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    align-items: flex-start !important;
    gap: 4px;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      line-height: 25px;
      width: 100%;
      gap: 8px;

      & > div {
        color: #000000;
        font-size: 14px;

        &:first-child {
          font-weight: 700;
          min-width: 60px;
        }

        &:last-child {
          font-weight: 400;
        }
      }

      &:last-child {
        border-top: 1px solid #D3D2D2;
      }
    }
  }

  &__transaction {
    display: flex;
    gap: 8px;
  }

  & > div {
    display: flex;
    align-items: center;

    word-break: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    & > a {
      text-decoration: none;
      color: #42B77A !important;
    }
  }
}
</style>

<style lang="scss">
.transactions-history-table-item__tooltip {
  padding: 14px;
  background: #fff;
  color: #16181F;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  & > .tooltip-arrow {
    bottom: -5px;
    width: 10px;
    height: 10px;
    position: absolute;
    left: calc(50% - 5px);
    transform: rotate(45deg);
    background: inherit;
    z-index: -1;
  }
}
</style>
