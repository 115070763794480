<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="900px"
  >
    <close-panel-button
      @close="show = false"
      text="My inventory"
    />

    <v-container v-if="product && show" class="px-10 py-0 assign-users-panel">
      <v-row v-if="!loading" no-gutters>
        <v-col cols="12" class="assign-users-panel__title">
          Access to users
        </v-col>

        <v-col cols="12">
          <v-row no-gutters class="assign-users-panel__content">
            <v-col cols="12" class="assign-users-panel__selected-users">
              Users <span class="assign-users-panel__badge">{{ selectedUsers.length }}</span>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <add-users
                  v-model="selectedUsers"
                  :items="availableUsers"
                  activator-text="Add users manually"
                />
              </div>
            </v-col>

            <v-col cols="12">
              <v-alert v-if="showBulkEdit" dense tile color="#DFF3F2" class="pa-0 ma-0">
                <div class="assign-users-panel__bulk-edit-form">
                  <common-select
                    v-model="bulkEditOption"
                    :items="bulkEditOptions"
                    placeholder="Select spend limit option"
                    :height="35"
                    style="width: 100%; max-width: 220px"
                  />

                  <v-form v-if="bulkEditOption" ref="bulk-edit-form">
                    <common-input
                      v-model="bulkEditForm.spend_limit"
                      type="number"
                      placeholder="Limit"
                      style="max-width: 75px;"
                      :rules="[rules.required, rules.numberGreaterThanZero]"
                      :height="35"
                    />

                    <span>
                      Set period:
                    </span>

                    <common-select
                      v-model="bulkEditForm.period"
                      :items="periodOptions"
                      placeholder="Period"
                      style="max-width: 120px;"
                      :rules="[rules.required]"
                      :height="35"
                    />
                  </v-form>

                  <common-button
                    class="ml-auto"
                    @click="onBulkEditSubmit()"
                    :disabled="bulkEditOption === null"
                  >
                    Set
                  </common-button>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-data-table
                v-model="bulkEditSelectedUsers"
                :items="selectedUsers"
                :headers="headers"
                show-select
                :items-per-page="selectedUsers.length"
                hide-default-footer
              >
                <template #item.name="{ item }">
                  {{ item | userName }}
                </template>
                <template #item.spend_limit="{ item }">
                  <template v-if="item.id === editedRecipientId">
                    <common-input
                      v-model="editForm.spend_limit"
                      type="number"
                      :min="1"
                      ref="edit-form-limit"
                      placeholder="Limit"
                      :rules="[rules.required, rules.numberGreaterThanZero]"
                      :height="35"
                    />
                  </template>

                  <template v-else>
                    {{ item.spend_limit }}
                  </template>
                </template>
                <template #item.period="{ item }">
                  <template v-if="item.id === editedRecipientId">
                    <common-select
                      v-model="editForm.period"
                      :items="periodOptions"
                      placeholder="Period"
                      ref="edit-form-period"
                      disable-attach
                      :rules="[rules.required]"
                      :height="35"
                    />
                  </template>

                  <template v-else>
                    {{ item.period | capitalizeFirstLetter }}
                  </template>
                </template>
                <template #item.balance="{ item }">
                  {{ item.balance }}
                </template>
                <template #item.options="{ item }">
                  <div class="assign-users-panel__table-options">
                    <template v-if="item.id !== editedRecipientId">
                      <icons-edit
                        :width="13"
                        :height="13"
                        color="#9B9B9B"
                        @click.native.stop="editItem(item)"
                      />

                      <icons-delete
                        :width="13"
                        :height="17"
                        color="#9B9B9B"
                        @click.native.stop="removeItem(item)"
                      />
                    </template>

                    <template v-else>
                      <span @click="onSaveChanges()">
                        Set
                      </span>

                      <v-divider vertical />

                      <span v-if="item.spend_limit" @click="onRemoveLimit">Remove limit</span>

                      <span v-else @click="onCancelEdit">Cancel</span>
                    </template>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="assign-users-panel__buttons">
          <common-button :height="44" style="max-width: 174px" @click="onSubmit">
            Save changes
          </common-button>
        </v-col>
      </v-row>

      <v-row v-else no-gutters>
        <v-col cols="12">
          <common-loader style="padding: 300px 0" />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import { assignUsersHeaders } from '@/assets/headers'

import AddUsers from '@/components/myCampaign/TheCampaignSettingsPanelAddUsers.vue'

import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'AssignUsersPanel',
  components: { AddUsers },
  mixins: [panelVModel],
  filters: {
    userName: function (item) {
      const { firstname, lastname, email } = item

      let result = ''
      if (firstname) result += `${firstname} `
      if (lastname) result += lastname

      return result || email || ''
    },
    capitalizeFirstLetter: function (value) {
      if (!value) { return value }

      return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
    }
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    loading: false,
    rules: {
      required: v => !!v || '',
      numberGreaterThanZero: v => v > 0 || ''
    },
    editedRecipientId: null,
    editForm: {
      spend_limit: null,
      period: null,
    },
    bulkEditOption: 0,
    bulkEditForm: {
      spend_limit: null,
      period: null,
    },
    selectedUsers: [],
    bulkEditSelectedUsers: [],
    availableUsers: [],
    periodOptions: [
      {
        text: 'Monthly',
        value: 'monthly',
      },
      {
        text: 'Annually',
        value: 'annually',
      }
    ],
    bulkEditOptions: [
      {
        text: 'No limit',
        value: 0,
      },
      {
        text: 'Set spend limit',
        value: 1,
      }
    ],
    headers: assignUsersHeaders,
  }),
  computed: {
    showBulkEdit () {
      return !!this.bulkEditSelectedUsers.length
    },
    itemId () {
      return this.product?.item_id
    },
  },
  watch: {
    show: function (val) {
      if (val)  {
        this.loading = true

        Promise.all([
          this.getAvailableUsers(),
          this.getAssignedUsers()
        ]).then((response) => {
          const [availableUsers, assignedUsers] = response

          this.selectedUsers = assignedUsers?.data ?? []
          this.availableUsers = availableUsers?.data?.map((user) => ({
            ...user,
            spend_limit: null,
            period: null,
            balance: null,
          })) ?? []
        }).finally(() => (this.loading = false))
      }
    }
  },
  methods: {
    getAvailableUsers () {
      return Api.get('/customer/subaccounts/inventory-users')
    },
    getAssignedUsers () {
      const { itemId } = this

      return Api.get(`/customer/purchased-inventory/item/${itemId}/users`)
    },
    //#region bulk edit methods
    onBulkEditSubmit () {
      const bulkFormRef = this.$refs?.['bulk-edit-form']
      const { bulkEditOption, bulkEditForm } = this

      if (bulkEditOption === 1) {
        if (bulkFormRef?.validate()) {
          this.bulkUpdate(bulkEditForm)
          this.clearBulkEdit()
        }
      } else {
        this.bulkUpdate({ period: null, spend_limit: null })
        this.clearBulkEdit()
      }
    },
    bulkUpdate ({ period, spend_limit }) {
      const { selectedUsers, bulkEditSelectedUsers } = this

      bulkEditSelectedUsers.forEach((bulkEditUser) => {
        const userIndex = selectedUsers.findIndex((user) => user.id === bulkEditUser.id)

        if (userIndex !== -1) {
          const currentData = this.selectedUsers[userIndex]

          this.$set(this.selectedUsers, userIndex, {
            ...currentData,
            spend_limit,
            period,
          })
        }
      })
    },
    clearBulkEdit () {
      this.bulkEditSelectedUsers = []
      this.bulkEditForm = {
        spend_limit: null,
        period: null,
      }
      this.bulkEditOption = 0
    },
    //#endregion
    //#region row edit methods
    editItem ({ id, spend_limit, period }) {
      this.editedRecipientId = id ?? null
      this.editForm = {
        spend_limit: spend_limit,
        period: period,
      }
    },
    removeItem ({ id }) {
      this.selectedUsers = this.selectedUsers.filter((user) => user.id !== id)
    },
    onSaveChanges () {
      const { editedRecipientId, editForm, selectedUsers } = this
      const userIndex = selectedUsers.findIndex((user) => user.id === editedRecipientId)
      const limitInput = this.$refs['edit-form-limit'],
        periodInput = this.$refs['edit-form-period']

      const validationResult = [limitInput, periodInput]
        .map((el) => el?.$children?.[0])
        .filter((input) => !input.validate(true))

      if (validationResult.length !== 0) { return }

      if (userIndex !== -1) {
        const currentData = selectedUsers[userIndex]

        this.$set(this.selectedUsers, userIndex, {
          ...currentData,
          ...editForm,
        })
      }

      this.onCancelEdit()
    },
    onCancelEdit () {
      this.editedRecipientId = null
      this.editForm = {
        spend_limit: null,
        period: null,
      }
    },
    onRemoveLimit () {
      const { editedRecipientId, selectedUsers } = this
      const userIndex = selectedUsers.findIndex((user) => user.id === editedRecipientId)

      if (userIndex !== -1) {
        const currentData = selectedUsers[userIndex]

        this.$set(this.selectedUsers, userIndex, {
          ...currentData,
          spend_limit: null,
          period: null,
        })
      }

      this.onCancelEdit()
    },
    //#endregion
    onSubmit () {
      const { itemId, selectedUsers } = this

      this.loading = true

      Api.put(`/customer/purchased-inventory/item/${itemId}/users`, {
        users: selectedUsers
      })
        .then(() => {
          this.$cgToast.successBold('Successfully saved changes')

          this.getAssignedUsers()
            .then(({ data }) => (this.assignedUsers = data))
            .finally(() => (this.loading = false))
        })
        .catch(() => {
          this.$cgToast.error('An unexpected error occurred, please try again later')
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.assign-users-panel {
  & > .row {
    gap: 40px;
  }

  &__title {
    color: #000;
    font-weight: 300;
    font-size: 40px;
    line-height: 44px;
  }

  &__content {
    gap: 14px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &__selected-users {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: #000;
  }

  &__badge {
    min-width: 22px;
    height: 22px;
    border-radius: 48px;
    padding: 4px;
    line-height: 1;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #219358;
    color: #fff;
  }

  &__bulk-edit-form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 16px;
    align-items: center;

    & > form {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: inherit;
      align-items: center;

      & > span {
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }

  &__table-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    justify-content: flex-end;

    & > svg,
    & > span {
      cursor: pointer;
    }

    & > span {
      color: #42B77A;

      &:hover {
        color: #219358;
      }
    }
  }

  &::v-deep .v-data-table {
    // td and th padding
    & thead > tr > th,
    & tbody > tr > td {
      padding: 0 12px;
    }

    & thead > tr > th {
      height: 34px;

      text-transform: uppercase;
      font-size: 11px;
      line-height: 13px;
      color: #000;
      font-weight: 700;
    }

    & tbody > tr {
      &:nth-child(even) {
        background-color: #fbfbfb;
      }

      &.v-data-table__selected,
      &.v-data-table__selected td:first-child {
        background-color: #f7f7f7 !important;
      }

      & > td {
        height: 65px;
        cursor: default;
      }
    }
    // checkbox styles
    & thead > tr > th:first-child,
    & tbody > tr > td:first-child {
      padding: 0;
      min-width: 30px !important;
      width: 30px !important;

      & > div {
        display: flex;
        justify-content: center;
        
        & .v-input--selection-controls__input {
          margin: 0;
        }
      }
    }
  }
}
</style>
