
import { Mixins, Component, Prop } from 'vue-property-decorator'
import type { ISendCampaignResponse } from '../../types'
import { PanelType, RecipientMethod, LinkType } from '../../types'

import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import PreviewCampaign from '@/mixins/previewCampaign'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'
// @ts-ignore
import QrCode from 'vue-qrcode-component'

import PreProductionDialog from '@/components/myCampaign/panels/sendEgiftPanel/PreProductionDialog.vue'

@Component({
  components: {
    QrCode,
    PreProductionDialog,
  }
})
export default class SendEgiftPanelSuccess extends Mixins(
  recipientMethodProp,
  approvalDataProps,
  PreviewCampaign,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  @Prop({ required: true }) successResponse!: ISendCampaignResponse | null;

  showQrCode = false;

  showPreProductionDialog = false;

  get panelTypeIsLink (): boolean {
    return this.panelType === PanelType.Link
  }

  get panelTypeIsEgift (): boolean {
    return this.panelType === PanelType.EGift
  }

  get isStandardLink (): boolean {
    const { form, recipientMethod } = this

    if (recipientMethod === RecipientMethod.UnrestrictedLink) { return false }

    return form.linkType === LinkType.StandardLink
  }

  get egiftLink (): string {
    return this.successResponse?.url || ''
  }

  get recipientsCount (): number {
    return this.successResponse?.number_of_recipients || 0
  }

  get isAutoApproved (): boolean {
    return this.successResponse?.is_approved ?? false
  }

  get showApprovalMessage (): boolean {
    const { isAutoApproved, checkoutNeedsApproval } = this;
    return !isAutoApproved && checkoutNeedsApproval
  }

  get linkTypeFeatures (): Array<string> {
    const { linkType } = this.form

    const result = [
      'Recipients must validate their emails to redeem',
      'Each email address is eligible for 1 gift redemption',
    ]

    if (linkType === LinkType.UnrestrictedLink) result.splice(0, 1)
    return result
  }

  get isCampaignPreProduction (): boolean {
    return this.campaign.is_pre_production ?? false
  }

  mounted () {
    if (this.isCampaignPreProduction) {
      this.showPreProductionDialog = true;
    }
  }

  copyLink (): void {
    navigator.clipboard.writeText(this.egiftLink)

    this.$store.dispatch('snackbar/showSnackbar', {
      message: 'Link copied to clipboard',
      type: 'success'
    })
  }

  copySingleLink (): void {
    const { recipients } = this.successResponse as ISendCampaignResponse

    navigator.clipboard.writeText(recipients[0]?.url)

    this.$store.dispatch('snackbar/showSnackbar', {
      message: 'Link copied to clipboard',
      type: 'success'
    })
  }

  downloadFileWithLinks (): void {
    const { recipients } = this.successResponse as ISendCampaignResponse

    const headers = ['First name', 'Last name', 'Email', 'URL']
    let csvContent = 'data:text/csvcharset=utf-8,' + headers.join(',') + '\n'

    csvContent += recipients
      .map((r) => [r.first_name, r.last_name, r.email, r.url].join(','))
      .join('\n')

    const fileUrl = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', fileUrl)
    link.setAttribute('download', 'recipients_links.csv')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  showPreview(): void {
    const { form, campaign, panelType } = this

    this.previewCampaign({
      campaignId: campaign.entity_id,
      logoUrl: form.logoUrl || undefined,
      recipients: [],
      senderName: form.giftGiverName || undefined,
      subjectLine: form.emailSubjectLine || undefined,
      greetingMessage: form.greeting || undefined,
      calendlyLink: form.calendlyLink || undefined,
      videoUrl: form.videoGreeting || undefined,
      canUpgrade: form.settings.recipientCanUpgrade,
      canRegift: form.settings.recipientCanReEgift,
      hideEmailStep: panelType === PanelType.Link,
    })
  }
}
