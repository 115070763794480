<template>
  <div class="copy-paste-table-input__input">
    <textarea v-model="inputValue" />

    <div class="copy-paste-table-input__input-placeholder">
      <icons-copy-paste />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyPasteTableInput',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    }
  },
  computed: {
    inputValue: {
      get () { return this.value },
      set (val) { this.$emit('input', val) },
    },
  },
  methods: {
    focusTextArea () {
      this.$el.querySelector('textarea')?.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.copy-paste-table-input {
  &__input {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 24px 20px;
    min-height: 360px;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > textarea {
      position: absolute;
      resize: none;
      inset: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      color: transparent;
      background-color: transparent;
      font-size: 24px;
      border: none;
      z-index: 2;

      &:focus {
        outline: 1px solid #A7DAD8;
        border: 2px solid #A7DAD8;
        cursor: auto;
        color: #000;
      }
    }
  }

  &__input-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    gap: 8px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #95979D;
  }
}
</style>