
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, PanelType, LinkType } from '../../types'
import type { VuetifyForm } from '../../types';

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import recipientMethodProp from '../mixins/recipientMethodProp'

import SendEgiftPanelRecipientsManually from '../recipients/SendEgiftPanelRecipientsManually.vue'
import UnrestrictedGiftLinkTooltip from '@/components/myCampaign/panels/sendEgiftPanel/TheUnrestrictedGiftLinkCheckboxTooltip.vue'

@Component({
  components: {
    SendEgiftPanelRecipientsManually,
    UnrestrictedGiftLinkTooltip,
  },
})
export default class SendEgiftPanelRecipients extends Mixins(
  panelTypeProp,
  recipientsProp,
  recipientMethodProp,
) {
  get showManuallyMethod (): boolean {
    return this.recipientMethod === RecipientMethod.Manually
  }

  get showUnrestrictedGiftLinkMethod (): boolean {
    const { panelType, recipientMethod } = this

    if (panelType !== PanelType.Link) { return false }

    return [RecipientMethod.Manually, RecipientMethod.UnrestrictedLink].includes(recipientMethod)
  }

  get unrestrictedGiftLink (): boolean {
    return this.recipientMethod === RecipientMethod.UnrestrictedLink
  }

  set unrestrictedGiftLink (val: boolean) {
    this.$emit(
      'update:recipientMethod',
      val ? RecipientMethod.UnrestrictedLink : RecipientMethod.Manually
    )

    if (val) { setTimeout(() => { this.$el.querySelector('input')?.focus() }, 0) }
  }

  get unrestrictedGiftLinkNumber (): number {
    return this.singleGiftLinkRecipients
  }

  set unrestrictedGiftLinkNumber (val: number) {
    this.$emit('update:singleGiftLinkRecipients', val || 0)
  }

  get disableContinueButton (): boolean {
    const { unrestrictedGiftLink, unrestrictedGiftLinkNumber } = this

    return unrestrictedGiftLink && unrestrictedGiftLinkNumber <= 0
  }

  mounted () {
    const { recipientMethod } = this

    if (![RecipientMethod.UnrestrictedLink, RecipientMethod.Manually].includes(recipientMethod)) {
      this.$emit('update:recipientMethod', RecipientMethod.Manually)
    }
  }

  handleContinueButton (): void {
    const { unrestrictedGiftLink } = this

    if (unrestrictedGiftLink) {
      this.$emit('update:recipients', [])
      this.$emit('update:linkType', LinkType.UnrestrictedLink)
      this.$emit('update:step', RootSteps.Review)
    }

    const result = (this.$refs['recipients-manually'] as VuetifyForm)?.validate()

    if (result) {
      this.$emit('update:linkType', LinkType.StandardLink)
      this.$emit('update:step', RootSteps.Review)
    }
  }

  showBulkOptions (): void {
    this.$emit('update:step', RootSteps.RecipientBulkOptions)
  }
}
