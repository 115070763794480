
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import Vue from 'vue/types/umd';
import type { ICampaignSettings, ActionLinkType } from '../../types';

export type VForm = Vue & { validate: () => boolean }

import formProp from '../mixins/formProp'
import campaignProp from '../mixins/campaignProp'
import panelTypeProp from '../mixins/panelTypeProp'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

import { required } from '@corporategift/design-system/validations'

import campaignExpirationDaysValidation from '@/utils/campaignExpirationDaysValidation'

@Component({
  mixins: [getTooltipTextBySlug],
  components: {
    SingleDateRangePicker,
  },
  methods: {
    required,
    campaignExpirationDaysValidation,
  },
})
export default class SendEgiftPanelMoreOptions extends Mixins(
  formProp,
  campaignProp,
  panelTypeProp,
) {
  description: string | null = null;

  videoGreeting: string | null = null;

  calendlyLink: string | null = null;

  disableReminderEmails = false;

  shippingDate: string | null = null;

  expirationDays: string | number = 90;

  actionLinkType: ActionLinkType = null;

  freeForm: { link: string | null; buttonText: string | null } = {
    link: null,
    buttonText: null,
  };

  get campaignSettings(): ICampaignSettings {
    return this.campaign?.egift_settings ?? {}
  }

  get canOverrideDisableReminders (): boolean {
    return this.campaignSettings.can_override_disable_recipient_reminders ?? false
  }

  get isPreProduction (): boolean {
    return this.campaign?.is_pre_production ?? false
  }

  created () {
    const {
      description,
      shippingArrivalDate,
      videoGreeting,
      calendlyLink,
      actionLinkType,
      freeForm,
      expirationDays,
      settings: { disableEmailReminders },
    } = this.form;

    this.description = description;
    this.videoGreeting = videoGreeting;
    this.calendlyLink = calendlyLink;
    this.disableReminderEmails = disableEmailReminders;
    this.shippingDate = shippingArrivalDate;
    this.actionLinkType = actionLinkType;
    this.expirationDays = expirationDays || 90;
    this.freeForm = { ...freeForm };
  }

  saveChanges (): void {
    if (!(this.$refs.form as VForm).validate()) { return }

    this.$emit('update:description', this.description);
    this.$emit('update:videoGreeting', this.videoGreeting);
    this.$emit('update:calendlyLink', this.calendlyLink);
    this.$emit('update:shippingArrivalDate', this.shippingDate);
    this.$emit('update:disableEmailReminders', this.disableReminderEmails);
    this.$emit('update:actionLinkType', this.actionLinkType);
    this.$emit('update:freeForm', this.freeForm);
    this.$emit('update:expirationDays', this.expirationDays);
    this.$emit('update:step', RootSteps.Review);
  }
}
