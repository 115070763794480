
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import type { ISavedLogo } from '../../types'

import Api from '@/axios/api'
import formProp from '../mixins/formProp'

import UploadLogo from '../components/UploadLogo.vue'

@Component({
  components: {
    UploadLogo,
  },
})
export default class SendEgiftPanelLogos extends Mixins(formProp) {
  loading = false;

  selectedImage: string | null = null;

  availableLogos: Array<ISavedLogo> = [];

  mounted () {
    this.loading = true
    this.selectedImage = this.form.logoUrl ?? null

    Api.get('/customer/logos')
      .then(({ data }: { data: Array<ISavedLogo> }) => (this.availableLogos = data))
      .finally(() => (this.loading = false))
  }

  addNewLogo (logoData: ISavedLogo): void {
    this.availableLogos = [logoData, ...this.availableLogos]
    this.selectedImage = logoData.url;
  }

  saveChanges (): void {
    this.$emit('update:logoUrl', this.selectedImage ?? null)
    this.$emit('update:step', RootSteps.Review)
  }
}
