<template>
  <common-loader v-if="loading" />

  <v-row no-gutters class="h100" v-else>
    <v-col cols="12" class="d-flex align-center justify-space-between">
      <span class="credits-info__charts--title">
        {{ marketplaceUser ? 'CG Credits Breakdown' : 'Budget Entities Breakdown' }}
      </span>

      <div class="d-flex g20 align-center f13 gray2" v-if="!marketplaceUser">
        Show:
        <v-switch
          v-model="showOnHold"
          color="#1DBF73"
          hide-details
          :ripple="false"
          class="pa-0 ma-0 credits-info__switch"
          label="On-hold per Budget Entity"
          inset
        />

        <the-credits-info-select
          v-model="selectedEntites"
          :items="availableEntites"
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      class="charts-items"
      :class="{ 'charts-items--credits-breakdown': marketplaceUser }"
    >
      <div class="charts-items__wrapper">
        <the-credits-info-chart-bar
          v-for="(entity, index) in filteredEntites"
          :entity="entity"
          :key="index"
          :show-on-hold="showOnHold"
          :max-balance="maxValue"
          :hide-tooltip="marketplaceUser"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TheCreditsInfoChartBar from './TheCreditsInfoChartBar'
import TheCreditsInfoSelect from './TheCreditsInfoSelect'

import { budgetEntities, loading, marketplaceUser, entitesSummary } from './mixins'

export default {
  name: 'TheCreditsInfoCharts',
  components: {
    TheCreditsInfoChartBar,
    TheCreditsInfoSelect
  },
  mixins: [
    marketplaceUser,
    entitesSummary,
    budgetEntities,
    loading
  ],
  data: () => ({
    showOnHold: false,
    selectedEntites: []
  }),
  computed: {
    entities () {
      return this.budgetEntities?.filter(item => item.balance > 0 && item.active)
    },
    markteplaceUserChartsData () {
      const { summary } = this
      const marketplaceUserCharts = []
      marketplaceUserCharts.push({
        name: 'Available Credits',
        color: '#EDCFC3',
        balance: summary.balance,
        on_hold: 0,
        active: true
      })
      marketplaceUserCharts.push({
        name: 'On Hold',
        color: '#4A4A4A',
        balance: summary.on_hold,
        on_hold: 0,
        active: true
      })
      return marketplaceUserCharts
    },
    filteredEntites () {
      const { entities, selectedEntites, marketplaceUser, markteplaceUserChartsData } = this
      if (selectedEntites.length) {
        return entities.filter((entity) => {
          return selectedEntites.includes(entity.id)
        })
      }
      return marketplaceUser ? markteplaceUserChartsData : entities
    },
    availableEntites () {
      return this.entities.map((entity) => ({
        text: entity.name,
        value: entity.id
      })) || []
    },
    maxValue () {
      const { showOnHold, filteredEntites } = this
      // take max value from entity balance or on hold if selected
      const values = filteredEntites?.reduce((acc, entity) => {
        if (showOnHold) {
          acc.push(...[entity.balance, entity.on_hold])
        } else {
          acc.push(...[entity.balance])
        }
        return acc
      }, [])
      return Math.max(...values)
    }
  }
}
</script>

<style lang="scss">
.credits-info__switch {
  .v-input__control .v-input__slot {
    border: none !important;
  }

  &.v-input--is-label-active {
    & .v-input {
      &--switch__track {
        opacity: 1;
      }
      &--switch__thumb {
        color: #fff !important;
      }
    }
  }

  label {
    color: #62646A !important;
    font-size: 13px !important;
    line-height: 24px !important;
    font-weight: 400;
  }
}
</style>

<style lang="scss" scoped>
.credits-info__charts {
  &--title {
    font-weight: 700;
    text-transform: uppercase;
    color: #919191;
    font-size: 14px;
    line-height: 17px;
  }

  .charts-items {
    // 36px = height of first row
    height: calc(100% - 36px);
    // 30px = margin-left
    max-width: calc(100% - 30px);
    overflow-x: auto;
    margin-left: 30px;
    padding: 36px 0 24px !important;

    &::before {
      position: absolute;
      content: 'Credits balance';
      transform: rotate(-90deg);
      bottom: calc(40px + 24px + 20px);
      left: 0px;
      font-size: 13px;
      line-height: 15px;
      color: #919191;
    }

    &--credits-breakdown {
      max-width: 100%;
      margin-left: 0;

      &::before {
        content: none;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      gap: 25px;
      flex-wrap: nowrap;
      align-items: flex-end;
      height: 100%;
      border-bottom: 1px solid #E4E4E4;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background: #C4C4C4;
    }
  }
}
</style>
