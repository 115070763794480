<template>
  <div class="account-auth-page__container">
    <cg-loader v-if="loading" style="height: unset" />

    <div v-else class="account-auth-page__form" :class="{ 'account-auth-page__form--step3': step === 3 }">
      <h1>{{ stepTitle }}</h1>

      <template v-if="step === 1">
        <span>
          Already have an account?
          <router-link
            :to="{
              name: 'LoginPage',
              query: { ...this.$route.query } }"
          >
            Sign in
          </router-link>
        </span>

        <cg-button
          :href="googleURL"
          outlined
          color="#4285F4"
          text-color="#4285F4"
          class="account-auth-page__google-btn"
          @click="onGoogleSSO"
        >
          <template #prepend-icon>
            <img src="https://cf.corporategift.com/images/google-logo.png" alt="" width="22" height="22" class="mr-3">
          </template>
          Continue with Google
        </cg-button>

        <div class="account-auth-page__divider">
          Or:
        </div>
      </template>

      <template v-if="step === 2">
        <span>
          with email <b>{{ form.email }}</b> <span @click="step = 1">Edit email</span>
        </span>
      </template>

      <template v-if="step === 3">
        <span>
          An activation email has been sent to <strong>{{ newAccountEmailFromParam || form.email }}</strong>.<br>
          Click the link in the email to activate your account.<br><br>

          <strong>Didn’t get the email?</strong><br>
          Check your spam folder. Or <a href="#" @click.prevent="reSendEmail()"> click to resend</a>
        </span>
      </template>

      <template v-if="step === 4">
        <span>
          <strong>{{ form.email }}</strong> is already has an account here.<br>
          Please enter your password or <a href="#" @click.prevent="step = 1">change email</a><br><br>
        </span>
      </template>

      <cg-form ref="form" @submit="goToTheNextStep">
        <!-- steps -->
        <template v-if="step === 1">
          <cg-input
            v-model="form.email"
            type="email"
            :validation="[required(), email()]"
            aria-autocomplete="none"
            placeholder="Email"
          >
            <template #label>Email address</template>
          </cg-input>

          <cg-button :disabled="!form.email" type="submit">Continue with Email</cg-button>
        </template>

        <template v-if="step === 2">
          <div class="d-flex flex-row align-end" style="gap: 16px">
            <cg-input
              v-model="form.firstName"
              :validation="[required()]"
              aria-autocomplete="none"
            >
              <template #label>Full name</template>
            </cg-input>
            <cg-input
              v-model="form.lastName"
              :validation="[required()]"
              aria-autocomplete="none"
            />
          </div>

          <div class="account-auth-page__password-container">
            <cg-input
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              :validation="[
                required(),
                minLength({ min: 10 }),
                hasSpecialChar,
                hasUppercase,
                hasLowercase,
                hasNumber,
              ]"
              aria-autocomplete="none"
              placeholder="Enter your password"
            >
              <template #label>Password</template>

              <template #append-icon>
                <div
                  class="account-auth-page__icon"
                  @click.prevent.stop="showPassword = !showPassword"
                >
                  <icons-eye v-if="showPassword" black />
                  <icons-eye-crossed v-else />
                </div>
              </template>
            </cg-input>

            <password-validation
              :password="form.password"
              :min-length="10"
            />
          </div>

          <cg-button :disabled="!form.password" type="submit">Create an account</cg-button>
        </template>

        <template v-if="step === 4">
          <div class="account-auth-page__password-container">
            <cg-input
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              :validation="[
                required(),
                minLength({ min: 10 }),
                hasSpecialChar,
                hasUppercase,
                hasLowercase,
                hasNumber,
              ]"
              aria-autocomplete="none"
              placeholder="Enter your password"
            >
              <template #label>
                <span class="account-auth-page__label">
                  Enter Password

                  <router-link :to="{ name: 'ForgotPasswordPage' }">Forgot your password?</router-link>
                </span>
              </template>

              <template #append-icon>
                <div
                  class="account-auth-page__icon"
                  @click.prevent.stop="showPassword = !showPassword"
                >
                  <icons-eye v-if="showPassword" black />
                  <icons-eye-crossed v-else />
                </div>
              </template>
            </cg-input>
          </div>

          <cg-button class="mt-5" :disabled="!form.password" type="submit">Sign in</cg-button>
        </template>
      </cg-form>

      <div v-if="step !== 3" class="account-auth-page__policy">
        By clicking "{{ footerTextTitle }}" button, you are creating a CorporateGift account, and you agree to Corporategift.com's
        <a href="https://corporategift.com/terms-and-conditions/" target="_blank">Terms of Use</a>
        and
        <a href="https://corporategift.com/privacy-policy/" target="_blank">Privacy Policy</a>.
      </div>
    </div>
  </div>
</template>

<script>
import setCookie from '@/utils/setCookie'
import syncEmail from '@/mixins/auth/syncEmail'
import authRedirectModule from '@/mixins/auth/authRedirectModule'
import { signInAction, signUpAction, reSendActivationEmail, checkIfAccountExist } from '@/services/authService'
import { required, email, minLength } from '@corporategift/design-system/validations'

import { CgButton, CgForm, CgInput, CgLoader } from '@corporategift/design-system'
import PasswordValidation from '@/components/accountSettings/AccountSettingsPasswordValidation.vue'

export default {
  name: 'RegisterPage',
  mixins: [
    syncEmail,
    authRedirectModule,
  ],
  components: {
    PasswordValidation,
    CgButton,
    CgLoader,
    CgInput,
    CgForm,
  },
  data: () => ({
    form: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    step: 1,
    loading: false,
    showPassword: false,
    newAccountEmailFromParam: null
  }),
  computed: {
    googleURL () {
      return `${process.env.VUE_APP_API_URL}/api/customer/google-sign-in`
    },
    stepTitle () {
      switch (this.step) {
        case 1:
          return 'Create an Account';
        case 2:
          return 'Welcome aboard';
        case 4:
          return 'We know you!';
        default:
          return "You're almost done.";
      }
    },
    footerTextTitle () {
      switch (this.step) {
        case 1:
          return 'Continue with email';
        case 2:
          return 'Create an account';
        case 4:
          return 'Sign in';
        default:
          return '';
      }
    }
  },
  created () {
    this.handleRouteQuery()
  },
  methods: {
    //#region validation
    minLength,
    required,
    email,
    hasUppercase: (v) => /[A-Z]/.test(v || ''),
    hasLowercase: (v) => /[a-z]/.test(v || ''),
    hasNumber: (v) => /[0-9]/.test(v || ''),
    hasSpecialChar: (v) => /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v || ''),
    //#endregion
    goToTheNextStep () {
      const { step, form } = this

      if (this.$refs.form.validate()) {
        if (step == 2) {
          this.onSubmit()
        } else if (step == 1) {
          this.loading = true;
          checkIfAccountExist(form.email)
            .then(() => {
              this.step = 4;
              this.loading = false;
            })
            .catch(() => {
              this.step += 1;
              this.loading = false;
            })
        } else if (step == 4) {
          this.loading = true;
          signInAction({ ...form })
            .then(({ token, isVendor }) => {
              setCookie(token, 14, 'cgToken')
              setCookie(Number(isVendor), 14, 'cgUserIsVendor')
              this.$store.commit('headerAuth/setToken', token)

              this.redirectLoggedInUser()
              this.loading = false;
            }).
            catch((e) => {
              this.$cgToast.error(e, { html: true });
              this.loading = false;
            })
        } else {
          this.step += 1;
        }
      }
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        const { form } = this
        this.loading = true
        // TODO move to try catch?
        signUpAction({ ...form, company: null, })
          .then(({ success, errors }) => {
            if (success) {
              this.step = 3;
            } else {
              this.$cgToast.error(errors?.at(0) || 'An unexpected error occurred, please try again later or contact our support')
            }
            this.loading = false
          })
          .catch((e) => {
            this.$cgToast.error(e, { html: true })
            this.loading = false
          })
      }
    },
    reSendEmail () {
      const { form, newAccountEmailFromParam } = this
      reSendActivationEmail(newAccountEmailFromParam || form.email)
        .then(() => {
          this.$cgToast.success('Activation Email Sent')
        })
        .catch((e) => {
          this.$cgToast.error(e, { html: true })
        })
    },
    handleRouteQuery () {
      const queryStep = +this.$route.query?.step || 0;
      if (queryStep === 3) {
        const urlParams = new URLSearchParams(window.location.search);
        const accountEmail = urlParams.get('accountEmail');
        this.newAccountEmailFromParam = decodeURI(accountEmail);
        this.step = queryStep;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/css/auth-page.scss";
</style>
