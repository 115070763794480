<template>
  <v-container class="px-0 pb-0 ma-0" style="padding-top: 35px">
    <v-row>
      <template v-if="showRecipientsAddressBook">
        <v-col cols="12" class="recipients-area__title">
          Select recipients from Contacts
        </v-col>

        <v-col v-if="isCampaignUSOnly" cols="12">
          <cg-alert type="information" background-color="#FCEBA4">
            This campaign can be shipped to {{ shippingType === 'us_only' ? 'US' : 'US & Canada' }} recipients only!
          </cg-alert>
        </v-col>
      </template>
      
      <v-col cols="12" class="pb-0" v-if="showRecipientsAddressBook">
        <v-row no-gutters class="address-book-table">
          <v-col cols="12">
            <v-row dense class="address-book-table__header">
              <v-col cols="2">
                <label>
                  Select
                </label>
                <the-select-multiple-contacts v-model="selectMultiple" style="max-width: 68px" />
              </v-col>
              <v-col cols="6">
                <label>
                  Find contacts & Groups
                </label>
                <common-input
                  v-model="search"
                  placeholder="Name, company, group name..."
                  append-icon="mdi-magnify"
                  style="width: 250px"
                  height="30"
                />
              </v-col>
              <v-col cols="4">
                <label>
                  Show
                </label>
                <div class="d-flex justify-space-between">
                  <common-checkbox v-model="show.contacts">
                    <span class="address-book-checkbox--text">
                      Contacts
                    </span>
                    <span class="address-book-checkbox--number-of-contacts">
                      {{ addresses.length }}
                    </span>
                  </common-checkbox>
                  <common-checkbox v-model="show.groups">
                    <span class="address-book-checkbox--text">
                      Groups
                    </span>
                    <span class="address-book-checkbox--number-of-contacts">
                      {{ groups.length }}
                    </span>
                  </common-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="loading">
            <common-loader />
          </v-col>

          <v-col cols="12" v-if="show.contacts && !loading">
            <template v-for="(address, index) in addresses">
              <v-row
                no-gutters
                :key="address.entity_id"
                class="mt-3 pa-4 address-book-table__row"
                align="center"
                align-content="center"
                style="border-bottom: 1px solid #E3E3E3"
              >
                <v-col class="felx-shrink-1 flex-grow-0 mr-2">
                  <address-book-checkbox
                    v-model="selectedContacts"
                    :input-value="address"
                    :disabled="!address.email_address"
                  />
                </v-col>
                <v-col cols="4">
                  {{ address.firstname }} {{ address.lastname }}
                </v-col>
                <v-col :cols="index !== editedRowId ? '7' : '5'">
                  <div v-if="index !== editedRowId">
                    <span v-if="address.email_address">
                      {{ address.email_address }}
                    </span>
                    <span
                      class="address-book-table__row--empty-email"
                      @click="editRow(index, address)"
                      v-else
                    >
                      Add email Address
                    </span>
                  </div>
                  <common-input
                    v-model="editedForm.email_address"
                    :rules="[
                      v => !!v || '',
                      v => /.+@.+\..+/.test(v),
                    ]"
                    validate-on-blur
                    v-else
                    placeholder="Email"
                    style="width: 300px"
                    height="34"
                  />
                </v-col>
                <v-col
                  class="pointer"
                >
                  <div class="d-flex" v-if="index !== editedRowId" @click="editRow(index, address)">
                    <icons-edit :width="15" :height="15" />
                  </div>

                  <div v-else class="d-flex justify-space-between">
                    <div
                      class="text-right recipients-area__link f15"
                      @click="save()"
                    >
                      Save
                    </div>

                    <div
                      class="text-right recipients-area__link f15"
                      @click="cancel()"
                    >
                      Cancel edit
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col cols="12" v-if="show.groups && !loading">
            <template v-for="group in groups">
              <v-row
                no-gutters
                :key="group.entity_id"
                class="mt-3 pa-4 address-book-table__row"
                align="center"
                align-content="center"
                style="border-bottom: 1px solid #E3E3E3"
              >
                <v-col class="felx-shrink-1 flex-grow-0 mr-2">
                  <address-book-checkbox
                    v-model="selectedContacts"
                    :input-value="group"
                  />
                </v-col>
                <v-col cols="4" class="d-flex align-center pr-2">
                  <icons-contacts-group
                    class="mr-2"
                    :width="17"
                    :height="17"
                  />

                  {{ group.name }}
                </v-col>
                <v-col cols="7" class="address-book-table__row--multiple">
                  {{ group.addresses_count }} Contacts
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col cols="12" class="py-3 address-book-sticky-button d-flex align-center mt-1" v-if="!loading">
            <common-button
              :disabled="!numberOfSelectedContacts"
              @click="continueReview(true)"
              height="45"
            >
              Continue to review recipients
            </common-button>

            <div class="pl-6 number-of-selected-users" v-if="numberOfSelectedContacts">
              Selected: {{ numberOfSelectedContacts }} {{ numberOfSelectedContacts > 1 ? 'recipients' : 'recipient' }}
            </div>
          </v-col>
        </v-row>
      </v-col>

      <template v-if="!showRecipientsAddressBook">
        <the-recipients-table
          v-model="items"
          v-bind="_props"
          :convert-data="false"
          backToAddressBook
          @go-back="recipientsTableBackHandler"
          @showPreview="(data) => $emit('showPreview', data)"
        />
      </template>
    </v-row>
  </v-container>
</template>

<script>
import TheSelectMultipleContacts from '@/components/myAddressBook/TheSelectMultipleContacts.vue'
import AddressBookCheckbox from './TheRecipientsAddressBookCheckbox.vue'
import TheRecipientsTable from './TheRecipientsTable.vue'
import { CgAlert } from '@corporategift/design-system'

import Api from '@/axios/api'
import takeCurrentDate from '@/components/myCampaign/panels/mixins/takeCurrentDate'
import marketplaceUser from '@/components/myCampaign/panels/mixins/marketplaceUser'
import panelType from '@/components/myCampaign/panels/mixins/panelType'

export default {
  name: 'TheRecipientsAddressBook',
  mixins: [
    panelType,
    marketplaceUser,
    takeCurrentDate,
  ],
  components: {
    TheSelectMultipleContacts,
    AddressBookCheckbox,
    TheRecipientsTable,
    CgAlert,
  },
  props: {
    shippingType: {
      type: String,
      required: false,
      default: null
    },
  },
  data: () => ({
    loading: false,
    search: '',
    showRecipientsAddressBook: true,
    selectMultiple: 'none',
    show: {
      contacts: true,
      groups: true
    },
    data: {
      addresses: [],
      groups: []
    },
    selectedContacts: [],
    editedRowId: null,
    editedForm: {
      entity_id: null,
      is_active: null,
      firstname: null,
      lastname: null,
      email_address: null
    }
  }),
  computed: {
    isCampaignUSOnly () {
      return this.shippingType !== 'international' || false
    },
    addresses () {
      const { data: { addresses }, search } = this
      return addresses?.filter(address =>
        address.firstname?.toUpperCase().includes(search?.toUpperCase()) ||
        address.lastname?.toUpperCase().includes(search?.toUpperCase())
      ) || []
    },
    groups () {
      const { data: { groups }, search } = this
      return groups?.filter(group => group.addresses_count > 0 && group.name.includes(search)) || []
    },
    items () {
      const createRecipientObject = ({ firstname, lastname, email_address: email }) => {
        const sendDate = this.takeCurrentDate()
        return ({
          first_name: firstname,
          last_name: lastname,
          email,
          send_date: sendDate
        })
      }

      return this.selectedContacts.reduce((acc, item) => {
        // TODO refactor
        if (item?.name !== undefined) {
          item?.addresses?.forEach((address) => {
            acc.push(createRecipientObject(address))
          })
        } else {
          acc.push(createRecipientObject(item))
        }
        return acc
      }, [])
    },
    numberOfSelectedContacts () {
      const { selectedContacts } = this
      return selectedContacts.reduce((acc, contact) => {
        acc += contact?.addresses ? contact?.addresses.length : 1

        return acc
      }, 0)
    }
  },
  watch: {
    selectMultiple: function (newVal) {
      const takeAddressesWithEmails = (addresses) => {
        return addresses.reduce((acc, address) => {
          if (address?.email_address) acc.push(address)

          return acc
        }, [])
      }

      const selected = []
      switch (newVal) {
        case 'all':
          selected.push(...takeAddressesWithEmails(this.addresses), ...this.groups)
          break
        case 'contacts':
          selected.push(...takeAddressesWithEmails(this.addresses))
          break
        case 'groups':
          selected.push(...this.groups)
          break
        default:
          break
      }
      this.selectedContacts = selected
    },
    items: function (newVal) {
      this.$emit('setUnsavedChanges', newVal?.length > 0)
    }
  },
  created () {
    this.getAddresses()
  },
  methods: {
    getAddresses () {
      this.loading = true
      Api.get('/campaigns/send-egift/get-addresses')
        .then(({ data }) => {
          this.data = data
          this.loading = false
        })
    },
    continueReview (val) {
      this.showRecipientsAddressBook = !val
      this.$emit('hideNavigation', val)
      this.$emit('hideCloseButton', val)
    },
    recipientsTableBackHandler () {
      this.continueReview(false)
      this.show = {
        contacts: true,
        groups: true
      }
    },
    editRow (rowId, address) {
      this.editedRowId = rowId
      this.editedForm.entity_id = address.entity_id
      this.editedForm.email_address = address.email_address
      // debug bootstrap app
      this.data.addresses = this.data.addresses?.filter((item) => item) || []
    },
    save () {
      const { entity_id: entityId, email_address: email } = this.editedForm
      if (!email) return
      // TODO refactor filter method
      this.data.addresses?.filter((item) => {
        if (item.entity_id === entityId) {
          item.email_address = email
        }
        return item
      })

      this.data.groups?.filter((item) => {
        if (item.addresses_count > 0) {
          // TODO refactor filter method
          item.addresses?.filter((address) => {
            if (address.entity_id === entityId) {
              address.email_address = email
            }
            return address
          })
        }

        return item
      })

      this.cancel()
    },
    cancel () {
      this.editedRowId = null
      this.editedForm = {
        entity_id: null,
        is_active: null,
        firstname: null,
        lastname: null,
        email_address: null
      }
      // debug bootstrap app
      this.data.addresses = this.data.addresses?.filter((item) => item) || []
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #B8B8B8;
}
.address-book-table {
  &__header {
    background-color: #F5F5F5;
    padding: 12px;
  }

  &__row {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000;

    &--multiple {
      line-height: 17px;
      color: #9B9B9B;
    }

    &--empty-email {
      text-decoration: underline;
      cursor: pointer;
      color: #F15B5B;
    }
  }
}
.address-book-checkbox {
  &--text {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #9B9B9B;
    display: flex;
    align-items: center;
  }

  &--number-of-contacts {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    // padding-top: 1px;
    margin-left: 4px;
    color: #000;
  }
}
.address-book-sticky-button {
  position: sticky;
  bottom: 0;
  background-color: #fff;

  .number-of-selected-users {
    font-weight: 300;
    color: #9B9B9B;
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
