<template>
  <v-navigation-drawer
    class="my-users-edit-multiple-panel"
    v-lock-scroll="show"
    v-model="show"
    width="650px"
    temporary
    right
    app
  >
    <div class="my-users-edit-multiple-panel__title">
      <div>
        Edit options for <b>{{ numberOfSelectedUsers }}</b> users
      </div>

      <div class="my-users-edit-multiple-panel__title-icon" @click="show = false">
        <icons-close color="#62646A" :width="17" :height="17" />
      </div>
    </div>

    <v-form ref="form" class="my-users-edit-multiple-panel__content">
      <template v-if="!loading">
        <div class="my-users-edit-multiple-panel__switch-wrapper">
          <cg-switch v-model="switchData.spendLimit">
            Spend limit settings
          </cg-switch>

          <cg-radio-group
            v-if="switchData.spendLimit"
            v-model="form.spendLimitOption"
            :radios-data="internalOptions.spendLimit"
          />

          <div
            v-if="form.spendLimitOption === 'set-spend-limit'"
            class="my-users-edit-multiple-panel__spend-limit-form"
          >
            <common-input
              v-model.number="form.spendLimit.limit"
              :rules="[validation.required]"
              type="number"
              placeholder="0.00"
              prefix="$"
              :min="0"
              :step="1"
              :height="40"
              style="max-width: 90px"
            />

            <common-select
              v-model="form.spendLimit.time"
              :items="options.spendLimitTimeFrames"
              :rules="[validation.required]"
              placeholder="Time frame"
              item-text="value"
              item-value="key"
              :height="40"
            />
          </div>
        </div>

        <div class="my-users-edit-multiple-panel__switch-wrapper">
          <cg-switch v-model="switchData.orderApproval">
            Order approval settings

            <common-tooltip
              top
              :max-width="250"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              {{ getTooltipTextBySlug('users_order_approver') }}
            </common-tooltip>
          </cg-switch>

          <cg-radio-group
            v-if="switchData.orderApproval"
            v-model="form.orderApprovalOption"
            :radios-data="internalOptions.orderApproval"
          />

          <div
            v-if="form.orderApprovalOption === 'set-order-approval'"
            class="my-users-edit-multiple-panel__item-wrapper"
          >
            <common-select
              v-model="form.orderApprover"
              :items="availableApprovers"
              placeholder="Select approver"
              :rules="[validation.approverRequired]"
              :height="40"
              item-text="name"
              item-value="approver_id"
              id="orders-approval"
            />
          </div>
        </div>

        <div class="my-users-edit-multiple-panel__switch-wrapper">
          <cg-switch v-model="switchData.budgetEntities">
            Budget entities
          </cg-switch>

          <template v-if="switchData.budgetEntities">
            <div class="my-users-edit-multiple-panel__item-wrapper">
              <select-multiple-entities
                id="budget-entities"
                v-model="form.selectedBudgetEntities"
                :items="options.budgetEntitiesList"
                placeholder="Select budget entities"
                item-text="name"
                item-value="id"
                :height="40"
                clearable
                chips
              />
            </div>

            <div class="my-users-edit-multiple-panel__radio-group">
              <cg-radio
                v-model="strategies.budgetEntities"
                v-for="radioData in internalOptions.budgetEntities"
                :key="radioData.defaultText"
                :input-value="radioData.inputValue"
              >
                {{ radioData.defaultText }}

                <common-tooltip
                  v-if="radioData.tooltip"
                  :z-index="10"
                  :activator-size="20"
                >
                  {{ radioData.tooltip }}
                </common-tooltip>
              </cg-radio>
            </div>
          </template>
        </div>

        <div class="my-users-edit-multiple-panel__switch-wrapper">
          <cg-switch v-model="switchData.accessToCategories">
            Access to categories
          </cg-switch>

          <template v-if="switchData.accessToCategories">
            <div class="my-users-edit-multiple-panel__item-wrapper">
              <select-multiple-entities
                id="categories-access"
                v-model="form.selectedCategories"
                :items="options.categoriesList"
                placeholder="Select categories"
                item-text="title"
                item-value="value"
                :height="40"
                clearable
                chips
              />
            </div>

            <div class="my-users-edit-multiple-panel__radio-group">
              <cg-radio
                v-model="strategies.categories"
                v-for="radioData in internalOptions.categories"
                :key="radioData.defaultText"
                :input-value="radioData.inputValue"
              >
                {{ radioData.defaultText }}

                <common-tooltip
                  v-if="radioData.tooltip"
                  :z-index="10"
                  :activator-size="20"
                >
                  {{ radioData.tooltip }}
                </common-tooltip>
              </cg-radio>
            </div>
          </template>
        </div>

        <div class="my-users-edit-multiple-panel__switch-wrapper">
          <cg-switch v-model="switchData.accessToCampaigns">
            Access to campaigns
          </cg-switch>

          <template v-if="switchData.accessToCampaigns">
            <div class="my-users-edit-multiple-panel__item-wrapper">
              <select-multiple-entities
                id="campaigns-access"
                v-model="form.selectedCampaigns"
                :items="options.campaignsList"
                placeholder="Select campaigns"
                item-text="name"
                item-value="id"
                :height="40"
                clearable
                chips
              />
            </div>

            <div class="my-users-edit-multiple-panel__radio-group">
              <cg-radio
                v-model="strategies.campaigns"
                v-for="radioData in internalOptions.campaigns"
                :key="radioData.defaultText"
                :input-value="radioData.inputValue"
              >
                {{ radioData.defaultText }}

                <common-tooltip
                  v-if="radioData.tooltip"
                  :z-index="10"
                  :activator-size="20"
                >
                  {{ radioData.tooltip }}
                </common-tooltip>
              </cg-radio>
            </div>
          </template>
        </div>

        <div class="my-users-edit-multiple-panel__switch-wrapper">
          <cg-switch v-model="switchData.accessToInventory">
            Access to inventory items
          </cg-switch>

          <template v-if="switchData.accessToInventory">
            <div class="my-users-edit-multiple-panel__item-wrapper">
              <select-multiple-entities
                id="categories-access"
                v-model="form.selectedInventoryItems"
                :items="options.inventoryItemsList"
                placeholder="Select inventory items"
                item-text="name"
                item-value="id"
                :height="40"
                clearable
                chips
              />
            </div>

            <div class="my-users-edit-multiple-panel__radio-group">
              <cg-radio
                v-model="strategies.inventoryItems"
                v-for="radioData in internalOptions.inventoryItems"
                :key="radioData.defaultText"
                :input-value="radioData.inputValue"
              >
                {{ radioData.defaultText }}

                <common-tooltip
                  v-if="radioData.tooltip"
                  :z-index="10"
                  :activator-size="20"
                >
                  {{ radioData.tooltip }}
                </common-tooltip>
              </cg-radio>
            </div>
          </template>
        </div>

        <cg-button
          :height="44"
          :disabled="disableSubmitButton"
          class="my-users-edit-multiple-panel__button"
          @click="updateMultipleUsers()"
        >
          Save changes for {{ numberOfSelectedUsers }} users
        </cg-button>
      </template>

      <common-loader
        v-else
        flex
        relative
        minHeightAuto
        style="padding: 100px 0"
      />
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import { uniqWith, isEqual } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

import SelectMultipleEntities from '@/components/mySubAccounts/SelectMultipleEntities.vue'
import { CgSwitch, CgButton, CgRadioGroup, CgRadio } from '@corporategift/design-system'

const { mapActions } = createNamespacedHelpers('users')

export default {
  name: 'MyUsersEditMultiplePanel',
  components: {
    CgRadio,
    CgSwitch,
    CgButton,
    CgRadioGroup,
    SelectMultipleEntities,
  },
  mixins: [
    panelVModel,
    getTooltipTextBySlug,
  ],
  props: {
    selectedUsersIds: {
      type: Array,
      required: false,
      default: () => ([])
    },
  },
  data: () => ({
    loading: false,
    internalOptions: {
      spendLimit: [
        {
          defaultText: 'No spend limit for all users selected',
          inputValue: 'no-spend-limit',
        },
        {
          defaultText: 'Set new spend limit ',
          inputValue: 'set-spend-limit',
        },
      ],
      orderApproval: [
        {
          defaultText: 'No approval required for selected users',
          inputValue: 'no-order-approval',
        },
        {
          defaultText: 'Set approver for selected users',
          inputValue: 'set-order-approval',
        },
      ],
      budgetEntities: [
        {
          defaultText: "Add selected to user's current budget entities",
          inputValue: 'add',
        },
        {
          defaultText: "Set selected as user's budget entities",
          inputValue: 'set',
          tooltip: 'Choosing this option will set the selected budget entities as the only ones associated with these users, replacing any existing associated budget entities',
        },
        {
          defaultText: "Remove selected from user's current budget entities",
          inputValue: 'remove',
        },
      ],
      campaigns: [
        {
          defaultText: "Add selected to user's current campaigns",
          inputValue: 'add',
        },
        {
          defaultText: "Set selected as user's campaigns",
          inputValue: 'set',
          tooltip: 'Choosing this option will set the selected campaigns as the only ones associated with these users, replacing any existing associated campaigns',
        },
        {
          defaultText: "Remove selected from user's current campaigns",
          inputValue: 'remove',
        },
      ],
      categories: [
        {
          defaultText: "Add selected to user's current categories",
          inputValue: 'add',
        },
        {
          defaultText: "Set selected as user's categories",
          inputValue: 'set',
          tooltip: 'Choosing this option will set the selected categories as the only ones associated with these users, replacing any existing associated categories',
        },
        {
          defaultText: "Remove selected from user's current categories",
          inputValue: 'remove',
        },
      ],
      inventoryItems: [
        {
          defaultText: "Add selected to user's current inventory items",
          inputValue: 'add',
        },
        {
          defaultText: "Set selected as user's inventory items",
          inputValue: 'set',
          tooltip: 'Choosing this option will set the selected inventory items as the only ones associated with these users, replacing any existing associated inventory items',
        },
        {
          defaultText: "Remove selected from user's current inventory items",
          inputValue: 'remove',
        },
      ],
    },
    switchData: {
      spendLimit: false,
      orderApproval: false,
      budgetEntities: false,
      accessToCategories: false,
      accessToCampaigns: false,
      accessToInventory: false,
    },
    strategies: {
      budgetEntities: 'add',
      campaigns: 'add',
      categories: 'add',
      inventoryItems: 'add',
    },
    options: {
      budgetEntitiesList: [],
      categoriesList: [],
      approversList: [],
      inventoryItemsList: [],
      campaignsList: [],
      spendLimitTimeFrames: [],
    },
    form: {
      spendLimitOption: 'no-spend-limit',
      orderApprovalOption: 'no-order-approval',
      setSpendLimit: false,
      spendLimit: {
        limit: null,
        time: null,
      },
      orderApprover: null,
      selectedBudgetEntities: [],
      selectedCategories: [],
      selectedInventoryItems: [],
      selectedCampaigns: [],
    },
    validation: {
      approverRequired: v => v !== null || '',
      required: v => !!v || '',
    },
  }),
  computed: {
    numberOfSelectedUsers () {
      return this.selectedUsersIds?.length ?? 0
    },
    availableApprovers () {
      const { approversList } = this.options

      const result = [
        { approver_id: 0, name: 'All admins', },
      ]
      result.push(...approversList)

      return result ?? []
    },
    disableSubmitButton () {
      return Object.values(this.switchData).every((item) => !item)
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        Object.assign(this.$data, this.$options.data())
        this.getSelectOptions()
      }
    }
  },
  methods: {
    ...mapActions(['getUsers']),
    getSelectOptions () {
      this.loading = true
      const promise1 = Api.get('/customer/subaccounts/additional-data')
        .then(({
          data: {
            campaigns,
            access_to_categories,
            order_approvers,
            budget_entities,
            inventory_products,
            spend_limit_time_frames,
          },
        }) => {
          this.options.categoriesList = access_to_categories ?? []
          this.options.approversList = order_approvers ?? []
          this.options.budgetEntitiesList = budget_entities ?? []
          this.options.inventoryItemsList = inventory_products ?? []
          this.options.spendLimitTimeFrames = spend_limit_time_frames ?? []
          this.options.campaignsList = campaigns ?? []
        })
        .catch((e) => (console.error(e)))

      const promise2 = Api.get('/customer/subaccounts/multiple', {
        params: { ids: this.selectedUsersIds?.join(',') }
      })
        // .then(({ data }) => (this.checkIfAnyDataIsSame(data)))
        // disabled for now?

      Promise.all([promise1, promise2])
        .finally(() => (this.loading = false))
    },
    checkIfAnyDataIsSame (usersData = []) {
      const spendLimitEnabled = [...new Set(usersData?.map((user) => user.spend_limit_enabled))]
      const spendLimit = uniqWith(usersData?.map((user) => user.spend_limit), isEqual)
      const orderApprover = [...new Set(usersData?.map((user) => user.order_approver))]
      const budgetEntities = uniqWith(
        usersData?.map((user) => user.budget_entities?.map((budget) => budget.id)),
        isEqual,
      )
      const categories = uniqWith(
        usersData?.map((user) => user.access_to),
        isEqual,
      )
      const inventoryProducts = uniqWith(
        usersData?.map((user) => user.inventory_products?.map((inventoryProduct) => inventoryProduct.id)),
        isEqual,
      )
      const campaigns = uniqWith(
        usersData?.map((user) => user.campaigns?.map((campaign) => campaign.id)),
        isEqual,
      )

      if (spendLimitEnabled.length === 1) { this.form.setSpendLimit = spendLimitEnabled?.at(0) ?? false }
      if (spendLimit.length === 1) {
        const spData = spendLimit.at(0)

        this.form.spendLimit = {
          limit: spData?.amount ?? null,
          time: spData?.time_frame ?? null,
        }

        if (spData?.amount) {
          this.switchData.spendLimit = true
          this.form.spendLimitOption = 'set-spend-limit'
        }
      }
      if (orderApprover.length === 1) { this.form.orderApprover = orderApprover?.at(0) ?? null }
      if (budgetEntities.length === 1) {
        this.form.selectedBudgetEntities = budgetEntities?.at(0) ?? []

        if (this.form.selectedBudgetEntities.length) {
          this.switchData.budgetEntities = true
        }
      }
      if (categories.length === 1) {
        this.switchData.accessToCategories = true
        this.form.selectedCategories = categories?.at(0) ?? null
      }
      if (inventoryProducts.length === 1) {
        this.form.selectedInventoryItems = inventoryProducts?.at(0) ?? []

        if (this.form.selectedInventoryItems.length) {
          this.switchData.accessToInventory = true
        }
      }
      if (campaigns.length === 1) {
        this.form.selectedCampaigns = campaigns?.at(0) ?? []

        if (this.form.selectedCampaigns.length) {
          this.switchData.accessToCampaigns = true
        }
      }
      if (this.form.orderApprover !== null) {
        this.switchData.orderApproval = true
      }
    },
    updateMultipleUsers () {
      if (!this.$refs.form.validate()) { return }

      const { form, selectedUsersIds, switchData, strategies } = this
      const { spendLimit, selectedInventoryItems, spendLimitOption, orderApprovalOption, orderApprover } = form
      // TODO refactor
      const modules = {
        'spent_limit': switchData.spendLimit,
        'order_approvers': switchData.orderApproval,
        'campaigns': switchData.accessToCampaigns,
        'budget_entities': switchData.budgetEntities,
        'inventory_products': switchData.accessToInventory,
        'access_to': switchData.accessToCategories,
      }

      const selectedModules = []

      Object.entries(modules).forEach(([key, val]) => {
          if (val) { selectedModules.push(key) }
      })

      const spendLimitData = (spendLimit && form.spendLimit.limit && form.spendLimit.time)
        ? ({ amount: form.spendLimit.limit, time_frame: form.spendLimit.time, })
        : null
      
      const budgetEntities = form.selectedBudgetEntities.map((budgetId) => ({
        id: budgetId,
        limit: null,
        time: null,
      }))

      this.loading = true
      Api.put(`/customer/subaccounts/multiple`,
        {
          selected_modules: selectedModules,
          ids: selectedUsersIds,
          access_to: form.selectedCategories,
          access_to_update_strategy: strategies.categories,
          order_approver: (switchData.orderApproval && orderApprovalOption === 'set-order-approval') ? orderApprover : null,
          spend_limit_enabled: spendLimitOption !== 'no-spend-limit',
          spend_limit: spendLimitData,
          campaigns: form.selectedCampaigns?.map((val) => ({ id: val })) ?? [],
          campaigns_update_strategy: strategies.campaigns,
          budget_entities: budgetEntities,
          budget_entities_update_strategy: strategies.budgetEntities,
          inventory_products: selectedInventoryItems.map((id) => ({ id })) ?? [],
          inventory_products_update_strategy: strategies.inventoryItems,
        },
        { params: { ids: selectedUsersIds?.join(',') } }
      )
        .then(() => {
          this.show = false
          this.$cgToast.successBold('Changes saved successfully')
          this.getUsers()
        })
        .catch(() => {
          this.$cgToast.error('An error occurred, please contact our support')
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-edit-multiple-panel {
  &::v-deep > .v-navigation-drawer__content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;

    & > div {
      color: #000;
      font-weight: 300;
      font-size: 40px;
      line-height: 44px;

      & > b {
        font-weight: 700;
      }
    }

    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__switch-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 0;
    border-top: 1px solid #D3D2D2;

    &::v-deep .common-toggle {
      gap: 20px;
    }

    &::v-deep .common-toggle__content {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    &:last-of-type {
      border-bottom: 1px solid #D3D2D2;
    }
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > label {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #222325;
    }

    &::v-deep .common-checkbox__content--bold {
      font-size: 20px;
      line-height: 24px;
      gap: 8px;
      align-items: center;
      color: #222325 !important;
    }
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px 12px;
    box-sizing: border-box;

    &::v-deep .cg-radio__content > div:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }

  &__spend-limit-form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &__button {
    border-radius: 100px;
    max-width: fit-content;
    margin-top: 30px;
  }
}
</style>
