<template>
  <div
    class="transactions-history-table__header"
    :class="{'transactions-history-table__header--sticky': isSticky}"
    :style="`--header-height: ${headerHeight}px`"
  >
    <div>Product</div>
    <div>Order #</div>
    <div>Kit name</div>
    <div>Kit sku</div>
    <div>Campaign</div>
    <div>User</div>
    <div>Transaction type</div>
    <div>Parent order #</div>
    <div>Date</div>
    <div>Quantity</div>
    <div>$ value</div>
    <div>$ total value</div>
    <div>Status</div>
  </div>
</template>

<script>
import getHeaderHeight from '@/mixins/getHeaderHeight'

export default {
  name: 'TheTransactionsHistoryTableHeader',
  mixins: [getHeaderHeight],
  data: () => ({
    isSticky: false,
  }),
  computed: {
    isOnTransactionsTab () {
      const queryString = this.$route.query ?? {}

      return queryString?.tab === 'transactions' ?? queryString?.tab
    },
  },
  watch: {
    isOnTransactionsTab: {
      immediate: true,
      handler: function (val) {
        if (val) {
          // make header sticky after ~0.5sec to avoid jumping
          setTimeout(() => (this.isSticky = true), 550)
        } else {
          this.isSticky = false
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/components/myInventory/styles/transactions-history-row-grid.scss";

.transactions-history-table__header {
  @include transactionsHistoryRowGrid;

  background: #fff;
  border-top: 1px solid #D3D2D2;
  border-bottom: 1px solid #D3D2D2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  width: inherit;
  z-index: 2;
  top: 0;
  overflow-x: hidden;

  & > div {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #000000;
  }

  &--sticky {
    top: calc(var(--header-height, 160px) - 2px);
  }
}
</style>
