var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popper',{attrs:{"options":{
    placement: 'right',
    modifiers: {
      offset: {
        offset: '0, 4px'
      },
      preventOverflow: {
        boundariesElement: 'offsetParent'
      }
    }
  }}},[_c('div',{staticClass:"popper pa-4 text-left white customized-popper",staticStyle:{"max-width":"350px"}},[_c('ul',{staticClass:"kit-items-tooltip pl-0"},_vm._l((_vm.items),function(item){return _c('li',{key:item.sku},[_c('img',{attrs:{"src":item.image_url,"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"green2 orders-table__product-status",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Kit items ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }