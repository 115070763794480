import { render, staticRenderFns } from "./TheMyFavoritesShareListItemPanel.vue?vue&type=template&id=2d7cd28a&scoped=true&"
import script from "./TheMyFavoritesShareListItemPanel.vue?vue&type=script&lang=js&"
export * from "./TheMyFavoritesShareListItemPanel.vue?vue&type=script&lang=js&"
import style0 from "./TheMyFavoritesShareListItemPanel.vue?vue&type=style&index=0&id=2d7cd28a&prod&lang=scss&scoped=true&"
import style1 from "./TheMyFavoritesShareListItemPanel.vue?vue&type=style&index=1&id=2d7cd28a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7cd28a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VChip,VCol,VCombobox,VContainer,VDivider,VNavigationDrawer,VRow,VTextarea})
