<template>
  <div>
    <cg-loader v-if="loading" />

    <div v-show="!loading" class="add-credit-card-form">
      <div class="bluesnap-hosted-field__input-wrapper">
        <label for="card-number">Credit card number</label>

        <div class="bluesnap-hosted-field__credit-card">
          <div class="bluesnap-hosted-field" id="card-number" data-bluesnap="ccn" />

          <img :src="creditCardIcon" alt="">
        </div>
      </div>

      <div class="bluesnap-hosted-field__double-inputs">
        <div class="bluesnap-hosted-field__input-wrapper">
          <label for="expiration-date">Expiry date</label>
          <div class="bluesnap-hosted-field" id="expiration-date" data-bluesnap="exp" />
        </div>
        <div class="bluesnap-hosted-field__input-wrapper">
          <label for="cvv">Card verification code</label>
          <div class="bluesnap-hosted-field" id="cvv" data-bluesnap="cvv" />
        </div>
      </div>

      <cg-checkbox v-if="showSetAsDefault" v-model="saveAsDefault" :disabled="forceSaveAsDefault">
        Set as default
      </cg-checkbox>

      <span>
        Billing address
      </span>

      <billing-address-form
        ref="billingAddress"
        @onBullingAddressUpdate="(val) => billingForm = val"
      />

      <cg-button v-if="showAddCardButton" @click="sendCard">
        Add card
      </cg-button>
    </div>
  </div>
</template>

<script>
/* global bluesnap */
import Api from '@/axios/api'

import { CgButton, CgCheckbox, CgLoader } from '@corporategift/design-system'
import BillingAddressForm from './BillingAddressForm.vue'

export default {
  name: 'AddBluesnapCreditCardForm',
  components: {
    CgButton,
    CgLoader,
    CgCheckbox,
    BillingAddressForm,
  },
  props: {
    showSetAsDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAddCardButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    forceSaveAsDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    creditCardIcon: '/images/credit-cards/master-card.svg',
    loading: false,
    billingForm: {},
    saveAsDefault: false,
    blueSnapToken: null,
  }),
  watch: {
    forceSaveAsDefault: {
      immediate: true,
      handler: function (val) {
        this.saveAsDefault = val
      }
    },
  },
  created () {
    this.initBluesnap();
  },
  methods: {
    async takeToken () {
      return Api.get('bluesnap/payment-fields-token')
        .then((data) => {
          this.blueSnapToken = data
          return data
        })
    },
    initBluesnap () {
      this.loading = true
      this.showError = false
      this.takeToken().then((apiToken) => {
        var bsObj = {
          token: apiToken,
          onFieldEventHandler: {
            setupComplete: function () {
              this.updateLoadingState(false)
            },
            // tagId returns: "ccn", "cvv", "exp"
            onFocus: function (tagId) {}, // Handle focus
            onBlur: function (tagId) {}, // Handle blur
            onError: function (tagId, errorCode) {
              if (errorCode === '10') console.log('Invalid values')
              if (errorCode === '14040') this.updateToken() || console.log('Token is expired')
              if (errorCode === '14041') this.updateToken() || console.log('Could not find token')
              if (errorCode === '400') console.log('Session expired - refrresh page to continue')
            }, // Handle a change in validation
            /* errorDescription is optional. Returns BlueSnap's standard error description */

            onType: function (tagId, cardType, cardData) {
              const creditCardWrapper = document.querySelector('.credit-card-number-wrapper')
              if (tagId === 'ccn' && cardType === 'UNKNOWN') {
                creditCardWrapper?.classList?.add('credit-card-number-wrapper--invalid')
              } else {
                creditCardWrapper?.classList?.remove('credit-card-number-wrapper--invalid')
                this.updateCreditCardIcon(cardType)
              }
            }, /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
            onEnter: function (tagId) {}, // Will trigger when shopper presses enter while inside one of the inputs
            onValid: function (tagId) {} // Handle a change in validation
          },
          style: {
            input: {
              'font-family':
                'RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
              'font-style': 'italic',
              'line-height': '20px',
              color: '#000000de',
              display: 'inherit',
              padding: '0 8px'
            },
            ':focus': {
              color: '#000'
            },
            '.invalid': {
              color: '#FA5E5E',
            },
            '#ccn': {
              'letter-spacing': '4.5px',
              'border-color': '#d3d2d2',
              'border-width': '1px',
              'border-style': 'solid'
            },
            '#ccn.invalid': {
              'border-color': '#FA5E5E'
            },
            '#cvv': {
              'border-color': '#d3d2d2',
              'border-width': '1px',
              'border-style': 'solid'
            },
            '#cvv.invalid': {
              'border-color': '#FA5E5E'
            },
            '#exp': {
              'border-color': '#d3d2d2',
              'border-width': '1px',
              'border-style': 'solid'
            },
            '#exp.invalid': {
              'border-color': '#FA5E5E'
            }
          },
          ccnPlaceHolder: '____ ____ ____ ____',
          cvvPlaceHolder: 'CVC',
          expPlaceHolder: 'MM / YY'
        }

        bsObj.onFieldEventHandler.updateCreditCardIcon = this.updateCreditCardIcon
        bsObj.onFieldEventHandler.updateToken = this.updateToken

        bsObj.onFieldEventHandler.updateLoadingState = (val) => {
          this.loading = val
        }

        bluesnap.hostedPaymentFieldsCreate(bsObj)

        // BlueSnap adds iframe to the body and that element adds another scrollbar
        // this query select hides all BlueSnap iframes
        document.querySelectorAll('#bs-fraud-sid').forEach(element => {
          element.style.display = 'none'
        })
      })
    },
    updateToken () {
      this.takeToken().then(token => bluesnap.hostedPaymentFieldsUpdateToken(token))
    },
    updateCreditCardIcon (cardType) {
      let val = '/images/credit-cards/master-card.svg'
      switch (cardType) {
        case 'VISA':
          val = '/images/credit-cards/visa.svg'
          break;
        case 'DISCOVER':
          val = '/images/credit-cards/discover.svg'
          break;
        case 'DINERS':
          val = '/images/credit-cards/diners-club.svg'
          break;
        case 'AMEX':
          val = '/images/credit-cards/american-express.svg'
          break;
        default:
          break;
      }
      this.creditCardIcon = val
    },
    sendCard () {
      if (!this.$refs.billingAddress?.validate()) { return }
      const { billingForm, saveAsDefault, blueSnapToken } = this
      const gRecaptcha = window.grecaptcha.enterprise

      gRecaptcha.ready(() => {
        this.loading = true
        gRecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'SUBMIT' })
          .then((token) => {
            bluesnap.hostedPaymentFieldsSubmitData((callback) => {
              if (callback?.cardData) {
                Api.post('/bluesnap/credit-cards', {
                  pfToken: blueSnapToken,
                  billingContactInfo: billingForm,
                  recaptcha_token: token,
                  is_default: saveAsDefault,
                })
                  .then(() => {
                    this.loading = false
                    this.$emit('onAddCard')
                  })
                  .catch((e) => {
                    Promise.reject(e)
                    this.loading = false
                  })
              } else {
                this.loading = false
                this.$cgToast.error(callback?.error, { html: true })
              }
            })
          })
          .catch((e) => {
            this.loading = false
            this.$cgToast.error(e, { html: true })
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-credit-card-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > span {
    padding: 20px 0 10px;
    color: #95979D;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    line-height: normal;
    font-weight: 700;
    font-size: 14px;
  }

  & button {
    border-radius: 100px;
  }
}

.cg-loader {
  max-height: 200px;
  margin: 200px auto 0;
}
</style>

<style lang="scss">
.bluesnap-hosted-field {
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & label {
      color: #000;
      font-weight: 700;
      font-size: 15px;
      line-height: normal;
    }
  }

  &__double-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &__credit-card {
    position: relative;

    & > img {
      width: 40px;
      object-fit: contain;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & iframe {
    max-height: 40px;
    border: none !important;
  }
}
</style>