<template>
  <div
    class="chart"
    :class="{
      'chart--small': isChartSmall,
      'chart--show-on-hold': showOnHold
    }"
    :chart-name="entity.name"
  >
    <v-tooltip
      top
      :nudge-top="(isChartSmall && showOnHold) ? 4 : 20"
      z-index="4"
      :content-class="tooltipContentClass"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="chart__wrapper"
          :style="`
            --chart-height: ${chartWrapperHeight}%;
          `"
          v-bind="attrs"
          v-on="on"
        >
          <div
            class="chart__bar"
            :chart-value="entityBalance"
            :style="`
              --chart-color: ${entity.color};
              --chart-height: ${chartBalanceHeight}%;
            `"
          />
          <div
            class="chart__bar"
            v-if="showOnHold"
            :chart-value="entityOnHold"
            :style="`
              --chart-color: #4A4A4A;
              --chart-height: ${chartOnHoldHeight}%;
            `"
          />
        </div>
      </template>
      <span v-if="!hideTooltip">
        <v-row no-gutters>
          <v-col cols="12" class="content--title">
            {{ entity.name }}
          </v-col>
          <v-col cols="12" class="content--credits">
            <v-row dense align="center">
              <v-col
                cols="6"
                class="content--dot"
                :style="`--dot-color: ${entity.color}`"
              >
                Available:
              </v-col>
              <v-col cols="6" class="text-right black2">
                {{ entityBalance }}
              </v-col>

              <v-col
                cols="6"
                class="content--dot"
                style="--dot-color: #4A4A4A"
              >
                On hold:
              </v-col>
              <v-col cols="6" class="text-right black2">
                {{ entityOnHold }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TheCreditsInfoChartBar',
  props: {
    entity: {
      type: Object,
      required: true
    },
    showOnHold: {
      type: Boolean,
      required: true
    },
    maxBalance: {
      type: Number,
      required: true
    },
    hideTooltip: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    chartWidth: 0
  }),
  computed: {
    entityMaxValue () {
      const { balance, on_hold: onHold } = this.entity
      return Math.max(...[balance, onHold])
    },
    chartBalanceBaseHeight () {
      const { maxBalance, entity: { balance } } = this
      return ((balance || 0) * 100) / maxBalance
    },
    chartOnHoldBaseHeight () {
      const { maxBalance, entity: { on_hold: onHold } } = this
      return ((onHold || 0) * 100) / maxBalance
    },
    chartBalanceHeight () {
      const { entity: { balance }, entityMaxValue, showOnHold } = this
      if (showOnHold) {
        return ((balance || 0) * 100) / entityMaxValue
      } else {
        return 100
      }
    },
    chartOnHoldHeight () {
      const { entity: { on_hold: onHold }, entityMaxValue, chartOnHoldBaseHeight, showOnHold } = this
      if (showOnHold) {
        return ((onHold || 0) * 100) / entityMaxValue
      } else {
        return chartOnHoldBaseHeight
      }
    },
    chartWrapperHeight () {
      const { chartBalanceBaseHeight, chartOnHoldBaseHeight, showOnHold } = this
      if (showOnHold) {
        return Math.max(...[chartBalanceBaseHeight, chartOnHoldBaseHeight])
      } else {
        return chartBalanceBaseHeight
      }
    },
    entityBalance () {
      const { balance } = this.entity
      return this.$options.filters.priceFilter(
        balance,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    },
    entityOnHold () {
      const { on_hold: onHold } = this.entity
      return this.$options.filters.priceFilter(
        onHold,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    },
    isChartSmall () {
      const { chartWidth } = this
      return chartWidth === 48
    },
    tooltipContentClass () {
      let contentClass = 'v-tooltip__content--bottom chart-bar-tooltip'
      if (this.hideTooltip) contentClass += ' chart-bar-tooltip--hide'
      return contentClass
    }
  },
  mounted () {
    this.chartWidth = this.$el.clientWidth
  },
  updated () {
    this.chartWidth = this.$el.clientWidth
  }
}
</script>

<style lang="scss">
.chart-bar-tooltip {
  background: #fff !important;
  border: none !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15) !important;
  padding: 16px;
  min-width: 170px;
  max-width: 180px;
  opacity: 1 !important;

  .content {
    &--title {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #16181f;
      word-break: break-word;
      padding-bottom: 20px !important;
    }

    &--credits {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #979797;
    }

    &--dot {
      position: relative;
      padding-left: 16px !important;

      &::before {
        width: 10px;
        height: 10px;
        background: var(--dot-color);
        border-radius: 50%;
        content: ' ';
        position: absolute;
        transform: translate(0, 60%);
        left: 0;
        bottom: 60%;
      }
    }
  }

  &--hide {
    display: none !important;
  }

  &::before, &::after {
    background-color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.chart {
  position: relative;
  min-width: 48px;
  width: 75px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  &--small {
    width: 48px;
  }

  &.chart--small.chart--show-on-hold {
    .chart__bar::before {
      content: none !important;
    }
  }

  &:not(.chart--small).chart--show-on-hold {
    width: 150px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    height: var(--chart-height);
    width: 100%;
  }

  &__bar {
    position: relative;
    height: var(--chart-height);
    background: var(--chart-color);
    flex: 1 1 auto;

    &::before {
      top: -20px;
      padding-bottom: 6px;
      content: attr(chart-value);
      color: #262626;
    }
  }

  &__bar::before, &::after {
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::after {
    bottom: -20px;
    content: attr(chart-name);
    color: #919191;
  }
}
</style>
