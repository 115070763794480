<template>
  <div class="credit-card-item">
    <img src="/images/ACH_icon.svg" alt="">

    <div class="credit-card-item__info">
      <div v-if="companyName">Company Name: {{ companyName }}</div>
      <div>Account Number: {{ publicAccountNumber }}</div>
      <div>Routing Number: {{ publicRoutingNumber }}</div>
    </div>

    <div class="credit-card-item__actions">
      <icons-delete
        v-tooltip="{
          content: 'Remove this account',
          ...standardTooltipProps,
          offset: 8,
        }"
        :width="14"
        :height="17"
        @click.native="deleteAch"
      />

      <icons-check-circle
        v-if="showDefaultIcon"
        class="is-active"
        :height="20"
        :width="20"
        :filled="isSelected"
      />
    </div>
  </div>
</template>

<script>
import standardTooltipProps from '@/mixins/standardTooltipProps'

export default {
  name: 'AchItem',
  mixins: [standardTooltipProps],
  props: {
    data: {
      type: Object,
      required: true,
    },
    showDefaultIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    companyName () { return this.data?.businessName ?? null },
    publicAccountNumber () {
      return this.data?.last4 ?? this.data?.publicAccountNumber ?? null
    },
    publicRoutingNumber () {
      return this.data?.routingNumber ?? this.data?.publicRoutingNumber ?? null
    },
  },
  methods: {
    deleteAch () {
      this.$emit('deletePaymentMethod', this.data)
    },
  }
}
</script>

<style lang="scss" scoped>
.credit-card-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 14px;
  background-color: #F5F5F5;
  gap: 32px;

  & > img {
    object-fit: contain;
    width: 68px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #222325;
    font-weight: 400;
    line-height: 22px;
    font-size: 15px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-left: auto;
    justify-content: space-between;

    & > svg { 
      cursor: pointer;
    }
  }
}

.card-expired {
  color: #FF5A60;
}

.is-active {
  pointer-events: none;
}
</style>
