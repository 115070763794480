
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DepositInfoItem extends Vue {
  @Prop({ required: false }) title!: string;

  @Prop({ required: true }) content!: string;

  @Prop({ required: false }) videoUrl!: string;

  @Prop({ required: false, default: false }) expandable!: boolean;

  expand = false;

  get showContent (): boolean {
      const { expand, expandable } = this

    return expandable ? expand : true
  }
}
