import { render, staticRenderFns } from "./AddAchPanel.vue?vue&type=template&id=70b24524&scoped=true&"
import script from "./AddAchPanel.vue?vue&type=script&lang=js&"
export * from "./AddAchPanel.vue?vue&type=script&lang=js&"
import style0 from "./AddAchPanel.vue?vue&type=style&index=0&id=70b24524&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b24524",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
