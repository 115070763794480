<template>
  <div class="dashboard-card-pagination">
    <div
      class="dashboard-card-pagination__arrow"
      :class="{ 'dashboard-card-pagination__arrow--disabled': currentIndex === 0 }"
      @click="currentIndex !== 0 ? currentIndex -= 1 : null"
    >
      <icons-arrow-left color="#95979D" />
    </div>
    <div class="dashboard-card-pagination__content">
      {{ currentIndex + 1 }}
      <span>
        /{{ maxLength }}
      </span>
    </div>
    <div
      class="dashboard-card-pagination__arrow"
      :class="{ 'dashboard-card-pagination__arrow--disabled': currentIndex === (maxLength - 1) }"
      @click="currentIndex !== (maxLength - 1) ? currentIndex += 1 : null"
    >
      <icons-arrow-left color="#95979D" style="transform: rotate(-180deg);" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardCardPagination',
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    maxLength: {
      type: Number,
      required: true,
    }
  },
  computed: {
    currentIndex: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-card-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 8px;
  user-select: none;

  &__arrow {
    cursor: pointer;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &--disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &__content {
    font-weight: 700;
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 3.5px;
    color: #38A39C;

    & > span {
      color: #95979D !important;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;
    }
  }
}
</style>