<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    :width="$vuetify.breakpoint.xsOnly ? undefined : 562"
  >
    <div class="create-campaign-exit-modal__wrapper">
      <div class="create-campaign-exit-modal__content">
        <div class="create-campaign-exit-modal__title">Are you sure?</div>

        <span class="create-campaign-exit-modal__text">
          You haven't saved changes to campaign. Are you sure you want to exit?
        </span>

        <div class="create-campaign-exit-modal__actions">
          <cg-button outlined @click="show = false">Continue edit campaign</cg-button>
          <cg-button @click.native="onExitPage">Exit</cg-button>
        </div>
      </div>

      <v-icon color="white" aria-label="Cancel" class="pointer" @click="show = false">
        mdi-close
      </v-icon>
    </div>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'
import onExitPage from '@/components/createCampaign/mixins/onExitPage'

import { CgButton } from '@corporategift/design-system'

export default {
  name: 'CreateCampaignCloseModal',
  mixins: [
    panelVModel,
    onExitPage,
  ],
  components: { CgButton },
}
</script>

<style lang="scss" scoped>
.create-campaign-exit-modal {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media screen and (max-width: 430px) {
      & > .v-icon {
        display: none;
      }
    }
  }

  &__content {
    gap: 20px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;

    @media screen and (max-width: 430px) {
      padding: 16px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000;
  }

  &__actions {
    gap: 12px;
    display: flex;
    flex-direction: row;

    & > button,
    & > a {
      border-radius: 100px;
    }
  }
}
</style>
