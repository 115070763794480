<template>
  <v-container class="ma-0 pa-6">
    <v-row dense>
      <!-- select -->
      <v-col class="flex-shrink-1 flex-grow-0">
        <label for="select">
          Select
        </label>
        <the-select-multiple-contacts
          v-model="filterSelect"
          :options="selectItems"
          v-on="$listeners"
        />
      </v-col>
      <!-- find -->
      <v-col :cols="hideShowFilter ? 4 : 3">
        <label for="find">
          Find Contacts & Group
        </label>

        <common-input
          v-model="filterSearch"
          height="30"
          filter-style
          :append-icon="!filterSearch ? 'mdi-magnify' : null"
          clearable
          placeholder="Name, company, group name..."
        />
      </v-col>
      <!-- sort by -->
      <v-col :cols="hideShowFilter ? 3 : 2">
        <label for="sort">
          Sort by
        </label>

        <common-select
          v-model="filterSortBy"
          :items="sortByItems"
          height="30"
          filter-style
          :menu-z-index="3"
          placeholder="Select"
          class="filter-bar-select"
        />
      </v-col>
      <!-- show -->
      <v-col class="ml-auto flex-shrink-1 flex-grow-0" v-if="!hideShowFilter">
        <label for="show">
          Show
        </label>

        <div class="d-flex" style="gap: 20px">
          <common-checkbox v-model="filterShowContacts" center-content>
            <span class="show__name mr-2">
              Contacts
            </span>
            <span class="show__name show__name--bold">
              {{ contactsNumber }}
            </span>
          </common-checkbox>

          <common-checkbox v-model="filterShowGroups" center-content>
            <span class="show__name mr-2">
              Groups
            </span>
            <span class="show__name show__name--bold">
              {{ groupsNumber }}
            </span>
          </common-checkbox>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheSelectMultipleContacts from './TheSelectMultipleContacts'

import { sortByEnum } from './types/sortByEnum'

export default {
  name: 'TheFilterBar',
  components: {
    TheSelectMultipleContacts
  },
  props: {
    contactsNumber: {
      type: Number,
      required: false,
      default: 0
    },
    groupsNumber: {
      type: Number,
      required: false,
      default: 0
    },
    hideShowFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: String,
      required: false,
      default: null
    },
    showContacts: {
      type: Boolean,
      required: false,
      default: true
    },
    showGroups: {
      type: Boolean,
      required: false,
      default: true
    },
    sortBy: {
      type: String,
      required: false,
      default: null
    },
    select: {
      type: String,
      required: false,
      default: 'none'
    },
    selectItems: {
      type: Array,
      required: false,
      default: () => ([
        'all',
        'none',
        'contacts',
        'groups'
      ])
    }
  },
  data: () => ({
    sortByItems: Object.values(sortByEnum)
  }),
  computed: {
    filterSearch: {
      get () {
        return this.search
      },
      set (val) {
        this.$emit('update:search', val)
      }
    },
    filterShowContacts: {
      get () {
        return this.showContacts
      },
      set (val) {
        this.$emit('update:showContacts', val)
      }
    },
    filterShowGroups: {
      get () {
        return this.showGroups
      },
      set (val) {
        this.$emit('update:showGroups', val)
      }
    },
    filterSortBy: {
      get () {
        return this.sortBy
      },
      set (val) {
        this.$emit('update:sortBy', val)
      }
    },
    filterSelect: {
      get () {
        return this.select
      },
      set (val) {
        this.$emit('update:select', val)
      }
    }
  }
}
</script>

<style lang="scss">
.filter-bar-select .v-input__slot {
  padding-left: 2px !important;
}
</style>

<style lang="scss" scoped>
label {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #b8b8b8;
}

.container {
  background-color: #f5f5f5;
}

.show__name {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #9B9B9B;

  &--bold {
    font-weight: 700;
    color: #000;
  }
}
</style>
