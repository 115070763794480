<template>
  <div class="order-item-details-recipient">
    <div><b>{{ addressFullName }}</b></div>
    <div>{{ address.street | capitalizeFirstLetter }}</div>
    <div>
      {{ addressCity | capitalizeFirstLetter }},
      {{ addressRegion }}
      {{ addressPostCode }}
    </div>
    <div>{{ addressCountryCode }}</div>
    <div v-if="addressPhone">Phone: {{ addressPhone }}</div>
    <div v-if="companyName">Company: {{ companyName }}</div>
  </div>
</template>

<script>
export default {
  name: 'TheMyOrdersTableDetailsRecipient',
  // TODO mixin
  filters: {
    capitalizeFirstLetter: (val) => {
      if (!val) return val

      const [firstLetter, ...rest] = val

      return [firstLetter.toUpperCase(), ...rest].join('')
    }
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  computed: {
    addressFullName () {
      const { first_name, last_name } = this.address

      return [first_name, last_name]?.join(' ') ?? null
    },
    addressCity () {
      return this.address?.city ?? null
    },
    addressRegion () {
      return this.address?.region ?? null
    },
    addressPostCode () {
      return this.address?.postcode ?? null
    },
    addressCountryCode () {
      const { country } = this.address

      return new Intl.DisplayNames(['en'], { type: 'region' }).of(country || 'US')
    },
    addressPhone () {
      return this.address?.telephone ?? null
    },
    companyName () {
      return this.address?.company ?? null
    },
  }
}
</script>

<style lang="scss" scoped>
.order-item-details-recipient {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 14px;
  background-color: #fff;
  max-height: 300px;
  max-width: 200px;
  font-weight: 400;
  font-size: 14px;
  color: #16181F;
  overflow-y: auto;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  & b {
    font-weight: 700;
  }
}
</style>
