
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SubAccount extends Vue {
  @Prop({ default: 18 }) readonly width!: number | string;

  @Prop({ default: 19 }) readonly height!: number | string;

  @Prop({ default: 'currentColor', required: false }) readonly color!: string;
}
