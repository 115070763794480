<template>
  <v-menu
    max-width="200"
    open-on-click
    offset-y
    bottom
    left
    tile
    z-index="4"
    content-class="favorites-product__options"
  >
    <template #activator="{ on, attrs }">
      <div
        class="options-activator"
        :class="{ 'options-activator--hover-mode': hoverMode }"
        v-bind="attrs"
        v-on="on"
        @click.prevent
      >
        <icons-settings-line />
      </div>
    </template>

    <v-list dense>
      <v-list-item dense @click="$emit('showMoreInfo')">
        <v-list-item-title>
          More info
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense @click="$emit('removeFromList')" v-if="!hideRemoveItem">
        <v-list-item-title>
          Remove from collection
        </v-list-item-title>
      </v-list-item>

      <save-to-collections-item
        :wishlists="wishlists"
        :productId="productId"
        v-if="!hideSaveToCollectionsItem"
        :productWishlists="productWishlists"
        :currentWishlistId="currentWishlistId"
        @addNewList="(val) => $emit('addNewList', val)"
        @addProductToList="(val) => $emit('addProductToList', val)"
        @removeProductFromList="(val) => $emit('removeProductFromList', val)"
      />
    </v-list>
  </v-menu>
</template>

<script>
import SaveToCollectionsItem from './TheMyFavoritesProductOptionsSaveToCollection.vue'

export default {
  name: 'TheMyFavoritesProductOptions',
  components: {
    SaveToCollectionsItem,
  },
  props: {
    productId: {
      type: [Number, String],
      required: true
    },
    hoverMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideRemoveItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSaveToCollectionsItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    wishlists: {
      type: Array,
      required: false,
      default: () => ([])
    },
    productWishlists: {
      type: Array,
      required: true
    },
    currentWishlistId: {
      type: Number,
      required: false,
      default: null
    }
  },
}
</script>

<style lang="scss" scoped>
.favorites-product__options {
  padding: 0px !important;

  .v-list {
    padding: 10px 0px;
  }

  ::v-deep .v-list-item {
    padding: 0px 15px !important;
    color: #62646A;
    cursor: pointer;

    &--dense {
      min-height: 30px;
    }

    &__title {
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 35px;
    }
  }
}

.options-activator {
  background-color: #fff;
  display: flex;
  justify-items: center;
  align-content: center;
  width: 36px;
  height: 36px;
  margin: 6px;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
  transition: background-color ease-in-out 0.2s;

  &--hover-mode, &:hover {
    background-color: #f4f4f4;
  }
}
</style>
