<template>
  <v-row no-gutters>
    <v-col cols="12" class="d-flex align-center">
      <div class="bold-text">Units to refill</div>
      <refill-input
        v-model="inputValue"
        v-on="$listeners"
        style="max-width: 50px"
        class="ml-2 mr-5"
        :marketplaceProductQty="marketplaceProductQty"
        :missingInfo="false"
        :id="entityId"
        :outOfStock="marketplaceProductQty === 0"
      />
      <div class="sub-text" v-if="minQty > 1">
        Minimum quantity of this item is {{ minQty }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RefillInput from '../RefillPanel/RefillInput'

export default {
  name: 'ProductFormWithoutOptions',
  components: {
    RefillInput
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    minQty: {
      type: Number,
      required: true
    },
    marketplaceProductQty: {
      type: Number,
      required: false,
      default: 0
    },
    entityId: {
      type: Number,
      required: true
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set () {}
    }
  }
}
</script>

<style lang="scss">
.sub-text {
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  line-height: 18px;
  color: #a1a1a1;
}
</style>
