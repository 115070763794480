import { render, staticRenderFns } from "./TheTransactionsTableWrapper.vue?vue&type=template&id=004d4826&scoped=true&"
import script from "./TheTransactionsTableWrapper.vue?vue&type=script&lang=js&"
export * from "./TheTransactionsTableWrapper.vue?vue&type=script&lang=js&"
import style0 from "./TheTransactionsTableWrapper.vue?vue&type=style&index=0&id=004d4826&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004d4826",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
