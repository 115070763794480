<template>
  <div class="add-to-group">
    <div class="add-to-group__button" @click="showList = !showList">
      <v-img
        src="/images/add_group_ico.svg"
        alt="Add to group icon"
        width="17"
        max-width="17"
        height="17"
        max-height="17"
      />
      <span>
        Add to group...
      </span>
    </div>

    <div class="add-to-group__list elevation-3" v-if="showList">
      <div class="list-items">
        <div
          v-for="(item, index) in groups"
          class="list-items__item"
          :class="isItemSelected(item.entity_id) && 'list-items__item--selected'"
          @click="selectItem(item.entity_id)"
          :key="index"
        >
          <icons-check-circle
            height="22"
            width="22"
            :filled="isItemSelected(item.entity_id)"
            :checkStrokeWidth="1.4"
          />
          <div class="list-items__item--text">
            {{ item.name }}
          </div>
        </div>
      </div>

      <common-input
        v-model="newGroupName"
        placeholder="+ Enter new group"
        height="30"
        min="2"
        :hideDetails="false"
        hint="Min 2 characters"
      />

      <common-button
        block
        height="32"
        @click="saveList()"
        :disabled="disableButton"
      >
        Save & Close
      </common-button>
    </div>
  </div>
</template>

<script>
import { groups } from './mixins'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'TheAddToGroupButton',
  mixins: [
    groups
  ],
  props: {
    value: {
      type: Array,
      required: true
    },
    userNewGroup: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    actualList: [],
    showList: false,
    newGroupName: null
  }),
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    disableButton () {
      if (!this.newGroupName) return false

      return this.newGroupName?.length < 2
    }
  },
  watch: {
    showList: function (val) {
      if (val) {
        this.actualList = cloneDeep(this.selected)
        this.newGroupName = this.userNewGroup
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.showList = false
      }
    },
    isItemSelected (itemId) {
      return this.actualList.includes(itemId)
    },
    selectItem (itemId) {
      const { actualList } = this
      if (this.isItemSelected(itemId)) {
        const newSelected = actualList.filter((sItem) => sItem !== itemId)
        this.actualList = newSelected
      } else {
        const newSelected = actualList
        newSelected.push(itemId)
        this.actualList = newSelected
      }
    },
    saveList () {
      this.selected = this.actualList
      this.$emit('update:userNewGroup', this.newGroupName)
      this.showList = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-group {
  position: relative;
  // max-width: 140px;

  &__button {
    color: #219358;
    font-weight: 400;
    cursor: pointer;
    gap: 10px;
    max-width: 120px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }

  &__list {
    position: absolute;
    z-index: 4;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    min-height: 100px;
    min-width: 140px;
    max-width: 800px;
    bottom: 100%;
    margin: 12px -20px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    &::after {
      content: ' ';
      width: 16px;
      height: 16px;
      background-color: rgba(255, 255, 255, 0.98);
      position: absolute;
      bottom: 0;
      left: 64px;
      margin-bottom: -8px;
      z-index: -1;
      transform: rotate(45deg);
      box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 0px 0px rgb(0 0 0 / 14%);
    }

    .list-items {
      min-height: 0px;
      max-height: 200px;
      max-width: 800px;
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__item {
        font-weight: 400;
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        color: #16181F;
        word-break: break-word;
        padding: 8px;
        transition: background-color ease-in-out 0.2s;

        &--text {
          width: calc(100% - 22px);
        }

        &--selected {
          background-color: #D8F1E4;
        }
      }
    }
  }
}
</style>
