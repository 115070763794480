<template>
  <ul class="campaign-automation-group-info-tooltip">
    <li v-for="contact in contacts" :key="contact.id">
      <span>{{ getContactName(contact) }}</span>

      <span
        v-if="trigger === 'email'"
        :class="{ 'missing': !contact.email }"
      >
        {{ getContactEmail(contact) }}
      </span>

      <span
        v-else
        :class="{ 'missing': !contactHasDate(contact) }"
      >
        <template v-if="contactHasDate(contact)">
          {{ getContactDate(contact) }}
        </template>

        <template v-else>
          Missing
        </template>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AutomationCampaignPanelGroupInfoTooltip',
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    trigger: {
      type: String,
      required: false,
    },
  },
  methods: {
    getContactName ({ first_name, last_name }) {
      return [first_name, last_name].join(' ')
    },
    getContactDate ({ anniversary_date, birthday_date }) {
      const { trigger } = this
      return trigger === 'anniversary' ? anniversary_date : birthday_date
    },
    contactHasDate (contact) {
      return !!this.getContactDate(contact)
    },
    getContactEmail ({ email }) {
      if (email) { return email.length > 10 ? `${email.slice(0, 10)}...` : email }
      return 'Missing'
    }
  },
}
</script>

<style lang="scss" scoped>
.campaign-automation-group-info-tooltip {
  gap: 14px;
  width: 100%;
  display: flex;
  list-style-type: none;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 14px;
  max-height: 440px;
  overflow: hidden auto;
  min-width: 210px;
  max-width: 260px;
  margin: 8px 0;
  box-shadow: 0px 0px 30px 0px #00000026;
  
  & > li {
    gap: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > span {
      font-size: 14px;
      line-height: 18px;
      color: #222325;

      &:nth-child(1) {
        word-break: break-word;
        white-space: break-spaces;
        font-weight: 700;
      }

      &:nth-child(2) {
        white-space: nowrap;
        font-weight: 400;
      }

      &.missing {
        color: #DD080E;
        font-weight: 500;
      }
    }
  }
}
</style>

