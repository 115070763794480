<template>
  <div class="add-to-cart-product">
    <v-img
      contain
      :height="$vuetify.breakpoint.xsOnly ? 190 : 260"
      :max-height="$vuetify.breakpoint.xsOnly ? 190 : 260"
      :width="$vuetify.breakpoint.xsOnly ? 190 : 238"
      :max-width="$vuetify.breakpoint.xsOnly ? 190 : 238"
      :src="productImage"
      :alt="productName"
    >
      <div class="add-to-cart-product__statuses">
        <update-status-sheet
          v-if="productIsCustomized"
          color="#A7DAD8"
          textColor="#004642"
        >
          Customized
        </update-status-sheet>

        <update-status-sheet
          v-if="productIsOOS"
          color="#fff"
          textColor="#FA5E5E"
          style="border: 1px solid #FA5E5E"
        >
          Out of Stock
        </update-status-sheet>
      </div>
    </v-img>

    {{ productName }}
  </div>
</template>

<script>
import UpdateStatusSheet from '@/components/UpdateStatusSheet.vue'

export default {
  name: 'TheMyFavoritesProblemWithAddToCartPanelItem',
  components: {
    UpdateStatusSheet,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    imageUrl: 'https://cf.corporategift.com/media/catalog/product',
  }),
  computed: {
    productIsCustomized () {
      return this.product?.product?.should_be_customized ?? false
    },
    productIsOOS () {
      return this.product?.product?.qty.is_out_of_stock ?? false
    },
    productName () {
      return this.product?.product?.name ?? null
    },
    productImage () {
      const image = this.product?.product?.image ?? null
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : this.imageUrl + image
    }
  },
}
</script>

<style lang="scss" scoped>
.add-to-cart-product {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
  height: 100%;
  color: #000;
  font-size: 15px;
  line-height: 23px;
  max-width: 190px;
  font-weight: 400;

  &__statuses {
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
  }

  @media (min-width: 960px) {
    max-width: 238px;
  }
}
</style>
