<template>
  <v-navigation-drawer
    v-model="show"
    v-scroll-lock="show"
    app
    temporary
    right
    width="900px"
    class="multiple-tracking-panel"
  >
    <close-panel-button
      @close="show = false"
      text="Close"
    />

    <v-container class="px-9">
      <v-row dense>
        <v-col cols="12" class="f25 lato-light">
          Track Items Shipping
        </v-col>
        <v-col cols="12" class="pt-6">
          <v-data-table
            :headers="headers"
            :items="trackingData"
            :items-per-page="999"
            item-key="track_number"
            hide-default-footer
          >
            <template #item.track_number="{ item }">
              <a
                :href="item.track_url"
                target="_blank"
              >
                {{ item.track_number }}
              </a>
            </template>
            <template #item.status="{ item }">
              <span v-if="item.status">
                {{ item.status }}
              </span>
              <span v-else>
                N/A
              </span>
            </template>
            <template #item.items="{ item }">
              <ul>
                <li v-for="(product, index) in item.items" :key="index">
                  {{ product }}
                </li>
              </ul>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { multipleTrackingHeaders } from '@/assets/headers'

export default {
  name: 'MultipleTrackingPanel',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    trackingData: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    headers: multipleTrackingHeaders
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.multiple-tracking-panel {
  .v-data-table  {
    .v-data-table__wrapper  {
      .v-data-table-header th {
        border-top: none;
        border-right: none;
        padding: 0 12px;

        span {
          font-size: 16px;
          font-weight: 700;
          color: #333;
          font-weight: unset;
        }
      }

      tbody {
        tr {
          font-size: 14px;
          color: #555;
          font-weight: 400;
          cursor: default;

          td {
            padding: 25px 12px;
          }

          a {
            word-break: break-word !important;
            color: #42b77a !important;
          }

          li {
            list-style: unset;
          }
        }
      }
    }
  }
}
</style>
