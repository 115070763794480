<template>
  <v-form ref="ach-form">
    <v-row>
      <v-col cols="12" class="f15 lato-bold black1 pb-6">
        Enter your bank account information:
      </v-col>

      <v-col cols="12">
        <label>Full name</label>
        <div class="d-flex">
          <common-input
            v-model="userFirstName"
            placeholder="First Name"
            :rules="[v => !userLastName || !!v || '']"
          />
          <common-input
            v-model="userLastName"
            placeholder="Last Name"
            :rules="[v => !userFirstName || !!v || '']"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <label for="account-type">Account type</label>
        <common-select
          v-model="accountType"
          placeholder="Select account type"
          :items="accountApprovers"
          :rules="[v => !!v || '']"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="['CORPORATE_CHECKING', 'CORPORATE_SAVINGS'].includes(accountType)"
      >
        <label for="company-name">Company name</label>
        <common-input
          v-model="company"
          placeholder="Company name"
          :rules="[v => !!v || '']"
        />
      </v-col>
      <v-col cols="12">
        <label for="routing-number">Routing number</label>
        <common-input
          v-model="routingNumber"
          placeholder="9 digits"
          :rules="[v => !!v || '']"
        />
      </v-col>
      <v-col cols="12">
        <label for="account-number">Account number</label>
        <common-input
          v-model="accountNumber"
          placeholder="Enter account number"
          :rules="[v => !!v || '']"
        />
      </v-col>

      <v-col cols="12" xl="11" class="f15 error--text" v-if="errors.length">
        {{ errors[0] }}
      </v-col>

      <v-col cols="12">
        <common-button
          height="42"
          width="200"
          @click="$emit('addAch')"
        >
          Buy Credits
        </common-button>
      </v-col>
      <v-col cols="12" xl="11" class="f15 lato-italic gray2">
        By clicking “Buy Credits” you agree to the CorporateGift.com
        <a
          href="https://corporategift.com/terms-and-conditions/"
          target="_blank"
          class="f15 lato-italic green3"
        >
          Terms of Service and Payments Policy.
        </a>
        Prepaid credits are non-refundable and non-transferrable.
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'AchPayment',
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => ([])
    },
    accountNum: {
      type: String,
      required: false,
      default: null
    },
    routingNum: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: null
    },
    firstName: {
      type: String,
      required: false,
      default: null
    },
    lastName: {
      type: String,
      required: false,
      default: null
    },
    companyName: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    accountApprovers: [
      {
        text: 'Consumer checking',
        value: 'CONSUMER_CHECKING'
      },
      {
        text: 'Consumer savings',
        value: 'CONSUMER_SAVINGS'
      },
      {
        text: 'Corporate checking',
        value: 'CORPORATE_CHECKING'
      },
      {
        text: 'Corporate savings',
        value: 'CORPORATE_SAVINGS'
      }
    ]
  }),
  computed: {
    accountNumber: {
      get () {
        return this.accountNum
      },
      set (val) {
        this.$emit('update:accountNum', val)
      }
    },
    routingNumber: {
      get () {
        return this.routingNum
      },
      set (val) {
        this.$emit('update:routingNum', val)
      }
    },
    accountType: {
      get () {
        return this.type
      },
      set (val) {
        this.$emit('update:type', val)
      }
    },
    userFirstName: {
      get () {
        return this.firstName
      },
      set (val) {
        this.$emit('update:firstName', val)
      }
    },
    userLastName: {
      get () {
        return this.lastName
      },
      set (val) {
        this.$emit('update:lastName', val)
      }
    },
    company: {
      get () {
        return this.companyName
      },
      set (val) {
        this.$emit('update:companyName', val)
      }
    }
  },
  methods: {
    validate () {
      return this.$refs['ach-form'].validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.f15 {
  font-size: 15px !important;
}
label {
  font-size: 15px;
  margin-bottom: 4px;
  font-weight: 700;
}
</style>
