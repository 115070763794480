<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    tag="aside"
    width="800px"
    class="right-side-panel"
  >
    <panel-header :show.sync="show">FAQ</panel-header>

    <cg-loader v-if="loading" />

    <div v-else class="right-side-panel__content-wrapper">
      <cg-chip-group
        v-if="availableTags.length > 1"
        v-model="selectedTag"
        :chipsData="availableTags"
        class="campaign-faq-panel__tags"
      />

      <div class="campaign-faq-panel__items">
        <campaign-faq-item
          v-for="item in filteredItems"
          :key="item.id"
          :title="item.title"
          :content="item.content"
          :video-link="item.video_url"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import ApiCms from '@/axios/apiCMS'
import panelVModel from '@/mixins/panelVModel'

import { CgLoader, CgChipGroup } from '@corporategift/design-system'
import PanelHeader from '@/components/PanelHeader.vue'
import CampaignFaqItem from './CreateCampaignFaqItem.vue'

export default {
  name: 'CreateCampaignFaqPanel',
  mixins: [panelVModel],
  components: {
    CgLoader,
    PanelHeader,
    CgChipGroup,
    CampaignFaqItem,
  },
  data: () => ({
    loading: false,
    data: [],
    selectedTag: 'All',
  }),
  computed: {
    availableTags () {
      const faqTags = [...new Set(this.data.map((faqItem) => faqItem.tag))].filter((tag) => tag)
      return ['All', ...faqTags]
    },
    filteredItems () {
      const { selectedTag, data } = this

      if (selectedTag === 'All') { return data }
      return data.filter((faqItem) => faqItem.tag === selectedTag)
    },
  },
  created () {
    this.loading = true;

    ApiCms.get('/campaign-faqs?populate=faq&pagination[limit]=1000')
      .then(({ data }) => {
        this.data = data.map((faqItem) => ({
          id: faqItem.id,
          title: faqItem.attributes.title,
          content: faqItem.attributes.content,
          video_url: faqItem.attributes.video_url ?? null,
          tag: faqItem.attributes.faq?.data?.attributes?.Title ?? null,
        }))
      })
      .finally(() => (this.loading = false))
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/common/right-side-panel.scss';

.campaign-faq-panel {
  &__items {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  &__tags::v-deep .cg-chip__content {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
  }
}
</style>
