<template>
  <div class="my-orders-table-product-multiple">
    <div
      v-for="(product, index) in products"
      :key="index"
      class="my-orders-table-product-multiple__item"
    >
      <v-img
        :src="getImagePath(product)"
        :alt="product.name"
        contain
        width="50"
        max-width="50"
        height="50"
        max-height="50"
      />

      <ul>
        <li>{{ product.name }}</li>
        <li v-if="product.customization && product.customization.length">Customized</li>
        <li v-if="(product.quantity > 1) || isMacOrder">Qty: {{ product.quantity }}</li>
        <li v-if="isMacOrder && product.recipients_count">Recipients: {{ product.recipients_count }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMyOrdersTableProductMultipleProducts',
  props: {
    products: {
      type: Array,
      required: true,
    },
    orderType: {
      type: String,
      required: false,
      default: null
    },
  },
  computed: {
    isMacOrder () {
      return this.orderType?.toLowerCase() === 'mac'
    },
  },
  methods: {
    getImagePath ({ image_url }) {
      if (!image_url) return '/images/empty-order.png'

      return image_url.includes('no_selection') ? '/images/empty-order.png' : image_url
    }
  }
}
</script>

<style lang="scss" scoped>
.my-orders-table-product-multiple {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 14px;
  overflow-y: auto;
  max-height: 300px;
  max-width: 260px;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 14px;

    & > .v-image {
      border: 1px solid #e4e4e4;
    }

    & > ul {
      display: flex;
      margin: 0;
      padding: 0;
      flex-direction: column;
      list-style-type: none;

      & > li {
        font-weight: 400;
        white-space: normal;
        color: #16181F;
        font-size: 14px;

        &:first-child {
          font-weight: 700;
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>
