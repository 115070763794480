<template>
  <div class="payment-module-achs-container">
    <div class="change-payment-method-link" @click="$emit('update:step', 5)">
      + Add new  ACH
    </div>

    <ach-item
      v-for="ach in availableAchs"
      :key="ach.token"
      :data="ach"
      show-default-icon
      :isSelected="ach.token === selectedAchToken"
      :class="{ 'credit-card-item--is-selected': ach.token === selectedAchToken }"
      @deletePaymentMethod="onDeleteAch(ach.token)"
      @click.native="selectedAchToken = ach.token"
    />

    <cg-button :disabled="!selectedAchData" @click="onContinue">Continue</cg-button>
  </div>
</template>

<script>
import PaymentType from '../utils/PaymentType'

import AchItem from '@/components/payment/AchItem.vue'
import { CgButton } from '@corporategift/design-system'

export default {
  name: 'PayWithAch',
  components: {
    AchItem,
    CgButton,
  },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    availableAchs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedAchToken: null,
  }),
  computed: {
    defaultAchAccount () {
      const { availableAchs } = this

      if (!availableAchs.length) { return null }

      const defaultAch = availableAchs.find((achItem) => achItem.default)
      return defaultAch ?? availableAchs?.at(0);
    },
    selectedAchData () {
      const { selectedAchToken, availableAchs } = this

      if (!selectedAchToken) { return null }
      return availableAchs.find((cardData) => cardData.token === selectedAchToken)
    },
  },
  created () {
    const { paymentData, defaultAchAccount } = this
    if (paymentData && paymentData?.token) {
      this.selectedAchToken = paymentData?.token
    } else if (defaultAchAccount) {
      this.selectedAchToken = defaultAchAccount?.token
    }
  },
  methods: {
    onContinue () {
      const { selectedAchData } = this

      if (selectedAchData) {
        this.$emit('onAchSelect', PaymentType.ACH, selectedAchData);
      }
    },
    onDeleteAch (achToken) {
      this.$emit('onDeleteAch', achToken)
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-module-achs-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > .credit-card-item {
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color ease-in-out 0.26s;

    &:not(&--is-selected):hover {
      background-color: #F4F4F4;
    }

    &--is-selected {
      border-color: #D3D2D2;
      cursor: default;
      background-color: #fff;
    }
  }

  & .change-payment-method-link {
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    color: #42B77A;
  }

  & .cg-button {
    margin-top: 20px;
    border-radius: 100px;
  }
}
</style>
