import { render, staticRenderFns } from "./SendEgiftPanelRecipientsContacts.vue?vue&type=template&id=b90fd07a&scoped=true&"
import script from "./SendEgiftPanelRecipientsContacts.vue?vue&type=script&lang=ts&"
export * from "./SendEgiftPanelRecipientsContacts.vue?vue&type=script&lang=ts&"
import style0 from "./SendEgiftPanelRecipientsContacts.vue?vue&type=style&index=0&id=b90fd07a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b90fd07a",
  null
  
)

export default component.exports