<template>
  <v-container class="share-wishlist pt-10 pt-md-3">
    <v-row v-if="!loadingProducts">
      <v-col cols="12" class="share-wishlist__title" v-if="wishlistAuthor">
        {{ wishlistAuthor }}’s favorites
      </v-col>
      <v-col cols="12" class="share-wishlist__list-title pt-8" v-if="wishlistName">
        {{ wishlistName }}
      </v-col>
      <v-col cols="12" v-if="listProducts.length">
        <v-row :no-gutters="!$vuetify.breakpoint.xsOnly" justify="space-between" align="end">
          <v-col cols="12" md="3">
            <common-input
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search an item..."
              filter-style
              clearable
              :height="30"
              style="max-width: 266px"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="share-wishlist__add-all-items-to-cart"
            :class="{ 'justify-start': $vuetify.breakpoint.xsOnly }"
            @click="addAllItemsToCart()"
            v-if="false"
          >
            <icons-add-to-cart />
            Add all items to cart
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="listProducts.length">
        <v-row class="share-wishlist__products">
          <the-my-favorites-product
            v-for="product in filteredProducts"
            :key="product.id"
            :product="product"
            share-view
            @showEGiftPanel="showEGiftPanel"
            @showMoreInfo="showProductMoreInfo(product)"
            @showSendDirectPanel="addItemToCart(product)"
          />
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <v-row class="share-wishlist__no-products">
          <v-col cols="12" md="6" class="no-products__title">
            Nothing is here
          </v-col>
          <v-col cols="12" md="6" xl="4" class="no-products__into">
            This favorites list has been removed...
          </v-col>
          <v-col cols="12" md="6">
            <icons-favorites-list />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <common-loader v-else style="padding: 300px 0" />

    <send-egift-panel
      v-model="showSendEGiftPanel"
      :send-favorite-product="true"
      :gift="selectedProductCampaign"
      :panel-type="sendEGiftPanelType"
      :marketplace-user="!userIsCgElite"
      @updateShippingPrice="(val) => selectedProductCampaign.prices.shipping_price = val"
    />

    <the-my-favorites-more-info-panel
      v-model="showMoreInfoPanel"
      :product="selectedProduct"
      @showEGiftPanel="showEGiftPanel"
    />

    <the-my-favorites-problem-with-add-to-cart-panel
      v-model="showProblemWithAddToCartPanel"
      :products="listProducts"
    />
  </v-container>
</template>

<script>
import Api from '@/axios/api'
import userIsCgElite from '@/mixins/userIsCgElite'
import updateCartAmount from '@/mixins/updateCartAmount'

import SendEgiftPanel from '@/components/myCampaign/panels/TheSendEgiftPanel.vue'
import TheMyFavoritesProduct from '@/components/myFavorites/TheMyFavoritesProduct.vue'
import TheMyFavoritesMoreInfoPanel from '@/components/myFavorites/TheMyFavoritesMoreInfoPanel.vue'
import TheMyFavoritesProblemWithAddToCartPanel from '@/components/myFavorites/TheMyFavoritesProblemWithAddToCartPanel.vue'

export default {
  name: 'ShareWishlist',
  mixins: [
    userIsCgElite,
    updateCartAmount,
  ],
  components: {
    SendEgiftPanel,
    TheMyFavoritesProduct,
    TheMyFavoritesMoreInfoPanel,
    TheMyFavoritesProblemWithAddToCartPanel
  },
  data: () => ({
    search: null,
    loadingProducts: false,
    wishlistName: null,
    wishlistAuthor: null,
    listProducts: [],
    selectedProduct: null,
    selectedProductCampaign: null,
    sendEGiftPanelType: 'egift',
    showMoreInfoPanel: false,
    showSendEGiftPanel: false,
    showProblemWithAddToCartPanel: false,
  }),
  computed: {
    filteredProducts () {
      const { listProducts, search } = this
      if (!search) return listProducts

      return listProducts?.filter(({ product }) => product.name.toLowerCase().includes(search.toLowerCase())) || []
    },
    isCustomizedProductInList () {
      return !!this.listProducts?.filter(({ product }) => product.should_be_customized)?.length ?? false
    },
    isOOSProductInList () {
      return !!this.listProducts?.filter(({ product }) => product.qty.is_out_of_stock)?.length ?? false
    },
    isConfigurableProductInList () {
      return !!this.listProducts?.filter(({ product }) => product.type.is_configurable)?.length ?? false
    }
  },
  created () {
    this.$store.commit('app/setHideUserMenu', true)
    this.$store.dispatch('tooltips/getTooltips', null)
    this.loadingProducts = true

    Api.get(`/shared/wishlists/${this.$route.params.code}/items`, {
      params: { paginate: false }
    }).then(({ data, wishlist }) => {
      this.wishlistName = wishlist?.name ?? null
      this.wishlistAuthor = wishlist?.customer_first_name || null
      this.listProducts = data
    })
    .catch((e) => (console.error(e)))
    .finally(() => (this.loadingProducts = false))
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('headerAuth/logout')
    next()
  },
  methods: {
    showProductMoreInfo (product) {
      this.selectedProduct = product
      this.showMoreInfoPanel = true
    },
    addItemToCart (product) {
      const {
        link,
        product: {
          min_qty: minQty,
          should_be_customized: customized,
          type: { is_configurable: configurable }
        },
        product_id: productId,
      } = product

      if (customized || configurable) {
        window.open(link, '_blank')
      } else {
        this.$cgToast.information('Adding product to cart')

        Api.post('/cart/items', [{ qty: minQty, product_id: Number(productId), redirect_url: link }])
          .then(() => {
            this.$cgToast.successBold('Successfully added product to cart')

            this.updateCartAmount()
          })
          .catch((e) => {
            console.error(e)
            this.$cgToast.error('An error occurred, please contact our support')
          })

      }
    },
    addAllItemsToCart () {
      const { isCustomizedProductInList, isOOSProductInList, isConfigurableProductInList, listProducts } = this

      if (isCustomizedProductInList || isOOSProductInList, isConfigurableProductInList) {
        this.showProblemWithAddToCartPanel = true
      } else {
        this.$cgToast.information('Adding products to cart')

        const data = listProducts.map((item) => ({
          qty: item.product?.min_qty ?? 1,
          product_id: Number(item.product_id),
          redirect_url: item?.link ?? '-',
        }))

        Api.post('/cart/items', data)
          .then(() => {
            this.$cgToast.successBold('Successfully added products to cart')
          })
          .catch((e) => {
            console.error(e)
            this.$cgToast.error('An error occurred, please contact our support')
          })
      }
    },
    showEGiftPanel (product, type = 'egift') {
      this.convertProductToCampaignsProduct(product)
      this.selectedProduct = product
      this.sendEGiftPanelType = type
      this.showSendEGiftPanel = true
    },
    convertProductToCampaignsProduct (product) {
      const { product: productData, product_id: id } = product
      const campaignObject = {
        calendly_link: null,
        collection: {
          entity_id: id,
          inventory: {
            enabled: false,
            max_qty: false,
          },
          is_disabled: productData.qty.is_out_of_stock,
          name: productData.name,
          items: [
            { product: productData }
          ]
        },
        customer_group: {
          logo: this.$store.state.header?.headerData?.logoUrl ?? null
        },
        description: null,
        egift_email_opening_line: null,
        egift_settings: {
          coin_value: null,
          egift_type: 'single',
          is_regift_enabled: false,
          is_upgrade_enabled: false,
          max_allowed_selection: null,
          tax_on_checkout: false,
        },
        email_subject_line: null,
        entity_id: id,
        gift_card_fee: null,
        greeting_message: null,
        is_disabled: productData.qty.is_out_of_stock,
        is_prepaid: false,
        logo_url: this.$store.state.header?.headerData?.logoUrl ?? null,
        name: productData.name,
        options_count: 1,
        prices: {
          items_price: productData.price,
          shipping_price: 0,
          total_price: 0,
        },
        prices_range: {
          min: productData.price,
          max: null,
        },
        thankyou_messages: null,
        video_greeting: null,
      } 
      this.selectedProductCampaign = campaignObject
    }
  }
}
</script>

<style lang="scss" scoped>
.share-wishlist {
  &__title {
    font-weight: 300;
    color: #000;
    font-size: 40px;
    line-height: 44px;
  }

  &__list-title {
    font-weight: 700;
    color: #000;
    font-size: 28px;
    line-height: 30px;
  }

  &__add-all-items-to-cart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #42B77A;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  }

  &__no-products {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 80px;
    gap: 36px;

    .no-products {
      &__title {
        font-size: 40px;
        line-height: 30px;
        color: #000;
        font-weight: 300;
      }

      &__into {
        font-size: 15px;
        line-height: 23px;
        color: #000;
        font-weight: 400;
      }
    }
  }

  &__products {
    gap: 10px;
    row-gap: 30px;
    padding: 12px;

    @media (min-width: 960px) {
      gap: 30px;
    }
  }

  @media (min-width: 960px) {
    max-width: 600px;
  }

  @media (min-width: 1264px) {
    max-width: 1185px;
  }

  @media (min-width: 1904px) {
    max-width: 1474px;
  }
}
</style>
