
import { Vue, Component } from 'vue-property-decorator'
import type IQuoteItem from './types/IQuoteItem';
import type IQuoteItemDetails from './types/IQuoteItemDetails';
import type IQuotesResponse from './types/IQuotesResponse'

import Api from '@/axios/api'

import MyQuotesDetailsPanel from './panels/MyQuotesDetailsPanel.vue'
import MyQuotesTableHeader from './MyQuotesTableHeader.vue'
import MyQuotesTableRow from './MyQuotesTableRow.vue'

@Component({
  components: {
    MyQuotesDetailsPanel,
    MyQuotesTableHeader,
    MyQuotesTableRow,
  },
})
export default class MyQuotesTable extends Vue {
  loading = false;

  showQuoteDetailsPanel = false;

  itemIdHelper?: number | null = null;

  items: Array<IQuoteItem> = [];

  get selectedQuoteHelper (): IQuoteItemDetails | null {
    const { itemIdHelper, items } = this

    return items?.find((item) => item.id === itemIdHelper) ?? null
  }

  created () {
    this.fetchQuotes()
  }

  fetchQuotes (): void {
    this.loading = true;

    Api.get<undefined, IQuotesResponse>('/customer/quotes')
      .then(({ data }) => {
        this.items = data
          .sort((a, b) => new Date(b.generated_at).getTime() - new Date(a.generated_at).getTime());
      })
      .catch(() => {
        // alert
      })
      .finally(() => (this.loading = false))
  }

  handleShowQuoteDetails (itemId: number): void {
    this.itemIdHelper = itemId;
    this.showQuoteDetailsPanel = true;
  }

  updateQuoteItem (item: IQuoteItemDetails): void {
    const { id: idToUpdate } = item;
    const itemIndex = this.items.findIndex(({ id }) => id === idToUpdate);

    if (itemIndex !== -1) { this.$set(this.items, itemIndex, item) }
  }
}
