
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, LinkType } from '../../types'
import type { IReviewField } from '../../types'

import personalSpendLimitProp from '../mixins/personalSpendLimitProp'
import campaignTotalCostProp from '../mixins/campaignTotalCostProp'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import recipientMethodProp from '../mixins/recipientMethodProp'
import defaultPaymentProp from '../mixins/defaultPaymentProp'
import recipientsProp from '../mixins/recipientsProp'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'

import ReviewField from '../components/ReviewField.vue'
import ReviewTextarea from '../components/ReviewTextarea.vue'
import ReviewFieldsPayment from '../components/ReviewFieldsPayment.vue'
import ReviewFieldsSettings from '../components/ReviewFieldsSettings.vue'
import ReviewVideoThumbnail from '../components/ReviewVideoThumbnail.vue'
import ReviewFieldsLinkType from '../components/ReviewFieldsLinkType.vue'
import ReviewFieldsArrivalDate from '../components/ReviewFieldsArrivalDate.vue'

enum FieldsKeys {
  GiftDescription = 'gift-description',
  GiftEmailSubjectLine = 'gift-email-subject-line',
  GiftGiverName = 'gift-giver-name',
  GiftGiverEmail = 'gift-giver-email',
  GiftGreetingVideo = 'gift-greeting-video',
  GiftCalendlyLink = 'gift-calendly-link',
  GiftWelcomeMessage = 'gift-welcome-message'
}

@Component({
  mixins: [
    getTooltipTextBySlug,
    getProductImageBySlug,
  ],
  components: {
    ReviewField,
    ReviewTextarea,
    ReviewFieldsPayment,
    ReviewFieldsSettings,
    ReviewVideoThumbnail,
    ReviewFieldsLinkType,
    ReviewFieldsArrivalDate,
  }
})
export default class SendEgiftPanelReviewFields extends Mixins(
  personalSpendLimitProp,
  campaignTotalCostProp,
  recipientMethodProp,
  defaultPaymentProp,
  recipientsProp,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  fieldsKeys: Array<string> = Object.values(FieldsKeys)

  fieldsEditModeHelper: Array<{ key: string, value: boolean }> = this.fieldsKeys
    .map((fieldKey) => ({
      key: fieldKey,
      value: false,
    }));
  // object with keys that is set to every field
  // object that is using keys to check if field is in edit mode

  get isPreProduction (): boolean {
    return this.campaign?.is_pre_production ?? false
  }

  get giftDescription (): string | null {
    return this.form.description
  }

  set giftDescription (val: string | null) {
    this.$emit('update:description', val)
  }

  get recipientText (): string {
    const { numberOfRecipients, recipients } = this

    return numberOfRecipients > 1
      ? `${numberOfRecipients} recipients`
      : recipients[0]?.email || '1 Recipient'
  }

  get giftEmailSubjectLine (): string | null {
    return this.form.emailSubjectLine
  }

  set giftEmailSubjectLine (val: string | null) {
    this.$emit('update:emailSubjectLine', val)
  }

  get giftGreetingMessage (): string | null {
    const { greeting } = this.form
    const message = greeting?.replaceAll('\n', '')

    if (!message) { return null }

    return this.form.greeting?.replace(/\n{2,}/g, '<br />') ?? null
  }

  get giftWelcomeMessage (): string | null {
    return this.form?.welcomeMessage ?? null
  }

  set giftWelcomeMessage (val: string | null) {
    this.$emit('update:welcomeMessage', val)
  }

  get giftLogo (): string | null {
    return this.form.logoUrl ?? null
  }

  get giftGiverName (): string | null {
    return this.form.giftGiverName
  }

  set giftGiverName (val: string | null) {
    this.$emit('update:giftGiverName', val)
  }

  get giftGiverEmail (): string | null {
    return this.form.giftGiverEmail
  }

  set giftGiverEmail (val: string | null) {
    this.$emit('update:giftGiverEmail', val)
  }

  get giftGreetingVideo (): string | null {
    return this.form.videoGreeting
  }

  set giftGreetingVideo (val: string | null) {
    this.$emit('update:videoGreeting', val)
  }

  get giftCalendlyLink (): string | null {
    return this.form.calendlyLink
  }

  set giftCalendlyLink (val: string | null) {
    this.$emit('update:calendlyLink', val)
  }

  get isCalendlyLinkSet (): boolean {
    const { form: { actionLinkType }, giftCalendlyLink } = this

    return !!(giftCalendlyLink && actionLinkType === 'calendly');
  }

  get giftCanUpgrade (): boolean {
    return this.form.settings?.recipientCanUpgrade ?? false
  }

  set giftCanUpgrade (val: boolean) {
    this.$emit('update:recipientCanUpgrade', val)
  }

  get giftCanReEgift (): boolean {
    return this.form.settings?.recipientCanReEgift ?? false
  }

  set giftCanReEgift (val: boolean) {
    this.$emit('update:recipientCanReEgift', val)
  }

  get giftLinkType (): LinkType {
    return this.form.linkType
  }

  set giftLinkType (val: LinkType) {
    this.$emit('update:linkType', val)
  }

  get giftShippingArrivalDate (): string | null {
    return this.form.shippingArrivalDate ?? null
  }

  set giftShippingArrivalDate (val: string | null) {
    this.$emit('update:shippingArrivalDate', val)
  }

  get giftHasReminderEmails (): boolean {
    return !this.form.settings.disableEmailReminders ?? false
  }

  set giftHasReminderEmails (val: boolean) {
    this.$emit('update:disableEmailReminders', !val)
  }

  get showMoreOptionsLink (): boolean {
    const {
      videoGreeting,
      calendlyLink,
      shippingArrivalDate,
      description,
      settings: { disableEmailReminders }
    } = this.form

    return !videoGreeting
      || !calendlyLink
      || !shippingArrivalDate
      || !description
      || !disableEmailReminders
  }

  get isLinkTypeSetToRestricted (): boolean {
    return this.giftLinkType === LinkType.SingleLink
  }

  get canOverrideDisableReminders (): boolean {
    return this.campaign?.egift_settings?.can_override_disable_recipient_reminders ?? false
  }

  get checkoutNeedsApproval (): boolean {
    return this.$store.state.header?.headerData?.checkoutNeedsApproval ?? false
  }

  handleLabelClick ({ value, key }: { value: boolean, key: string | null }) {
    const fieldIndex = this.fieldsEditModeHelper.findIndex((item) => item.key === key)

    if (fieldIndex !== -1) {
      const fieldItem = this.fieldsEditModeHelper[fieldIndex]

      this.$set(this.fieldsEditModeHelper, fieldIndex, {
        ...fieldItem,
        value,
      })
    }
  }

  showMoreOptionsView (): void {
    this.$emit('update:step', RootSteps.MoreOptions)
  }

  showRecipientView (): void {
    this.$emit(
      'update:step',
      this.recipientMethod === RecipientMethod.UnrestrictedLink
        ? RootSteps.Recipient
        : RootSteps.ReviewRecipients
    )
  }

  showGreetingMessageView (): void {
    this.$emit('update:step', RootSteps.GreetingMessage)
  }

  showLogosAndImagesView (): void {
    this.$emit('update:step', RootSteps.LogosAndImages)
  }

  onExitEditMode (refName: FieldsKeys): void {
    const refElement = this.$refs?.[refName]

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
