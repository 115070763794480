import { render, staticRenderFns } from "./DataTable.vue?vue&type=template&id=d1c9d1ba&"
import script from "./DataTable.vue?vue&type=script&lang=js&"
export * from "./DataTable.vue?vue&type=script&lang=js&"
import style0 from "./DataTable.vue?vue&type=style&index=0&id=d1c9d1ba&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VDivider,VImg,VSheet,VTooltip})
