import { render, staticRenderFns } from "./MyOrder.vue?vue&type=template&id=044d2398&scoped=true&"
import script from "./MyOrder.vue?vue&type=script&lang=js&"
export * from "./MyOrder.vue?vue&type=script&lang=js&"
import style0 from "./MyOrder.vue?vue&type=style&index=0&id=044d2398&prod&lang=scss&"
import style1 from "./MyOrder.vue?vue&type=style&index=1&id=044d2398&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044d2398",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VContainer,VDialog,VRow,VSheet})
