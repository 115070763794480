<template>
  <v-container class="px-0 pb-0 ma-0">
    <v-row>
      <v-col cols="12" class="recipients-area__title">
        Review recipients info
      </v-col>
      <v-col cols="12" class="file-upload__count d-flex align-center mb-8" v-if="!loading">
        <icons-check-circle
          :width="22"
          :height="22"
          filled
          :check-stroke-width="2"
          class="mr-4"
        />
        <template v-if="!removedDuplicates">
          {{ backToAddressBook ? 'Contacts imported' : backToCopyPaste ? 'Recipients added ' : 'File uploaded' }}
          successfully - total:
          <span class="px-2 font-weight-bold">
            {{ items.length }} recipients
          </span>
        </template>
        <template v-else>
          Duplicates removed
        </template>
      </v-col>

      <v-col cols="12" v-if="loading">
        <common-loader />
      </v-col>

      <v-col
        v-if="!loading"
        class="file-upload__duplicates"
        :class="!numberOfDuplicates() && 'justify-end'"
      >
        <div class="d-flex font-italic" v-if="numberOfDuplicates()">
          Found {{ numberOfDuplicates() }} duplicate Emails. <span class="recipients-area__link f15 px-1" @click="removeDuplicates()"> Remove duplicates </span>
        </div>

        <div
          class="d-flex recipients-area__link f15"
          @click="$emit('go-back')"
          v-if="!backToAddressBook"
        >
          <icons-download-invoice
            :width="14"
            :height="18"
            style="transform: rotate(180deg)"
            class="mr-2"
          />
          {{ backToCopyPaste ? 'Re-paste columns' : 'Change file' }}
        </div>

        <div
          class="d-flex recipients-area__link f15"
          @click="$emit('go-back')"
          v-else
        >
          <icons-contacts
            :width="18"
            :height="19"
            class="mr-2"
          />

          Back to Contacts
        </div>
      </v-col>

      <v-col cols="12" v-if="!loading">
        <v-row no-gutters class="recipients-table">
          <v-col cols="12" class="recipients-table__header">
            <div>#</div>
            <div>First name</div>
            <div>Last name</div>
            <div
              :class="emailIsOptional && 'recipients-table__header--optional'"
            >
              Email
            </div>
            <div v-if="panelType !== 'link'">Send date</div>
            <div v-else></div>
            <div></div>
            <div></div>
          </v-col>

          <v-col
            cols="12"
            class="recipients-table__row"
            v-for="(item, index) in items"
            :key="index"
          >
            <div>{{ index + 1 }}</div>
            <div>
              <div v-if="!isItemEdited(index)">
                {{ item.first_name }}
              </div>
              <common-input
                v-model="editedForm.firstName"
                :rules="[validation.required]"
                validate-on-blur
                v-else
                ref="firstName"
                placeholder="First name"
                height="36"
              />
            </div>
            <div>
              <div v-if="!isItemEdited(index)">
                {{ item.last_name }}
              </div>
              <common-input
                v-model="editedForm.lastName"
                v-else
                ref="lastName"
                placeholder="Last name"
                height="36"
              />
            </div>
            <div>
              <common-text-with-tooltip
                v-if="!isItemEdited(index)"
                :class="{ 'invalid-row': !isEmailValid(item.email) }"
              >
                {{ item.email }}
                <template #tooltip>
                  {{ item.email }}
                </template>
              </common-text-with-tooltip>

              <common-input
                v-model="editedForm.email"
                :rules="
                  emailIsOptional
                  ? []
                  : [validation.required, validation.email]"
                validate-on-blur
                v-else
                ref="email"
                placeholder="Email"
                height="36"
              />
            </div>
            <div v-if="panelType !== 'link'">
              <div v-if="!isItemEdited(index)">
                <template v-if="item.send_date">
                  {{ item.send_date | dateFilter }}
                </template>
              </div>
              <div v-else style="position: relative">
                <single-date-range-picker
                  v-model="editedForm.sendDate"
                  @input="(val) => editedForm.sendDate = val"
                  :disabled="marketplaceUser"
                  class="table-date-range-picker"
                  picker-style="--picker-margin-top: -1px; --min-width: 120px"
                  showCheckbox
                  :checkboxValue.sync="setSendDateForAllRecipients"
                >
                  <template #append-outer>
                    <div>
                    </div>
                  </template>
                </single-date-range-picker>
              </div>
            </div>
            <div v-else></div>
            <div>
              <div
                class="recipients-table--duplicate"
                v-if="isItemDuplicated(item) && !isItemEdited(index)"
              >
                Duplicate
              </div>

              <div
                v-if="isItemEdited(index)"
                class="text-right recipients-area__link f15"
                @click="save()"
              >
                Save
              </div>
            </div>
            <div>
              <div
                class="recipients-table__actions"
                v-if="!isItemEdited(index)"
              >
                <div class="d-flex pointer" @click="editRow(index, item)">
                  <icons-edit :width="15" :height="15" />
                </div>

                <div class="d-flex pointer" @click="showDeleteDialog(index)">
                  <icons-delete :width="14" :height="17" />
                </div>
              </div>
              <div
                v-if="isItemEdited(index)"
                @click="cancel()"
                class="recipients-area__link f15 text-no-wrap"
              >
                Cancel edit
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="pt-8 address-book-sticky-button" v-if="!loading">
        <common-button
          height="45"
          :disabled="disableContinueButton"
          @click="$emit('showPreview', items)"
        >
          Continue to review eGift
        </common-button>
      </v-col>
    </v-row>
    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" persistent content-class="elevation-0" width="500">
      <v-container>
        <v-row dense>
          <v-col cols="11">
            <div class="delete-card">
              <div class="delete-card__text">
                Are you sure you want to delete <br />
                <b>{{ deleteRecipientFullName }}</b>?
              </div>
              <div class="delete-card__actions">
                <v-btn
                  depressed
                  color="black white--text"
                  class="lato-bold f15"
                  style="border-width: 2px"
                  height="45"
                  outlined
                  @click="showDeleteDialog(null, false)"
                  tile
                >
                  Cancel
                </v-btn>

                <v-btn
                  depressed
                  tile
                  color="black white--text"
                  class="ml-4 lato-bold f15"
                  @click="deleteRow(rowIdToDelete)"
                  height="45"
                >
                  Remove
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="1">
            <div class="d-flex pointer justify-end" @click="showDeleteDialog(null, false)">
              <icons-close
                :width="30"
                :height="30"
                color="#000"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

import takeCurrentDate from '@/components/myCampaign/panels/mixins/takeCurrentDate'
import marketplaceUser from '@/components/myCampaign/panels/mixins/marketplaceUser'
import panelType from '@/components/myCampaign/panels/mixins/panelType'

export default {
  name: 'TheRecipientsTable',
  components: {
    SingleDateRangePicker
  },
  mixins: [
    panelType,
    takeCurrentDate,
    marketplaceUser,
  ],
  props: {
    value: {
      type: [File, Array],
      required: false,
      default: null
    },
    convertData: {
      type: Boolean,
      required: false,
      default: true
    },
    backToAddressBook: {
      type: Boolean,
      required: false,
      default: false
    },
    backToCopyPaste: {
      type: Boolean,
      required: false,
      default: false
    },
    linkType: {
      type: String,
      required: false,
      default: null
    },
  },
  data: () => ({
    loading: true,
    deleteDialog: false,
    items: [],
    setSendDateForAllRecipients: false,
    editedRowId: null,
    rowIdToDelete: null,
    removedDuplicates: false,
    validation: {
      required: v => !!v || '',
      email: v => /.+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ''
    },
    editedForm: {
      firstName: null,
      lastName: null,
      email: null,
      sendDate: null
    }
  }),
  computed: {
    emailIsOptional () {
      const { panelType, linkType } = this

      if (panelType === 'egift') { return false }
      if (linkType === 'single_link_restricted') { return false }

      return true
    },
    deleteRecipientFullName () {
      const { rowIdToDelete, items } = this
      const item = items?.at(rowIdToDelete)

      if (item) { return [item?.first_name, item?.last_name].join(' ') }
      return null
    },
    isDataInTableValid () {
      const { items } = this

      const result = items.filter((item) => (item.first_name && this.isEmailValid(item.email)))
      return result.length === items.length
    },
    disableContinueButton () {
      const { editedRowId, isDataInTableValid } = this

      if (editedRowId !== null) { return true }
      return !isDataInTableValid
    }
  },
  created () {
    if (this.value) {
      this.clearData()
      if (this.convertData) {
        this.readFile(this.value)
      } else {
        this.items = this.value
        this.loading = false
      }
    }
  },
  methods: {
    clearData () {
      Object.assign(this.$data, this.$options.data())
    },
    readFile (file) {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        this.items = this.csvToArray(result)
        this.numberOfDuplicates()
        this.loading = false
      }
      reader.readAsText(file)
    },
    csvToArray (csvFile, delimiter = /,|\t/) {
      const { panelType } = this

      // remove first row with headers
      csvFile.slice(0, csvFile.indexOf('\n')).split(delimiter)

      const headers = ['first_name', 'last_name', 'email', 'send_date']
      let rows = csvFile.slice(csvFile.indexOf('\n') + 1).split('\n')
      // Remove empty
      rows = rows?.filter((item) => !!item) || []

      const arrayOfRecipients = rows.map((row) => {
        const values = row.split(delimiter)

        const recipient = headers.reduce((object, header, index) => {
          if (header === headers[3]) {
            const date = values[index]?.trim()

            object[header] = (panelType !== 'link' && this.isDateValid(date))
              ? new Date(date).toISOString()
              : this.takeCurrentDate()
          } else {
            object[header] = values[index]?.trim()
          }
          return object
        }, {})

        return recipient
      })

      return arrayOfRecipients
    },
    isDateValid (sendDate) {
      if (!sendDate) { return false }
      return new Date(sendDate) >= new Date(this.takeCurrentDate())
    },
    isItemDuplicated (itemToCheck) {
      if (!itemToCheck.email) return false

      const { items } = this
      const howManyTimesOccurs = items?.filter((item) => item.email === itemToCheck.email)

      return howManyTimesOccurs.length > 1
    },
    isItemEdited (itemIndex) {
      return itemIndex === this.editedRowId
    },
    isEmailValid (email) {
      const isEmailRequired = this.panelType === 'egift'

      if (!isEmailRequired && !email) { return true }
      return this.validation.email(email)
    },
    numberOfDuplicates () {
      const { items } = this
      const emails = items.map(item => item.email)
      const emailsCounts = {}
      emails.forEach((email) => {
        if (email) emailsCounts[email] = (emailsCounts[email] || 0) + 1
      })
      const duplicatesCount = Object.keys(emailsCounts).reduce((acc, key) => {
        if (emailsCounts[key] > 1) acc += emailsCounts[key]
        return acc
      }, 0)
      return duplicatesCount
    },
    removeDuplicates () {
      const { items } = this
      const result = items?.filter((item, index, array) => {
        if (!item.email) return true
        return array.findIndex((ele) => ele.email === item.email) === index
      }) || []
      this.items = result
      this.removedDuplicates = true
    },
    showDeleteDialog (indexToDelete, show = true) {
      this.rowIdToDelete = indexToDelete
      this.deleteDialog = show
    },
    deleteRow (indexToDelete) {
      this.items = this.items?.filter((item, index) => {
        if (index !== indexToDelete) return item
      }) || []
      this.showDeleteDialog(null, false)
    },
    editRow (rowIndex, rowItem) {
      this.editedRowId = rowIndex
      this.editedForm.firstName = rowItem.first_name
      this.editedForm.lastName = rowItem.last_name
      this.editedForm.email = rowItem.email
      this.editedForm.sendDate = rowItem.send_date
    },
    async validateInputs () {
      await this.$nextTick()
      const firstNames = this.$refs?.firstName
      const lastNames = this.$refs?.lastName
      const emails = this.$refs?.email

      const result = [...firstNames, ...lastNames, ...emails].reduce((acc, ele) => {
        const [input] = ele?.$children ?? []
        if (input) { acc.push(input?.validate(true) ?? true) }

        return acc
      }, [])

      return result.every(value => value)
    },
    async save () {
      const { firstName, lastName, email, sendDate } = this.editedForm
      const { editedRowId } = this
      const sendDateToSet = sendDate || this.takeCurrentDate()

      if (await this.validateInputs()) {
        this.items?.forEach((item, index) => {
          if (index === editedRowId) {
            const form = {
              first_name: firstName,
              last_name: lastName,
              email,
              send_date: sendDateToSet
            }

            this.$set(this.items, index, form)
          }
        })

        if (this.setSendDateForAllRecipients) {
          this.items?.filter(item => { item.send_date = sendDateToSet })
        }

        this.cancel()
        }
    },
    cancel () {
      this.editedRowId = null
      this.editedForm.firstName = null
      this.editedForm.lastName = null
      this.editedForm.email = null
      this.editedForm.sendDate = null
    }
  }
}
</script>

<style lang="scss">
.table-date-range-picker {
  .v-input__slot {
    padding: 0 8px !important;
  }
}
</style>

<style lang="scss" scoped>
.recipients-table {
  &__header {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-top: thin solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;

    & > div {
      color: #000;
      font-weight: 700;
      line-height: 13px;
      font-size: 0.75rem;

      height: 48px;
      padding: 0 16px;
      display: inline-flex;
      user-select: none;
      text-align: start;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
      text-transform: uppercase;
    }

    &--optional::after {
      padding-left: 4px;
      content: '(optional)';
      text-transform: lowercase;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    cursor: default;

    & > div {
      color: #000;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;

      min-height: 48px;
      padding: 8px 16px;
      display: inline-flex;
      user-select: none;
      text-align: start;
      align-items: center;
      word-break: break-word;
      justify-content: flex-start;
    }
  }

  &__header, &__row {
    & > div {
      &:nth-child(1) {
        width: 5%;
        min-width: 5%;
        max-width: 5%;
        padding: 0 8px;
        word-break: keep-all;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5) {
        width: 15%;
        min-width: 15%;
        max-width: 15%;
      }

      &:nth-child(4) {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
      }

      &:nth-child(6) {
        justify-content: center;
      }

      &:nth-child(6), &:nth-child(7) {
        width: 10%;
        min-width: 10%;
        max-width: 10%;
      }
    }
  }

  &--duplicate {
    color: #f15b5b;
    padding: 4px;
    border: 1px solid #f15b5b;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &::v-deep .text-with-tooltip__wrapper {
    overflow: hidden;
  }
}

.file-upload {
  &__count {
    background-color: #D8F1E4;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #4A4A4A;
  }

  &__duplicates {
    font-weight: 400;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    color: #A1A1A1;
  }
}

.delete-card {
  padding: 40px 35px;
  background-color: #fff;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__text {
    font-weight: 400;
    font-size: 15px;
    color: #000;
    line-height: 25px;
    text-align: left;
  }
}

.invalid-row {
  color: #f15b5b;
}

.address-book-sticky-button {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}
</style>
