<template>
  <div
    class="inventory-status-list-view-item"
    :class="{
      'inventory-status-list-view-item--root-variant': productVariants.length > 1,
      'inventory-status-list-view-item--simple': !superAdmin && !masterAccount,
      'inventory-status-list-view-item--bold-border': isLastVariantChild && !nextItemIsAVariant,
    }"
  >
    <div class="inventory-status-list-view-item__product">
      <list-view-item-images :images="productImages" v-if="!itemIsAVariantChild" />

      <div v-else class="inventory-status-list-view-item__product-variant">
        <div>
          {{ productVariantCode }}
        </div>
      </div>

      <div>
        <a
          v-if="productLink && !itemIsAVariantChild"
          :href="productLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ productName }}
        </a>
        <div v-else>{{ productName }}</div>
        <div v-if="productSku">{{ productSku }}</div>
      </div>
    </div>

    <div class="inventory-status-list-view-item__associated-campaigns">
      <ul>
        <li v-for="campaign in campaignsToShow" :key="campaign.id">
          <icons-campaigns />
          {{ campaign.name }}
        </li>

        <v-popover
          placement="top"
          trigger="hover"
          :container="false"
          v-if="showMoreCampaignsLink"
        >
          <li class="inventory-status-list-view-item__more-info-link">
            <icons-campaigns />
            More...
          </li>
          <div slot="popover" class="inventory-status-list-view-item__more-info-tooltip">
            <ul>
              <li v-for="campaign in campaignsToShowInMoreInfo" :key="campaign.id">
                <icons-campaigns />
                {{ campaign.name }}
              </li>
            </ul>
          </div>
        </v-popover>
      </ul>
    </div>

    <div
      class="inventory-status-list-view-item__units-wrapper"
      :class="{
        'inventory-status-list-view-item__units-wrapper--no-variants': productVariants.length < 1
      }"
    >
      <tray-variants
        :variants="productVariants"
        :showVariantsTooltip="productVariants.length > 1"
      >
        <div
          class="inventory-status-list-view-item__units-info"
          :style="`--badge-color: ${availableUnits ? '#007E76' : '#F15B5B'}`"
        >
          {{ availableUnits }}
        </div>
      </tray-variants>
    </div>

    <template v-if="superAdmin || masterAccount">
      <div
        class="inventory-status-list-view-item__units-wrapper"
        :class="{
          'inventory-status-list-view-item__units-wrapper--no-variants': productVariants.length < 1
        }"
      >
        <tray-variants
          :variants="productVariants"
          show-on-hold
          :showVariantsTooltip="productVariants.length > 1"
        >
          <div
            class="inventory-status-list-view-item__units-info"
            :class="{ 'inventory-status-list-view-item__units-info--link': onHoldUnits }"
            style="--badge-color: #D3D2D2"
            :aria-disabled="!superAdmin && !masterAccount"
            v-tooltip="{
              content: 'Click to view where the on-hold items are',
              ...iconTooltipProps,
              offset: 8,
              classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
            }"
            @click="onHoldUnits && showProductUnitsOnHold()"
          >
            {{ onHoldUnits }}
          </div>
        </tray-variants>
      </div>

      <div
        class="inventory-status-list-view-item__units-wrapper"
        :class="{
          'inventory-status-list-view-item__units-wrapper--no-variants': productVariants.length < 1
        }"
      >
        <tray-variants
          :variants="productVariants"
          show-in-production
          :showVariantsTooltip="productVariants.length > 1"
        >
          <div class="inventory-status-list-view-item__units-info" style="--badge-color: #ECD885">
            {{ unitsInProduction }}
          </div>
        </tray-variants>
      </div>
    </template>

    <template v-else>
      <div class="inventory-status-list-view-item__units-wrapper">
        <div
          class="
            inventory-status-list-view-item__units-info
            inventory-status-list-view-item__units-info--outlined
          "
          style="--badge-color: #F15B5B"
        >
          {{ productBalance || 0 }}
        </div>
      </div>
    </template>

    <div class="inventory-status-list-view-item__price">{{ costPerUnit | priceFilter }}</div>

    <div class="inventory-status-list-view-item__price">{{ totalCost | priceFilter }}</div>

    <div class="inventory-status-list-view-item__options">
      <icons-history
        v-if="!itemIsAKit && (superAdmin || masterAccount)"
        v-tooltip="{ content: 'Transactions history', ...iconTooltipProps }"
        :width="20"
        :height="20"
        @click.native.stop="showOrdersHistory()"
      />
      <list-view-item-settings
        v-if="showItemSettings"
        :isKit="itemIsAKit"
        :hasVariants="productVariants.length > 1"
        @onKitPanel="showKitPanel()"
        @onProductSettings="showProductSettings()"
        @onOrderRefill="showOrderRefill()"
        @onAddToCart="addItemToCart(item)"
        @onAssignUsers="showAssignUsers()"
        @onSendWithEgift="sendItemAsEGift(item)"
      />
    </div>
  </div>
</template>

<script>
import genInventoryProductUrl from '../utils/genInventoryProductUrl'

import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import iconTooltipProps from './mixins/iconTooltipProps'
import sendInventoryActions from './mixins/sendInventoryActions'

import ListViewItemImages from './TheListViewItemImages.vue'
import TrayVariants from '../InventoryCardTray/TrayVariants.vue'
import ListViewItemSettings from './TheListViewItemSettings.vue'

export default {
  name: 'TheListViewItem',
  mixins: [
    superAdmin,
    masterAccount,
    iconTooltipProps,
    sendInventoryActions,
  ],
  components: {
    TrayVariants,
    ListViewItemImages,
    ListViewItemSettings,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    nextItemIsAVariant: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    itemIsAKit () {
      return this.item?.is_kit ?? false
    },
    itemIsAVariantChild () {
      return this.item?.variant_child ?? false
    },
    isLastVariantChild () {
      return this.item?.last_variant_child ?? false
    },
    productLink () {
      const productUrl = this.item?.product?.product_url ?? null
      return genInventoryProductUrl(productUrl)
    },
    productImages () {
      const { item: { product }, itemIsAKit } = this

      return itemIsAKit
        ? (product?.kit?.products?.map((item) => ({ image: item?.image ?? null, name: item?.name ?? null })) ?? [])
        : [{ image: product?.image ?? null, name: product?.name ?? null }]
    },
    productVariantCode () {
      return this.item?.variant_code ?? null
    },
    productName () {
      return this.item?.product?.name ?? null
    },
    productSku () {
      return this.item?.product?.sku ?? null
    },
    associatedCampaigns () {
      return this.item?.product?.campaigns ?? []
    },
    numberOfAssociatedCampaigns () {
      return this.associatedCampaigns?.length ?? 0
    },
    showMoreCampaignsLink () {
      return this.numberOfAssociatedCampaigns > 3
    },
    campaignsToShow () {
      const { associatedCampaigns, showMoreCampaignsLink } = this

      return showMoreCampaignsLink ? associatedCampaigns.slice(0, 2) : associatedCampaigns
    },
    campaignsToShowInMoreInfo () {
      const { associatedCampaigns, numberOfAssociatedCampaigns } = this

      return associatedCampaigns.slice(2, numberOfAssociatedCampaigns) ?? []
    },
    availableUnits () {
      return this.item?.product?.stock?.qty ?? 0
    },
    onHoldUnits () {
      return this.item?.product?.stock?.qty_on_hold ?? 0
    },
    unitsInProduction () {
      return this.item?.product?.stock?.qty_in_production ?? 0
    },
    productBalance () {
      return this.item?.product?.balance
    },
    costPerUnit () {
      return this.item?.product?.cost_per_unit ?? 0
    },
    totalCost () {
      return this.item?.product?.total_value ?? 0
    },
    productVariants () {
      return this.item?.product?.variations?.short || []
    },
    showItemSettings () {
      const { productBalance, masterAccount, superAdmin, itemIsAVariantChild } = this

      if (itemIsAVariantChild) { return false }
      if (masterAccount || superAdmin) { return true }

      return productBalance > 0
    },
  },
  methods: {
    getProductId () {
      const { product: { product_id: productId } } = this.item
      return productId
    },
    showProductUnitsOnHold () {
      const productId = this.getProductId()

      if (this.itemIsAVariantChild) {
        this.$emit('showUnitsOnHoldPanelForItem', this.item)
      } else {
        this.$emit('showUnitsOnHoldPanel', productId)
      }

    },
    showProductSettings () {
      const productId = this.getProductId()

      this.$emit('showProductSettingsPanel', productId)
    },
    showKitPanel () {
      this.$emit('showKitPanel', this.item)
    },
    showOrderRefill () {
      const productId = this.getProductId()

      this.$emit('showRefillPanel', productId)
    },
    showAssignUsers () {
      const productId = this.getProductId()

      this.$emit('showAssignUsersPanel', productId)
    },
    showOrdersHistory () {
      const productId = this.getProductId()
      const { productName } = this
      // TODO add one action
      this.$store.commit('transactions-history/setSearch', productName);
      this.$store.commit('transactions-history/setFiltersProductId', productId);
      this.$store.commit('transactions-history/setFiltersCampaignId');
      this.$store.commit('transactions-history/setFiltersUserId');
      this.$store.commit('transactions-history/setFiltersTypes');
      this.$store.commit('transactions-history/setFiltersDateRange', { start: null, end: null });
      this.$store.commit('transactions-history/setFiltersOrderId');

      const query = Object.assign({}, this.$route.query)
      query.tab = 'transactions'
      this.$router.replace({ query }).catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/components/myInventory/styles/inventory-list-grid.scss";

.inventory-status-list-view-item {
  @include inventoryListGrid;
  min-height: 108px;

  &__product {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 14px;
    align-items: center;

    & > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > div {
        &:nth-child(1) {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #95979D;
        }
      }

      & > a {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        cursor: pointer;
        letter-spacing: 0;

        &:hover {
          color: #219358 !important;
        }
      }
    }
  }

  &__product-variant {
    min-width: 120px;
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      padding: 4px 8px;
      min-width: 80px;
      background: #D2F5F3;
      min-height: 22px;

      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: uppercase;
      color: #0A223E;
    }
  }

  &__associated-campaigns {
    display: flex;
    align-items: center;

    & > ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;

      & > li {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 8px;

        font-weight: 400;
        font-size: 14px;
        color: #222325;

        & > svg {
          min-width: 18px;
        }
      }
    }

    &::v-deep .trigger {
      width: fit-content;
    }
  }

  &__more-info-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #42B77A;
  }

  &__more-info-tooltip {
    display: flex;
    padding: 14px;
    background: #fff;
    width: 200px;
    max-width: 200px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

    & > ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;

      & > li {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 8px;

        font-weight: 400;
        font-size: 14px;
        color: #222325;

        & > svg {
          min-width: 18px;
        }
      }
    }
  }

  &__units-wrapper {
    display: flex;
    align-items: center;

    &:not(&--no-variants) {
      & > span {
        cursor: pointer;
      }
    }
  }

  &__units-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: fit-content;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    pointer-events: none;

    &::before {
      content: '';
      width: 10px;
      border-radius: 50%;
      height: 10px;
      background-color: var(--badge-color, #007E76);
    }

    &--link {
      color: #42B77A;
      cursor: pointer;

      pointer-events: unset;

      &[aria-disabled="true"] {
        cursor: default;
        color: #000;

        pointer-events: none;
      }
    }

    &--outlined::before {
      border: 1px solid var(--badge-color, #007E76);
      background-color: #fff;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }

  &__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;

    & > svg {
      cursor: pointer;
    }
  }

  &--root-variant {
    border-top: 1px solid #D3D2D2;
  }

  &--bold-border {
    border-width: 2px !important;
  }

  &--simple {
    width: 100%;
    grid-template-columns: 350px 200px repeat(2, 90px) 84px 110px 1fr;
  }
}
</style>
