import { render, staticRenderFns } from "./CampaignPanel.vue?vue&type=template&id=c1b52eb2&scoped=true&"
import script from "./CampaignPanel.vue?vue&type=script&lang=js&"
export * from "./CampaignPanel.vue?vue&type=script&lang=js&"
import style0 from "./CampaignPanel.vue?vue&type=style&index=0&id=c1b52eb2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1b52eb2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VNavigationDrawer,VSelect,VTextField,VTextarea})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
