<template>
  <div class="credit-card-item">
    <img :src="creditCardImage" alt="">

    <div class="credit-card-item__info">
      <div>{{ cardType }}</div>
      <div>{{ creditCardNumber }}</div>
      <div :class="{ 'card-expired': isExpired }">Exp: {{ expirationDate }}</div>
    </div>

    <div class="credit-card-item__actions">
      <icons-delete
        v-tooltip="{
          content: 'Remove this card',
          ...standardTooltipProps,
          offset: 8,
        }"
        :width="14"
        :height="17"
        @click.native="deleteCard"
      />

      <icons-check-circle
        v-if="!hideDefaultIcon"
        v-tooltip="{
          content: 'Set as default card',
          ...standardTooltipProps,
          offset: 8,
        }"
        :class="{ 'is-active': isDefault }"
        :height="20"
        :width="20"
        :filled="isDefault"
        @click.native="setDefault"
      />

      <icons-check-circle
        v-else
        class="is-active"
        :height="20"
        :width="20"
        :filled="isSelected"
      />
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'
import standardTooltipProps from '@/mixins/standardTooltipProps'

export default {
  name: 'CreditCardItem',
  mixins: [standardTooltipProps],
  props: {
    data: {
      type: Object,
      required: true,
    },
    hideDefaultIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cardType () {
      return this.data?.cardType ?? null;
    },
    isDefault () {
      return this.data?.isDefault ?? this.data?.is_default ?? false;
    },
    token () {
      return this.data?.token ?? null;
    },
    expirationDate () {
      const { expirationMonth, expirationYear } = this.data ?? {}
      return [expirationMonth, expirationYear?.slice(2, 4)].join('/')
    },
    isExpired () {
      const { expirationMonth, expirationYear } = this.data ?? {}

      const cardExpirationDate = new Date()
      cardExpirationDate.setFullYear(expirationYear)
      cardExpirationDate.setMonth(expirationMonth - 1)

      return cardExpirationDate < new Date()
    },
    cardLastFourDigits () {
      return this.data?.cardLastFourDigits
    },
    creditCardNumber () {
      const { cardLastFourDigits } = this
      return `XXXX-XXXX-XXXX-${cardLastFourDigits}`
    },
    creditCardImage () {
      const { cardType } = this

      switch (cardType) {
        case 'Visa':
        case 'VISA':
          return '/images/credit-cards/visa.svg'
        case 'Discover':
        case 'DISCOVER':
          return '/images/credit-cards/discover.svg'
        case 'American Express':
        case 'AMEX':
          return '/images/credit-cards/american-express.svg'
        default:
          return '/images/credit-cards/master-card.svg'
      }
    },
  },
  methods: {
    deleteCard () {
      this.$emit('deletePaymentMethod', this.data)
    },
    setDefault () {
      const { cardLastFourDigits, cardType, token } = this

      Api.patch(`/braintree/payment-methods/${token}/default`)
      this.$emit('updateDefaultCard', { cardLastFourDigits, cardType })
    },
  },
}
</script>

<style lang="scss" scoped>
.credit-card-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 14px;
  background-color: #F5F5F5;
  gap: 32px;

  & > img {
    object-fit: contain;
    width: 68px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #222325;
    font-weight: 400;
    line-height: 22px;
    font-size: 15px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-left: auto;
    justify-content: space-between;

    & > svg { 
      cursor: pointer;
    }
  }
}

.card-expired {
  color: #FF5A60;
}

.is-active {
  pointer-events: none;
}
</style>
