<template>
  <div>
    <cg-loader v-if="loading" />

    <div v-show="!loading" class="add-ach-form">
      <cg-form ref="achForm">
        <div class="add-ach-form__double-inputs">
          <cg-input
            v-model="achForm.firstName"
            :validation="[validation.required]"
          >
            <template #label>
              First name
            </template>
          </cg-input>
          <cg-input
            v-model="achForm.lastName"
            :validation="[validation.required]"
          >
            <template #label>
              Last Name
            </template>
          </cg-input>
        </div>

        <cg-select
          v-model="achForm.approverType"
          :items="accountApprovers"
          :validation="[validation.required]"
        >
          <template #label>
            Account type
          </template>
        </cg-select>

        <cg-input
          v-if="['CORPORATE_CHECKING', 'CORPORATE_SAVINGS'].includes(achForm.approverType)"
          v-model="achForm.businessName"
          :validation="[validation.required]"
        >
          <template #label>
            Company name
          </template>
        </cg-input>

        <cg-input
          v-model="achForm.routingNumber"
          :validation="[
            validation.required,
            validation.minLength,
          ]"
          :maxlength="9"
        >
          <template #label>
            Routing number
          </template>
        </cg-input>

        <cg-input
          v-model="achForm.accountNumber"
          :validation="[validation.required]"
          >
          <template #label>
            Account number
          </template>
        </cg-input>
      </cg-form>

      <span>
        Billing address
      </span>

      <billing-address-form
        ref="billingAddress"
        :availableCountries="['US']"
        @onBullingAddressUpdate="(val) => billingForm = val"
      />

      <cg-button v-if="showAddAchButton" @click="onSubmit">
        Add ACH account
      </cg-button>
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'
import PaymentType from './paymentModule/utils/PaymentType'
import { getStateShortcut } from '@/utils/getStateShortcut'

import { CgForm, CgButton, CgLoader, CgInput, CgSelect } from '@corporategift/design-system'
import BillingAddressForm from './BillingAddressForm.vue'

import { required, minLength } from '@corporategift/design-system/validations'

export default {
  name: 'AddAchForm',
  components: {
    BillingAddressForm,
    CgLoader,
    CgSelect,
    CgButton,
    CgForm,
    CgInput,
  },
  props: {
    showAddAchButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    loading: false,
    accountApprovers: [
      {
        text: 'Consumer checking',
        value: 'CONSUMER_CHECKING',
        type: 'personal',
        account_type: 'checking',
      },
      {
        text: 'Consumer savings',
        value: 'CONSUMER_SAVINGS',
        type: 'personal',
        account_type: 'savings',
      },
      {
        text: 'Corporate checking',
        value: 'CORPORATE_CHECKING',
        type: 'business',
        account_type: 'checking',
      },
      {
        text: 'Corporate savings',
        value: 'CORPORATE_SAVINGS',
        type: 'business',
        account_type: 'savings',
      }
    ],
    achForm: {
      firstName: null,
      lastName: null,
      accountNumber: null,
      routingNumber: null,
      approverType: null,
      businessName: null,
      locations: 'US',
    },
    billingForm: {},
    validation: {
      required: required(),
      minLength: minLength({ min: 9, errorMessage: 'Routing Number should be exactly 9 characters long' }),
    },
    usBankAccountInstance: null,
  }),
  computed: {
    selectedApproverData () {
      const { accountApprovers, achForm: { approverType } } = this
      const selectedApprover = accountApprovers
        .find((approver) => approver.value === approverType)
      
      return selectedApprover ?? null
    },
  },
  created () {
    this.initBraintreeAch();
  },
  methods: {
    initBraintreeAch () {
      this.loading = true

      Api.get('/braintree/token')
        .then(({ token }) => {
          window.braintree.client.create(
            { authorization: token },
            (err, clientInstance) => {
              if (err) {
                console.error('There was an error creating the Braintree Client.');
                return;
              }

              window.braintree.usBankAccount.create(
                { client: clientInstance },
                (usBankAccountErr, usBankAccountInstance) => {
                  if (usBankAccountErr) {
                    console.error(usBankAccountErr);
                    return
                  }

                  this.usBankAccountInstance = usBankAccountInstance
                }
              );
            }
          );
        })
        .catch(() => {
          console.error('Fetch client braintree token issue')
        })
        .finally(() => (this.loading = false))
    },
    // public
    validate () {
      const isBillingAddressValid = this.$refs.billingAddress?.validate()
      const isAchFormValid = this.$refs.achForm?.validate()

      return isBillingAddressValid && isAchFormValid
    },
    onSubmit () {
      if (this.validate()) {
        const { achForm, billingForm, selectedApproverData: approverData, usBankAccountInstance } = this
        this.loading = true

        const gRecaptcha = window.grecaptcha.enterprise
        const mandateText = `By clicking ["Continue"], I authorize Braintree, a service of PayPal,
          on behalf of [your business name here] (i) to verify my bank account information using
          bank information and consumer reports and (ii) to debit my bank account.`
        const bankDetails = {
          ...achForm,
          accountType: approverData.account_type,
          ownershipType: approverData.type,
          billingAddress: {
            ...billingForm,
            extendedAddress: null,
            streetAddress: billingForm?.street ?? null,
            locality: billingForm?.city ?? null,
            zipCode: billingForm?.postcode ?? null,
            postalCode: billingForm?.postcode ?? null,
            region: getStateShortcut(billingForm?.region)
          }
        }

        usBankAccountInstance.tokenize({ bankDetails, mandateText })
          .then(({ nonce }) => {
            gRecaptcha.ready(() => {
              gRecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'SUBMIT' })
                .then((token) => {
                  Api.post('braintree/ach', {
                    billingAddress: billingForm,
                    method: PaymentType.ACH,
                    paymentMethodNonce: nonce,
                    recaptcha_token: token,
                  })
                    .then(() => (this.$emit('onAddAch')))
                    .catch(({ response }) => {
                      const errorMessage = response?.data?.message || 'An error occurred, please contact our support'
                      this.$cgToast.error(errorMessage, { html: true })
                    })
                    .finally(() => (this.loading = false))
                })
                .catch(() => (this.loading = false))
            })
          })
          .catch(() => {
            this.loading = false
            this.$cgToast.error('An unexpected error occurred with a payment', { html: true })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-ach-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  & > span {
    padding: 20px 0 10px;
    color: #95979D;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    line-height: normal;
    font-weight: 700;
    font-size: 14px;
  }

  & button {
    border-radius: 100px;
  }

  &__double-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
  }
}

.cg-loader {
  max-height: 200px;
  margin: 200px auto 0;
}
</style>
