<template>
<!-- TODO clean mess -->
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    v-click-outside="clickOutside"
    class="budget-entity-panel"
    app
    stateless
    temporary
    right
    width="900px"
    ref="drawer"
  >
    <cg-alert v-if="budgetWasUpdating" type="warning" class="sticky-top">
      {{
        budgetIsStillUpdating
          ? 'This budget is still updating, it can take up to a few minutes'
          : 'Changes saved successfully! Refresh the page to see updates'
      }}
    </cg-alert>

    <!-- TODO debug ref drawer -->
    <close-panel-button v-if="!isAlertShow" @close="close()" />

    <unsaved-changes-alert
      @saveChanges="isInEditMode ? editBudgetEntity() : addNewBudgetEntity()"
      @closePanel="show = false"
      v-else
    >
      <template #default>
        You haven't saved your changes, exit without saving changes?
      </template>
    </unsaved-changes-alert>

    <common-loader v-if="showLoader" />

    <v-container class="px-10 py-0" v-else>
      <v-row>
        <v-col cols="12" class="f30 lato-light">
          {{ selectedEntity ? 'Edit budget entity details' : 'Add new budget entity' }}
        </v-col>

        <v-col cols="12">
          <v-row dense>
            <v-col cols="3">
              <label for="entity-name">
                Budget entity name
              </label>
              <common-input
                v-model="form.name"
                :disabled="selectedEntity && form.general"
                :error-messages="errors.name"
                id="entity-name"
                clearable
                :hide-details="false"
                style="width: 182px"
              />
            </v-col>
            <v-col
              cols="2"
              class="text-right d-flex flex-column"
              v-if="selectedEntity"
            >
              <label>Current balance</label>
              <span class="f25 font-weight-regular">
                {{
                  form.balance | priceFilter({
                    style: 'currency',
                    currency: 'USD'
                  })
                }}
              </span>
            </v-col>
            <v-col cols="3" class="ml-auto d-flex align-center justify-space-around">
              <label for="status-checkbox" class="gray4 f14">
                <strong class="f15 black1">Status:</strong>
                {{ form.active ? 'Active' : 'Disabled' }}
              </label>
              <common-switch
                v-model="form.active"
                :disabled="selectedEntity && form.general"
                id="status-checkbox"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <label for="description">
            Budget entity description
          </label>
          <common-input
            v-model="form.description"
            :error-messages="errors.description"
            id="description"
            clearable
            :hide-details="false"
          />
        </v-col>

        <v-col v-if="userHasAccessToInventory" cols="12">
          <common-checkbox v-model="form.is_inventory_only">
            For inventory shipping costs only
          </common-checkbox>
        </v-col>

        <v-col v-if="userIsCgElite" cols="12">
          <v-divider class="my-6"/>
        </v-col>

        <v-col v-if="userIsCgElite" cols="12">
          <common-checkbox v-model="form.low_balance_alert_enabled">
            Enable low balance alert
          </common-checkbox>

          <div class="d-flex g10 align-center pt-5">
            Send alert email to:
            
            <select-multiple-entities
              id="alert-users"
              v-model="form.low_balance_recipients"
              :items="userApprovers"
              :disabled="!form.low_balance_alert_enabled"
              :badgeSuffix="form.low_balance_recipients.length > 1 ? 'approvers' : 'approver'"
              style="max-width: 200px"
              item-text="name"
              item-value="id"
              :height="36"
              badge
            />

            when balance drops to

            <common-input
              v-model.number="form.low_balance_alert_value"
              type="number"
              :disabled="!form.low_balance_alert_enabled"
              style="max-width: 100px"
              :height="36"
              prefix="$"
            />

            <common-tooltip top :max-width="280" :z-index="8" :activator-size="20">
              You will receive an email notifying you to reload CG Credits (you won't be charged)
            </common-tooltip>
          </div>
        </v-col>

        <template v-if="!userIsInGeneralGroup">
          <v-col cols="12">
            <v-divider class="my-6"/>
          </v-col>

          <v-col cols="12">
            <label for="enable-order-limit">
              Spend limit
            </label>
            <div class="d-flex align-center pt-10">
              <common-switch
                v-model="form.enable_order_limit"
                id="enable-order-limit"
              />
              <span class="gray4 ml-4" style="display: flex; gap: 8px; align-items: center">
                Enable spend limit per order

                <common-tooltip top :max-width="350" :z-index="8" :activator-size="20">
                  Set the max amount for an order paid from this budget entity
                </common-tooltip>
              </span>

              <span
                v-if="Array.isArray(errors.enable_order_limit) && errors.enable_order_limit.length"
                class="d-inline-block mt-2 red1"
                id="spend-limit-error"
              >
                {{ errors.enable_order_limit[0] }}
              </span>
            </div>
          </v-col>

          <v-col cols="12" class="d-flex align-center" v-if="form.enable_order_limit">
            <label for="order-limit" class="mr-2">
              Max amount per order:
            </label>
            <!-- TODO fix global prepend inner icon... -->
            <common-input
              v-model="form.order_limit"
              id="order-limit"
              class="input-icon"
              height="30"
              prepend-inner-icon="$"
              style="max-width: 75px;"
            />

            <span v-if="orderLimitError" class="red1">{{ orderLimitError }}</span>
          </v-col>

          <v-col cols="12" v-if="userApprovers.length">
            <v-divider class="my-6"/>
          </v-col>

          <v-col cols="12">
            <approvers
              v-if="userApprovers.length"
              :approvers="userApprovers"
              :selectedEntity="form"
              :is-edit="isInEditMode"
              @selected="(selected) => form.approvers = selected"
            />
          </v-col>

          <v-col cols="12" class="d-flex align-center">
            <label for="approval-limit" class="mr-2">
              Request approval for transactions above:
            </label>
            <common-input
              v-model="form.approval_limit"
              :disabled="!form.approvers.length"
              id="approval-limit"
              class="input-icon"
              height="30"
              prefix="$"
              style="max-width: 75px;"
            />
          </v-col>

          <v-col cols="12">
            <v-divider class="my-6"/>
          </v-col>

          <v-col cols="12">
            <label for="balance-checkbox">
              Show balance on checkout
            </label>

            <div class="d-flex align-center pt-6">
              <common-switch
                v-model="form.show_balance"
                id="balance-checkbox"
              />

              <div class="f15 gray4 ml-4" style="display: flex; gap: 8px;">
                Display this budget entity balance for subaccounts oncheckout

                <common-tooltip top :max-width="250" :z-index="8" :activator-size="20">
                  When checking out, users shall see the current balance of the budget entity
                </common-tooltip>
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <v-divider class="my-6"/>
          </v-col>

          <v-col cols="12">
            <customers
              :is-edit="isInEditMode"
              :selectedEntity="form"
              :accounts="userAccounts"
              :budgetEntities="budgetEntities"
              :budgetEntityId="form.id"
              ref="customers"
              :show-panel="show"
              @setAccounts="(selected) => form.accounts = selected"
            />
          </v-col>
        </template>

        <v-col cols="12">
          <common-button
            @click="isInEditMode ? editBudgetEntity() : addNewBudgetEntity()"
            :disabled="!userHasChanges"
            :loading="addEditLoading"
            style="width: 160px;"
            height="45"
          >
            Save changes
          </common-button>
        </v-col>

        <v-col cols="12" class="f15 lato-italic gray1 pb-10">
          By clicking “Save” you agree to the CorporateGift.com
          <a
            href="https://corporategift.com/terms-and-conditions/"
            target="_blank"
            class="f15 lato-italic green3"
          >
            Terms of Service and Payments Policy.
          </a>
          Prepaid credits are non-refundable and non-transferrable.
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import { isEqual } from 'lodash'

import { CgAlert } from '@corporategift/design-system'
import Approvers from '@/components/myCGCredits/budgetEntities/Approvers'
import Customers from '@/components/myCGCredits/budgetEntities/Customers'
import UnsavedChangesAlert from '@/components/myInventory/Panels/common/UnsavedChangesAlert'
import SelectMultipleEntities from '@/components/mySubAccounts/SelectMultipleEntities.vue'

import panelVModel from '@/mixins/panelVModel'
import userIsCgElite from '@/mixins/userIsCgElite'
import { selectedEntity, budgetEntities } from '../mixins'

export default {
  name: 'AddEditBudgetEntity',
  components: {
    SelectMultipleEntities,
    UnsavedChangesAlert,
    Customers,
    Approvers,
    CgAlert,
  },
  mixins: [
    panelVModel,
    userIsCgElite,
    selectedEntity,
    budgetEntities,
  ],
  data: () => ({
    isAlertShow: false,
    loading: false,
    addEditLoading: false,
    budgetEntityIsStillUpdating: null,
    orderLimitError: '',
    form: {
      name: null,
      description: null,
      reload_limit: null,
      order_limit: null,
      approval_limit: null,
      color: null,
      active: true,
      general: false,
      balance: null,
      approvers: [],
      accounts: [],
      is_inventory_only: false,
      show_balance: true,
      low_balance_alert_enabled: false,
      low_balance_alert_value: null,
      low_balance_recipients: [],
    },
    errors: {},
    userApprovers: [],
    userAccounts: []
  }),
  computed: {
    isInEditMode () {
      return !!this.selectedEntity || false
    },
    selectedEntityId () {
      return this.selectedEntity?.id || null
    },
    defaultEntity () {
      return this.selectedEntity || this.$options.data().form
    },
    budgetWasUpdating () {
      return this.defaultEntity?.is_updating ?? false
    },
    budgetIsStillUpdating () {
      const { budgetWasUpdating, budgetEntityIsStillUpdating } = this

      if (budgetEntityIsStillUpdating !== null) { return budgetEntityIsStillUpdating }
      return budgetWasUpdating
    },
    userHasChanges () {
      const { budgetIsStillUpdating, defaultEntity, form } = this

      if (budgetIsStillUpdating) { return false }
      return !isEqual(defaultEntity, Object.assign({}, form))
    },
    userIsInGeneralGroup () {
      const companyId = this.$store.state.header?.headerData?.companyId ?? 1;
      return companyId === 1
    },
    userHasAccessToInventory () {
      return this.$store.state.header?.headerData?.menus?.myInventory ?? false
    },
    showLoader () {
      const { loading, budgetIsStillUpdating } = this

      if (budgetIsStillUpdating) { return true }
      return loading
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.fetchData()
        if (this.selectedEntity) this.form = Object.assign({}, this.selectedEntity)
      } else {
        Object.assign(this.$data, this.$options.data())
        // remove error messages from customers data table and clear editableCustomer object
        if (this.$refs.customers) {
          this.$refs.customers.editableCustomerErrors = []
          this.$refs.customers.editableCustomer = {}
          this.$store.commit('budgetEntities/setErrors', {})
        }
      }
    },
    'form.approvers': function (val) {
      if (val?.length === 0 && this.form.approval_limit > 0) this.form.approval_limit = 0
    },
    errors: {
      deep: true,
      handler: 'scrollToFirstErrorInput'
    },
  },
  methods: {
    fetchData () {
      const { selectedEntityId } = this

      this.loading = true
      let fetchBudgetInfo = Promise.resolve([])

      if (selectedEntityId) {
        fetchBudgetInfo = Api.get(`credit/budget-entities/${selectedEntityId}`)
          .then(({ data }) => (this.budgetEntityIsStillUpdating = data.is_updating))
      }


      const fetchApprovers = Api.get('/credit/budget-entities/approvers')
        .then(({ data }) => (this.userApprovers = data))
      const fetchAccounts = Api.get('/credit/budget-entities/accounts')
        .then(({ data }) => (this.userAccounts = data))

      Promise.all([fetchApprovers, fetchAccounts, fetchBudgetInfo])
        .finally(() => (this.loading = false))
    },
    clickOutside (e) {
      if (e.target.className === 'v-overlay__scrim' && this.show) {
        this.close()
      }
    },
    addNewBudgetEntity () {
      const { form } = this
      this.addEditLoading = true
      Api.post('/credit/budget-entities', form)
        .then(({ errors }) => {
          if (errors && Object.keys(errors).length) {
            this.errors = errors
          } else {
            this.$cgToast.success('Budget entity was successfully added')
            this.show = false
            this.$emit('fetchNewData')
          }
        })
        .catch(({ response: { data: { errors } } }) => {
          this.errors = errors || {}
        }).finally(() => (this.addEditLoading = false))
    },
    editBudgetEntity () {
      const { form } = this
      Api.put(`/credit/budget-entities/${form.id}`, form)
        .then(({ errors }) => {
          if (errors && Object.keys(errors).length) {
            this.errors = errors
          } else {
            this.$cgToast.success('Budget entity was successfully changed')
            this.show = false
            this.$emit('fetchNewData')
          }
        })
        .catch(({ response: { data: { errors } } }) => {
          this.errors = errors || {}
        }).finally(() => (this.addEditLoading = false))
    },
    close () {
      this.drawerScrollToTop()

      if (this.userHasChanges) {
        this.isAlertShow = true
      } else {
        this.show = false
      }
    },
    scrollToFirstErrorInput () {
      const { errors } = this
      let requiredField = undefined

      if (errors?.name) {
        requiredField = this.$el.querySelector('#entity-name')
      } else if (errors?.description) {
        requiredField = this.$el.querySelector('#description')
      } else if (errors?.enable_order_limit?.length) {
        requiredField = this.$el.querySelector('#spend-limit-error')
      }

      if (requiredField) {
        this.$el.querySelector('.v-navigation-drawer__content').scrollTo({
          top: requiredField.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    drawerScrollToTop () {
      this.$el.querySelector('.v-navigation-drawer__content').scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
@import "src/css/add-edit-be";
</style>

<style lang="scss" scoped>
label {
  font-size: 15px;
  font-weight: 700;
  color: #000;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
