
import { Component, Mixins } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import type { IRecipient } from '../../types'

import Api from '@/axios/api'
import recipientsProp from '../mixins/recipientsProp'
import takeCurrentDate from '../mixins/takeCurrentDate'
import recipientValidations from '../mixins/recipientValidations'

import AddressBookCheckbox from '@/components/myCampaign/panels/sendEgiftPanel/recipients/TheRecipientsAddressBookCheckbox.vue'

interface ICampaignContact {
  email_address: string | null;
  entity_id: number;
  firstname: string | null;
  lastname: string | null;
  is_active: boolean;
}

interface ICampaignGroup {
  addresses_count: number;
  entity_id: number;
  name: string | null;
  addresses: Array<ICampaignContact>
}

interface IRequestBody {
  data: {
    addresses: Array<ICampaignContact>,
    groups: Array<ICampaignGroup>,
  }
}

@Component({
  components: {
    AddressBookCheckbox,
  },
})
export default class SendEgiftPanelRecipientsContacts extends Mixins(
  recipientsProp,
  takeCurrentDate,
  recipientValidations,
) {
  search: string | null = null;

  loading = false

  show = {
    contacts: true,
    groups: true,
  }

  editedContactId: number | null = null;

  editedContactForm: ICampaignContact = {
    email_address: null,
    entity_id: 0,
    firstname: null,
    lastname: null,
    is_active: false,
  }

  availableContacts: Array<ICampaignContact> = []

  availableGroups: Array<ICampaignGroup> = []

  selectedContacts: Array<ICampaignContact | ICampaignGroup> = []

  get numberOfContacts (): number {
    return this.availableContacts?.length ?? 0
  }

  get numberOfGroups (): number {
    return this.availableGroups?.filter(({ addresses_count }) => addresses_count > 0)?.length ?? 0
  }

  get userHasContactsOrGroups (): boolean {
    const { numberOfContacts, numberOfGroups } = this

    return !!(numberOfContacts || numberOfGroups)
  }

  get filteredContacts (): Array<ICampaignContact> {
    const { search, show: { contacts }, availableContacts } = this

    if (!contacts) { return [] }

    if (!search) { return availableContacts }

    return availableContacts.filter(({ email_address, firstname, lastname }) => {
      return [firstname, lastname, email_address]
        .filter((val) => val)
        .join(' ')
        .toLowerCase()
        .includes(search?.toLowerCase())
    })
  }

  get filteredGroups (): Array<ICampaignGroup> {
    const { search, show: { groups }, availableGroups } = this

    if (!groups) { return [] }

    if (!search) {
      return availableGroups.filter(({ addresses_count }) => addresses_count > 0)
    }

    return availableGroups.filter(({ name, addresses_count }) =>
      addresses_count > 0 && name?.toLowerCase()?.includes(search?.toLowerCase())
    )
  }

  get numberOfSelectedContacts (): number {
    return this.selectedContacts.reduce((acc, item) => {
      if ('name' in item) {
        acc += item.addresses_count
      } else {
        acc += 1
      }

      return acc
    }, 0)
  }

  mounted () {
    this.loading = true

    Api.get<any, IRequestBody>('/campaigns/send-egift/get-addresses')
      .then(({ data }) => {
        this.availableContacts = data?.addresses ?? []
        this.availableGroups = data?.groups ?? []
      })
      .finally(() => (this.loading = false))
  }

  editContactInfo (contact: ICampaignContact): void {
    this.editedContactId = contact.entity_id

    this.editedContactForm = { ...contact }
  }

  saveChanges (): void {
    const { editedContactId, editedContactForm, availableContacts } = this

    if (!editedContactForm.email_address) { return }

    const recipientIndex = availableContacts
      .findIndex(({ entity_id }) => entity_id === editedContactId)

    if (recipientIndex !== -1) {
      this.$set(this.availableContacts, recipientIndex, { ...editedContactForm, })
    }
    // TODO debug Vue reactivity
    this.availableGroups.forEach((group) => {
      if (group.addresses_count > 0) {
        group.addresses.forEach((contact) => {
          if (contact.entity_id === editedContactId) {
            contact.email_address === editedContactForm.email_address ?? null
          }
        })
      }
    })

    this.cancelEditing()
  }

  cancelEditing (): void {
    this.editedContactId = null

    this.editedContactForm = {
      email_address: null,
      entity_id: 0,
      firstname: null,
      lastname: null,
      is_active: false,
    }
  }

  handleContinue (): void {
    const { selectedContacts } = this
    const currentDate = this.takeCurrentDate()

    const createRecipientObject = ({ firstname, lastname, email_address }: ICampaignContact): IRecipient => ({
      firstName: firstname,
      lastName: lastname,
      email: email_address,
      sendDate: currentDate,
    })

    this.loading = true

    const newRecipients: Array<IRecipient> = selectedContacts.reduce<Array<IRecipient>>((acc, item) => {
      if ('name' in item) {
        item.addresses?.forEach((contact) => {
          acc.push(createRecipientObject(contact))
        })
      } else {
        acc.push(createRecipientObject(item))
      }

      return acc
    }, [])

    this.$emit('update:recipients', newRecipients)
    this.$emit('update:step', RootSteps.ReviewRecipients)
  }
}
