<template>
  <div class="common-loader">
    <img alt="" src="https://ecs-prod-cloudfront-us-east-1.s3.amazonaws.com/images/loader/black-loader.gif" />
  </div>
</template>

<script>
export default {
  name: 'CommonLoader',
}
</script>

<style lang="scss" scoped>
.common-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  height: 100%;

  & > img {
    object-fit: contain;
  }
}
</style>
