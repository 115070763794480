<template>
  <v-container class="my-favorites pt-10 pt-md-3">
    <v-row>
      <v-col cols="12">
        <span class="page-title">My favorites</span>
      </v-col>
      <v-col cols="12" v-if="!loadingList">
        <v-chip-group
          :column="!$vuetify.breakpoint.xsOnly"
          :show-arrows="false"
        >
          <the-my-favorites-list-item
            v-for="availableItem in availableLists"
            :key="availableItem.id"
            :isSelected="availableItem.id === selectedListId"
            :listData="availableItem"
            tabindex="0"
            @click.native="selectedListId = availableItem.id"
            @keypress.native="selectedListId = availableItem.id"
          />

          <the-my-favorites-create-list-item
            tabindex="0"
            @click.native="showAddNewList = true"
            @keypress.native="showAddNewList = true"
          />
        </v-chip-group>
      </v-col>

      <template v-if="selectedListId">
        <v-col
          cols="12"
          class="my-favorites__list-info"
          :class="{ 'align-start' : inEditMode }"
        >
          <span v-if="!inEditMode">
            {{ selectedItemName }}
          </span>
          
          <common-input
            v-if="inEditMode"
            v-model="editedItemNewName"
            :rules="[v => !!v || '']"
            height="30"
            maxlength="30"
            size="30"
            style="max-width: 308px"
            :hide-details="!isNewCollectionNameAlreadyExists"
            :error-messages="isNewCollectionNameAlreadyExists ?
              ['You already have a collection with this name.'] :
              null"
            placeholder="Name"
            @keypress.enter="saveFavoritesListChanges()"
          />

          <template v-if="inEditMode">
            <div class="d-flex g10 align-center">
              <div
                class="icon-wrapper"
                tabindex="0"
                @click="saveFavoritesListChanges()"
                @keypress="saveFavoritesListChanges()"
              >
                <icons-check-circle
                  :width="24"
                  :height="24"
                  outlineColor="#42B77A"
                  outlineCheckColor="#42B77A"
                />
              </div>
              <div
                class="icon-wrapper"
                tabindex="0"
                @click="inEditMode = false"
                @keypress="inEditMode = false"
              >
                <icons-close-circle :width="24" :height="24" />
              </div>
            </div>
          </template>

          <template v-else>
            <common-tooltip top :min-width="50" v-if="!isSelectedItemGeneral">
              <template #activator>
                <div
                  class="icon-wrapper"
                  tabindex="0"
                  @click="$vuetify.breakpoint.mdAndUp ? (inEditMode = true) : (showEditListPanel = true)"
                  @keypress="$vuetify.breakpoint.mdAndUp ? (inEditMode = true) : (showEditListPanel = true)"
                >
                  <icons-edit :width="13" :height="13" />
                </div>
              </template>
              Edit collection name
            </common-tooltip>

            <common-tooltip top :min-width="50">
              <template #activator>
                <div
                  class="icon-wrapper"
                  tabindex="0"
                  @click="showSharePanel = true"
                  @keypress="showSharePanel = true"
                >
                  <icons-share :width="24" :height="24" />
                </div>
              </template>
              Share collection
            </common-tooltip>
          </template>

          <div
            class="my-favorites__delete"
            v-if="inEditMode"
            tabindex="0"
            @click="showDeleteList = true"
            @keypress="showDeleteList = true"
          >
            <icons-delete :width="14" :height="17" />

            Remove collection from favorites
          </div>
        </v-col>

        <v-col cols="12" v-if="listProducts.length">
          <v-row
            :no-gutters="!$vuetify.breakpoint.xsOnly"
            justify="space-between"
            align="end"
          >
            <v-col cols="12" md="3">
              <common-input
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search an item..."
                filter-style
                clearable
                :height="30"
                style="max-width: 266px"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="my-favorites__add-all-items-to-cart"
              :class="{ 'justify-start': $vuetify.breakpoint.xsOnly }"
              @click="addAllItemsToCart()"
              v-if="false"
            >
              <icons-add-to-cart />
              Add all items to cart
            </v-col>
          </v-row>
        </v-col>
      </template>

      <template v-if="!loadingProducts && !listProducts.length && selectedListId">
        <v-col cols="12">
          <v-row class="my-favorites__no-products">
            <v-col cols="12" md="6" class="no-products__title">
              Nothing is here
            </v-col>
            <v-col cols="12" md="6" xl="4" class="no-products__into">
              No items in your collections yet
            </v-col>
            <v-col cols="12" md="6">
              <icons-favorites-list />
            </v-col>
          </v-row>
        </v-col>
      </template>

      <template v-if="!loadingProducts && listProducts.length && selectedListId">
        <v-col cols="12">
          <v-row class="my-favorites__products">
            <the-my-favorites-product
              :key="product.id"
              :product="product"
              :wishlists="availableLists"
              v-for="product in filteredProducts"
              :currentWishlistId="selectedListId"
              @addNewList="(val) => availableLists.push(val)"
              @showEGiftPanel="showEGiftPanel"
              @showMoreInfo="showProductMoreInfo(product)"
              @showSendDirectPanel="addItemToCart(product)"
              @removeFromList="removeProduct(product.product_id)"
              @removeProductFromList="removeProductFromListWithId"
              @addProductToList="addProductToList"
            />
          </v-row>
        </v-col>
      </template>

      <common-loader v-if="loadingProducts || loadingList" style="padding: 200px 0" />
    </v-row>

    <the-my-favorites-create-list-item-dialog
      v-model="showAddNewList"
      :mobile-version="$vuetify.breakpoint.xsOnly"
      :current-wishlists="availableLists"
      @addNewItem="onAddNewItem"
    />

    <the-my-favorites-remove-list-item-dialog
      v-model="showDeleteList"
      :listId="selectedListId"
      @removeListItem="(val) => removeListItem(val)"
    />

    <the-my-favorites-remove-product-dialog
      v-model="showRemoveProductDialog"
      :listId="selectedListId"
      :productId="productIdToRemove"
      :mobile-version="$vuetify.breakpoint.xsOnly"
      @removeFromList="removeProductFromList"
    />

    <send-egift-panel
      v-model="showSendEGiftPanel"
      :send-favorite-product="true"
      :gift="selectedProductCampaign"
      :panel-type="sendEGiftPanelType"
      :marketplace-user="!userIsCgElite"
      @updateShippingPrice="(val) => selectedProductCampaign.prices.shipping_price = val"
    />

    <the-my-favorites-share-list-item-panel
      v-model="showSharePanel"
      :favorite-list="selectedItemData"
    />

    <the-my-favorites-problem-with-add-to-cart-panel
      v-model="showProblemWithAddToCartPanel"
      :products="listProducts"
    />

    <the-my-favorites-edit-list-panel
      v-model="showEditListPanel"
      :favorite-list="selectedItemData"
      :current-wishlists="availableLists"
      @updateListName="(val) => updateSelectedFavoriteListName(val)"
      @removeListItem="(val) => removeListItem(val)"
    />

    <the-my-favorites-more-info-panel
      v-model="showMoreInfoPanel"
      :product="selectedProduct"
      @showEGiftPanel="showEGiftPanel"
    />
  </v-container>
</template>

<script>
import Api from '@/axios/api'
import userIsCgElite from '@/mixins/userIsCgElite'
import updateCartAmount from '@/mixins/updateCartAmount'

import SendEgiftPanel from '@/components/myCampaign/panels/TheSendEgiftPanel.vue'
import TheMyFavoritesProduct from '@/components/myFavorites/TheMyFavoritesProduct.vue'
import TheMyFavoritesListItem from '@/components/myFavorites/TheMyFavoritesListItem.vue'
import TheMyFavoritesEditListPanel from '@/components/myFavorites/TheMyFavoritesEditListPanel.vue'
import TheMyFavoritesMoreInfoPanel from '@/components/myFavorites/TheMyFavoritesMoreInfoPanel.vue'
import TheMyFavoritesCreateListItem from '@/components/myFavorites/TheMyFavoritesCreateListItem.vue'
import TheMyFavoritesShareListItemPanel from '@/components/myFavorites/TheMyFavoritesShareListItemPanel.vue'
import TheMyFavoritesRemoveProductDialog from '@/components/myFavorites/TheMyFavoritesRemoveProductDialog.vue'
import TheMyFavoritesCreateListItemDialog from '@/components/myFavorites/TheMyFavoritesCreateListItemDialog.vue'
import TheMyFavoritesRemoveListItemDialog from '@/components/myFavorites/TheMyFavoritesRemoveListItemDialog.vue'
import TheMyFavoritesProblemWithAddToCartPanel from '@/components/myFavorites/TheMyFavoritesProblemWithAddToCartPanel.vue'

export default {
  name: 'MyFavorites',
  components: {
    SendEgiftPanel,
    TheMyFavoritesProduct,
    TheMyFavoritesListItem,
    TheMyFavoritesEditListPanel,
    TheMyFavoritesMoreInfoPanel,
    TheMyFavoritesCreateListItem,
    TheMyFavoritesShareListItemPanel,
    TheMyFavoritesRemoveProductDialog,
    TheMyFavoritesCreateListItemDialog,
    TheMyFavoritesRemoveListItemDialog,
    TheMyFavoritesProblemWithAddToCartPanel,
  },
  mixins: [
    userIsCgElite,
    updateCartAmount,
  ],
  data: () => ({
    // data
    availableLists: [],
    listProducts: [],
    selectedListId: null,
    inEditMode: false,
    editItemName: false,
    selectedProduct: null,
    editedItemNewName: null,
    productIdToRemove: null,
    selectedProductCampaign: null,
    sendEGiftPanelType: 'egift',
    // filters and loadings
    search: null,
    loadingList: false,
    loadingProducts: false,
    // panels
    showAddNewList: false,
    showDeleteList: false,
    showSharePanel: false,
    showEditListPanel: false,
    showMoreInfoPanel: false,
    showSendEGiftPanel: false,
    showRemoveProductDialog: false,
    showProblemWithAddToCartPanel: false,
  }),
  computed: {
    filteredProducts () {
      const { listProducts, search } = this
      if (!search) return listProducts

      return listProducts?.filter(({ product }) => product.name.toLowerCase().includes(search.toLowerCase())) || []
    },
    selectedItemData () {
      const { availableLists, selectedListId } = this
      return availableLists?.filter((item) => item.id === selectedListId)[0] || null
    },
    selectedItemName () {
      return this.selectedItemData?.name || null
    },
    isSelectedItemGeneral () {
      return this.selectedItemData?.is_general || false
    },
    isCustomizedProductInList () {
      return !!this.listProducts?.filter(({ product }) => product.should_be_customized)?.length ?? false
    },
    isOOSProductInList () {
      return !!this.listProducts?.filter(({ product }) => product.qty.is_out_of_stock)?.length ?? false
    },
    isConfigurableProductInList () {
      return !!this.listProducts?.filter(({ product }) => product.type.is_configurable)?.length ?? false
    },
    isNewCollectionNameAlreadyExists () {
      const { availableLists, editedItemNewName, selectedItemName } = this
      const listsNames = availableLists?.map((item) => item.name)?.filter((name) => name !== selectedItemName) ?? []
      return listsNames.includes(editedItemNewName?.trim())
    }
  },
  watch: {
    selectedListId: function () {
      this.inEditMode = false
      this.editItemName = false
      this.fetchProducts()
    },
    editItemName: function () {
      this.editedItemNewName = this.selectedItemName
    },
    inEditMode: function () {
      this.editItemName = true
    },
    showRemoveProductDialog: function (val) {
      if (!val) this.productIdToRemove = null
    }
  },
  created () {
    this.loadingList = true
    Api.get('/customer/wishlists').then(({ data }) => {
      this.availableLists = data;
      this.loadingList = false

      this.selectedListId = data[0]?.id ?? null
    })
  },
  methods: {
    saveFavoritesListChanges () {
      const { selectedListId, editedItemNewName, isNewCollectionNameAlreadyExists } = this
      if (!editedItemNewName || editedItemNewName?.length < 2 || isNewCollectionNameAlreadyExists) return

      this.updateSelectedFavoriteListName(editedItemNewName)
      this.inEditMode = false

      Api.put(`/customer/wishlists/${selectedListId}`, { name: editedItemNewName })
        .catch((e) => { console.error(e) })
    },
    updateSelectedFavoriteListName (newName) {
      const { selectedListId } = this
      const listIndex = this.availableLists.findIndex((item) => item.id === selectedListId)
      this.availableLists[listIndex].name = newName
    },
    fetchProducts () {
      const { selectedListId } = this
      if (selectedListId === null) return

      this.loadingProducts = true
      Api.get(`/customer/wishlists/${selectedListId}/items`).then(({ data }) => {
        this.listProducts = data
        this.loadingProducts = false
      })
    },
    onAddNewItem (newList) {
      this.availableLists.push(newList)
      this.selectedListId = newList.id
    },
    removeListItem (listId) {
      const listIndex = this.availableLists.findIndex((item) => item.id === listId)
      this.availableLists.splice(listIndex, 1)
      this.selectedListId = null
      this.listProducts = []
    },
    removeProduct (productId) {
      this.productIdToRemove = productId
      this.showRemoveProductDialog = true
    },
    removeProductFromList (productId) {
      const { selectedListId, listProducts } = this
      this.listProducts = listProducts.filter((item) => item.product_id !== productId)
      this.availableLists.filter((item) => item.id === selectedListId)[0].products_count -= 1
    },
    addItemToCart (product) {
      const {
        link,
        product: {
          min_qty: minQty,
          should_be_customized: customized,
          type: { is_configurable: configurable }
        },
        product_id: productId,
      } = product

      if (customized || configurable) {
        window.open(link, '_blank')
      } else {
        this.$cgToast.information('Adding product to cart')

        Api.post('/cart/items', [{ qty: minQty, product_id: Number(productId), redirect_url: link }])
          .then(() => {
            this.$cgToast.successBold('Successfully added product to cart')

            this.updateCartAmount()
          })
          .catch((e) => {
            console.error(e)
            this.$cgToast.error('An error occurred, please contact our support')
          })
      }
    },
    showProductMoreInfo (product) {
      this.selectedProduct = product
      this.showMoreInfoPanel = true
    },
    addAllItemsToCart () {
      const { isCustomizedProductInList, isOOSProductInList, isConfigurableProductInList, listProducts } = this

      if (isCustomizedProductInList || isOOSProductInList || isConfigurableProductInList) {
        this.showProblemWithAddToCartPanel = true
      } else {
        this.$cgToast.information('Adding products to cart')

        const data = listProducts.map((item) => ({
          qty: item.product?.min_qty ?? 1,
          product_id: Number(item.product_id),
          redirect_url: item?.link ?? '-',
        }))

        Api.post('/cart/items', data)
          .then(() => {
            this.$cgToast.successBold('Successfully added products to cart')
          })
          .catch((e) => {
            console.error(e)
            this.$cgToast.error('An error occurred, please contact our support')
          })
      }
    },
    showEGiftPanel (product, type = 'egift') {
      this.convertProductToCampaignsProduct(product)
      this.selectedProduct = product
      this.sendEGiftPanelType = type
      this.showSendEGiftPanel = true
    },
    convertProductToCampaignsProduct (product) {
      const { product: productData, product_id: id } = product
      const campaignObject = {
        calendly_link: null,
        collection: {
          entity_id: id,
          inventory: {
            enabled: false,
            max_qty: false,
          },
          is_disabled: productData.qty.is_out_of_stock,
          name: productData.name,
          items: [
            { product: productData }
          ]
        },
        customer_group: {
          logo: this.$store.state.header?.headerData?.logoUrl ?? null
        },
        description: null,
        egift_email_opening_line: null,
        egift_settings: {
          coin_value: null,
          egift_type: 'single',
          is_regift_enabled: false,
          is_upgrade_enabled: false,
          max_allowed_selection: null,
          tax_on_checkout: false,
        },
        email_subject_line: null,
        entity_id: id,
        gift_card_fee: null,
        greeting_message: null,
        is_disabled: productData.qty.is_out_of_stock,
        is_prepaid: false,
        logo_url: this.$store.state.header?.headerData?.logoUrl ?? null,
        name: productData.name,
        options_count: 1,
        prices: {
          items_price: productData.price,
          shipping_price: 0,
          total_price: 0,
        },
        prices_range: {
          min: productData.price,
          max: null,
        },
        thankyou_messages: null,
        video_greeting: null,
      } 
      this.selectedProductCampaign = campaignObject
    },
    removeProductFromListWithId ({ listId, productId }) {
      const productIndex = this.listProducts.findIndex((item) => item.product_id === productId)
      const wishlistIndex = this.availableLists.findIndex((list) => list.id === listId)
      const productWishlists = this.listProducts[productIndex]?.product?.wishlists
      const wishlistProducts = this.availableLists[wishlistIndex].products_count

      this.listProducts[productIndex].product.wishlists = productWishlists.filter((id) => id !== listId)
      this.availableLists[wishlistIndex].products_count -= 1
      if (wishlistProducts === 1) this.availableLists[wishlistIndex].image =  null
    },
    addProductToList ({ listId, productId }) {
      const productIndex = this.listProducts.findIndex((item) => item.product_id === productId)
      const wishlistIndex = this.availableLists.findIndex((list) => list.id === listId)
      const wishlistProducts = this.availableLists[wishlistIndex].products_count

      this.listProducts[productIndex]?.product?.wishlists?.push(listId)
      this.availableLists[wishlistIndex].products_count += 1
      if (wishlistProducts === 0) {
        this.availableLists[wishlistIndex].image = this.listProducts[productIndex]?.product?.image ?? null
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.my-favorites {
  padding-bottom: 64px;

  &::v-deep .v-slide-group__content {
    gap: 10px;
  }

  &__list-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    & > span {
      font-size: 28px;
      line-height: 30px;
      font-weight: 700;
      color: #000;
    }

    & .icon-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    color: #FF5A60;
  }

  &__add-all-items-to-cart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #42B77A;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  }

  &__no-products {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 80px;
    gap: 36px;

    .no-products {
      &__title {
        font-size: 40px;
        line-height: 30px;
        color: #000;
        font-weight: 300;
      }

      &__into {
        font-size: 15px;
        line-height: 23px;
        color: #000;
        font-weight: 400;
      }
    }
  }

  &__products {
    gap: 10px;
    row-gap: 30px;
    padding: 12px;

    @media (min-width: 960px) {
      gap: 30px;
    }
  }

  @media (min-width: 960px) {
    max-width: 600px;
  }

  @media (min-width: 1264px) {
    max-width: 1185px;
  }

  @media (min-width: 1904px) {
    max-width: 1474px;
  }
}
</style>
