<template>
  <div
    class="update-status-sheet"
    :class="outlined && 'update-status-sheet--outlined'"
    :style="`--color: ${color}; --text-color: ${textColor}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UpdateStatusSheet',
  props: {
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'currentColor'
    },
    textColor: {
      type: String,
      required: false,
      default: 'var(--color)'
    }
  }
}
</script>

<style lang="scss">
.update-status-sheet {
  background: var(--color);
  color: var(--text-color);
  height: fit-content;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 143.5%;
  border-radius: 4px;

  &--outlined {
    background: none;
    color: var(--text-color);
    border: 1px solid var(--color);
  }
}
</style>
