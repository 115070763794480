
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import type { IReviewField } from '../../types'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import ReviewField from './ReviewField.vue'
import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

@Component({
  mixins: [getTooltipTextBySlug],
  components: {
    ReviewField,
    ReviewSaveCancelIcons,
    SingleDateRangePicker,
  },
})
export default class ReviewFieldsArrivalDate extends Vue {
  @Prop({ required: false, default: null }) arrivalDate!: string | null;

  inEditMode = false;

  arrivalDateHelper: string | null = null;

  @Watch('inEditMode', { immediate: false })
  onEditModeChange (newVal: boolean) {
    if (newVal) {
      this.arrivalDateHelper = this.arrivalDate;
    }
  }

  handleOnSave (): void {
    this.$emit('update:arrivalDate', this.arrivalDateHelper)

    this.handleOnCancel()
  }

  handleOnCancel (): void {
    const refElement = this.$refs?.['review-field']

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
