<template>
  <common-loader v-if="loading" />

  <v-row no-gutters class="h100" v-else>
    <v-col cols="12" class="d-flex flex-column justify-start">
      <div class="d-flex align-center g10">
        <span class="credits-info__summary--title">
          Available Credit
        </span>
        <popper
          :options="{
            placement: 'top',
            modifiers: { offset: { offset: '0,10px' } }
          }"
        >
          <div class="popper px-4 py-2 text-left">
            <v-row no-gutters>
              <v-col cols="12" class="lato-bold">
                Available Credits
              </v-col>
              <v-col cols="12">
                This is your total amount of available CG Credits across <b>all</b> budget entities
              </v-col>
            </v-row>
          </div>
          <icons-question
            slot="reference"
            :width="24"
            :height="24"
            color="#62646A"
          />
        </popper>
      </div>
      <span class="credits-info__summary--value">
        {{
          summary.balance | priceFilter({
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
          })
        }}
      </span>

      <div v-if="userHasSubscriptionPayment" class="credits-info__subscription-payment">
        Next subscription payment is scheduled for 3/5/2020 for $2500
      </div>
    </v-col>
    <v-col cols="12" class="d-flex flex-column justify-end">
      <div class="d-flex align-center g10">
        <span class="credits-info__summary--title">
          On hold
        </span>
        <popper
          ref="popper"
          @show="onPopperShow"
          :options="{
            placement: 'top',
            modifiers: {
              offset: { offset: '0,10px' },
              preventOverflow: {
                padding: {
                  left: getSidebarWidth(),
                  altAxis: true,
                },
              },
              rootBoundary: 'main',
            }
          }"
        >
          <div class="popper px-4 py-2 text-left" style="min-width: 440px">
            <v-row no-gutters>
              <v-col cols="12" class="lato-bold">
                On Hold
              </v-col>
              <v-col cols="12">
                This is the balance of CG Credits used to purchase E-Gifts which are currently unredeemed.<br>
                Once an E-Gift is redeemed, its cost is deducted from your On Hold balance.<br>
                If you close an E-Gift order with unredeemed E-Gifts, their total cost will be converted back to Available Credit in the General budget entity.
              </v-col>
            </v-row>
          </div>
          <icons-question
            slot="reference"
            :width="24"
            :height="24"
            color="#62646A"
          />
        </popper>
      </div>
      <span class="credits-info__summary--value">
        {{
          summary.on_hold | priceFilter({
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
          })
        }}
      </span>

      <div v-if="showSalesManagerInfo" class="credits-info__csm-contact">
        <a :href="`mailto:${userSalesManagerEmail}`">Contact CSM</a> to reset balance
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Popper from 'vue-popperjs'

import { entitesSummary, loading } from './mixins'

export default {
  name: 'TheCreditsInfoSummary',
  components: {
    Popper
  },
  mixins: [
    entitesSummary,
    loading
  ],
  props: {
    userSubscriptionInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    customerSuccessManagerInfo () {
      return this.$store.state.header?.headerData?.customerSuccessManager ?? null
    },
    userHasSubscriptionPayment () {
      return !!this.userSubscriptionInfo ?? false
    },
    userSalesManagerEmail () {
      return this.customerSuccessManagerInfo?.email ?? null
    },
    showSalesManagerInfo () {
      const { userSalesManagerEmail, summary } = this

      return userSalesManagerEmail && summary?.on_hold < 0
    },
  },
  methods: {
    onPopperShow () {
      this.$refs.popper.options.modifiers.preventOverflow.padding.left = this.getSidebarWidth()
    },
    getSidebarWidth () {
      return parseInt(document.querySelector('.common-sidebar').style.width) + 6 || 70
    }
  }
}
</script>

<style lang="scss" scoped>
.credits-info {
  &__summary {
    &--title {
      font-weight: 700;
      color: #919191;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
    }

    &--value {
      font-weight: 300;
      font-size: 40px;
      line-height: 48px;
      color: #16181f;
    }
  }

  &__subscription-payment {
    padding-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4A4A4A;
  }

  &__csm-contact {
    padding-top: 4px;
    font-weight: 400;
    color: #4A4A4A;
    font-size: 14px;
    line-height: 17px;

    & > a {
      font-weight: 700;
      color: #42B77A !important;
    }
  }
}
</style>
