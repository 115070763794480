<template>
  <div class="footer-newsletter">
    <label for="newsletter">
      About Seasonal Offers and Specials 
    </label>
    <div>
      <common-input
        v-model="form.email"
        type="email"
        ref="newsletter"
        id="newsletter"
        placeholder="Your email"
        :rules="[validation.required, validation.email]"
      />
      <common-button :loading="loading" @click="submit()">Join</common-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import getCookie from '@/utils/getCookie'

export default {
  name: 'TheFooterNewsletterForm',
  data: () => ({
    loading: false,
    userIsAssigned: false,
    form: {
      email: null,
      recaptchaToken: null,
    },
    validation: {
      required: v => !!v || '',
      email: v => /.+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ''
    },
  }),
  methods: {
    submit () {
      if (!this.$refs.newsletter?.$children?.[0]?.validate()) return

      const gRecaptcha = window.grecaptcha.enterprise

      gRecaptcha.ready(() => {
        this.loading = true
        gRecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'SUBMIT' })
          .then((token) => {
            this.form.recaptchaToken = token
          })
          .then(() => {
            axios.post(`${process.env.VUE_APP_API_URL}/api/shared/newsletter/subscribe`, {
              email: this.form.email,
              'recaptcha_token': this.form.recaptchaToken,
              'gclid': getCookie('gclid') || undefined,
            })
            .then(() => {
              this.userIsAssigned = true
              this.$cgToast.successBold('Thank you for joining the CorporateGift.com email list!')
            })
            .catch((error) => {
              const errorMessage = error?.response?.data?.at(0) || 'An error occurred, please contact our support'
              this.$cgToast.error(errorMessage)
            })
            .finally(() => (this.loading = false))
          })
          .catch((e) => {
            console.error(e)
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-newsletter {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > label {
    font-weight: 400;
    font-size: 14px;
    color: #212529;
  }

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
</style>
