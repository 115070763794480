<template>
  <div class="favorites-createlist-item">
    <slot>
      + Start a new collection
    </slot>
  </div>
</template>

<script>
export default {
  name: 'TheMyFavoritesCreateListItem',
}
</script>

<style lang="scss" scoped>
.favorites-createlist-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 43px;
  padding: 8px 30px;
  height: 50px;
  background-color: #fff;
  border: 2px solid #000;
  cursor: pointer;

  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  line-height: 16px;

  @media (min-width: 960px) {
    & {
      height: 66px;
    }
  }
}
</style>
