
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReviewField extends Vue {
  @Prop({ default: true, required: false }) editable!: boolean;

  @Prop({ default: false, required: false }) disabled!: boolean;

  @Prop({ default: null, required: false }) fieldKey!: string | null;

  @Prop({ default: false, required: false }) editModeAsColumn!: boolean;

  @Prop({ default: false, required: false }) reviewModeAsColumn!: boolean;

  @Prop({ default: false, required: false }) allowToClickLabelInEditMode!: boolean;

  @Prop({ default: false, required: false }) disableHoverTooltip!: boolean;

  editMode = false;

  get disableLabelClick (): boolean {
    const { editMode, allowToClickLabelInEditMode, disabled } = this

    if (disabled) { return true }

    if (allowToClickLabelInEditMode) { return false }

    return editMode
  }

  get hideHoverTooltip (): boolean {
    const { disableHoverTooltip, editMode, disabled } = this

    return editMode || disableHoverTooltip || disabled
  }

  onLabelClick(): void {
    const newValue = !this.editMode

    const label: HTMLDivElement | null = this.$el.querySelector('review-field__label')

    if (label) { label.classList.remove('review-field__label--error') }

    if (this.editable) { this.editMode = newValue }

    this.$emit('onLabelClick', { value: newValue, key: this.fieldKey })
  }

  handleTooltip (event: MouseEvent) {
    const { hideHoverTooltip } = this

    const tooltip: HTMLDivElement | null = this.$el.querySelector('.review-field__tooltip')

    if (tooltip && !hideHoverTooltip) {
      const rootEl = this.$el as HTMLDivElement
      const rootLeft = rootEl?.getBoundingClientRect()?.left ?? 0

      const { pageX, clientY } = event
      

      tooltip.style.left = `${pageX - (rootLeft - 10)}px`
      tooltip.style.top = `${clientY + 28}px`
    }
  }
}
