
import { Vue, Component } from 'vue-property-decorator'

import ApiCms from '@/axios/apiCMS'

interface IEgiftRefundPolicy {
  attributes: {
    title: string | null,
    text: string | null,
  }
}

@Component
export default class SendEgiftPanelRefundPolicy extends Vue {
  loading = false;

  title: string | null = null;

  content: string | null = null;

  mounted () {
    const { title, content } = this

    if (!title || !content) {
      this.loading = true

      ApiCms.get('/collections-egift-refund-policy?populate=*')
        .then(({ data }: { data: IEgiftRefundPolicy }) => {
          this.title = data?.attributes?.title
          this.content = data?.attributes?.text
        })
        .finally(() => (this.loading = false))
    }
  }
}
