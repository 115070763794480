<template>
  <popper
    :options="{
      placement: 'top',
      modifiers: {
        offset: { offset: '0,6px', },
        preventOverflow: { enabled: false },
        hide: { enabled: false },
      },
    }"
  >
    <tray-variants-tooltip
      v-bind="_props"
      :style="!showVariantsTooltip && 'display: none'"
    />

    <div slot="reference">
      <slot />
    </div>
  </popper>
</template>

<script>
import 'vue-popperjs/dist/vue-popper.css'

import Popper from 'vue-popperjs'
import TrayVariantsTooltip from './TrayVariantsTooltip'

export default {
  name: 'TrayVariants',
  components: {
    TrayVariantsTooltip,
    Popper
  },
  props: {
    variants: {
      type: Array,
      required: true
    },
    showVariantsTooltip: {
      type: Boolean,
      required: false,
      default: true
    },
    showOnHold: {
      type: Boolean,
      required: false,
      default: false
    },
    showInProduction: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.variants {
  color: #000;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  gap: 4px;

  &__title {
    color: #222325;
    font-weight: 700;
    padding-bottom: 8px !important;
  }

  &__size {
    color: #979797;
  }

  &__units {
    text-align: right;
  }

  &--nowrap {
    white-space: nowrap;
  }
}
</style>
