<template>
  <div class="add-multiple-users-panel-copy-paste">
    <div class="add-multiple-users-panel-copy-paste__table">
      <div class="add-multiple-users-panel-copy-paste__table-column">
        <div class="add-multiple-users-panel-copy-paste__table-header">#</div>

        <div
          class="add-multiple-users-panel-copy-paste__table-content"
          :class="{
            'add-multiple-users-panel-copy-paste__table-content--filled': tableUsers.length
          }"
        >
          <div
            class="add-multiple-users-panel-copy-paste__table-content-item
              add-multiple-users-panel-copy-paste__table-content-item--center"
            v-for="(user, index) in tableUsers"
            :key="index"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>

      <div class="add-multiple-users-panel-copy-paste__table-column">
        <div class="add-multiple-users-panel-copy-paste__table-header">
          First name

          <div
            class="add-multiple-users-panel-copy-paste__table-header-link"
            @click="clearRecipientColumnData('firstName', 'firstNames')"
            v-if="tableFirstNames"
          >
            Clear
          </div>
        </div>
        <div
          class="add-multiple-users-panel-copy-paste__table-content"
          :class="{ 'add-multiple-users-panel-copy-paste__table-content--filled': tableFirstNames }"
        >
          <copy-paste-table-input v-model="tableFirstNames" ref="firstNames" v-if="!tableFirstNames">
            Paste here list of first names
          </copy-paste-table-input>

          <div
            class="add-multiple-users-panel-copy-paste__table-content-item"
            v-for="(user, index) in tableUsers"
            :key="index"
            v-else
          >
            {{ user.firstName }}
          </div>
        </div>
      </div>

      <div class="add-multiple-users-panel-copy-paste__table-column">
        <div class="add-multiple-users-panel-copy-paste__table-header">
          Last name

          <div
            class="add-multiple-users-panel-copy-paste__table-header-link"
            @click="clearRecipientColumnData('lastName', 'lastNames')"
            v-if="tableLastNames"
          >
            Clear
          </div>
        </div>
        <div
          class="add-multiple-users-panel-copy-paste__table-content"
          :class="{ 'add-multiple-users-panel-copy-paste__table-content--filled': tableLastNames }"
        >
          <copy-paste-table-input v-model="tableLastNames" ref="lastNames" v-if="!tableLastNames">
            Paste here list of last names
          </copy-paste-table-input>

          <div
            class="add-multiple-users-panel-copy-paste__table-content-item"
            v-for="(user, index) in tableUsers"
            :key="index"
            v-else
          >
            {{ user.lastName }}
          </div>
        </div>
      </div>

      <div class="add-multiple-users-panel-copy-paste__table-column">
        <div class="add-multiple-users-panel-copy-paste__table-header">
          Email

          <div
            class="add-multiple-users-panel-copy-paste__table-header-link"
            @click="clearRecipientColumnData('email', 'emails')"
            v-if="tableEmails"
          >
            Clear
          </div>
        </div>
        <div
          class="add-multiple-users-panel-copy-paste__table-content"
          :class="{ 'add-multiple-users-panel-copy-paste__table-content--filled': tableEmails }"
        >
          <copy-paste-table-input v-model="tableEmails" ref="emails" v-if="!tableEmails">
            Paste here list of Emails
          </copy-paste-table-input>

          <div
            class="add-multiple-users-panel-copy-paste__table-content-item"
            v-for="(user, index) in tableUsers"
            :key="index"
            v-else
          >
            {{ user.email }}
          </div>
        </div>
      </div>
    </div>

    <div class="add-multiple-users-panel-copy-paste__actions">
      <cg-button :disabled="disableContinueButton" @click="onContinue">
        Continue
      </cg-button>
    </div>
  </div>
</template>

<script>
import { CgButton } from '@corporategift/design-system'
import CopyPasteTableInput from '../components/CopyPasteTableInput.vue'

export default {
  name: 'MultipleUsersUploadCopyPaste',
  components: {
    CgButton,
    CopyPasteTableInput,
  },
  props: {
    usersData: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data: () => ({
    tableUsers: [],
    tableForm: {
      firstNames: null,
      lastNames: null,
      emails: null,
    },
  }),
  computed: {
    tableFirstNames: {
      get () { return this.tableForm.firstNames },
      set (val) {
        if (!this.isValueASpecialChar(val)) {
          this.setRecipientsColumnData(val, 'firstName')
        } else {
          this.fillRecipientColumnWith('firstName')
        }

        this.tableForm.firstNames = val
      },
    },
    tableLastNames: {
      get () { return this.tableForm.lastNames },
      set (val) {
        if (!this.isValueASpecialChar(val)) {
          this.setRecipientsColumnData(val, 'lastName')
        } else {
          this.fillRecipientColumnWith('lastName')
        }

        this.tableForm.lastNames = val
      },
    },
    tableEmails: {
      get () { return this.tableForm.emails },
      set (val) {
        if (!this.isValueASpecialChar(val)) {
          this.setRecipientsColumnData(val, 'email')
        } else {
          this.fillRecipientColumnWith('email')
        }

        this.tableForm.emails = val
      },
    },
    isFormFilled () {
      return !Object.values(this.tableForm).every((value) => value === null)
    },
    disableContinueButton () {
      return Object.values(this.tableForm).some((value) => value === null)
    },
  },
  mounted () {
    const { usersData } = this

    if (usersData.length) {
      const usersWithData = usersData
        .filter((recipient) => recipient.firstname || recipient.lastname || recipient.email)

      this.tableUsers = structuredClone(usersWithData.map((userData) => ({
        firstName: userData.firstname,
        lastName: userData.lastname,
        email: userData.email,
      })))
      this.setFormData()
    }

    this.setFocusedField()
  },
  methods: {
    isValueASpecialChar (value) {
      return value?.match(/^[^a-zA-Z0-9]+$/)
    },
    setFormData() {
      this.setFormDataColumn('firstname', 'firstNames')
      this.setFormDataColumn('lastname', 'lastNames')
      this.setFormDataColumn('email', 'emails')
    },
    setFormDataColumn (userKey, formKey) {
      const value = this.usersData.reduce((acc, user) => {
        if (user[userKey]) { acc += `${user[userKey]}\n` }
        return acc
      }, '')
      
      if (value) { this.tableForm[formKey] = value }
    },
    setFocusedField () {
      const {
        firstNames,
        lastNames,
        emails,
      } = this.tableForm

      switch (null) {
        case firstNames:
          this.focusTextField('firstNames')
          break;
        case lastNames:
          this.focusTextField('lastNames')
          break;
        case emails:
          this.focusTextField('emails')
          break;
        default:
          break;
      }
    },
    focusTextField (fieldRefName) {
      setTimeout(() => { this.$refs[fieldRefName]?.focusTextArea() }, 0)
    },
    fillRecipientColumnWith (columnName, fillWith = null) {
      this.tableUsers.forEach((item, index) => {
        const data = this.tableUsers[index]
        data[columnName] = fillWith
        this.$set(this.tableUsers, index, data)
      })
    },
    setRecipientsColumnData (columnData, columnName) {
      // split csv columns
      const columnArray = columnData.split('\n')
      // remove the last element when it is empty
      if (!columnArray[columnArray.length - 1]) { columnArray.pop() }

      columnArray.forEach((value, index) => {
        if (this.tableUsers[index]) {
          const data = this.tableUsers[index]
          data[columnName] = value
          this.$set(this.tableUsers, index, data)
        } else {
          const recipientInfo = {}
          recipientInfo[columnName] = value
          this.$set(this.tableUsers, index, recipientInfo)
        }
      })

      setTimeout(() => { this.setFocusedField() }, 100)
    },
    clearRecipientColumnData (columnName, formName) {
      this.fillRecipientColumnWith(columnName)
      this.tableForm[formName] = null
      if (!this.isFormFilled) { this.tableUsers = [] }
      this.focusTextField(formName)
    },
    onContinue () {
      const { tableUsers } = this

      this.$emit(
        'update:usersData',
        tableUsers.map((userData) => ({
          firstname: userData.firstName?.trim(),
          lastname: userData.lastName?.trim(),
          email: userData.email?.trim(),
        }))
      )
      this.$emit('update:step', 4)
    }
  },
}
</script>

<style lang="scss" scoped>
.add-multiple-users-panel-copy-paste {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__table {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex: 1 1;
    border-top: 1px solid #D3D2D2;
  }
    
  &__table-column {
    display: flex;
    flex: 0 1 200px;
    flex-direction: column;

    &:nth-child(1) {
      flex: 0 1 36px;
    }

    &:last-child {
      flex: 1 1 300px;
    }

    &:nth-child(n + 2) {
      & > .add-multiple-users-panel-copy-paste__table-content {
        border-left: none;
      }
    }
  }

  &__table-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: #000;
    text-transform: uppercase;
    padding: 12px 14px;
  }

  &__table-header-link {
    font-weight: 400;
    font-size: 14px;
    line-height: inherit;
    color: #42B77A;
    text-transform: capitalize;
    cursor: pointer;
  }

  &__table-content {
    background-color: #FAFAFA;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    border: 1px dashed #D3D2D2;

    &--filled {
      border: none;
      border-top: 1px solid #D3D2D2;
    }
  }

  &__table-content-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0 1 40px;
    padding: 0 14px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    min-height: 40px;
    height: 40px;
    line-height: 17px;
    color: #000;
    border-bottom: 1px solid #D3D2D2;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      line-height: inherit;
      font-size: inherit;
    }

    &:nth-child(odd) {
      background-color: #FFFFFF;
    }

    &:nth-child(even) {
      background-color: #FAFAFA;
    }

    &--center {
      justify-content: center;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
</style>
