<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    depressed
    :tile="tile"
    :color="color"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'CommonButton',
  props: {
    color: {
      type: String,
      required: false,
      default: 'black white--text',
    },
    tile: {
      type: Boolean,
      required: false,
      default: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.v-btn--outlined {
  border-width: 2px !important;

  &:hover {
    color: #62646A !important;
    border-color: #62646A !important;
    background-color: #fff !important;

    &::before {
      background-color: transparent;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
  font-size: 18px;
  font-weight: 700;

  &__content {
    font-family: inherit;
    letter-spacing: normal;
  }

  &:not(&--outlined):hover {
    background: #62646A !important;
  }

  &:is(&--text):hover {
    background: inherit !important;
  }

  &--disabled {
    background: #C0C0C0 !important;
    color: #fff !important;
  }
}

.theme--light.v-btn.v-btn--disabled {
  background: #C0C0C0 !important;
  color: #fff !important;
}
</style>
