import { render, staticRenderFns } from "./BottomPanel.vue?vue&type=template&id=1d55c8fa&scoped=true&"
import script from "./BottomPanel.vue?vue&type=script&lang=js&"
export * from "./BottomPanel.vue?vue&type=script&lang=js&"
import style0 from "./BottomPanel.vue?vue&type=style&index=0&id=1d55c8fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d55c8fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
