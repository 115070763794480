
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, LinkType } from '../../types'
import type { IRecipient } from '../../types'

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import recipientMethodProp from '../mixins/recipientMethodProp'
import recipientValidations from '../mixins/recipientValidations'

import SingleDatePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

@Component({
  components: {
    SingleDatePicker,
  },
})
export default class SendEgiftPanelReviewRecipients extends Mixins(
  panelTypeProp,
  recipientsProp,
  recipientMethodProp,
  recipientValidations,
) {
  editRowId: number | null = null

  setSendDateForAllRecipients = false

  editForm: IRecipient = {
    firstName: null,
    lastName: null,
    email: null,
    sendDate: null,
  }

  get egiftRecipients (): Array<IRecipient> {
    return this.recipients
  }

  set egiftRecipients (val: Array<IRecipient>) {
    this.$emit('update:recipients', val)
  }

  get disableContinueButton (): boolean {
    const { egiftRecipients, isRecipientDataInvalid } = this

    if (!egiftRecipients.length) { return true }

    const validationResults = egiftRecipients.reduce((acc: boolean[], recipient: IRecipient): boolean[] => {
      acc.push(isRecipientDataInvalid(recipient))
      return acc
    }, [])

    return validationResults.includes(true)
  }

  created() {
    if (
      [RecipientMethod.UnrestrictedLink, RecipientMethod.Manually].includes(this.recipientMethod)
    ) {
      this.$emit('update:step', RootSteps.Recipient)
    }
  }

  isRecipientDataInvalid (recipient: IRecipient): boolean {
    const { firstNameValidation, lastNameValidation, emailValidation } = this

    const firstNameResult = firstNameValidation
      .map((validation) => {
        return validation(recipient?.firstName || null)
      })
      .includes('')

    const lastNameResult = lastNameValidation
      .map((validation) => {
        return validation(recipient?.lastName || null)
      })
      .includes('')

    const emailResult = emailValidation
      .map((validation) => {
        return validation(recipient?.email || null)
      })
      .includes('')

    return firstNameResult || lastNameResult || emailResult || false
  }

  isRecipientEmailInvalid (email: string | null): boolean {
    const { emailValidation } = this

    if (!email) { return true }

    if (this.isEmailDuplicated(email)) { return true }

    return emailValidation
      .map((validation) => validation(email || null))
      .includes('')
  }

  isEmailDuplicated (emailToCheck: string): boolean {
    const { egiftRecipients } = this

    if (!emailToCheck) return false

    const emails = egiftRecipients?.map((recipient) => recipient.email) ?? []
    return emails.filter((email) => email === emailToCheck).length !== 1
  }

  editRecipientInfo (index: number, recipient: IRecipient): void {
    this.editRowId = index

    this.editForm = Object.assign({}, recipient)
  }

  deleteRecipient (index: number): void {
    this.egiftRecipients.splice(index, 1)

    if (index === this.editRowId) { this.cancelEditing() }
  }

  saveChanges (): void {
    const { editForm, editRowId } = this
    // TODO validate inputs??
    if (this.isRecipientDataInvalid(editForm)) { return }

    const recipientIndex = this.egiftRecipients.findIndex((recipient, index) => index === editRowId)

    if (recipientIndex !== -1) {
      this.$set(this.egiftRecipients, recipientIndex, { ...editForm, })
    }

    if (this.setSendDateForAllRecipients) {
      this.egiftRecipients?.forEach((recipient, index) => {
        this.$set(this.egiftRecipients, index, {
          ...recipient,
          sendDate: editForm.sendDate,
        })
      })
    }

    this.cancelEditing()
  }

  cancelEditing (): void {
    this.editRowId = null

    this.editForm = {
      firstName: null,
      lastName: null,
      email: null,
      sendDate: null,
    }
  }

  handleContinue (): void {
    this.$emit('update:singleGiftLinkRecipients', 0)
    this.$emit('update:linkType', LinkType.StandardLink)
    this.$emit('update:step', RootSteps.Review)
  }
}
