import { render, staticRenderFns } from "./TheApprovalPanel.vue?vue&type=template&id=16555aec&scoped=true&"
import script from "./TheApprovalPanel.vue?vue&type=script&lang=js&"
export * from "./TheApprovalPanel.vue?vue&type=script&lang=js&"
import style0 from "./TheApprovalPanel.vue?vue&type=style&index=0&id=16555aec&prod&lang=scss&"
import style1 from "./TheApprovalPanel.vue?vue&type=style&index=1&id=16555aec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16555aec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VChip,VCol,VCombobox,VContainer,VNavigationDrawer,VRow,VTextarea})
