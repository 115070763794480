<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    :width="$vuetify.breakpoint.md ? '580px' : '850px'"
    class="share-favorites-panel"
  >
    <close-panel-button @close="() => show = false" v-if="$vuetify.breakpoint.mdAndUp">
      Close
    </close-panel-button>

    <close-mobile-panel-button @click.native="show = false" v-else>
      Share <b>{{ listName }}</b> collection
    </close-mobile-panel-button>

    <v-container class="px-4 px-md-9 py-md-0" v-if="!loading && favoriteList">
      <v-row class="flex-column">
        <v-col cols="12" class="share-favorites-panel__title" v-if="$vuetify.breakpoint.mdAndUp">
          Share <b>{{ listName }}</b> collection
        </v-col>

        <v-col cols="12" lg="7" class="pt-6 pt-md-10">
          <label for="share-link">Copy link</label>
          <common-input
            v-model="shareLink"
            disabled
            dense
            id="share-link"
            class="pt-2"
            @click.native="copyLink()"
          >
            <template #append>
              <icons-copy />
            </template>
          </common-input>
        </v-col>

        <v-col cols="12" lg="7" class="py-8">
          <v-divider />
        </v-col>

        <v-col cols="12" lg="7" class="pb-8">
          <label for="recipient-emails">Enter recipients’ email addresses</label>
          <v-combobox
            v-model="recipientEmails"
            multiple
            small-chips
            clearable
            id="recipient-emails"
            :items="[]"
            append-icon=""
            color="black"
            solo
            flat
            outlined
            hide-no-data
            hide-details
            @input="handleInputChange"
            class="rounded-0 pt-2"
            style="cursor: pointer"
          >
            <template #selection="{ attrs, item, parent, selected }">
              <v-chip
                class="my-1"
                color="#EDFCFB"
                v-bind="attrs"
                :input-value="selected"
                small
              >
                <span class="pr-2">
                  {{ item }}
                </span>

                <div class="d-flex align-center pointer" @click="parent.selectItem(item)">
                  <icons-close :width="10" :height="11" />
                </div>
              </v-chip>
            </template>
          </v-combobox>
          <span class="share-favorites-panel__combobox-hint">
            Multiple emails separated by Tab/Enter
          </span>
        </v-col>

        <v-col cols="12" lg="7" class="pb-8">
          <label for="share-message">Message:</label>

          <v-textarea
            v-model="message"
            id="share-message"
            color="black"
            solo
            flat
            outlined
            hide-details
            class="pt-2 rounded-0"
            rows="6"
          />
        </v-col>

        <v-col
          cols="12"
          lg="7"
          class="d-flex g10"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <common-button
            :disabled="!recipientEmails.length || !message"
            :block="$vuetify.breakpoint.xsOnly"
            :class="{ 'order-2': !$vuetify.breakpoint.xsOnly }"
            class="favorites-button"
            height="44"
            width="110"
            @click="shareFavorites()"
          >
            Share
          </common-button>

          <common-button
            :class="{
              'order-1': !$vuetify.breakpoint.xsOnly,
            }"
            :block="$vuetify.breakpoint.xsOnly"
            class="favorites-button"
            outlined
            height="44"
            @click="show = false"
          >
            Cancel
          </common-button>
        </v-col>
      </v-row>
    </v-container>

    <common-loader v-if="loading" />
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'TheMyFavoritesShareListItemPanel',
  mixins: [panelVModel],
  props: {
    favoriteList: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    shareLink: null,
    recipientEmails: [],
    message: null,
  }),
  computed: {
    listName () {
      return this.favoriteList?.name  ?? null
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.generateShareLink()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
  },
  methods: {
    generateShareLink () {
      const { origin } = window.location
      const { sharing_code: code } = this.favoriteList
      this.shareLink = `${origin}/wishlist/${code}`
    },
    copyLink () {
      navigator.clipboard.writeText(this.shareLink || '');
      this.$cgToast.success('Link copied to clipboard')
    },
    handleInputChange (val) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const arrayOfItems = val.join(',').split(',').filter((item) => pattern.test(item));
      this.recipientEmails = arrayOfItems;
    },
    shareFavorites () {
      const { message, recipientEmails, favoriteList: { id } } = this
      this.loading = true
      Api.post(`/customer/wishlists/${id}/share-via-email`, {
        message,
        recipients: recipientEmails.map((item) => ({ email: item }))
      }).then(({ success }) => {
        if (success) {
          this.$cgToast.success('Collection shared successfully!')
          this.show = false
        }
        // TODO error message?
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/favorites-button.scss";

.share-favorites-panel {
  & label {
    font-size: 15px;
    color: #222325;
    line-height: 18px;
    font-weight: 700;
  }

  &__title {
    color: #000;
    font-size: 40px;
    line-height: 44px;
    font-weight: 300;

    & > b {
      font-weight: 700;
    }
  }

  &__combobox-hint {
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
    font-weight: 400;
    font-style: italic;
    padding-top: 8px;
    display: block;
  }
}
</style>

<style lang="scss">
.share-favorites-panel {
  & .v-input {
    pointer-events: auto;

    & .v-input__append-inner {
      padding-left: 8px;
    }
  }

  & .v-autocomplete > .v-input__control > .v-input__slot {
    min-height: 36px !important;
  }

  #share-link .v-input__control > .v-input__slot {
    cursor: pointer;
  }

  & input[disabled] {
    color: #222325;
    cursor: pointer;
  }
}
</style>
