<template>
  <div class="my-campaigns-page">
    <div class="my-campaigns-page__title" :class="{ 'mt-7': !isPageInIframe }">
      <span>My campaigns</span>

      <cg-button
        v-if="showCreateCampaignButton && userCanCreateCampaign"
        :height="46"
        :to="{ name: 'CreateCampaignName', params: { campaignId: 'create' } }"
      >
        <template #prepend-icon>
          <icons-add />
        </template>

        Create new campaign
      </cg-button>
    </div>

    <div class="my-campaigns-page__filters">
      <the-campaign-search-input
        :initial-search.sync="search"
        :campaign-id.sync="campaignId"
        :creator-id.sync="creatorId"
      />

      <common-select
        v-model="selectedTag"
        :items="availableTags"
        placeholder="Tags"
        item-value="id"
        item-text="name"
        style="width: 220px; max-width: 220px"
        :height="30"
        filter-style
      />

      <common-select
        v-model="sortBy"
        :items="sortByItems"
        placeholder="Sort by"
        style="width: 220px; max-width: 220px"
        :height="30"
        filter-style
      />

      <common-select
        v-model="filterBy"
        :items="filterByItems"
        placeholder="Filter by:"
        style="width: 220px; max-width: 220px"
        :height="30"
        filter-style
      />

      <common-checkbox v-model="showDisabledCampaigns">
        Show disabled campaigns
      </common-checkbox>
    </div>

    <common-loader v-if="loading" class="my-campaigns-page__main-loader" />

    <template v-if="!loading && campaignItems.length">
      <the-campaign-card
        v-for="campaign in campaignItems"
        :key="campaign.entity_id"
        :item="campaign"
        :user-permissions="userPermissions"
        @showGiftOptionsPanel="showGiftOptionsPanel"
        @showEstCostPanel="showEstCostPanel"
        @showAutomationPanel="showAutomationPanel"
        @showEGiftPanel="showEGiftPanel"
        @showCampaignSettingsPanel="showCampaignSettingsPanel"
        @showSendDirectPanel="showSendDirectPanel(campaign)"
      />
    </template>

    <div v-else-if="!loading && !campaignItems.length" class="my-campaigns-page__empty">
      <icons-empty-campaigns :width="118" :height="91" />

      <span>
        {{
          search
            ? 'No campaigns/creators found'
            : 'Campaigns are the easiest and fastest way to send gifts for recurring occasions (e.g. employees onboarding, birthdays, clients anniversaries, etc.)'
        }}
      </span>

      <cg-button
        v-if="userCanCreateCampaign"
        :height="46"
        :to="{ name: 'CreateCampaignName', params: { campaignId: 'create' } }"
      >
        <template #prepend-icon>
          <icons-add />
        </template>

        Create new campaign
      </cg-button>
    </div>

    <gift-options-panel
      v-model="giftOptionsPanel"
      :gift="selectedGift"
    />

    <the-est-cost-panel
      v-model="estCostPanel"
      :gift="selectedGift"
    />

    <the-campaign-settings-panel
      v-model="campaignSettingsPanel"
      :gift="selectedGift"
      :availableTags="availableTags"
      @fetchData="fetchData()"
    />
    <!-- view-mode -->
    <send-egift-panel
      v-model="sendEGiftPanel"
      :campaign="selectedGift"
      :panelType="sendEGiftPanelType"
      :marketplaceUser="!userIsCgElite"
    />
    <!-- default mode -->
    <old-send-egift-panel
      v-model="sendEGiftPanelOld"
      :gift="selectedGift"
      :panelType="sendEGiftPanelType"
      :marketplaceUser="!userIsCgElite"
    />

    <automation-panel
      v-model="automationPanel"
      :campaign="selectedGift"
      @automationCreated="onAutomation('created')"
      @automationRemoved="onAutomation('removed')"
    />
  </div>
</template>

<script>
import Api from '@/axios/api'
import setCookie from '@/utils/setCookie'

import { CgButton } from '@corporategift/design-system'
import TheCampaignCard from '../components/myCampaign/TheCampaignCard.vue'
import GiftOptionsPanel from '../components/myCampaign/panels/TheGiftOptionsPanel.vue'
import TheEstCostPanel from '../components/myCampaign/panels/TheEstCostPanel.vue'
import TheCampaignSettingsPanel from '../components/myCampaign/panels/TheCampaignSettingsPanel.vue'
import SendEgiftPanel from '@/components/myCampaign/panels/SendEgiftPanel.vue'
import OldSendEgiftPanel from '../components/myCampaign/panels/TheSendEgiftPanel.vue'
import AutomationPanel from '@/components/myCampaign/panels/TheAutomationCampaignPanel.vue'
import TheCampaignSearchInput from '@/components/myCampaign/TheCampaignSearchInput.vue'

import isPageInIframe from '@/components/myCampaign/panels/mixins/isPageInIframe'
import superAdmin from '@/mixins/superAdmin'
import adminAccount from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import userIsCgElite from '@/mixins/userIsCgElite'
import campaignsFilters from '@/components/myCampaign/mixins/campaignsFilters'

export default {
  name: 'MyCampaignsPage',
  components: {
    TheCampaignSettingsPanel,
    TheCampaignSearchInput,
    OldSendEgiftPanel,
    AutomationPanel,
    SendEgiftPanel,
    GiftOptionsPanel,
    TheCampaignCard,
    TheEstCostPanel,
    CgButton,
  },
  mixins: [
    campaignsFilters,
    isPageInIframe,
    userIsCgElite,
    masterAccount,
    adminAccount,
    superAdmin,
  ],
  data: () => ({
    loading: false,
    userGroup: null,
    selectedGift: null,
    giftOptionsPanel: false,
    estCostPanel: false,
    campaignSettingsPanel: false,
    sendEGiftPanel: false,
    sendEGiftPanelOld: false,
    sendDirectPanel: false,
    automationPanel: false,
    sendEGiftPanelType: 'egift',
    campaignItems: [],
    userPermissions: [],
    // axios controllers
    getCampaignsController: null,
    getTagsController: null,
  }),
  computed: {
    userCanCreateCampaign () {
      return this.userPermissions.includes('create')
    },
    showCreateCampaignButton () {
      const { loading, campaignItems } = this

      if (loading) { return false }
      return !!campaignItems.length
    },
    routeParams () {
      return this.$route.query ?? null
    },
  },
  watch: {
    queryParams: {
      handler: 'fetchData',
      immediate: true
    },
    automationPanel: function (val) {
      if (!val) { this.clearRouteParams() }
    }
  },
  created () {
    if (this.$route.query?.iframe) {
      const params = this.$route.query

      if (params.token) {
        const token = params.token.replaceAll('%7C', '|')
        this.$store.commit('headerAuth/setToken', token)
        setCookie(token, 14, 'cgToken')
        this.$store.commit('app/setHideHeader', true)
        this.$store.commit('app/setHideUserMenu', true)
        this.$store.dispatch('tooltips/getTooltips', null)

        this.fetchData()
      }
    }
  },
  updated () {
    if (this.isPageInIframe) {
      const helpButton = document.querySelector('#launcher')
      if (helpButton) helpButton.style.display = 'none'
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isPageInIframe) {
      this.$store.dispatch('headerAuth/logout')
    }

    next()
  },
  methods: {
    fetchData () {
      this.loading = true
      const promises = []

      if (this.getCampaignsController || this.getTagsController) {
        this.getCampaignsController?.abort()
        this.getTagsController?.abort()
        this.getCampaignsController = null
        this.getTagsController = null
      }

      this.getCampaignsController = new AbortController();
      this.getTagsController = new AbortController();

      promises.push(Api.get('/campaigns', {
        signal: this.getCampaignsController.signal,
        params: this.queryParams
      }))

      promises.push(Api.get('/campaigns/tags', {
        signal: this.getTagsController.signal,
        params: { for_customer: true }
      }))

      Promise.all(promises)
        .then((response) => {
          this.campaignItems = response[0].data
          this.userPermissions = response[0].permissions ?? []
          this.campaignTags = response[1].data
          this.loading = false

          this.$nextTick(() => { this.handleRouteParams() })
        })
        .catch((e) => {
          if (e?.message !== 'canceled') {
            this.loading = false
          }
        })
        .finally(() => {
          this.getCampaignsController = null
          this.getTagsController = null
        })
    },
    handleRouteParams () {
      const { routeParams } = this

      if (routeParams) {
        if (routeParams?.panel === 'integration' && routeParams?.campaign_id) {
          const selectedCampaign = this.campaignItems.find(({ entity_id }) => entity_id === Number(routeParams.campaign_id))

          if (selectedCampaign) {
            this.showAutomationPanel(selectedCampaign);
          } else {
            this.clearRouteParams()
          }
        }
      }
    },
    clearRouteParams () {
      if (Object.values(this.routeParams).length) {
        this.$router.replace({ 'query': null });
      }
    },
    showGiftOptionsPanel (product) {
      this.setSelectedGift(product)
      this.giftOptionsPanel = true
    },
    showEGiftPanel (product, type = 'egift') {
      const { display_in_view_mode: showViewMode } = product

      this.setSelectedGift(product)
      this.sendEGiftPanelType = type

      this.sendEGiftPanel = !!showViewMode
      this.sendEGiftPanelOld = !showViewMode
    },
    showEstCostPanel (product) {
      this.setSelectedGift(product)
      this.estCostPanel = true
    },
    showAutomationPanel (product) {
      this.setSelectedGift(product)
      this.automationPanel = true
    },
    showCampaignSettingsPanel (product) {
      this.setSelectedGift(product)
      this.campaignSettingsPanel = true
    },
    showSendDirectPanel (product) {
      const { collection: { items } } = product
      const {
        product_id: productId,
        link,
        product: {
          type: {
            is_configurable: isConfigurable
          }
        }
      } = items[0]

      if (isConfigurable) {
        window.open(link, '_blank')
      } else {
        window.open(`${process.env.VUE_APP_MAGENTO_URL}/personalize/checkout/addtocart/?productId=${productId}&personalized_qty=1`, '_blank')
      }
    },
    setSelectedGift (product) {
      this.selectedGift = product
    },
    onAutomation (action = 'created') {
      const { selectedGift, campaignItems } = this
      const campaignId = selectedGift.entity_id
      const campaignIndex = campaignItems.findIndex((item) => item.entity_id === campaignId)

      if (campaignIndex !== -1) {
        this.$set(
          this.campaignItems,
          campaignIndex,
          { ...selectedGift, has_automation: action === 'created' },
        )
      }
    },
  }
}
</script>

<style lang="scss">
.my-campaigns-page {
  display: grid;
  gap: 30px;
  padding: 0 80px;
  margin-inline: auto;
  max-width: 1690px;
  grid-template-columns: repeat(4, 1fr);

  .v-select__slot {
    padding: 0 5px;
  }
  .v-text-field {
    > .v-input__control {
      > .v-input__slot:after {
        border: none;
      }
    }
  }

  .v-input__slot {
    input {
      &::placeholder {
        font-weight: 400;
        font-style: italic;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #a1a1a1;
      }
    }

    &::before {
      border-color: rgba(0, 0, 0, 0) !important;
    }
  }

  .v-select__slot .v-input__icon i {
    margin-left: 0px;

    &::before {
      color: #919191;
      font-size: 20px;
    }
  }

  &__filter {
    line-height: 16px;
    white-space: nowrap;
  }

  @container page-wrapper (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.my-campaigns-page__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;

  & > span {
    color: #000;
    line-height: 65px;
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 300;
  }

  & > button,
  & > a {
    border-radius: 100px;
  }
}

.my-campaigns-page__filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  grid-column: 1 / -1;
}

.my-campaigns-page__main-loader {
  grid-column: 1 / -1;
  padding: 250px 0;
}

.my-campaigns-page__empty {
  grid-column: 1 / -1;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  & > span {
    max-width: 512px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    line-height: 29.9px;
    color: #222325;
  }

  & > button,
  & > a {
    border-radius: 100px;
  }
}
</style>
