<template>
  <div class="the-inventory-status">
    <div class="the-inventory-status__legends">
      <legend-dot color="green">Units in stock</legend-dot>
      <legend-dot color="red">Out of stock</legend-dot>
      <template v-if="superAdmin || masterAccount">
        <legend-dot color="yellow">Units in production</legend-dot>
        <legend-dot color="grey">Units on hold</legend-dot>
      </template>
      <template v-else>
        <legend-dot color="red" outlined>My balance</legend-dot>
      </template>
    </div>

    <div class="the-inventory-status__filters">
      <div class="the-inventory-status__filters-inputs">
        <search-by-associated-data-input
          :initialSearch.sync="search"
          :productId.sync="filterProductId"
          :campaignId.sync="filterCampaignId"
        />

        <common-select
          v-if="superAdmin || masterAccount"
          v-model="filterBy"
          :items="filterByItems"
          placeholder="Filter by"
          item-value="id"
          item-text="name"
          style="width: 220px; max-width: 220px"
          :height="30"
          filter-style
        />
      </div>

      <div class="the-inventory-status__actions">
        <icons-inventory-list-view
          v-tooltip="{ content: 'List view', ...iconTooltipProps }"
          :class="{ 'selected-view': inventoryView === 'list' }"
          @click.native="inventoryView = 'list'"
        />
        <icons-inventory-grid-view
          v-tooltip="{ content: 'Grid view', ...iconTooltipProps }"
          :class="{ 'selected-view': inventoryView === 'grid' }"
          @click.native="inventoryView = 'grid'"
        />

        <the-table-download-invoice
          v-if="adminAccount || superAdmin || masterAccount"
          :downloadMethod="downloadCSV"
          wrapperStyles="margin-left: 20px"
        />
      </div>
    </div>

    <div class="the-inventory-status__content">
      <grid-view
        v-if="inventoryView === 'grid'"
        @showKitPanel="showKitPanel"
        @showRefillPanel="showRefillPanel"
        @showUnitsOnHoldPanel="showUnitsOnHoldPanel"
        @showAssignUsersPanel="showAssignUsersPanel"
        @showProductSettingsPanel="showProductSettingsPanel"
      />

      <list-view
        v-else
        @showKitPanel="showKitPanel"
        @showRefillPanel="showRefillPanel"
        @showProductSettingsPanel="showProductSettingsPanel"
        @showUnitsOnHoldPanel="showUnitsOnHoldPanel"
        @showAssignUsersPanel="showAssignUsersPanel"
        @showUnitsOnHoldPanelForItem="showUnitsOnHoldPanelForItem"
      />
    </div>

    <kit-panel
      v-model="kitPanel"
      :kit="selectedKit"
      :shouldBeStateless="refillPanel"
      @showRefillPanel="showRefillPanelWKit"
      @setSelectedKitName="setSelectedKitName"
    />

    <refill-panel
      v-model="refillPanel"
      :product="selectedProduct"
      :selectedKitName="selectedKitName"
      @fetchInventoryList="getInventoryList"
      @setSelectedKitName="setSelectedKitName"
    />

    <product-settings-panel
      v-model="productSettingsPanel"
      :product="selectedProduct"
      @fetchInventoryList="getInventoryList"
    />

    <assign-users-panel
      v-model="assignUsersPanel"
      :product="selectedProduct"
    />

    <units-on-hold-panel
      v-model="unitsOnHoldPanel"
      :product="selectedProduct"
    />
  </div>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'
import { createNamespacedHelpers } from 'vuex'
import superAdmin from '@/mixins/superAdmin'
import adminAccount from '@/mixins/adminAccount'
import masterAccount from '@/mixins/masterAccount'
import iconTooltipProps from './mixins/iconTooltipProps'

import GridView from './TheGridView.vue'
import ListView from './TheListView.vue'
import LegendDot from '@/components/myInventory/LegendDot'
import TheTableDownloadInvoice from '@/components/myCGCredits/TheTableDownloadInvoice.vue'
import SearchByAssociatedDataInput from '@/components/myInventory/SearchByAssociatedDataInput.vue'

import KitPanel from '@/components/myInventory/Panels/KitPanel'
import RefillPanel from '@/components/myInventory/Panels/RefillPanel'
import AssignUsersPanel from '@/components/myInventory/Panels/AssignUsersPanel.vue'
import UnitsOnHoldPanel from '@/components/myInventory/Panels/UnitsOnHoldPanel.vue'
import ProductSettingsPanel from '@/components/myInventory/Panels/ProductSettingsPanel'

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('inventory')

export default {
  name: 'TheInventoryStatusPage',
  mixins: [
    superAdmin,
    adminAccount,
    masterAccount,
    iconTooltipProps,
  ],
  components: {
    GridView,
    ListView,
    LegendDot,
    KitPanel,
    RefillPanel,
    UnitsOnHoldPanel,
    AssignUsersPanel,
    ProductSettingsPanel,
    TheTableDownloadInvoice,
    SearchByAssociatedDataInput,
  },
  data: () => ({
    inventoryView: 'grid',
    // panels
    productSettingsPanel: false,
    assignUsersPanel: false,
    refillPanel: false,
    kitPanel: false,
    unitsOnHoldPanel: false,
    // helpers
    selectedProduct: null,
    selectedKit: null,
    selectedKitName: null, // used for panel when user open it inside kit panel
  }),
  computed: {
    ...mapState([
      'inventoryList',
      'loading',
      'filters',
    ]),
    ...mapGetters([
      'findProductById',
      'params',
    ]),
    search: {
      get () { return this.filters.search },
      set (val = null) { this.setSearch(val) },
    },
    filterProductId: {
      get () { return this.filters.productId },
      set (val = null) { this.setFiltersProductId(val) },
    },
    filterCampaignId: {
      get () { return this.filters.campaignId },
      set (val = null) { this.setFiltersCampaignId(val) },
    },
    filterBy: {
      get () { return this.filters.filter_by },
      set (val = null) { this.setFilterBy(val) },
    },
    filterByItems () {
      const { filterBy } = this
      const filters = [
        { id: 'available', name: 'Available' },
        { id: 'oos', name: 'Out of stock' },
        { id: 'in_production', name: 'In production' },
        { id: 'on_hold', name: 'On hold' },
      ]

      if (filterBy) { filters.unshift({ id: null, name: 'All' }) }
      return filters
    },
    routeQuery () { return this.$route.query ?? {} },
  },
  watch: {
    inventoryView: function (val) {
      localStorage.setItem('inventoryView', val)
    },
    filters: {
      deep: true,
      handler: 'handleFilterParams',
    },
    loading: function (val) {
      if (!val) {
        this.showKitPanelBasedOnQueryParams()
      }
    }
  },
  created () {
    const { routeQuery } = this

    const storedViewData = localStorage.getItem('inventoryView')
    if (storedViewData) { this.inventoryView = storedViewData }

    if (routeQuery?.product_id) {
      this.setLoading(true)
      if (routeQuery?.product_name) { this.search = routeQuery?.product_name }
      if (routeQuery?.product_id) { this.filterProductId = Number(routeQuery?.product_id) }
    } else {
      this.getInventoryList()
    }
  },
  methods: {
    ...mapMutations([
      'setSearch',
      'setLoading',
      'setFilterBy',
      'setFiltersProductId',
      'setFiltersCampaignId',
    ]),
    ...mapActions(['getInventoryList']),
    setSelectedKitName (name = null) {
      this.selectedKitName = name
    },
    showKitPanelBasedOnQueryParams () {
      const { routeQuery, inventoryList } = this

      if (routeQuery?.product_id) {
        const productId = Number(routeQuery?.product_id)

        const kitInfo = inventoryList.find(({ product }) => product.product_id === productId)
        if (kitInfo) { this.showKitPanel(kitInfo) }
      }
    },
    showRefillPanel (id) {
      this.selectedProduct = this.findProductById(id)
      this.refillPanel = true
    },
    showUnitsOnHoldPanel (id) {
      this.selectedProduct = this.findProductById(id)
      this.unitsOnHoldPanel = true
    },
    showUnitsOnHoldPanelForItem (item) {
      this.selectedProduct = item?.product ?? {}
      this.unitsOnHoldPanel = true
    },
    showProductSettingsPanel (id) {
      this.selectedProduct = this.findProductById(id)
      this.productSettingsPanel = true
    },
    showRefillPanelWKit (product) {
      this.selectedProduct = product
      this.refillPanel = true
    },
    showKitPanel (kit) {
      this.selectedKit = kit
      this.kitPanel = true
    },
    showAssignUsersPanel (id) {
      this.selectedProduct = this.findProductById(id)
      this.assignUsersPanel = true
    },
    handleFilterParams: debounce(function () {
      this.getInventoryList()
      // clear route query
      if (this.filters.productId === null) { this.$router.replace({ query: {} }).catch(() => {}) }
    }, 400),
    async downloadCSV () {
      return Api.get('/customer/purchased-inventory/csv', { params: this.params })
        .then((response) => {
          this.$store.commit('downloadCSV', response)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.the-inventory-status {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__legends {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__filters-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;

    &::v-deep > svg {
      cursor: pointer;

      & > #square-helper {
        fill: transparent;
      }

      &:hover > #square-helper {
        transition: fill ease-in-out 0.46s;
        fill: #F4F4F4;
      }

      &.selected-view {
        & > #square-helper {
          fill: #EDFCFB;
        }
      }
    }
  }

  &__content {
    width: 100%;
  }
}
</style>

<style lang="scss">
.inventory-status__icon-tooltip {
  padding: 6px 12px 4px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000;
  border: 1px solid #B3CAC9;
  background-color: #D2F5F3;

  &--dark {
    background-color: #4A4A4A;
    border-color: #4A4A4A;
    color: #fff;
  }

  &--white {
    color: #16181F;
    border-color: #00000026;
    background-color: #fff;
  }
}
</style>
