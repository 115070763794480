import { render, staticRenderFns } from "./TheTransactionsHistoryPage.vue?vue&type=template&id=38e51990&scoped=true&"
import script from "./TheTransactionsHistoryPage.vue?vue&type=script&lang=js&"
export * from "./TheTransactionsHistoryPage.vue?vue&type=script&lang=js&"
import style0 from "./TheTransactionsHistoryPage.vue?vue&type=style&index=0&id=38e51990&prod&lang=scss&scoped=true&"
import style1 from "./TheTransactionsHistoryPage.vue?vue&type=style&index=1&id=38e51990&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e51990",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VExpandTransition})
