import { render, staticRenderFns } from "./TheAutoReloadPanel.vue?vue&type=template&id=0b364fa4&scoped=true&"
import script from "./TheAutoReloadPanel.vue?vue&type=script&lang=js&"
export * from "./TheAutoReloadPanel.vue?vue&type=script&lang=js&"
import style0 from "./TheAutoReloadPanel.vue?vue&type=style&index=0&id=0b364fa4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b364fa4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VDivider,VNavigationDrawer})
