<template>
  <div
    class="inventory-status-list-view__header"
    :class="{
      'inventory-status-list-view__header--simple': !superAdmin && !masterAccount,
      'inventory-status-list-view__header--sticky': isSticky,
    }"
    :style="`--header-height: ${headerHeight}px`"
  >
    <div>Product</div>
    <div>Associated campaigns</div>
    <div># Available</div>
    <template v-if="superAdmin || masterAccount">
      <div># On-hold</div>
      <div>In production</div>
    </template>
    <template v-else>
      <div>Balance</div>
    </template>
    <div>Cost per unit</div>
    <div>$ Total value</div>
    <div><!-- options --></div>
  </div>
</template>

<script>
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import getHeaderHeight from '@/mixins/getHeaderHeight'

export default {
  name: 'TheListViewHeader',
  mixins: [
    superAdmin,
    masterAccount,
    getHeaderHeight,
  ],
  data: () => ({
    isSticky: false,
  }),
  computed: {
    isOnInventoryTab () {
      const queryString = this.$route.query ?? {}

      return queryString?.tab === 'inventory' || !queryString?.tab
    },
  },
  watch: {
    isOnInventoryTab: {
      immediate: true,
      handler: function (val) {
        if (val) {
          // make header sticky after ~0.5sec to avoid jumping
          setTimeout(() => (this.isSticky = true), 550)
        } else {
          this.isSticky = false
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/components/myInventory/styles/inventory-list-grid.scss";

.inventory-status-list-view__header {
  @include inventoryListGrid;

  background: #fff;
  border-top: 1px solid #D3D2D2;
  border-bottom: 1px solid #D3D2D2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  width: inherit;
  z-index: 2;
  top: 0;
  overflow-x: hidden;

  & > div {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #000000;
  }

  &--sticky {
    top: calc(var(--header-height, 160px) - 2px);
  }

  &--simple {
    width: 100%;
    grid-template-columns: 350px 200px repeat(2, 90px) 84px 110px 1fr;
  }
}
</style>
