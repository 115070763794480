<template>
  <div class="inventory-status-list-view">
    <list-view-header ref="table-header" />

    <div class="inventory-status-list-view__scroller" @scroll="syncTableScroll">
      <template v-if="!loading && listViewItems.length">
        <list-view-item
          v-for="(item, index) in listViewItems"
          :key="item.product.item_id"
          :item="item"
          :next-item-is-a-variant="isNextItemAVariant(index)"
          v-on="$listeners"
        />
      </template>

      <div
        v-else-if="!loading && !listViewItems.length"
        class="inventory-status-list-view__empty"
      >
        <icons-empty-recent-orders color="#D8F1E4" />

        No inventory products found
      </div>

      <common-loader
        v-if="loading || loadingPage"
        :style="loadingPage ? 'padding: 16px 0' : 'padding: 100px 0'"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import loadMoreOnScroll from './mixins/loadMoreOnScroll'

import ListViewHeader from './TheListViewHeader.vue'
import ListViewItem from './TheListViewItem.vue'

const { mapState } = createNamespacedHelpers('inventory')

export default {
  name: 'TheListView',
  mixins: [loadMoreOnScroll],
  components: {
    ListViewHeader,
    ListViewItem,
  },
  computed: {
    ...mapState(['inventoryList']),
    listViewItems () {
      const { inventoryList } = this
      const result = [...inventoryList]

      const createItemFromVariant = (parentItem, variantInfo, isLastItem) => {
        const parentItemProduct = parentItem?.product ?? {}

        return ({
          entity_id: parentItem?.entity_id,
          est_expire_at: parentItem?.est_expire_at,
          is_disabled: false,
          is_kit: false,
          variant_child: true,
          last_variant_child: isLastItem,
          variant_code: variantInfo?.code,
          product: {
            auto_refill: { ...(parentItemProduct?.auto_refill ?? {}) },
            campaigns: parentItemProduct?.campaigns ?? [],
            cost_per_unit: variantInfo?.cost_per_unit,
            description: parentItemProduct?.description,
            flags: { ...(parentItemProduct?.flags ?? {}) },
            image: parentItemProduct?.image,
            is_disabled: parentItemProduct?.is_disabled,
            item_id: variantInfo?.item_id,
            name: variantInfo?.name,
            sku: variantInfo?.sku,
            notify_for_qty_below: { ...(parentItemProduct?.notify_for_qty_below ?? {}) },
            price: { ...(parentItemProduct?.price ?? {}) },
            product_id: variantInfo?.product_id,
            product_url: parentItemProduct?.product_url,
            qty: variantInfo?.qty,
            stock: {
              marketplace_product_qty: variantInfo?.marketplace_product_qty,
              qty: variantInfo?.qty,
              qty_in_kit: null,
              qty_in_production: variantInfo?.qty_in_production,
              qty_on_hold: variantInfo?.qty_on_hold,
            },
            total_value: variantInfo?.total_value,
            variations: [],
          },
        })
      }

      return result.reduce((acc, item) => {
        acc.push(item)

        const itemVariants = item?.product?.variations?.short || []
        const itemHasVariants = itemVariants?.length > 1

        if (itemHasVariants) {
          const itemChildren = itemVariants?.map((variant, index, { length }) =>
            createItemFromVariant(item, variant, length - 1 === index)
          );
          if (itemChildren?.length) { acc.push(...itemChildren) }
        }

        return acc
      }, [])
    },
  },
  methods: {
    syncTableScroll (e) {
      this.$refs['table-header'].$el.scrollLeft = e.target.scrollLeft;
    },
    isNextItemAVariant (index) {
      const { listViewItems } = this

      const nextItem = listViewItems?.at(index + 1) ?? null

      if (!nextItem) return false

      return (nextItem?.product?.variations?.short || [])?.length > 1
    }
  },
}
</script>

<style lang="scss" scoped>
.inventory-status-list-view {
  width: 100%;
  overscroll-behavior: contain;
  position: relative;

  &__scroller {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    & > div {
      border-bottom: 1px solid #D3D2D2;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    justify-content: center;
    gap: 30px;
    height: 300px;
    min-width: 1334px;
    max-width: 1490px;
    border-bottom: none !important;

    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
