<template>
  <div class="payment-module-ach-container">
    <ach-item v-if="paymentData" :data="paymentData" />

    <div
      class="payment-module-ach-container__link"
      @click="$emit('changeSavedPayment', 'bluesnap_ach')"
    >
      Change
    </div>
  </div>
</template>

<script>
import AchItem from '@/components/payment/AchItem.vue'

export default {
  name: 'BluesnapAchMethod',
  components: { AchItem },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    validate () {
      return this.paymentData !== null
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-module-ach-container {
  &__link {
    font-weight: 400;
    font-size: 15px;
    margin-top: 14px;
    line-height: 18px;
    color: #42B77A;
    cursor: pointer;
    width: fit-content;
  }

  &::v-deep .credit-card-item {
    border: 1px solid #D3D2D2;
    cursor: default;
    background-color: #fff;

    &__actions {
      display: none;
    }
  }
}
</style>
