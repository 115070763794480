export default function copyTextToClipboard(text) {
  const rootEl = document.querySelector('#app');
  const storage = document.createElement('textarea');
  storage.value = text;
  console.log('copy: ', text);
  rootEl.appendChild(storage);

  storage.select();
  storage.setSelectionRange(0, 99999);
  document.execCommand('copy');
  rootEl.removeChild(storage);
}
