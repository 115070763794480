import { render, staticRenderFns } from "./CustomizedPopup.vue?vue&type=template&id=306905b2&"
import script from "./CustomizedPopup.vue?vue&type=script&lang=js&"
export * from "./CustomizedPopup.vue?vue&type=script&lang=js&"
import style0 from "./CustomizedPopup.vue?vue&type=style&index=0&id=306905b2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
