
import { Vue, Component } from 'vue-property-decorator'

import DepositInfoItem from '../components/DepositInfoItem.vue'

interface IInfoItem {
  title?: string,
  content: string,
  videoUrl?: string,
}

@Component({
  components: {
    DepositInfoItem,
  },
})
export default class SendEgiftPanelDepositInfo extends Vue {
  items: Array<IInfoItem> = [
    {
      videoUrl: 'https://www.youtube.com/embed/Gho6du8j-nM?rel=0',
      content: `Your total eGift cost is dependent on the number of recipients that redeem gifts
        and what they choose (for gift cost), and where they live (for tax and shipping). <br /><br />
        We’ve calculated the maximum possible costs below, plus our non-refundable eGift fee,
        which are due as “Today’s deposit.” <br /><br /> When you close this eGift order and
        settle the transaction after the redemption period, you will be refunded for the total
        difference between your deposit and actual cost.`,
    },
    {
      title: 'Max gift cost',
      content: `The total of the highest priced gift in this Collection, multiplied by the number
        of recipients in this order.`,
    },
    {
      title: 'Max shipping',
      content: `The total of the highest ground shipping rate from this Collection, multiplied by
        the number of recipients in this order.`,
    },
    {
      title: 'Max sales tax',
      content: `6.625% sales tax on the highest priced gift in this Collection, multiplied by the
        number of recipients in this order. You will only pay sales tax for recipients who ship
        their gift to the State of New Jersey, and for the actual value of the gifts they redeem.`,
    },
    {
      title: 'eGift fee',
      content: `To cover our costs to service your eGift, a non-refundable eGift fee per
        recipient is applied. This fee is calculated at 12% of the low end of your selected
        budget range.<br /><br /> eGift fees are waived for CG Elite platform members.
        <a href="https://corporategift.com/platform/" target="_blank">Learn more</a>`,
    },
  ]
}
