
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReviewVideoThumbnail extends Vue {
  @Prop({ required: true }) readonly videoUrl!: string;

  videoThumbnail: string | null = null;

  get isYoutubeVideo (): boolean {
    return this.videoUrl.includes('youtu');
  }

  get isLoomVideo (): boolean {
    return this.videoUrl.includes('loom');
  }

  get isViMailVideo (): boolean {
    return this.videoUrl.includes('kommute')
  }

  get isVidyardVideo (): boolean {
    return this.videoUrl.includes('vidyard');
  }

  mounted () {
    const { videoUrl, isLoomVideo, isYoutubeVideo, isVidyardVideo, isViMailVideo } = this

    if (isYoutubeVideo) {
      const youtubeId = this.getYoutubeId(videoUrl)
      this.videoThumbnail = `https://img.youtube.com/vi/${youtubeId}/default.jpg`
    }

    if (isLoomVideo) {
      const loomId = this.takeArgumentFromPathnameById(videoUrl, 4)
      // this.videoThumbnail = `https://cdn.loom.com/sessions/thumbnails/${loomId}.jpg`
      // this.videoThumbnail = `https://www.loom.com/share/${loomId}`
    }

    if (isVidyardVideo) {
      const vidyardId = this.takeArgumentFromPathnameById(videoUrl, 4)
      this.videoThumbnail = `https://play.vidyard.com/${vidyardId}.jpg`
    }

    if (!this.videoThumbnail) {
      this.videoThumbnail = '/images/video-thumbnail.png'
    }
  }

  getYoutubeId (url: string): string | null {
    return url?.match(/(^|=|\/)([0-9A-Za-z_-]{11})(\/|&|$|\?|#)/)?.[2] ?? null
  }

  takeArgumentFromPathnameById (url: string, index: number, splitBy = '/'): string {
    return url.split(splitBy)[index]
  }

  showPreviewModal (): void {
    this.$store.dispatch('app/handleVideoModal', {
      show: true,
      url: this.videoUrl,
    })
  }
}
