
import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import type { IReviewField } from '../../types'
import { LinkType, RecipientMethod } from '../../types'

import recipientMethodProp from '../mixins/recipientMethodProp'
import recipientsProp from '../mixins/recipientsProp'

import ReviewField from './ReviewField.vue'
import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'

@Component({
  components: {
    ReviewField,
    ReviewSaveCancelIcons,
  },
})
export default class ReviewFieldsLinkType extends Mixins(recipientMethodProp, recipientsProp) {
  @Prop({ required: true }) linkType!: LinkType;

  inEditMode = false;

  linkTypeHelper: LinkType = LinkType.StandardLink;

  get linkTypeReviewText (): string {
    const { linkType, isSelectedUnrestrictedLink } = this

    if (isSelectedUnrestrictedLink) { return 'Unrestricted gift link' }

    return linkType === LinkType.StandardLink ? 'Standard' : 'Single link verified'
  }

  get isSelectedUnrestrictedLink (): boolean {
    return this.recipientMethod === RecipientMethod.UnrestrictedLink
  }

  get disableSingleLink (): boolean {
    return !this.recipients.every((recipient) => recipient.firstName && recipient.email) ?? true
  }

  @Watch('inEditMode', { immediate: false })
  onEditModeChange (newVal: boolean) {
    if (newVal) { this.linkTypeHelper = this.linkType }
  }

  handleOnSave (): void {
    this.$emit('update:linkType', this.linkTypeHelper)

    this.handleOnCancel()
  }

  handleOnCancel (): void {
    const refElement = this.$refs?.['review-field']

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
