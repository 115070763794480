<template>
  <div class="transactions-history-table" :class="transitionClasses">
    <transactions-history-table-header ref="table-header" />

    <div
      class="transactions-history-table__scroller"
      ref="scroller"
      @scroll="onScrollerScroll($event)"
    >
      <template v-if="!loading && transactions.length">
        <transactions-history-table-item
          v-for="transaction in transactions"
          :key="transaction.id"
          :item="transaction"
        />
      </template>

      <div v-else-if="!loading && !transactions.length" class="transactions-history-table__empty">
        <icons-empty-recent-orders color="#D8F1E4" />

        No transactions history found
      </div>

      <common-loader v-else style="padding: 100px 0" />

      <div v-if="!loading && showLoadMoreButton" class="transactions-history-table__load-more">
        <load-more
          :is-visible="showLoadMoreButton"
          :loading="loadingBtn"
          :size="loadMoreNumber"
          @more="showMoreOrders()"
        />
      </div>
    </div>

    <table-scrollbar-helper
      v-show="showTableScrollbarHelper"
      ref="scrollbar-helper"
      :left="tableLeft"
      :headerWidth="1784"
      :scrollbarWidth="tableWidth"
      @updateScroll="onScrollHelper"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import onScrollParentTableHandler from './mixins/onScrollParentTableHandler'

import LoadMore from '@/components/LoadMore'
import TableScrollbarHelper from '../TableScrollbarHelper.vue'
import TransactionsHistoryTableHeader from './TheTransactionsHistoryTableHeader.vue'
import TransactionsHistoryTableItem from './TheTransactionsHistoryTableItem.vue'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('transactions-history')

export default {
  name: 'TheTransactionsHistoryTable',
  mixins: [onScrollParentTableHandler],
  components: {
    LoadMore,
    TableScrollbarHelper,
    TransactionsHistoryTableItem,
    TransactionsHistoryTableHeader,
  },
  data: () => ({
    tableWidth: 0,
    tableLeft: 0,
    loadingBtn: false,
    showScrollbarHelper: false,
    isVerticalScrollbarVisible: false,
  }),
  computed: {
    ...mapState([
      'loading',
      'transactions',
    ]),
    ...mapGetters([
      'loadMoreNumber',
      'showLoadMoreButton',
    ]),
    transitionClasses () {
      const { scrollOverflow } = this

      return ({
        'transactions-history-table--overflow-left': scrollOverflow.left,
        'transactions-history-table--overflow-right': scrollOverflow.right,
      })
    },
    sidebarWidth () {
      return this.$vuetify?.application?.left ?? 64
    },
    isOnTransactionsTab () {
      const queryString = this.$route.query ?? {}

      return queryString?.tab === 'transactions' ?? queryString?.tab
    },
    showTableScrollbarHelper () {
      const { loading, transactions, showScrollbarHelper, isVerticalScrollbarVisible } = this

      if (!showScrollbarHelper) { return false }

      return !loading && transactions?.length
    },
  },
  watch: {
    isOnTransactionsTab: {
      immediate: true,
      handler: function (val) {
        if (val) {
          setTimeout(() => {
            this.setScrollbarHelperData()
            this.showScrollbarHelper = true
          }, 550)
        } else {
          this.showScrollbarHelper = false
        }
      }
    },
    transactions: {
      immediate: false,
      deep: true,
      handler: 'setScrollbarHelperData',
    },
    sidebarWidth: function () {
      setTimeout(() => (this.setScrollbarHelperData()), 300)
    },
  },
  // TODO improve how position left is calculated
  mounted () {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.tableWidth = entry.contentRect.width
        this.tableLeft = entry.target.getBoundingClientRect().left ?? 128
      }
    })
    const tableScroller = this.$el.querySelector('.transactions-history-table__scroller')
    resizeObserver.observe(tableScroller)
  },
  methods: {
    ...mapActions(['loadMoreTransactions']),
    setScrollbarHelperData () {
      const tableScroller = this.$el.querySelector('.transactions-history-table__scroller')
      const rect = tableScroller?.getBoundingClientRect()

      this.tableWidth = rect?.width ?? 1100
      this.tableLeft = rect?.left ?? 128
      // TODO debug (document.body has same height)
      // setTimeout(() => {
      //   const { scrollHeight, clientHeight } = document.body
      //   this.isVerticalScrollbarVisible = clientHeight !== scrollHeight
      // }, 0)
    },
    showMoreOrders () {
      this.loadingBtn = true
      this.loadMoreTransactions()
        .catch((e) => (console.error(e)))
        .finally(() => (this.loadingBtn = false))
    },
    onScrollerScroll (e) {
      const newScrollLeft = e.target.scrollLeft
      this.$refs['table-header'].$el.scrollLeft = newScrollLeft
      this.$refs['scrollbar-helper'].$el.scrollLeft = newScrollLeft
    },
    onScrollHelper (newScrollLeft) {
      this.$refs['table-header'].$el.scrollLeft = newScrollLeft
      this.$refs['scroller'].scrollLeft = newScrollLeft
    }
  },
}
</script>

<style lang="scss" scoped>
.transactions-history-table {
  width: 100%;
  overscroll-behavior: contain;
  position: relative;

  &__scroller {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    & > div {
      border-bottom: 1px solid #D3D2D2;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    justify-content: center;
    gap: 30px;
    height: 300px;
    width: 1784px;
    border-bottom: none !important;

    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }

  &__load-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 149px;
    width: 1784px;
    border-bottom: none !important;

    & > button {
      margin: 0 !important;
    }
  }

  &::after {
    content: '';
    height: 100%;
    width: 0px;
    z-index: 3;
    transition: width ease-in-out 0.2s;
    background: linear-gradient(
      90deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,1) 75%,
      rgba(255,255,255,1) 100%
    );
    position: absolute;
    right: 0;
    top: 0;
  }

  &::before {
    content: '';
    height: 100%;
    width: 0px;
    z-index: 3;
    transition: width ease-in-out 0.2s;
    background: linear-gradient(
      -90deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,1) 75%,
      rgba(255,255,255,1) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }

  &--overflow-left {
    &::before {
      width: 100px;
    }
  }

  &--overflow-right {
    &::after {
      width: 100px;
    }
  }
}
</style>
