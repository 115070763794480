<template>
  <div class="cg-radio-group">
    <common-radio
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod.inputValue"
      v-model="selectedPaymentMethod"
      :input-value="paymentMethod.inputValue"
      @click.native="onRadioClick(paymentMethod)"
    >
      {{ paymentMethod.defaultText }}
    </common-radio>
  </div>
</template>

<script>
import PaymentType from '../utils/PaymentType'

export default {
  name: 'OtherPaymentMethods',
  props: {
    paymentMethod: {
      type: String,
      required: false,
    },
    availableMethods: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    defaultPaymentMethods: [
      {
        defaultText: 'CG Credits',
        inputValue: PaymentType.BE
      },
      {
        defaultText: 'ACH',
        inputValue: PaymentType.ACH
      },
      {
        defaultText: 'Test Method',
        inputValue: PaymentType.Test,
      },
      {
        defaultText: 'Credit card',
        inputValue: PaymentType.CC
      },
      {
        defaultText: 'Credit card',
        inputValue: PaymentType.CC_Bluesnap
      },
      {
        defaultText: 'ACH',
        inputValue: PaymentType.ACH_Bluesnap
      },
      {
        defaultText: 'PO',
        inputValue: PaymentType.PO
      },
    ],
  }),
  computed: {
    selectedPaymentMethod: {
      get () { return this.paymentMethod },
      set (val) { this.$emit('update:paymentMethod', val) },
    },
    paymentMethods () {
      const { availableMethods, defaultPaymentMethods } = this;

      return defaultPaymentMethods
        .filter(({ inputValue }) => availableMethods.includes(inputValue));
    },
  },
  methods: {
    onRadioClick ({ inputValue }) {
      this.$nextTick(() => {
        this.$emit('onPaymentMethodChange', inputValue)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.cg-radio-group {
  display: flex;
  flex-direction: column;
  gap: 20px 12px;
}
</style>
