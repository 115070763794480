<template>
  <cg-form ref="form" class="billing-address-form">
    <div class="billing-address-form__inputs-wrapper">
      <cg-input v-model="form.firstname" :validation="[validation.required]">
        <template #label>
          First name
        </template>
      </cg-input>
      <cg-input v-model="form.lastname" :validation="[validation.required]">
        <template #label>
          Last name
        </template>
      </cg-input>
    </div>

    <template v-if="enterAddressManually">
      <cg-input v-model="form.street" :validation="[validation.required]">
        <template #label>Street address</template>
      </cg-input>

      <cg-input v-model="form.city" :validation="[validation.required]">
        <template #label>City</template>
      </cg-input>

      <cg-country-select v-model="form.country">
        <template #label>Country</template>
      </cg-country-select>

      <cg-state-select
        v-model="form.region"
        :country="form.country"
        :validation="[validation.required]"
      >
        <template #label>State</template>
      </cg-state-select>

      <cg-input v-model="form.postcode" :validation="[validation.required]">
        <template #label>Postal code</template>
      </cg-input>
    </template>

    <loqate-autocomplete
      v-else
      :street.sync="form.street"
      :city.sync="form.city"
      :country.sync="form.country"
      :postcode.sync="form.postcode"
      :region.sync="form.region"
      :availableCountries="availableCountries"
      @onSelectedAddress="enterAddressManually = true"
    >
      <template #label>
        <div class="billing-address-form__autocomplete-label">
          Street address

          <span @click.prevent="enterAddressManually = true">Enter address manually</span>
        </div>
      </template>
    </loqate-autocomplete>

    <cg-phone-input
      v-model="form.telephone"
      :validation="[validation.required]"
    >
      <template #label>Phone</template>
    </cg-phone-input>
  </cg-form>
</template>

<script>
import { CgForm, CgInput, CgPhoneInput, CgStateSelect, CgCountrySelect } from '@corporategift/design-system'
import LoqateAutocomplete from './LoqateAutocomplete.vue'

import { required } from '@corporategift/design-system/validations'

export default {
  name: 'BillingAddressForm',
  components: {
    CgForm,
    CgInput,
    CgPhoneInput,
    CgCountrySelect,
    CgStateSelect,
    LoqateAutocomplete,
  },
  props: {
      /**
       * An array of available countries for Loqate
       * Empty Array means all countries are available
       */
    availableCountries: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data: () => ({
    form: {
      firstname: null,
      lastname: null,
      street: null,
      city: null,
      postcode: null,
      region: null,
      telephone: null,
      country: 'US',
    },
    enterAddressManually: false,
    validation: {
      required: required(),
    },
  }),
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.$emit('onBullingAddressUpdate', structuredClone(this.form))
      }
    }
  },
  methods: {
    clearData () {
      Object.assign(this.$data, this.$options.data())
    },
    validate () {
      return this.$refs.form?.validate()
    }
  },
}
</script>

<style lang="scss" scoped>
.billing-address-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__inputs-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &::v-deep .cg-input > label > span {
    font-weight: 400;
  }

  &__autocomplete-label {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: space-between;

    & > span {
      color: #42B77A;
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
</style>
