<template>
  <div
    class="approval-orders dashboard-card dashboard-card--small"
    :class="{
      'order-3': (!$vuetify.breakpoint.lgAndUp && !(superAdmin || masterAccount) && !$vuetify.breakpoint.xsOnly)
        || (!$vuetify.breakpoint.lgAndUp && (superAdmin || masterAccount) && !$vuetify.breakpoint.xsOnly),
      'order-2': ($vuetify.breakpoint.lgAndUp && !(superAdmin || masterAccount)) ||
        ($vuetify.breakpoint.lgAndUp && (superAdmin || masterAccount) && !hasInventory),
      'order-5': $vuetify.breakpoint.lgAndUp && (superAdmin || masterAccount) && hasInventory
        || $vuetify.breakpoint.xsOnly
    }"
  >
    <div class="approval-orders__title">
      {{ items.length ? 'Orders awaiting my approval' : 'Approve orders' }}

      <dashboard-card-pagination
        v-model="currentItemIndex"
        :max-length="items.length"
        v-if="items.length > 1"
      />
    </div>

    <v-divider />

    <template v-if="!loading">
      <div class="approval-orders__content" v-if="items.length">
        <div class="content__product">
          <div
            class="approval-orders__product-images"
            :class="{ 'approval-orders__product-images--no-borders' : activeItemImagesLength === 1 }"
          >
            <v-img
              v-for="(image, index) in activeItemImages"
              :key="index"
              :src="image"
              :alt="activeItemData.products[index].name"
              contain
              min-width="60"
              width="60"
              max-width="120"
              max-height="120"
              aspect-ratio="1"
            />

            <div class="approval-orders__product-images-info" v-if="activeItemImagesLength > 4">
              +{{ activeItemImagesLength - 3 }}
            </div>
          </div>

          <div>
            <span>{{ activeItemData.name }}</span>
            <span>{{ activeItemData.price | priceFilter }}</span>
          </div>
        </div>

        <div class="content__request-details">
          <div>
            Requested by:
            <span>{{ activeItemData.requested_by }}</span>
          </div>
          <router-link :to="{ name: 'MyOrder', params: { id: activeItemData.id } }">
            See request details
          </router-link>
        </div>

        <div class="content__actions">
          <div @click="declineOrder()">
            Decline
            <icons-close-circle-filled />
          </div>
          <div @click="approveOrder()">
            Approve
            <icons-check-circle :width="22" :height="22" filled :checkStrokeWidth="2" />
          </div>
        </div>
      </div>

      <div class="approval-orders__empty" v-else>
        <icons-empty-recent-orders color="#D8F1E4" />

        No orders waiting your approval
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

import DashboardCardPagination from './common/DashboardCardPagination.vue'

export default {
  name: 'TheApprovalOrders',
  components: {
    DashboardCardPagination
  },
  mixins: [
    superAdmin,
    masterAccount,
  ],
  props: {
    hasInventory: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    loading: false,
    currentItemIndex: 0,
    items: []
  }),
  computed: {
    activeItemData () {
      const { items, currentItemIndex } = this
      return items?.[currentItemIndex] || null
    },
    activeItemImagesLength () {
      return this.activeItemData?.images?.length ?? 0
    },
    activeItemImages () {
      const { images } = this.activeItemData ?? {}
      const sliceBy = this.activeItemImagesLength > 4 ? 3 : 4

      return images.slice(0, sliceBy) ?? []
    }
  },
  created () {
    this.loading = true
    Api.get('/customer/dashboard/approve-orders')
      .then(({ data }) => {
        this.items = data?.reverse() ?? []
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    declineOrder () {
      this.loading = true
      const { id } = this.activeItemData
      this.$store.dispatch('new-approvable-orders/declineOrder', { id })
        .then(() => {
          this.currentItemIndex = 0
          this.$cgToast.successBold('Order declined')
          this.removeItemFromList(id)
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
        })
        .finally(() => (this.loading = false))
    },
    approveOrder () {
      this.loading = true
      const { id } = this.activeItemData
      this.$store.dispatch('new-approvable-orders/approveOrder', { id })
        .then(() => {
          this.currentItemIndex = 0
          this.$cgToast.successBold('Order approved')
          this.removeItemFromList(id)
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
        })
        .finally(() => (this.loading = false))
    },
    removeItemFromList (id) {
      this.items = this.items.filter((item) => item.id !== id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.approval-orders {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > hr {
    border-color: #D3D2D2;
  }

  & a {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #42B77A !important;
  }

  &__title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    & > .content {
      &__product {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;

        & > div {
          display: flex;
          flex-direction: column;
          gap: 8px;

          & > span {
            color: #000;

            &:nth-child(1) {
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
            }

            &:nth-child(2) {
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }

      &__request-details {
        display: flex;
        flex-direction: column;
        background-color: #FAFAFA;
        padding: 10px 20px;
        gap: 8px;

        & > div {
          font-weight: 700;
          font-size: 15px;
          color: #62646A;

          & > span {
            font-weight: 400;
            font-size: inherit;
            color: inherit;
          }
        }
      }

      &__actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: auto;
        justify-content: flex-end;
        align-items: center;
        gap: 14px;

        & > div {
          cursor: pointer;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 4px;
          line-height: 17px;
          color: #42B77A;
        }
      }
    }
  }

  &__product-images {
    display: flex !important;
    flex-direction: row !important;
    gap: 0 !important;
    flex-wrap: wrap;
    max-width: 120px;
    min-width: 120px;
    max-height: 120px;
    width: 120px;
    height: 120px;

    & > .v-image {
      flex-grow: 0;
      border-left: 1px solid #e4e4e4;
      border-top: 1px solid #e4e4e4;

      &:nth-child(even) {
        border-right: 1px solid #e4e4e4;
      }

      &:nth-child(n + 3) {
        border-bottom: 1px solid #e4e4e4;
      }
    }

    &--no-borders > .v-image {
      border: none !important;
    }
  }

  &__product-images-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    border: 1px solid #e4e4e4;
    font-weight: 700;
    font-size: 28px;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 28px;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
