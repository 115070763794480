<template>
  <div class="campaign-automation-decline">
    <h1>My campaigns</h1>

    <cg-loader v-if="loading" />

    <template v-else>
      <div class="campaign-automation-decline__content">
        <icons-empty-campaigns :width="118" :height="91" />

        <span>
          {{ campaignName }} auto send to {{ recipientName }} cancellation confirmed. <br>
          This will not affect future scheduled eGifts.
        </span>

        <cg-button :to="{ name: 'MyCampaignsPage' }">Back to My Campaigns</cg-button>
      </div>
    </template>
  </div>
</template>

<script>
import Api from '@/axios/api'
import { CgLoader, CgButton } from '@corporategift/design-system'

export default {
  name: 'CampaignAutomationDecline',
  components: {
    CgButton,
    CgLoader,
  },
  data: () => ({
    loading: true,
    automationResponse: {
      campaign_name: null,
      first_name: null,
      last_name: null,
    }
  }),
  computed: {
    campaignId () {
      return this.$route.params.campaignId ?? 0
    },
    automationId () {
      return this.$route.params.automationId ?? 0
    },
    campaignName () {
      return this.automationResponse.campaign_name ?? ''
    },
    recipientName () {
      const { first_name, last_name } = this.automationResponse
      return [first_name, last_name].join(' ')
    }
  },
  mounted () {
    const { campaignId, automationId } = this;

    Api.patch(`/campaigns/${campaignId}/automation/${automationId}/decline`)
      .then(({ data }) => {
        this.automationResponse = data
        this.loading = false
      })
      .catch(() => {
        this.$cgToast.error("Can't find automation")
        this.$router.push({ name: 'MyCampaignsPage' })
      })
  }
}
</script>

<style lang="scss" scoped>
.campaign-automation-decline {
  padding: 50px 64px;
  margin-inline: auto;
  display: flex;
  max-width: 1785px;
  flex-direction: column;
  gap: 128px;
  align-items: center;

  & > h1 {
    margin: 0;
    color: #000;
    line-height: 65px;
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 300;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    & > span {
      max-width: 512px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      font-style: italic;
      line-height: 29.9px;
      color: #222325;
    }

    & > button,
    & > a {
      border-radius: 100px;
    }
  }
}
</style>
