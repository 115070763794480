<template>
  <v-col cols="12" class="campaign-settings-user">
    <v-row dense align="center">
      <v-col class="flex-grow-1 flex-shrink-0">
        <div v-if="userFullName" class="campaign-settings-user__user-info">
          {{ userFullName }}
        </div>
        <div v-if="user.email" class="campaign-settings-user__user-email">
          {{ user.email }}
        </div>
      </v-col>
      <v-col
        class="flex-grow-0 flex-shrink-1 d-flex align-center pointer"
        @click="$emit('remove', user)"
      >
        <icons-delete :width="14" :height="16" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'TheCampaignSettingsPanelUser',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    userFullName () {
      const { firstname, lastname } = this.user

      let result = ''
      if (firstname) result += `${firstname} `
      if (lastname) result += `${lastname}`

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-settings-user {
  padding: 20px 0;

  &__user-info {
    font-weight: 700;
    line-height: 18px;
    font-size: 15px;
    color: #000;
  }

  &__user-email {
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: #95979D;
    padding-top: 8px;
  }
}
</style>
