<template>
  <ul>
    <li>
      <b>Order #</b>
      {{ orderNumber }}
    </li>
    <li>
      <b>Date</b>
      {{ orderDate | dateFilter }}
    </li>
    <li v-if="orderFrom">
      <b>From</b>
      {{ orderFrom }}
    </li>
    <li v-if="orderShipTo">
      <b>Ship to</b>
      <template v-if="isOrderShipToAnArray">
        <template v-if="numberOfRecipients === 1">
          {{ orderShipTo[0].first_name }} {{ orderShipTo[0].last_name }}
        </template>

        <template v-else-if="numberOfRecipients > 1">
          <v-popover
            placement="top"
            trigger="hover"
            :container="false"
          >
            <a role="button">
              {{ numberOfRecipients }} Recipients
            </a>
            <recipients-list slot="popover" :recipients="orderShipTo" />
          </v-popover>
        </template>
      </template>

      <template v-else-if="!isOrderShipToAnArray">
        <v-popover
          placement="top"
          trigger="hover"
          :container="false"
        >
          <a role="button">
            {{ orderShipTo.first_name }} {{ orderShipTo.last_name }}
          </a>

          <recipient-info slot="popover" :address="orderShipTo" />
        </v-popover>
      </template>
    </li>
    <li>
      <b>Source</b>
      {{ orderSource }}
    </li>
  </ul>
</template>

<script>
import RecipientsList from './TheMyOrdersTableDetailsRecipients'
import RecipientInfo from './TheMyOrdersTableDetailsRecipient'

export default {
  name: 'TheMyOrdersTableDetails',
  components: {
    RecipientInfo,
    RecipientsList,
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  computed: {
    orderNumber () {
      return this.order?.number ?? null
    },
    orderDate () {
      return this.order?.date ?? null
    },
    orderFrom () {
      return this.order?.from?.trim() ?? null
    },
    orderShipTo () {
      return this.order?.ship_to ?? null
    },
    isOrderShipToAnArray () {
      return Array.isArray(this.orderShipTo)
    },
    numberOfRecipients () {
      return this.orderShipTo?.length ?? 0
    },
    orderSource () {
      return this.order?.source ?? null
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  gap: 8px;
  display: flex;
  list-style: none;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  color: #000;

  & > li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    word-break: break-word;
    gap: 4px;

    & > b {
      display: flex;
      flex: 0 1 60px;
      min-width: 60px;
      font-weight: 700;
    }

    & a {
      color: #219358 !important;
      white-space: initial;
    }
  }
}
</style>
