import { render, staticRenderFns } from "./TheAssignCampaignTagsCombobox.vue?vue&type=template&id=6cd5f430&"
import script from "./TheAssignCampaignTagsCombobox.vue?vue&type=script&lang=js&"
export * from "./TheAssignCampaignTagsCombobox.vue?vue&type=script&lang=js&"
import style0 from "./TheAssignCampaignTagsCombobox.vue?vue&type=style&index=0&id=6cd5f430&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VChip,VCombobox})
