<template>
  <div class="add-to-group__list">
    <div class="list-items">
      <div
        v-for="(item, index) in groups"
        class="list-items__item"
        :class="isItemSelected(item.entity_id) && 'list-items__item--selected'"
        @click="selectItem(item.entity_id)"
        :key="index"
      >
        <icons-check-circle
          height="22"
          width="22"
          :filled="isItemSelected(item.entity_id)"
          :checkStrokeWidth="1.4"
        />
        <div class="list-items__item--text">
          {{ item.name }}
        </div>
      </div>
    </div>

    <common-input
      v-if="!hideAddNewGroup"
      v-model="theNewGroupName"
      placeholder="+ Enter New Group"
      height="30"
      min="2"
      :hideDetails="false"
      hint="Min 2 characters"
    />
  </div>
</template>

<script>
import { groups } from './mixins'

export default {
  name: 'SelectGroupList',
  mixins: [
    groups
  ],
  props: {
    selectedGroups: {
      type: Array,
      required: true
    },
    newGroupName: {
      type: String,
      required: false,
      default: null
    },
    hideAddNewGroup: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    theSelectedGroups: {
      get () {
        return this.selectedGroups
      },
      set (val) {
        this.$emit('update:selectedGroups', val)
      }
    },
    theNewGroupName: {
      get () {
        return this.newGroupName
      },
      set (val) {
        this.$emit('update:newGroupName', val)
      }
    }
  },
  methods: {
    isItemSelected (item) {
      return this.theSelectedGroups.includes(item)
    },
    selectItem (item) {
      const { theSelectedGroups } = this
      if (this.isItemSelected(item)) {
        const newSelected = theSelectedGroups.filter((sItem) => sItem !== item)
        this.theSelectedGroups = newSelected
      } else {
        const newSelected = theSelectedGroups
        newSelected.push(item)
        this.theSelectedGroups = newSelected
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-group {
  &__list {
    background-color: #fff;
    min-height: 100px;
    min-width: 140px;
    max-width: 800px;
    bottom: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    .list-items {
      min-height: 0px;
      max-height: 200px;
      overflow: hidden auto;
      display: flex;
      flex-direction: column;

      &__item {
        font-weight: 400;
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        color: #16181F;
        word-break: break-word;
        padding: 8px;
        transition: background-color ease-in-out 0.2s;
        border-top: 1px solid #D8D8D8;

        &:last-child {
          border-bottom: 1px solid #D8D8D8;
        }

        &--text {
          width: calc(100% - 22px);
        }

        &--selected {
          background-color: #FAFAFA;
        }
      }
    }
  }
}
</style>
