<template>
  <div class="aggregated-card">
    <div class="aggregated-card__title">
      <div class="aggregated-users__title">
        Users
        <div class="aggregated-users__badge">{{ itemsTotal }}</div>
      </div>

      <dashboard-card-pagination
        v-model="currentPage"
        :max-length="totalPages"
        v-if="totalPages > 1"
      />
    </div>

    <div class="aggregated-card__divider" />

    <div class="aggregated-card__content" style="gap: 20px">
      <div v-for="item in itemsToDisplay" :key="item.id" class="aggregated-users__item">
        <icons-user :width="24" :height="24" />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import aggregatedViewWithPages from './mixins/aggregatedViewWithPages'

export default {
  name: 'TheAggregatedViewUsers',
  mixins: [aggregatedViewWithPages],
  data: () =>({
    itemsPerPage: 4,
  }),
}
</script>

<style lang="scss" scoped>
@import "../styles/aggregated-card.scss";

.aggregated-users {
  &__title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: inherit;
    align-items: center;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
  }

  &__badge {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
    min-width: 22px;
    padding: 2px 6px 0;
    height: 22px;
    background: #007E76;
    border-radius: 48px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    line-height: 35px;
    align-items: center;
  }
}
</style>
