<template>
  <div class="payment-module-credit-cards-container">
    <div class="change-payment-method-link" @click="$emit('update:step', 8)">
      + Add new credit card
    </div>

    <credit-card-item
      v-for="card in availableCreditCards"
      :key="card.binNumber"
      :data="card"
      hide-default-icon
      :isSelected="card.binNumber === selectedCardHelper"
      :class="{ 'credit-card-item--is-selected': card.binNumber === selectedCardHelper }"
      @click.native="selectedCardHelper = card.binNumber"
      @deletePaymentMethod="onDeleteCreditCard(card.binNumber)"
    />

    <cg-button :disabled="!selectedCardData" @click="onContinue">Continue</cg-button>
  </div>
</template>

<script>
import PaymentType from '../utils/PaymentType'

import CreditCardItem from '@/components/payment/CreditCardItem.vue'
import { CgButton } from '@corporategift/design-system'

export default {
  name: 'PayWithBluesnapCard',
  components: {
    CreditCardItem,
    CgButton,
  },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    availableCreditCards: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    // it will use binNumber since it is a unique value
    selectedCardHelper: null,
  }),
  computed: {
    defaultCreditCard () {
      const { availableCreditCards } = this
      return availableCreditCards.find(({ is_default }) => is_default) ?? null
    },
    selectedCardData () {
      const { selectedCardHelper, availableCreditCards } = this

      if (!selectedCardHelper) { return null }
      return availableCreditCards.find((cardData) => cardData.binNumber === selectedCardHelper)
    },
  },
  created () {
    const { paymentData, defaultCreditCard } = this

    if (paymentData && paymentData?.token) {
      this.selectedCardHelper = paymentData?.binNumber
    } else if (defaultCreditCard) {
      this.selectedCardHelper = defaultCreditCard?.binNumber ?? null
    }
  },
  methods: {
    onContinue () {
      const { selectedCardData } = this

      if (selectedCardData) {
        this.$emit('onCardSelect', PaymentType.CC_Bluesnap, selectedCardData);
      }
    },
    onDeleteCreditCard (cardFourDigits) {
      this.$emit('deleteBluesnapCreditCard', cardFourDigits)
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-module-credit-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > .credit-card-item {
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color ease-in-out 0.26s;

    &:not(&--is-selected):hover {
      background-color: #F4F4F4;
    }

    &--is-selected {
      border-color: #D3D2D2;
      cursor: default;
      background-color: #fff;
    }
  }

  & .change-payment-method-link {
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    color: #42B77A;
  }

  & .cg-button {
    margin-top: 20px;
    border-radius: 100px;
  }
}
</style>
