<template>
  <div
    class="common-checkbox"
    :class="{
      'common-checkbox--disabled': disabled
    }"
    :aria-disabled="disabled && 'disabled'"
    @click="!disabled ?checkboxValue = !checkboxValue : null"
  >
    <div
      class="common-checkbox__checkbox"
      :class="{
        'common-checkbox__checkbox--active': value,
        'common-checkbox__checkbox--disabled': disabled
      }"
      role="checkbox"
      :aria-checked="value"
    />
    <div
      class="d-flex common-checkbox__content"
      :class="{
        'common-checkbox__content--bold': boldText,
        'align-center': centerContent
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonCheckbox',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    boldText: {
      type: Boolean,
      required: false,
      default: false
    },
    centerContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    checkboxValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.common-checkbox {
  cursor: pointer;
  font-weight: 400;
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  &--disabled {
    cursor: default;
  }

  &__checkbox {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
    margin-top: 1px;
    border: 1px solid #DEDEDE;
    background-color: #fff;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: inline-flex;

    &--active {
      border: none;
      background-color: #42B77A;

      &::after {
        content: ' ';
        width: 6px;
        height: 10px;
        margin-top: -2px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    &--disabled {
      background-color: #F4F4F4;
      border-color: #D3D2D2;
    }
  }

  &__content {
    color: #000 !important;
    font-size: 15px;
    min-height: 20px;
    user-select: none;

    &--bold {
      font-weight: 700;
    }
  }
}
</style>
