<template>
  <v-img
    :alt="name"
    :src="getImagePath()"
    :width="width"
    contain
    :height="height"
    :max-height="maxHeight"
    class="relative v-image--with-padding"
    content-class="product-responsive-content"
    :class="{
      'v-image--hide-border': hideBorder,
      'v-image--hide-border-right': hideBorderRight
    }"
  >
    <div
      class="product_out_of_stock"
      v-if="isOutOfStock"
    >
      <div
        class="product_out_of_stock__message"
        :class="{
          uppercase: outOfStockUppercase,
          'product_out_of_stock__message--medium-size': medium
        }"
      >
        Out of stock
      </div>
    </div>

    <div
      class="product_from_inventory"
      :class="{ 'product_from_inventory--small': smallInventoryIcon }"
      v-if="isFromInventory"
    >
      <img
        src="/images/left_menu_icons/inventory-icon.svg"
        :width="smallInventoryIcon ? 8 : 20"
        :height="smallInventoryIcon ? 8 : 20"
      />
    </div>
  </v-img>
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    image: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    isOutOfStock: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: [Number, String],
      required: false
    },
    height: {
      type: [Number, String],
      required: false
    },
    maxHeight: {
      type: [Number, String],
      required: false
    },
    hideBorderRight: {
      type: Boolean,
      required: false,
      default: false
    },
    hideBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    outOfStockUppercase: {
      type: Boolean,
      required: false,
      default: true
    },
    medium: {
      type: Boolean,
      required: false,
      default: false
    },
    isFromInventory: {
      type: Boolean,
      required: false,
      default: false
    },
    smallInventoryIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    imageUrl: 'https://cf.corporategift.com/media/catalog/product'
  }),
  methods: {
    getImagePath () {
      const { image, imageUrl } = this
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : imageUrl + image
    }
  }
}
</script>

<style lang="scss">
.v-image--with-padding {
  .v-image__image {
    background-origin: content-box;
    padding: 4px;
  }
}

.product-responsive-content {
  width: unset !important;
}
</style>

<style lang="scss" scoped>
.v-image {
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  background-color: #fff;

  &--hide-border {
    border: none;
  }

  &--hide-border-right {
    border-right: none;
  }
}
.product_out_of_stock {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative;

  &__message {
    max-width: 100px;
    width: 100%;
    height: 27px;
    background-color: #f15b5b;
    display: grid;
    place-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fff;

    &--medium-size {
      font-size: 13px !important;
    }

    .uppercase {
      text-transform: uppercase;
    }
  }
}

.product_from_inventory {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #555555;

  &--small {
    width: 16px;
    height: 16px;
  }
}
</style>
