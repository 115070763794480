<template>
  <div class="create-campaign-name">
    <h1>Name your new campaign</h1>

    <cg-input
      v-model.trim="campaignName"
      ref="campaignNameInput"
      :width="420"
      :validate-on-blur="false"
      :validation="campaignNameValidation"
    >
      <template #hint-message>
        e.g. "Employee onboarding 2024" (You can edit this later)
      </template>
    </cg-input>

    <cg-button @click="goToNextPage">Continue to add gifts</cg-button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import campaignNameValidation from '@/components/createCampaign/mixins/campaignNameValidation'

import { CgInput, CgButton } from '@corporategift/design-system'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignName',
  mixins: [campaignNameValidation],
  components: {
    CgInput,
    CgButton,
  },
  computed: {
    ...mapState(['form']),
    campaignName: {
      get () { return this.form.name },
      set (val) { this.setCampaignName(val) },
    }
  },
  // mounted () {
  //   this.$nextTick(() => {
  //     const campaignNameInput = this.$el.querySelector('input');
  //     if (campaignNameInput) { campaignNameInput.focus() }
  //   })
  // },
  methods: {
    ...mapMutations(['setCampaignName']),
    goToNextPage () {
      if (this.$refs.campaignNameInput.validate()) {
        this.$router.push({ name: 'CreateCampaignSource', params: this.$route.params })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-name {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  & > .cg-input {
    min-width: 420px;
  }

  &::v-deep .cg-input__hint-message,
  &::v-deep .cg-input__error-message {
    font-size: 15px;
    line-height: 18px;
  }

  & > button,
  & > a {
    min-width: 200px;
    margin-top: 20px;
    border-radius: 100px;
  }

  @media screen and (max-width: 430px) {
    width: 100%;

    & > .cg-input {
      min-width: unset;
      width: 100%;
    }
  }
}
</style>